import React, { useEffect, useState } from "react";
import { Grid, Button} from "@material-ui/core";
import MetricIndicator from "../MetricIndicator";
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import styles from "../styles.module.scss";
import { METRICS } from "../../../constants";
import { NumberFound } from "libphonenumber-js";

function formatCurrencyWithCommas(number: Number) {
  if (typeof number !== "number") {
    return "";
  }

  const formattedNumber = number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formattedNumber;
}

function formatNumberToCurrencyM(number: Number) {
  if (typeof number !== "number") {
    return "";
  }

  const millionValue = number / 1_000_000;
  const formattedValue = millionValue.toFixed(1); // Round to 1 decimal place

  return `$${formattedValue}M`;
}

function formatDecimalToPercentage(decimalValue: Number) {
  if (typeof decimalValue !== "number") {
    return "";
  }

  const percentageValue = (decimalValue * 100).toFixed(0); // Convert decimal to percentage and round
  return `${percentageValue}%`;
}

function formatToPercentage(number: Number) {
  if (typeof number !== "number") {
    return "";
  }

  const formattedPercentage = Math.round(number) + "%";
  return formattedPercentage;
}

const keysToFilter = ["Key Metrics - ARR", "Key Metrics - Headcount", "Key Metrics - Total Raised",
"Key Metrics - ARR Growth", "Key Metrics - Headcount Growth", "Key Metrics - Last Valuation"]

const MainSection = ({ keyMetricsData }: any) => {
  const [filteredObject, setFilteredObject] = useState({} as any);
  const [collapsedData, setCollapsedData] = useState({} as any);
  const [isShowAllOutlooks, setIsShowAllOutlooks] = useState(false);

  useEffect(() => {
    const result = Object.fromEntries(
      Object.entries(keyMetricsData[0]).filter(
        ([key, value]) =>
          !(
            key.includes("Color") ||
            key.includes("Green") ||
            key.includes("Red") || 
            keysToFilter.includes(key)
          )
      )
    );
    const firstResults = Object.fromEntries(
      Object.entries(keyMetricsData[0]).filter(
        ([key, value]) =>
        keysToFilter.includes(key)
      )
    );
    setFilteredObject(result);
    setCollapsedData(firstResults)
  }, []);
  const formatMetric = (metric: string, metricsData: any) => {
    const sign = METRICS[metric];

    let label = metricsData[0][metric];
    if (label) {
      label = String(Math.round(label));
    }
    return `${sign}${label}`;
  };

  return (
    <section className={styles.mainSection}>
      <Grid container spacing={0}>
        {Object.keys(isShowAllOutlooks ? {...collapsedData, ...filteredObject} : collapsedData)
          .map((metric, index) => {
            if (
              metric.startsWith("Key Metrics Color") ||
              metric === "Key Metrics Green" ||
              metric === "Key Metrics Red"
            ) {
              return <></>;
            }
            return (
              <Grid item xs={4} key={index}>
                <div className={`${styles.metricItem} font-12`}>
                  <span>{metric.replace("Key Metrics - ", "")}</span>
                  <MetricIndicator
                    color={
                      keyMetricsData[0][
                        `Key Metrics Color - ${metric.replace(
                          "Key Metrics - ",
                          ""
                        )}`
                      ]
                    }
                    // label={formatMetric(metric, keyMetricsData[0])}
                    label={
                      metric === "Key Metrics - Total Raised / Headcount" ||
                      metric === "Key Metrics - ARR / Headcount"
                        ? formatCurrencyWithCommas(keyMetricsData[0][metric])
                        : metric === "Key Metrics - Total Raised" ||
                          metric === "Key Metrics - ARR" ||
                          metric === "Key Metrics - Last Valuation"
                        ? formatNumberToCurrencyM(keyMetricsData[0][metric])
                        : metric ===
                          "Key Metrics - Total Raised / Last Valuation"
                        ? formatDecimalToPercentage(keyMetricsData[0][metric])
                        : metric === "Key Metrics - ARR Growth" ||
                          metric === "Key Metrics - Headcount Growth"
                        ? formatToPercentage(keyMetricsData[0][metric])
                        : keyMetricsData[0][metric]
                    }
                  />
                </div>
              </Grid>
            );
          })}
      </Grid>
      <Button onClick={()=> setIsShowAllOutlooks(!isShowAllOutlooks)} id="blockElement">
        {isShowAllOutlooks ? (
          <>
            <span className={`${styles.collapseBtn} font-12`}>Collapse</span>
            <ArrowDropUp />
          </>
        ) : (
          <>
            <span className={`${styles.collapseBtn} font-12`}>View All</span>
            <ArrowDropDown />
          </>
        )}
      </Button>
    </section>
  );
};

export default MainSection;
