import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    makeStyles,
} from '@material-ui/core';

import ModalForm from '../../ModalForm';
import SuspenseLoading from '../../SuspenseLoading';

import { getBenchmarks } from '../../../store/sourcing/sourcingThunks';
import { selectBenchmarks } from '../../../store/sourcing/sourcingSlice';

import styles from './styles.module.scss';
import { METRICS_COLOR_MAP } from '../../../constants';

interface BenchmarksProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const Benchmarks: React.FC<BenchmarksProps> = ({
    isOpen = true,
    setIsOpen,
}) => {
    const [sortedBenchmarks, setSortedBenchmarks] = useState<any>(null);
    const dispatch = useDispatch();
    const benchmarks = useSelector(selectBenchmarks);

    const handleClose = () => {
        setSortedBenchmarks(null);
        setIsOpen(false);
    };

    const sortBenchmarks = () => {
        let sortedData: any = [];

        sortedData = benchmarks.reduce((result: any, item: any) => {
            const metric = item.Metric;
            if (result[metric]) {
                result[metric].push(item);
            } else {
               
                result[metric] = [item];
            }
            return result;
        }, {});

        setSortedBenchmarks(sortedData);
    }

    useEffect(() => {
        if (isOpen) {
            dispatch(getBenchmarks());
        }
    }, [isOpen]);

    useEffect(() => {
        if (benchmarks.length) {
            sortBenchmarks();
        }
    }, [benchmarks])

    return (
        <ModalForm
            open={isOpen}
            handleClose={handleClose}
            maxWidth="md"
            fullWidth={true}
        >
            <div className="border-0">
                <div className="card-body px-lg-5 py-lg-5">
                    <span className={`${styles.mainTitle} font-14`}>
                        Benchmarks
                    </span>

                    <section className={styles.benchmarks}>
                        <span className={`${styles.secondaryTitle} font-14`}>
                            Key Metrics Benchmark
                        </span>

                        {sortedBenchmarks ? (
                            <div className={styles.benchmarksLayout}>
                                {Object.keys(sortedBenchmarks).map((benchmark: any) => {
                                    return (
                                        <div className={styles.row} key={benchmark}>
                                            <div className={`${styles.cellHead} font-12`}>{benchmark}</div>
                                            {sortedBenchmarks[benchmark].map((cell: any, index: number) => (
                                                <div className={styles.cell} key={index}>
                                                    <span
                                                        className={styles.colorIndicator}
                                                        style={{ backgroundColor: METRICS_COLOR_MAP[cell.Color.toLocaleLowerCase()] }}
                                                    >
                                                    </span>
                                                    <span className='font-12'>{cell['Range Label']}</span>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <SuspenseLoading />
                        )}
                    </section>
                </div>
            </div>
        </ModalForm>
    )
};

export default Benchmarks;
