export interface CompanyFilterOptions {
    // CRM
    owner: any;
    sectors: any;
    status: any;
    "months since last contact": any;
    "in sequence?": any;
    "last sequence enrolled": any;
    "months since last sequence": any;
    // Demographics
    headcount: any;
    "headcount growth": any;
    region: any;
    "year founded": any;
    // Fundraising
    "total raised": any;
    // monthsSinceLastFundraise: any; todo need much maybe -> "months since last funding "
    // valuation: any; todo need much maybe -> "recent valuation"
    // totalRaisedValuation: any; todo need much -> maybe "total raised / last valuation"
    // totalRaisedHeadCount: any; todo dont much with comes value
    // Investors
    investors: any;
    "key investors": any;
    "key people": any;
    "Has FPC Relationship": any;
    "Has Target Entrepreneurs": any;
    // Financials
    "customer count": any;
    "Recent Valuation ($M)": any;
    arr: any;
    // arrGrowth: any; todo dont much with comes value
    cash: any;
    "cash burned": any;
    technology: any;
    "Last Meeting Date": any;
    "Last Contact Date": any;
    "Last Sequence Enrolled": any;
    "Next Meeting Date": any;
    Region: any;
    City: any;
    "Year Founded": any;
    "Headcount": any;
    "Headcount growth": any;
    "Total Raised ($M)": any;
    "Recent Valuation": any;
    "Recent Investment Stage": any;
    "Months Since Last Funding": any;
    "Ownership Status": any;
    "Total Raised / Last Valuation": any;
    "Total raised / Hedcount": any;
    "Investors": any;
    "Relationships": any;
    "Customer count": any;
    "ARR ($M)": any;
    "Arr / Headcount": any;
    "Saas Metrics As Of Date": any;
    "Key Words": any;
    "Tags": any;
}

export interface  INullableCompanyFilters {
    "nullable_technology": boolean,
    "nullable_owner": boolean,
    "nullable_sectors": boolean,
    "nullable_status": boolean,
    "nullable_Last Meeting Date": boolean,
    "nullable_Last Contact Date": boolean,
    "nullable_Last Sequence Enrolled": boolean,
    "nullable_Next Meeting Date": boolean,
    "nullable_in sequence?": boolean,
    "nullable_Has FPC Relationship": boolean;
    "nullable_Has Target Entrepreneurs": boolean;
    "nullable_Region": boolean,
    "nullable_City": boolean,
    "nullable_Year Founded": boolean,
    "nullable_Headcount": boolean,
    "nullable_Headcount growth": boolean,
    "nullable_Total Raised ($M)": boolean,
    "nullable_Recent Valuation ($M)": boolean,
    "nullable_Recent Investment Stage": boolean,
    "nullable_Months Since Last Funding": boolean,
    "nullable_Ownership Status": boolean,
    "nullable_Total Raised / Last Valuation": boolean,
    "nullable_Total raised / Hedcount": boolean,
    "nullable_Investors": boolean,
    "nullable_Relationships": boolean,
    "nullable_Customer count": boolean,
    "nullable_ARR ($M)": boolean,
    "nullable_Arr / Headcount": boolean,
    "nullable_Saas Metrics As Of Date": boolean,
    "nullable_Tags": boolean
}

export enum FilterHelpers  {
    MISSING_MAIN_FILTER = 'MISSING_MAIN_FILTER'
}


// TODO data item example
// {
//     "cinchy id": number, // 314524
//     "company name": string, //"Entersekt"
//     "in sequence?": null, //null
//     "last sequence enrolled": null, //null
//     "last contact date": number, //107
//     "last meeting date": number, //107
//     "months since last sequence": null, //null
//     "owner": string, //"Casilda Obieta <cangulo@forgepointcap.com>"
//     "status": string, //"Backlog"
//     "city": string, // "Stellenbosch"
//     "description": string, // "Developer of security and authentication software intended for financial institutions and consumers. The company offers transaction authentication and mobile application security services by harnessing the power of digital certificate technology, enabling consumers to transact securely and easily, on any digital channel, from any device."
//     "headcount": number, //208
//     "headcount growth": string, //"tbd"
//     "linkedin url": string, //"https://www.linkedin.com/company/entersekt"
//     "pitchbook url": string, //"https://my.pitchbook.com/profile/86902-93/company/profile"
//     "region": string, //"South Africa"
//     "year founded": number, //2008
//     "arr": null, //null
//     "arr / headcount": string, //"500000"
//     "cash": null, //null
//     "cash burned": null, //null
//     "customer count": null, //null
//     "saas metrics as of date": string, //"9/30/2022"
//     "last funding date": string, //"Jun 14, 2022 12:00:00 AM"
//     "months since last funding": null, //null
//     "ownership status": string, //"Privately Held (backing)"
//     "recent investment stage": string, //"PE Growth/Expansion"
//     "recent valuation": null, //null
//     "total raised": null, //null
//     "total raised / last valuation": string,  //".5"
//     "investors": string, // "Accel-KKR,Nedbank,PAPE Fund Managers,BoE Private Equity Investments,Endeavor Catalyst,Ramzi Mansour,AlphaCode (South Africa)"
//     "key investors": string, //"tbd"
//     "key people": string, //"tbd"
//     "category": string, //"Fintech, Identity"
//     "key words": string, //"customer authentication, identity security, passwordless, secure payments"
//     "sub category": string, //"Fintech Infrastructure, Identity Access Management"
//     "technology": string, //"Fintech Infrastructure, Identity Access Management"
//     "green flag count": string, //"tbd"
//     "red flag count": string, //"tbd"
//     "tags": null, //null
// }