import React, { memo, useEffect, useState } from 'react';
import { useField } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(2),
        },
    },
    input: {
        height: 14,
        borderRadius: 4,
        position: 'relative',
        border: '1px solid hsl(0, 0%, 70%)',
        padding: '10px 26px 10px 12px',
        background: 'white',
        fontSize: 16,
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#6e3cfa',
        },
    },
}))(InputBase);

const inputLabel = {
    color: '#6e3cfa',
}

function Range({ onChange, name, label, isReset, selectedRange, ...props }) {
    const [field, , helpers] = useField(props.field);
    // const [min, setMin] = useState(field.value?.[0]);
    // const [max, setMax] = useState(field.value?.[1]);


    useEffect(() => {
        // if(selectedRange) {
        //     const CName = capitalizeFirstLetter(name || '');
        //     if(selectedRange?.[field.name]) {
        //         selectedRange?.[field.name]?.[0] !== 'N/A' && setMin(selectedRange?.[field.name]?.[0]);
        //         selectedRange?.[field.name]?.[1] !== 'N/A' && setMax(selectedRange?.[field.name]?.[1]);
        //     }
        // }
    }, [selectedRange, name])

    useEffect(() => {
        if(isReset) {
            helpers.setValue([0, 0]);
        }
    }, [isReset]);

    const handleMinChange = (event) => {
        console.log('Log ::: event.target.value ===', event.target.value);
        // setMin(event.target.value)
        helpers.setValue([event.target.value || 'N/A', field.value?.[1] || 'N/A'])
    }

    const handleMaxChange = (event) => {
        console.log('Log ::: event.target.value ===', event.target.value);
        // setMax(event.target.value)
        helpers.setValue([field.value?.[0] || 'N/A',event.target.value || 'N/A'])
    }

    return (<div className="mt-2">
        <span className="font-14 flex-1">{label}</span>
        <div className="d-flex font-12">
            <FormControl className="mr-1">
                {/*<InputLabel style={inputLabel} type='number' htmlFor="min">Min</InputLabel>*/}
                <BootstrapInput
                    name={name}
                    type='number'
                    value={field.value?.[0]}
                    placeholder='Min'
                    onChange={handleMinChange}
                    onBlur={() => {
                        helpers.setTouched(true);
                        props.onBlur && props.onBlur();
                    }}
                />
            </FormControl>
            <FormControl  className="ml-2">
                {/*<InputLabel style={inputLabel} type='number' htmlFor="max">Max</InputLabel>*/}
                <BootstrapInput
                    name={name}
                    type='number'
                    value={field.value?.[1]}
                    placeholder='Max'
                    onChange={handleMaxChange}
                    onBlur={() => {
                        helpers.setTouched(true);
                        props.onBlur && props.onBlur();
                    }}
                />
            </FormControl>
        </div>
        </div>);
}

export default memo(Range);
