import React, { useState } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import ModalForm from '../ModalForm';
import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { useNotification } from '../../hooks/useNotification';

export const addCompany = async ({ description, website, name, input }) => {
        const queryParams = {
            'description': description,
            'website': website,
            'name': name,
        };

    const queryString = new URLSearchParams(queryParams).toString();

    const { response, status }  = await getData(`${QUERIES.COMPANY.ADD_COMPANY}?${queryString}`);
    if(status === STATUSES.SUCCESS) {
        return response.payload;
    }
}

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
    }
}));

export default function CreateCompanyModal (props) {
    const { isOpen, onClose } = props;
    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const classes = useStyles();

    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleAddCompany = () => {
        addCompany({ description, website, name }).then(() => {
            openNotification({
                open: true,
                message: 'Company Successfully Added',
            });
        }).then(() => {
            onClose();
        })
    };

    // “Add” button can’t be pressed unless “Company Website” and “Company Description” are filled out
    const isCreateDisabled =  website === '' || description === '';

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Add New Company
                            </h6>
                        </div>
                        <div>
                            <section>
                                <label className={`font-14`}>Company Website</label>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    fullWidth
                                    label={null}
                                    value={website}
                                    margin="dense"
                                    helperText="Required field"
                                    onChange={handleWebsiteChange}
                                />
                            </section>
                            <section>
                                <label className={`font-14`}>Company Name</label>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    fullWidth
                                    label={null}
                                    value={name}
                                    margin="dense"
                                    onChange={handleNameChange}
                                />
                            </section>
                            <section>
                                <label className={`font-14`}>Company Description</label>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    fullWidth
                                    label={null}
                                    multiline
                                    value={description}
                                    helperText="Required field"
                                    onChange={handleDescriptionChange}
                                />
                            </section>
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={handleAddCompany}
                        disabled={isCreateDisabled}
                    >
                        <span className='font-12'>Create Company</span>
                    </Button>
                </div>
            </ModalForm>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    );
}