import React from "react";
import styles from "./styles.module.scss";

const StatBox = ({
  title,
  value,
  valueColor,
  backgroundColor,
  hasBorder = false,
  sectionTitle = "",
  sectionColor=""
}: any) => {
  return (
    <>
      {sectionTitle ? (
        <div className={styles.boxContainer} style={{outline: `5px solid ${sectionColor}`}}>
          <div className={styles.sectionTitle} style={{backgroundColor: sectionColor}}>{sectionTitle}</div>
          <div
            className={hasBorder ? styles.borderBoxStyle : styles.boxStyle}
            style={{ backgroundColor: backgroundColor || "#f4f4f4",
            outline: `5px solid ${sectionColor}`,
            width: 'calc(100% + 40px)',
            marginLeft: '-20px',
            }}
          >
            <div className="font-14">{title}</div>
            <div
              className={`${styles.valueStyle} font-14`}
              style={{ color: valueColor || "black" }}
            >
              {value}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={hasBorder ? styles.borderBoxStyle : styles.boxStyle}
          style={{ backgroundColor: backgroundColor || "#f4f4f4", outline: hasBorder ? `5px solid ${sectionColor}` : undefined }}
        >
          <div className="font-14">{title}</div>
          <div
            className={`${styles.valueStyle} font-14`}
            style={{ color: valueColor || "black" }}
          >
            {value}
          </div>
        </div>
      )}
    </>
  );
};

export default StatBox;
