import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Button, makeStyles, TextField, MenuItem } from "@material-ui/core";
import {
  getKeyWords,
  getCompanyKeyWords,
  insertCompanyKeyWord,
  updateCompanyKeyWord,
} from "../../store/sourcing/sourcingThunks";
import {
  selectCompanyKeyWords,
  selectKeyWords,
  updateCompaniesData
} from "../../store/sourcing/sourcingSlice";
import ModalForm from "../ModalForm";
import styles from "./styles.module.scss";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
  inputField: {
    minWidth: "270px",
  },
}));

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  companyId: number;
  refreshApi?: () => void;
  activeTab?: string
  index?: number
};

const TaxonomyModal: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  companyId,
  refreshApi,
  activeTab,
  index
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [selectedKeyWords, setSelectedKeyWords] = useState<string[]>([]);
  const [keyWordsArray, setkeyWordsArray] = useState<string[]>([]);

  const keyWordsData = useSelector(selectKeyWords);
  const companyKeyWords = useSelector(selectCompanyKeyWords);

  useEffect(() => {
    dispatch(getKeyWords());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCompanyKeyWords(companyId));
      setDataRetrieved(true);
    };
  
    fetchData();
  }, [dispatch, companyId]);

  const handleClose = () => {
    setIsOpen(false);
    setDataRetrieved(false)
  };

  useEffect(() => {
    if(dataRetrieved) {
      setSelectedKeyWords(
        keyWordsData?.filter((k: any) => {
          return companyKeyWords.some((c: any) => {
            return c["Key Word Cinchy Id"] === k["Key Word Cinchy Id"] && c["Relevant"];
          });   
        })
      );
      setkeyWordsArray((prev) => {
        return keyWordsData?.filter(
          (k: any) => {
            return !companyKeyWords.some((c: any) => {
              return c["Key Word Cinchy Id"] === k["Key Word Cinchy Id"] && c["Relevant"];
            });
          }
        );
      });
      setSearchTerm("");
    }
  }, [keyWordsData, companyKeyWords, dataRetrieved]);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectKeyWord = (keyWord: any) => {
    if (!selectedKeyWords.includes(keyWord)) {
      setSelectedKeyWords([...selectedKeyWords, keyWord]);
    }
    const filteredKeyWords = keyWordsArray.filter(
      (c: any) => keyWord["Key Word Cinchy Id"] !== c["Key Word Cinchy Id"]
    );
    const filteredSuggestions = suggestions.filter(
      (c: any) => keyWord["Key Word Cinchy Id"] !== c["Key Word Cinchy Id"]
    );
    setSuggestions(filteredSuggestions);
    setkeyWordsArray(filteredKeyWords as any);
    setSearchTerm("");
  };

  const handleRemoveClick = (keyWordToRemove: any) => {
    const updatedSelectedKeyWords = selectedKeyWords.filter(
      (keyWord: any) => keyWord["Key Word Cinchy Id"] !== keyWordToRemove["Key Word Cinchy Id"]
    );
    setkeyWordsArray([...keyWordsArray, keyWordToRemove]);
    setSelectedKeyWords(updatedSelectedKeyWords);
  };

  useEffect(() => {
    let filteredKeyWords: any = [];
    if (searchTerm) {
      filteredKeyWords = keyWordsArray.filter((keyWord: any) =>
        keyWord["Key Word"]?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        keyWord["Tech Label Link.Tech"]?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setSuggestions(filteredKeyWords);
  }, [searchTerm]);

  const handleSubmit = useCallback(async () => {
    const onlyKeyWords = companyKeyWords.map((c: any) => {
      if(c["Key Words"] !== null) {
        return c["Key Words"]
      } 
      return c["Technology"]
    });
    let changeMade = false;
    const selectedOnlyKeyWords = selectedKeyWords.map(
      (c: any) => {
        if(c["Key Word"] !== null) {
          return c["Key Word"]
        } 
        return c["Tech Label Link.Tech"]
      }
    );
    await selectedOnlyKeyWords.forEach(async (s: any) => {
      if (!onlyKeyWords.includes(s)) {
        changeMade = true;
        await dispatch(
          insertCompanyKeyWord({
            companyId: companyId,
            keyWord: s,
          })
        );
      }
    });

    const transformed = selectedKeyWords.reduce((acc: any, item: any) => {
      Object.keys(item).forEach((key) => {
        const value = item[key];
        if (value !== null) {
          if (!acc[key]) {
            acc[key] = new Set();
          }
          acc[key].add(value);
        }
      });
      return acc;
    }, {});

    const transformedStrings = Object.keys(transformed).reduce((acc: any, key: any) => {
      acc[key] = Array.from(transformed[key]).join(', ');
      return acc;
    }, {});

    await companyKeyWords.forEach(async (c: any) => {
      if (selectedOnlyKeyWords.includes(c["Key Words"]) || selectedOnlyKeyWords.includes(c["Technology"])) {
        if (!c["Relevant"]) {
          if (
            !companyKeyWords.filter(
              (ck: any) => (ck["Key Words"] === c["Key Words"] || ck["Technology"] === c["Technology"]) && ck["Relevant"]
            ).length
          ) {
            changeMade = true;
            await dispatch(
              updateCompanyKeyWord({
                companyId: c["Cinchy Id"],
                relevant: "YES",
              })
            );
          }
        }
      } else {
        if (c["Relevant"]) {
          changeMade = true;
          await dispatch(
            updateCompanyKeyWord({
              companyId: c["Cinchy Id"],
              relevant: "NO",
            })
          );
        }
      }
    });
    setIsOpen(false);
    if(activeTab && index) {
        dispatch(updateCompaniesData({activeTab : activeTab, index: index, data: {
          "Category" : transformedStrings["Category"],
          "Sub Category" : transformedStrings["Sub Category.Sub Category"],
          "Technology" : transformedStrings["Tech Label Link.Tech"],
          "Key Words" : transformedStrings["Key Word"],
        }}))
    } else {
      if (changeMade) {
        refreshApi && refreshApi();
      }
    }
  }, [selectedKeyWords, companyKeyWords]);

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>Edit Taxonomy</h6>
          </div>
          <div className={styles.searchKeyWordsContainer}>
            <div className={styles.searchPartContainer}>
              <TextField
                type="text"
                placeholder="Search key words"
                className={classes.inputField}
                variant="outlined"
                margin="dense"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <div
                className={
                  !!suggestions.length ? styles.suggestedKeyWordContainer : ""
                }
              >
                {!!suggestions.length &&
                  suggestions.map((keyWord: any, index: number) => (
                    <div className={styles.suggestedKeyWord} 
                         onClick={() => handleSelectKeyWord(keyWord)} key={index}>
                      <span className={styles.suggestedKeyWordText}>
                        {keyWord["Key Word"] ? `${keyWord["Key Word"]} - ` : ''}{keyWord["Tech Label Link.Tech"]}
                      </span>
                    </div>
                  ))}
              </div>
            </div>

            <div className={styles.selectedKeyWordsContainer}>
              {!!selectedKeyWords.length && (
                <label>
                  <span className={styles.keyWordTitleText}>Key Words</span> -
                  <span className={styles.technologyTitleText}>Technology</span>{" "}
                  - <span className={styles.subCategoryTitleText}>
                    Sub Category
                  </span>{" "}
                  - <span className={styles.categoryTitleText}>Category</span>
                </label>
              )}
              {selectedKeyWords.map((keyWord: any) => (
                <div className={styles.selectedKeyWord}>
                  <span className={styles.companyKeyWord} key={keyWord}>
                    <span className={styles.selectedKeyWordText}>
                      {keyWord["Key Word"]?.replace(/\b\w/g, (match: string) =>
                        match.toUpperCase()
                      )}
                    </span>
                    {keyWord["Key Word"] ? " - " : ""}
                    <span className={styles.selectedTechnologyText}>
                      {keyWord["Tech Label Link.Tech"]}
                    </span>{" "}
                    -{" "}
                    <span className={styles.selectedSubCategoryText}>
                      {keyWord["Sub Category.Sub Category"]}
                    </span>{" "}
                    -{" "}
                    <span className={styles.selectedCategoryText}>
                      {keyWord["Category"]}
                    </span>
                  </span>
                  <Close
                    className={styles.closeButton}
                    onClick={() => handleRemoveClick(keyWord)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};
export default TaxonomyModal;
