// {columnName: "Cinchy Id", type: "Int64"}
// {columnName: "Full Name", type: "String"}
// {columnName: "Current Experiences", type: "String"}
// {columnName: "Past Experiences", type: "String"}
// {columnName: "FPC Contact", type: "String"}
// {columnName: "City", type: "String"}
// {columnName: "State", type: "String"}
// {columnName: "FPC Advisory Board", type: "Boolean"}
// {columnName: "FPC Employee", type: "Boolean"}
// {columnName: "Person URL", type: "String"}

import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';
import { SingleSelectFormik } from "../../components/SingleSelect/SIngleSelect";
import FreeSearch from '../../components/OpenTextSearch/OpenTextSearch';
export const peopleFilterFields = [
    {
        name: 'Full Name',
        label: 'Full Name',
        component: MultiselectFormik,
    },
    {
        name: 'Current Experiences',
        label: 'Current Experiences',
        component: FreeSearch,
    },
    {
        name: 'Past Experiences',
        label: 'Past Experiences',
        component: FreeSearch,
    },
    {
        name: 'Fpc Contact',
        label: 'FPC Contact',
        component: MultiselectFormik,
    },
    {
        name: 'City',
        label: 'City',
        component: MultiselectFormik,
    },
    {
        name: 'State',
        label: 'State',
        component: MultiselectFormik,
    },
    {
        name: 'fpc advisory board',
        label: 'FPC Advisory Board',
        component: SingleSelectFormik,
    },
    {
        name: 'fpc employee',
        label: 'FPC Employee',
        component: SingleSelectFormik,
    },
    {
        name: 'Person URL',
        label: 'Person URL',
        component: MultiselectFormik,
    }
]

export const PeopleStaticOptions = {
    'fpc employee': new Set(['Yes', 'No']),
    'fpc advisory board': new Set(['Yes', 'No'])
}