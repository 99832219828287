import React from 'react';
import {
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.module.scss';

const ModalCheckbox = ({ label, checked, handleChange }) => {
    return (
        <li>
            <FormControlLabel
                control={
                    <Checkbox
                        name="checkbox"
                        checked={checked}
                        color="primary"
                        onChange={handleChange}
                        icon={
                            <FontAwesomeIcon className={styles.selectedCheckbox} icon={['far', 'square']} />
                        }
                        checkedIcon={
                            <FontAwesomeIcon className={styles.selectedCheckbox} icon={['fas', 'square']} />
                        }
                    />
                }
                label={
                    <span className={`${styles.checkboxLabel} font-12`}>
                        {label}
                    </span>
                }
            />
        </li>
    )
};

export default ModalCheckbox;