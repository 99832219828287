import React, { useState, useCallback, useEffect } from "react";
import { Typography, Button, Tooltip, Switch } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  addCompanyContacts,
  deleteCompanyContacts,
  updateCompanyContacts,
} from "../../store/sourcing/sourcingThunks";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import Snackbar from "@material-ui/core/Snackbar";
import { Done, Close, Delete } from "@material-ui/icons";
import { formatEmptyCell } from "../../utils/utils";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "../../hooks/usePagination";
import Pagination from "../Pagination/Pagination";
import TSkeleton from "../TSkeleton/TSkeleton";

interface CompanyContactsProps {
  tableData: any;
  pagesDisplayed?: boolean;
  handleRefresh: () => void;
  companyId: number;
}

const CompanyContacts = ({
  tableData,
  pagesDisplayed = true,
  handleRefresh,
  companyId,
}: CompanyContactsProps) => {
  const [companyContactsData, setCompanyContactsData] = useState([] as any);
  const [rowToUpdate, setRowToUpdate] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);

  useEffect(() => {
    let tableHeaders = tableData.columns
    if(tableData.data.length) {
      tableHeaders = Object.keys(tableData.data[0]);
    }
    setHeaders(tableHeaders);
    setCompanyContactsData(
      tableData.data.map((row: any) => {
        let finalRow = [] as any;
        tableHeaders.map((header: any) => finalRow.push(row[header]));

        return finalRow;
      })
    );
  }, [tableData.data]);

  const [rowsToShow, setRowsToShow] = useState([] as any);
  const [insertedData, setInsertedData] = useState({} as any);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedRowIndex, setUpdatedRowIndex] = useState(-1);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;

  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: companyContactsData });

  const handleSelectDate = (date: Date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    setRowsToShow(companyContactsData.slice(startIndex, endIndex));
  }, [companyContactsData, currentPage]);

  const addRow = () => {
    setIsInsertOpen(true);
    let newRow = {} as any;
    headers.slice(2).forEach((header: string) => {
      newRow = { ...newRow, ...{ [header]: "" } };
    });
    setInsertedData(newRow);
  };

  const cancelInsert = () => {
    setIsInsertOpen(false);
  };

  const handleInputChange = useCallback(
    (value: any, columnName: string) => {
      setInsertedData((previous: any) => {
        previous[columnName] = value;
        return { ...previous };
      });
    },
    [setInsertedData]
  );

  const insertRow = useCallback(async () => {
    setIsInsertOpen(false);
    setIsLoading(true);
    await dispatch(
      addCompanyContacts({
        data: insertedData,
        companyId: companyId.toString(),
      })
    );
    openNotification({
      message: "Data inserted successfully",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 3000);
  }, [insertedData, selectedDate]);

  const handleRowUpdate = useCallback(
    (index: number) => {
      setRowToUpdate([...companyContactsData[index]]);
      setUpdatedRowIndex(index);
    },
    [companyContactsData]
  );

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (companyContactsData[index][0]) {
        await dispatch(deleteCompanyContacts(companyContactsData[index][0]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });

      handleRefresh();
    },
    [companyContactsData]
  );

  const cancelUpdate = useCallback(
    (index: number) => {
      setCompanyContactsData((previous: any) => {
        previous[index] = rowToUpdate;
        return [...previous];
      });
      setUpdatedRowIndex(-1);
    },
    [rowToUpdate]
  );

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const saveUpdate = useCallback(
    async (index: number) => {
      let finalData = {} as any;
      headers.map((col: string, i: number) => {
        finalData = {
          ...finalData,
          ...{ [col]: companyContactsData[index][i] },
        };
      });

      let isDataFilled = true;

      Object.keys(finalData).forEach((d: any) => {
        if (!finalData[d]) {
          isDataFilled = false;
          return;
        }
      });

      setUpdatedRowIndex(-1);
      setIsLoading(true);
      await dispatch(updateCompanyContacts(finalData));
      if (isDataFilled) {
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
      
      setTimeout(() => {
        handleRefresh();
        setIsLoading(false);
      }, 3000);
    },
    [companyContactsData, selectedDate]
  );

  const handleUpdateChange = useCallback(
    (value: any, rowIndex: number, colIndex: number) => {
      setCompanyContactsData((previous: any) => {
        previous[rowIndex][colIndex] = value;
        return [...previous];
      });
    },
    []
  );

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <div className={styles.companyContactsHeader}>
        <Typography style={{ whiteSpace: 'nowrap' }} className="mr-2 font-14 font-weight-bold">
          Company Contacts
        </Typography>
        {!isInsertOpen && (
          <div id="blockElement" className={styles.addNewEntryBtnContainer}>
            <Tooltip title="Add new entry" arrow placement="top">
              <Button
                id="blockElement"
                className="btn-primary mx-1 p-1 shadow-none border-0 d-inline-flex align-items-center justify-content-center"
                onClick={addRow}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="font-size-sm"
                />
                <span className="ml-1 font-10">Add New</span>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="table-responsive-md mb-spacing-2-x2">
        <Table
          className={`table table-hover table-striped table-bordered ${styles.table}`}
        >
          <thead className={styles.tableHead}>
            <tr className="font-12">
              {headers.map(
                (header: string, index: number) =>
                  index > 1 && <th key={index}>{header}</th>
              )}
              <th id="actions" className={`${styles.actionsColumn} font-12`}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {companyContactsData.slice(
                                    (currentPage - 1) * rowsPerPage,
                                    (currentPage - 1) * rowsPerPage + rowsPerPage
                                ).map((row: any, index: number) => {
              const rowIndex = (currentPage - 1) * 5 + index;
              return (
                <tr
                  className={
                    (rowIndex !== updatedRowIndex && updatedRowIndex !== -1) ||
                    isInsertOpen
                      ? styles.disabledRow
                      : ""
                  }
                  key={index}
                >
                  {row.map((col: any, colIndex: number) => {
                    if (colIndex > 1) {
                      return rowIndex !== updatedRowIndex ? (
                        <td key={colIndex} className="font-12">
                          {headers[colIndex] === "Primary Contact" ? (
                            isLoading ?  <TSkeleton numberOfRows={1} className="m-1"/> : <Checkbox
                              checked={col}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              disabled
                            />
                          ) : (
                            formatEmptyCell(col)
                          )}
                        </td>
                      ) : (
                        <td key={colIndex}>
                          {headers[colIndex] !== "Primary Contact" ? (
                            <input
                              type="text"
                              value={col}
                              className={styles.input}
                              onChange={(e) =>
                                handleUpdateChange(
                                  e.target.value,
                                  rowIndex,
                                  colIndex
                                )
                              }
                            />
                          ) : (
                            <Switch
                              checked={companyContactsData[rowIndex][colIndex]}
                              onClick={() =>
                                handleUpdateChange(
                                  !companyContactsData[rowIndex][colIndex],
                                  rowIndex,
                                  colIndex
                                )
                              }
                              className="switch-small toggle-switch-primary ml-auto"
                            />
                          )}
                        </td>
                      );
                    }
                  })}
                  <td id="actions" className="text-center">
                    {updatedRowIndex === -1 || rowIndex !== updatedRowIndex ? (
                      <>
                        <img
                          onClick={() => handleRowUpdate(rowIndex)}
                          className={styles.buttonClickable}
                          src="/edit.svg"
                          alt="Company Logo"
                          id="blockElement"
                        />

                        <Delete
                          className={styles.buttonClickable}
                          onClick={() => handleRowDelete(rowIndex)}
                        ></Delete>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 mr-1`}
                          onClick={() => saveUpdate(rowIndex)}
                        >
                          <Done/>
                        </Button>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 ml-1`}
                          onClick={() => cancelUpdate(rowIndex)}
                        >
                          <Close/>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            {isInsertOpen && (
              <tr>
                {Object.keys(insertedData).map((col, index) => (
                  <td key={index}>
                    {col === "Primary Contact" ? (
                      <Switch
                      checked={insertedData[col]}
                      onClick={() =>
                        handleInputChange(
                          !insertedData[col],
                          col
                        )
                      }
                      className="switch-small toggle-switch-primary ml-auto"
                    />
                    ) : (
                      <input
                        type="text"
                        value={insertedData[col]}
                        className={styles.input}
                        onChange={(e) => handleInputChange(e.target.value, col)}
                      />
                    )}
                  </td>
                ))}
                <td className="text-center">
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 mr-1`}
                    onClick={insertRow}
                  >
                    <Done/>
                  </Button>
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 ml-1`}
                    onClick={cancelInsert}
                  >
                    <Close/>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagesDisplayed && (
          <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
          handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
    </>
  );
};

export default CompanyContacts;
