import axios from 'axios';
import { getToken, setToken, removeToken } from '../helpers/auth';
import { camelizeKeys, decamelizeKeys } from '../helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://aphpse8fpa.execute-api.eu-north-1.amazonaws.com/dev/auth';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let isRefreshing = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let failedQueue: any[] = [];

const processQueue = (error: object | null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 400) {
      return {
        ...response,
        data: camelizeKeys(response.data),
      };
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      error.config &&
      error.response &&
      !originalRequest.retry
    ) {
      if (error.config?.url.includes('login')) {
        return Promise.reject(error);
      }
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return await api(originalRequest);
        } catch (err) {
          // eslint-disable-next-line no-return-await
          return await Promise.reject(err);
        }
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        api
          .post(
            '/api/v1/auth/refresh',
            {},
            {
              headers: { Authorization: `Bearer ${getToken()}` },
            }
          )
          .then(({ data: { data } }) => {
            setToken(data.accessToken);
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);
            resolve(api(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            removeToken();
            if (window) {
              window.location.reload();
            }

            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    // Ignore 'multipart/form-data' in this case
    if (config.headers['Content-Type'] !== 'multipart/form-data') {
      newConfig.data = decamelizeKeys(config.data);
      newConfig.params = decamelizeKeys(config.params);
    }

    return newConfig;
  },

  (error) => Promise.reject(error)
);

export default api;
