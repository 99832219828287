//@ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const themeOptionsSlice = createSlice({
  name: 'themeOptions',
  initialState: {
    sidebarFixed: true,
    sidebarFooter: true,
    sidebarShadow: false,
    sidebarStyle: 'app-sidebar--dark',
    sidebarUserbox: true,
    sidebarToggleMobile: false,
    sidebarToggle: false,
    sideBarWidth: 0,

    // Header

    headerFixed: true,
    headerShadow: true,
    headerBgTransparent: true,
    headerSearchHover: false,
    headerDrawerToggle: false,

    // Main content

    contentBackground: '',
    themeConfiguratorToggle: false,

    // Footer

    footerFixed: false,
    footerShadow: false,
    footerBgTransparent: true,

    // Page title

    pageTitleStyle: '',
    pageTitleBackground: '',
    pageTitleShadow: false,
    pageTitleIconBox: true,
    pageTitleDescription: true
  },
  reducers: {
    sidebarToggleMobile: (state, action) => {
      state.sidebarToggleMobile = action.payload;
    },

    sidebarToggle: (state, action) => {
      state.sidebarToggle = action.payload;
    },

    headerDrawerToggle: (state, action) => {
      state.headerDrawerToggle = action.payload;
    },

    setHeaderSearchHover: (state, action) => {
      state.headerSearchHover = action.payload;
    },

    setSideBarWidth: (state, action) => {
      state.sideBarWidth = action.payload;
    },
  },
});

export const {
  sidebarToggleMobile,
  sidebarToggle,
  headerDrawerToggle,
  headerSearchHover,
  setSideBarWidth,
} = themeOptionsSlice.actions;

export const setSidebarToggleMobile = toggle => dispatch => {
  dispatch(sidebarToggleMobile(toggle));
};

export const setSidebarToggle = toggle => dispatch => {
  dispatch(sidebarToggle(toggle));
};

export const setHeaderDrawerToggle = toggle => dispatch => {
  dispatch(headerDrawerToggle(toggle));
};

export const setHeaderSearchHover = toggle => dispatch => {
  dispatch(headerSearchHover(toggle));
};

export default themeOptionsSlice;
