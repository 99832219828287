import React, { ChangeEvent, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { TreeView } from "@material-ui/lab";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import { IndeterminateCheckBoxOutlined } from "@material-ui/icons";
import { TREE_LEVELS } from "../../constants";

const useStyles = makeStyles({
  treeItem: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '4rem',
  },
  icon: {
    cursor: 'pointer',
  },
  branch: {
    alignItems: 'center',
  },
  collapsed: {
    display: 'none',
  },
  expanded: {
    display: 'flex',
  },
  firstLevel: {
    marginLeft: '2rem',
  },
  checkbox: {
    color: '#6e3cfa !important',
    '& [class*="PrivateSwitchBase-input"]': {
      zIndex: 0,
    },
  },
  tech: {
    marginLeft: '-14px',
  },
  techContainer: {
    alignItems: 'start !important',
  },
  customLabelRoot: {
    alignItems: 'start !important',
  },
  customLabel: {
    marginTop: '7px !important',
    fontSize: '12px !important',
    fontFamily:  'Quicksand !important',
  },
}, { name: 'data-three' });

type DataTreeProps = {
  tree: any,
  handleChange: (event: ChangeEvent, level: number, key1: string, key2?: string, key3?: string) => void;
  styles?: any,
};

const DataTree: React.FC<DataTreeProps> = ({ tree, handleChange, styles }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<any>([[], [], []]);

  const expandElement = (level: number, index: number | string) => {
    const updated = [...expanded];
    updated[level].push(index);
    setExpanded(updated);
  };

  const collapseElement = (level: number, index: number | string) => {
    const elements = expanded[level].filter((el: number) => el !== index);
    const updated = [...expanded];
    updated[level] = elements
    setExpanded(updated);
  };

  const renderTree = (treeData: any) => {
    return (
      <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={<AddBoxOutlined />}
      >
        {Object.entries(treeData).map(([key1, value1]: any, index1) => {

          return (
            <section className={clsx(classes.branch, classes.expanded)} key={index1}>
              <article>
                {!expanded[TREE_LEVELS.ZERO].includes(key1) ? (
                  <AddBoxOutlined
                    className={classes.icon}
                    onClick={() => expandElement(TREE_LEVELS.ZERO, key1)}
                  />
                ) : (
                  <IndeterminateCheckBoxOutlined
                    className={classes.icon}
                    onClick={() => collapseElement(TREE_LEVELS.ZERO, key1)}
                  />
                )}
                <Checkbox className={classes.checkbox} name={key1} checked={value1.checked} onChange={(e) => handleChange(e, TREE_LEVELS.ZERO, key1)} />
                <span className={styles?.fontSize}>{key1}</span>

                {Object.entries(value1.data).map(([key2, value2]: any, index2) => {
                  return (
                    <section
                      className={clsx(classes.branch, classes.firstLevel, {
                        [classes.expanded]: expanded[TREE_LEVELS.ZERO].includes(key1),
                        [classes.collapsed]: !expanded[TREE_LEVELS.ZERO].includes(key1),
                      })}
                      key={index2}
                    >
                      <article>
                        {!expanded[TREE_LEVELS.FIRST].includes(key2) ? (
                          <AddBoxOutlined
                            className={classes.icon}
                            onClick={() => expandElement(TREE_LEVELS.FIRST, key2)}
                          />
                        ) : (
                          <IndeterminateCheckBoxOutlined
                            className={classes.icon}
                            onClick={() => collapseElement(TREE_LEVELS.FIRST, key2)} 
                          />
                        )}
                        <Checkbox className={classes.checkbox} name={key2} checked={value2.checked} onChange={(e) => handleChange(e, TREE_LEVELS.FIRST, key1, key2)}/>
                        <span className={styles?.fontSize}>{key2}</span>

                        <div className={clsx(classes.treeItem, {
                          [classes.expanded]: expanded[TREE_LEVELS.FIRST].includes(key2),
                          [classes.collapsed]: !expanded[TREE_LEVELS.FIRST].includes(key2),
                        })}>
                          {value2.data.map((item: { tech: string, checked: boolean }, index3: number) => {
                            return (
                                <div className='d'>
                                  <FormControlLabel
                                      className={classes.techContainer}
                                      classes={{
                                        root: classes.customLabelRoot,
                                        label: classes.customLabel
                                      }}
                                      key={index3}
                                      control={
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={(e) => handleChange(e, TREE_LEVELS.SECOND, key1, key2, item.tech)}
                                            name={item.tech}
                                            style={{ color: '#6e3cfa', padding: '0.25rem 0.4rem' }}
                                        />
                                      }
                                      label={item.tech}
                                  />
                                  {/*<span className={`${styles?.fontSize} ${classes.tech}`}>{item.tech}</span>*/}
                                </div>
                            )
                          })}
                        </div>

                      </article>

                    </section>
                  )
                })}

              </article>
            </section>
          )
        })}
      </TreeView>
    )
  };

  return <div>{renderTree(tree)}</div>;
}

export default DataTree;
