export const formatEmptyCell = (input: any) => {
    if (input === null || input === undefined) {
      return "--";
    } else if (typeof input === 'string' && input.trim() === '') {
      return "--";
    } else if (input instanceof Date && isNaN(input.getTime())) {
      return "--";
    } else if (typeof input === 'boolean') {
        if(input) {
            return "Yes";
        }
        return "No"
    }
  
    return input;
}