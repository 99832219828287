import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

import {
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Typography,
    Checkbox,
    Tooltip
} from '@material-ui/core';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import { getComparator, stableSort, findValueByKey, renderTableCellContent } from '../../utils/formatting';
import { formatEmptyCell } from '../../utils/utils';
import InfoIcon from '@material-ui/icons/Info';
import { useFPCIPriorities } from '../../hooks/useFPCIPriorities';
import IntelligenceModal from '../../components/IntelligenceModal';

import { makeStyles } from '@material-ui/core/styles';

const defaultHeadCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Company Name'
    },
    {
        id: 'website',
        numeric: false,
        disablePadding: true,
        label: 'Website'
    },
    {
        id: 'owner',
        numeric: false,
        disablePadding: true,
        label: 'Owner'
    },
    {
        id: 'totalRaised',
        numeric: true,
        disablePadding: true,
        label: 'Total Raised'
    },
    {
        id: 'headcount',
        numeric: true,
        disablePadding: true,
        label: 'Headcount'
    },
    {
        id: 'headcountGrowth',
        numeric: true,
        disablePadding: true,
        label: 'Headcount Growth'
    },
];

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells = defaultHeadCells,
        columnWidths
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        style={headerCheckboxStyle}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{minWidth: findValueByKey(columnWidths, headCell?.['label'])}}
                        key={headCell.id}
                        className={`${classes.headRow} font-14`}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'none'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={false}
                            hideSortIcon={true}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: 'black',
                backgroundColor: 'rgba(110, 60, 250, 0.05)'
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%'
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0
            })}>
            {numSelected > 0 && <>
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div">
                    {numSelected} selected
                </Typography>
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        {/*//TODO CF add functionality later*/}
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    wideRow: {
        minWidth: '500px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableHead: {
        backgroundColor: '#6e3cfa',
        height: '20px',
        lineHeight: '10px',
    },
    headRow: {
        height: '20px',
        lineHeight: '10px',
        color: '#fff !important',
        borderRight: '1px solid #fff',
        padding: '1rem',
        whiteSpace: 'nowrap',
        fontWeight: 'normal !important',
        '&:last-child': {
            padding: '1rem !important',
        },
        '& span:hover': {
            color: '#fff',
            cursor: 'default',
        },
        '& span:focus': {
            color: '#fff',
        }
    },
    tableCell: {
        borderRight: '1px solid #eeeff8',
        padding: '1rem',
        position: 'relative',

        '&:last-child': {
            padding: '1rem !important',
        },
    },
    searchHighlight: {
        backgroundColor: 'rgba(110, 60, 250)',
        color: 'white',
    },
    highlighted: {
        background: '#EDECEC',
        marginRight: "5px"
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    technologyTag: {
        margin: '5px',
        backgroundColor: '#EDECEC',
    },
    hoverBlack: {
        cursor: 'pointer',
        '&:hover': {
            color: '#4179B8 !important',
        },
    },
    cellIntelligenceBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
    cell: {
        position: 'relative',
        marginTop: "15px"
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        position: 'absolute',
        right: '16px',
        bottom: '0px',
    },
    fpcScoreBoxContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '18px',
        height: '18px',
        borderRadius: '2px',
        color: 'white'
    },
    flagColor: {
        width: '18px',
        height: '18px',
        borderRadius: '2px'
    }
}));

const checkboxStyle = {
    color: '#6e3cfa',
};

const headerCheckboxStyle = {
    color: '#fff',
};

const selectedRowStyle = {
    backgroundColor: "rgba(110, 60, 250, 0.05)"
}

export default function SelectionTable({ headCells, rows, selectedIds, updateSelectedIds, columnFormatValues, columnWidthValues }) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isIntelligenceModalOpen, setIsIntelligenceModalOpen] = useState(false);
    const [currentCompanyName, setCurrentCompanyName] = useState("");
    const [currentCompanyId, setCurrentCompanyId] = useState("");

    useFPCIPriorities();

    useEffect(() => {
        setSelected(selectedIds)
      }, []); 

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n["cinchy id"]);
            setSelected(newSelecteds);
            updateSelectedIds(newSelecteds)
            return;
        }
        setSelected([]);
        updateSelectedIds([])
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        updateSelectedIds(newSelected)
    };


    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleOpenCompanyDetails = (companyId) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    const handleCompanyWebsiteSplit = useCallback((value, id, flagData = "") => {
        const [companyName, website] = value.split(' | ');
        const [fpci, fpciColor, flagColor] = flagData?.split(' | ');
        return <div className={classes.cell}> 
            <div>
                <span className={classes.hoverBlack} onClick={() => handleOpenCompanyDetails(id)} style={{ cursor: 'pointer', color: 'black', display: 'block', fontSize: '14px', fontWeight: 'bold' }}>
                    {companyName}
                </span>
                <span  style={{ cursor: 'pointer', color: 'black', display: 'block' }}>
                    <a href={website?.startsWith('http') ? website : `http://${website}`} target="_blank" className={classes.hoverBlack} style={{ color: 'grey', fontSize: '12px' }}>{website}</a>
                </span>
            </div>
            <div className={classes.boxContainer}>
                <div className={classes.fpcScoreBoxContainer} style={{ background: `#${fpciColor}`}}>
                    <span className='font-12' >{fpci}</span>
                </div>
            <div className={classes.flagColor} style={{ background: `#${flagColor}`}}></div>
            </div>
            </div>
    }, []);

    const handleIntelligenceOpen = useCallback((companyId, company) => {
        setIsIntelligenceModalOpen(true);
        setCurrentCompanyId(companyId);
        setCurrentCompanyName(company);
    }, []);

    return (
        <>
            <div className="mb-spacing-6-x2">
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={!dense ? 'small' : 'medium'}
                            aria-label="enhanced table">
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={headCells?.filter(
                                    (_, index) => index !== 2 && index !== 0
                                  )}
                                columnWidths={columnWidthValues}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .map((row) => {
                                        const isItemSelected = isSelected(row["cinchy id"]);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                style={isItemSelected ? selectedRowStyle : null}
                                                >
                                                <TableCell onClick={(event) => handleClick(event, row["cinchy id"])} className={classes.tableCell} padding="checkbox">
                                                    <Checkbox style={checkboxStyle} checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div className='font-14 cursor-pointer'>
                                                        {handleCompanyWebsiteSplit(formatEmptyCell(row["company name"]), row['cinchy id'], row['company name2'])}
                                                    </div>
                                                    <Tooltip title="Intelligence" arrow placement="top">
                                                        <IconButton className={classes.cellIntelligenceBtn} size="small" aria-label="delete">
                                                            <InfoIcon stylle={{ color: 'black' }} fontSize='small' onClick={() => handleIntelligenceOpen(row["cinchy id"], row["company name"])} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                {Object.keys(row).filter(item => item !== 'cinchy id' && item !== 'company name2').slice(1).map(r=> {
                                                    return <TableCell align="left" className={r === "description" ? `${classes.wideRow, classes.tableCell} font-14`: `${classes.tableCell} font-14`}>
                                                            {renderTableCellContent(row, r, columnFormatValues, classes)}
                                                        </TableCell>}
                                                )}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <IntelligenceModal
                    id={currentCompanyId}
                    company={currentCompanyName}
                    isOpen={isIntelligenceModalOpen}
                    setIsOpen={() => setIsIntelligenceModalOpen(false)}>
                </IntelligenceModal>
            </div>
        </>
    );
}
