import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectThemeOptions } from '../store/themeOptions/themeOptionsSlice';

export const useTableHeight = () => {
    const { sidebarToggle  } = useSelector(selectThemeOptions);
    const [paginationItemWidth, setPaginationItemWidth] = useState(0);
    const [tableOffsetFromTop, setTableOffsetFromTop] = useState(null);
    const tableContainerRef = useRef(null);

    useEffect(() => {
        setPaginationItemWidth(sidebarToggle ? 60 : 352);
    }, [sidebarToggle])

    useEffect(() => {
        const offsetFromTop = tableContainerRef.current?.getBoundingClientRect?.()?.top;
        setTableOffsetFromTop(Math.floor(offsetFromTop));
    }, [tableContainerRef.current])

    return { tableContainerRef, paginationItemWidth, tableOffsetFromTop };
}