import React, { useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";

import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import {
  getTaxonomyCoreElements,
  getTaxonomyOtherElements,
} from "../../../../store/sourcing/whitespace/whitespaceThunks";
import {
  selectTaxonomyCoreElements,
  selectTaxonomyOtherElements,
} from "../../../../store/sourcing/whitespace/whitespaceSelectors";
// import HeaderActions from "../Common/HeaderActions";

export default function ViewTaxonomy() {
  const [groupedCoreElements, setGroupedCoreElements] = useState([] as any);
  const [groupedOtherElements, setGroupedOtherElements] = useState([] as any);

  const dispatch = useDispatch();
  const taxonomyCoreElements = useSelector(selectTaxonomyCoreElements);
  const taxonomyOtherElements = useSelector(selectTaxonomyOtherElements);

  useEffect(() => {
    setGroupedCoreElements(
      taxonomyCoreElements.reduce((groups: any, item: any) => {
        const category = item.Category;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        return groups;
      }, {})
    );
  }, [taxonomyCoreElements]);

  useEffect(() => {
    setGroupedOtherElements(
      taxonomyOtherElements.reduce((groups: any, item: any) => {
        const category = item.Category;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        return groups;
      }, {})
    );
  }, [taxonomyOtherElements]);

  useEffect(() => {
    dispatch(getTaxonomyCoreElements());
    dispatch(getTaxonomyOtherElements());
  }, []);

  const checkCoreColor = (color: string) => {
    if (color === "Red") {
      return "#DDB6BA";
    } else if (color === "Green") {
      return "#AAD7C2";
    } else if (color === "Purple") {
      return "#D8C6E7";
    } else if (color === "Yellow") {
      return "#EEE1B4";
    }
    return color;
  };

  const checkOtherColor = (color: string) => {
    if (color === "Light Grey") {
      return "#F5F5F5";
    } else if (color === "Dark Grey") {
      return "#D9D9D9";
    }
    return color;
  };

  return (
    <>
      {taxonomyCoreElements.length > 0 && taxonomyOtherElements.length > 0 && (
        <div>
          {/*<HeaderActions />*/}
          <Grid container={true}>
            <Grid item className="p-3" xs={6}>
              <Card className="card-box mb-spacing-6-x2 border rounded-0">
                <Grid item xs={12}>
                  <div className={styles.coreElementsTable}>
                    <div className={styles.coreElementsContainer}>
                      <p className={styles.coreElements}>Core Elements</p>
                    </div>
                    {Object.keys(groupedCoreElements).map((c: any) => {
                      return (
                        <Card
                          style={{
                            background: checkCoreColor(
                              groupedCoreElements[c][0]["Color"]
                            ),
                          }}
                          className={cn(
                            groupedCoreElements[c].length > 4
                              ? styles.largeCard
                              : groupedCoreElements[c].length < 3
                              ? styles.smallCard
                              : styles.card,
                            styles.pinkCard
                          )}
                        >
                          <div className={styles.cardTitleContainer}>
                            {/*<input*/}
                            {/*  className={styles.checkbox}*/}
                            {/*  type="checkbox"*/}
                            {/*  id="exampleCheckbox"*/}
                            {/*/>*/}
                            <span className={styles.cardTitle}>{c}</span>
                          </div>
                          <Grid container={true}>
                            {groupedCoreElements[c].map((item: any) => (
                              <Grid
                                item
                                className="p-2"
                                xs={
                                  groupedCoreElements[c].length > 4
                                    ? 4
                                    : groupedCoreElements[c].length < 3
                                    ? 12
                                    : 6
                                }
                              >
                                <Card className={`${styles.singleCard} font-12`}>
                                  {item["Sub Category"]}
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Card>
                      );
                    })}
                  </div>
                </Grid>
              </Card>
            </Grid>
            <Grid item className="p-3" xs={6}>
              {Object.keys(groupedOtherElements).map((o: any) => {
                return (
                  <div className="mb-2">
                    <Grid
                      style={{
                        background: checkOtherColor(
                          groupedOtherElements[o][0]["Color"]
                        ),
                      }}
                      className={styles.section}
                      container={true}
                    >
                      <Grid item xs={3}>
                        <div className={styles.cardTitleContainer}>
                          {/*<input*/}
                          {/*  className={styles.checkbox}*/}
                          {/*  type="checkbox"*/}
                          {/*  id="exampleCheckbox"*/}
                          {/*/>*/}
                          <span className={styles.cardTitle}>{o}</span>
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container={true}>
                          {groupedOtherElements[o].map((item: any) => (
                            <Grid item xs={4}>
                              <Card className={`${styles.singleCard} font-12`}>
                                {item["Sub Category"]}
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
