import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { formatEmptyCell } from './utils';
import StatusSelect from '../components/StatusSelect';
import OwnersSelect from '../components/OwnersSelect';
import PrioritySelect from '../components/PrioritySelect';
import RelationshipSelect from '../components/RelationshipSelect';
import SectorSelect from '../components/SectorSelect';
import TagsSelect from '../components/TagsSelect';
import FPCIPriorities from '../components/FPCIPrio';
import FPCIEngagement from '../components/FPCIEngagement';

import styles from '../pages/Sourcing/Financings/styles.module.scss';


export const FORMATTING_TYPES = {
    MILLIONS: 'Millions',
    DOLLARS: 'Dollars',
    PERCENTAGE: 'Percentage',
    BACKGROUNDCOMMA: 'Background-Comma',
    BACKGROUNDSLASH: 'Background-Slash',
    DATE: 'Date',
    LINK: 'Link',
    OWNERSELECT: 'Owner-Select',
    STATUSSELECT: 'Status-Select',
    DESCRIPTION: 'Description',
    BOOLEAN: 'Boolean',
    LINKCOMPANY: 'Link Company',
    OUTLOOKSELECT: 'Outlook-Select',
    INVESTOROUTLOOKSELECT: 'Investor Outlook-Select',
    ARRSELECT: 'ARR-Select',
    MULTIPLE: 'Multiple',
    OWNERSELECTPEOPLE: 'Owner-Select People',
    INVESTOROWNERSELECT: 'Investor Owner-Select',
    PRIORITYSELECT: 'Priority-Select',
    RELATIONSHIPSELECT: 'Relationship-Select',
    SECTORSELECT: 'Sector-Select',
    TAGSELECT: 'Tag-Select',
    WRAPPEDLINK: 'WrappedLink',
    MISSPASSLOSSSELECT: 'Miss Past Loss - Select',
    PRIORITY_SELECT: 'Companies Priority-Select',
    ENGAGEMENT_SELECT: 'Engagement-Select',
    COLOR: 'Color',
    COVERAGE: 'coverage-percentage',
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el) => [el]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
export const removeEmailFromOwner = (data) => {
    if(typeof data === 'object') {
        return data;
    }
    return data ? data.split(";") : "";
};

export const splitSectors = (data) => {
    return data ? data.split(";") : "";
};

const removeComma = (data) => {
    return data ? data.split(",") : "";
};

function formatCurrency(number) {
    if(!number) {
        return ""
    }
    const formattedNumber = '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
}

function formatCurrencyWithCommas(number) {
    if (typeof number !== 'number') {
        return '';
    }

    const formattedNumber = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    });

    return formattedNumber;
}

export function formatPercentage(decimalNumber) {
    if(decimalNumber) {
        // const percentage = Math.round(decimalNumber * 100);
        return decimalNumber + '%';
    }
    return '--';
}

export function formatDate(inputDate) {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return inputDate ? `${month}/${day}/${year}` : "";
}

function formatCurrencyWithNoDecimal(number) {
    if (!number) {
        return '';
    }

    const formattedNumber = '$' + Math.floor(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
}

const handleUrlClick = (event) => {
    event.stopPropagation();
};

export function formatTotalRaised(totalRaised) {
    // Check if the input is a valid number
    if (typeof totalRaised !== 'number' || isNaN(totalRaised)) {
        return;
    }

    // Format the number to display in millions with one decimal place
    const formattedAmount = (totalRaised / 1e6).toFixed(1);

    // Add the "$" sign and "M" suffix
    return `$${formattedAmount}M`;
}

export function formatMultiple(number) {
    const roundedNumber = Math.round(number * 10) / 10;
    const formattedNumber = roundedNumber + "x";
    
    return formattedNumber === "0x" ? "--" : formattedNumber;
}

export const findValueByKey = (array, key) => {
    const obj = array?.find?.(item => key in item);
    return obj ? obj[key] : undefined;
};

const renderTableCellContent = (row, column, columnFormat,  classes = {}, textToHighlight, rowIndex, { handleAddRecentOutlook } = {}) => {
    const lowerCasedColumnName = column.toLocaleLowerCase();
    let returnedValue = formatEmptyCell(row[column])

    const formatedColumns = columnFormat?.forEach(c=>{
        if(c[lowerCasedColumnName] === FORMATTING_TYPES.MILLIONS) {
            returnedValue =  formatEmptyCell(formatTotalRaised(row[column]))
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.DOLLARS) {
            returnedValue =  formatEmptyCell(formatCurrencyWithNoDecimal(row[column]))
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.OUTLOOKSELECT) {
            returnedValue = <>
                {/*{formatEmptyCell(row[column])}*/}
                {formatEmptyCell(row[column]).split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index < formatEmptyCell(row[column]).split('\n').length - 1 && <br />}
                    </React.Fragment>
                ))}
                {/*<div dangerouslySetInnerHTML={{_html: `${formatEmptyCell(row[column])}` }}/>*/}
                <Button
                    size="small"
                    className={`${styles.addRecentOutlook} btn-neutral-primary mb-2 mr-1`}
                >
                    <AddIcon
                        onClick={() =>
                            handleAddRecentOutlook(
                                row["Cinchy Id"],
                                rowIndex
                            )
                        }
                        className={
                            styles.addRecentOutlookButton
                        }
                    ></AddIcon>
                </Button>
            </>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.PERCENTAGE) {
            returnedValue =  row[column]!==null ? 
            `${Math.floor(row[column])}%`
             : formatEmptyCell(row[column])
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDCOMMA) {
            const refactoredData = removeComma(row[column])
            returnedValue =  refactoredData ? refactoredData?.map(r=> {
            if(textToHighlight && r.toLowerCase().includes(textToHighlight.toLowerCase())) {
                return <span className={classes?.searchHighlight}>{r}</span>
            }
            return <span className={classes?.highlighted}>{r}</span>
        }) : formatEmptyCell("");
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.DATE) {
            returnedValue = formatEmptyCell(formatDate(row[column]))
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.COVERAGE) {
            const [text, color] = row[column].split('#');
            returnedValue = <span style={{ color: `#${color}`, fontWeight: 'bold' }}>{text}</span>;
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDSLASH) {
            returnedValue = row[column] ? <div className={classes.tagContainer}> {row[column]?.split("|")?.map((item) => (
                <p className={`${classes.technologyTag} font-12`}>{item}</p>
              ))} </div> : formatEmptyCell(row[column])
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.LINK) {
            returnedValue = <a href={row[column]?.startsWith('http') ? row[column] : `http://${row[column]}`}
            target="_blank" onClick={handleUrlClick}>{formatEmptyCell(row[column])}</a>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.OWNERSELECT) {
            const refactoredData = removeEmailFromOwner(row[column])
            returnedValue = <OwnersSelect key={row['Cinchy Id'] || row['cinchy id']}  affinityId={row["Cinchy Id"] || row['cinchy id']} ownersInfo={refactoredData}></OwnersSelect>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.INVESTOROWNERSELECT) {
            const refactoredData = removeEmailFromOwner(row[column])
            returnedValue = <OwnersSelect key={row['Cinchy Id'] || row['cinchy id']}  affinityId={row["Cinchy Id"] || row['cinchy id']} ownersInfo={refactoredData} investorSelect={true}></OwnersSelect>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.SECTORSELECT) {
            const refactoredData = splitSectors(row[column])
            returnedValue = <SectorSelect key={row['Cinchy Id'] || row['cinchy id']}  cinchyId={row["Cinchy Id"] || row['cinchy id']} sectorsInfo={refactoredData}></SectorSelect>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.TAGSELECT) {
            const refactoredData = removeComma(row[column])
            returnedValue = <TagsSelect key={row['Cinchy Id'] || row['cinchy id']}  cinchyId={row["Cinchy Id"] || row['cinchy id']} tagsInfo={refactoredData}></TagsSelect>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.PRIORITYSELECT) {
            returnedValue = <PrioritySelect key={row['Cinchy Id'] || row['cinchy id']} cinchyId={row['Cinchy Id'] || row['cinchy id']} defaultValue={{ label: row[column], value: row[column] }} />
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.RELATIONSHIPSELECT) {
            returnedValue = <RelationshipSelect key={row['Cinchy Id'] || row['cinchy id']} cinchyId={row['Cinchy Id'] || row['cinchy id']} defaultValue={{ label: row[column], value: row[column] }} />
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.STATUSSELECT) {
            // @ts-ignore
            returnedValue = <StatusSelect key={row['Cinchy Id'] || row['cinchy id']} cinchyId={row['Cinchy Id'] || row['cinchy id']} defaultValue={{ label: row[column], value: row[column] }} />
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.PRIORITY_SELECT) {
            // @ts-ignore
            returnedValue = <FPCIPriorities key={row['Cinchy Id'] || row['cinchy id']} cinchyId={row['Cinchy Id'] || row['cinchy id']} defaultValue={{ label: row[column], value: row[column] }} />
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.ENGAGEMENT_SELECT) {
            // @ts-ignore
            returnedValue = <FPCIEngagement key={row['Cinchy Id'] || row['cinchy id']} cinchyId={row['Cinchy Id'] || row['cinchy id']} defaultValue={{ label: row[column], value: row[column] }} />
        }
        else if(c[lowerCasedColumnName] === FORMATTING_TYPES.DESCRIPTION) {
            if(textToHighlight && row[column]) {
                // highlight the text which is matching with textToHighlight
                const regex = new RegExp(textToHighlight, 'g');
    
                const style = {
                    backgroundColor: '#6e3cfa',
                    color: '#fff',
                }
    
                const styleAttribute = `background-color: ${style.backgroundColor}; color: ${style.color};`;
    
                // Replace all occurrences of the keyword with the highlighted version
                const highlightedText = row[column].replace(regex, '<span style="' + styleAttribute + '">'  + textToHighlight + '</span>');
                returnedValue = <span dangerouslySetInnerHTML={{ __html: highlightedText }}></span>
            } else {
                returnedValue = formatEmptyCell(row[column]);
            }
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BOOLEAN) {
            // @ts-ignore
            returnedValue = row[column] ? 'Yes' : 'No';
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.COLOR) {
            returnedValue = <div className='d-flex w-100 justify-content-center'>
                <div style={{
                backgroundColor: `#${row[column]}`,
                width: '15px',
                height: '15px',
                textAlign: 'center',
                borderRadius: '2px'}}>
                </div>
            </div>
        }
    })
    return returnedValue
};

export const formatContent = (row, column, columnName ,columnFormat, classes = {}, textToHighlight) => {
    const lowerCasedColumnName = columnName?.toLocaleLowerCase();
    let returnedValue = formatEmptyCell(column)

    const formatedColumns = columnFormat?.forEach(c=>{
        if(c[lowerCasedColumnName] === FORMATTING_TYPES.MILLIONS) {
            returnedValue =  formatEmptyCell(formatTotalRaised(column))
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.DOLLARS) {
            returnedValue =  formatEmptyCell(formatCurrencyWithNoDecimal(column))
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.PERCENTAGE) {
            returnedValue =  column!==null ? 
            `${Math.floor(column)}%`
             : formatEmptyCell(column)
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDCOMMA) {
            const refactoredData = removeComma(column)
            returnedValue =  refactoredData ? refactoredData?.map(r=> {
            if(textToHighlight && r.toLowerCase().includes(textToHighlight.toLowerCase())) {
                return <span className={classes?.searchHighlight}>{r}</span>
            }
            return <span className={classes?.highlighted}>{r}</span>
        }) : formatEmptyCell("");
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.DATE) {
            if(!isNaN(new Date(column))) {
                returnedValue = formatEmptyCell(formatDate(column))
            }
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDSLASH) {
            returnedValue = column ? <div className={classes.tagContainer}> {column?.split("|")?.map((item) => (
                <p className={`${classes.technologyTag} font-12`}>{item}</p>
              ))} </div> : formatEmptyCell(column)
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.LINK) {
            returnedValue = <a href={column?.startsWith('http') ? column : `http://${column}`}
            target="_blank" onClick={handleUrlClick}>{formatEmptyCell(column)}</a>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.WRAPPEDLINK) {
            returnedValue = <a href={column?.startsWith('http') ? column : `http://${column}`}
            target="_blank" onClick={handleUrlClick}>{column?.length > 25 ? formatEmptyCell(column).substring(0, 25) + "..." : formatEmptyCell(column)}</a>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.OWNERSELECT) {
            const refactoredData = removeEmailFromOwner(column)
            returnedValue = <OwnersSelect key={row['Cinchy Id']}  affinityId={row["Cinchy Id"]} ownersInfo={refactoredData}></OwnersSelect>
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.STATUSSELECT) {
            // @ts-ignore
            returnedValue = <StatusSelect key={row['Cinchy Id']} cinchyId={row['Cinchy Id']} defaultValue={{ label: column, value: column }} />
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.BOOLEAN) {
            // @ts-ignore
            returnedValue = column ? 'Yes' : 'No';
        }
    })

    return returnedValue
};

export const checkFormat = (columnName ,columnFormat, format) => {
    const lowerCasedColumnName = columnName.toLocaleLowerCase();
    return columnFormat?.filter(c=>c[lowerCasedColumnName] === format).length
}

function formatInvestors(data, style) {
    const refactoredData = removeComma(data)
    return refactoredData ? refactoredData?.map(r => <span className={style}>{r}</span>) : formatEmptyCell("");
}

function parseDate(date) {
    const inputDate = new Date(date);
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();

    return date ? `${month}/${day}/${year}` : "";
}

const formatOriginalDate = (inputDateStr) => {
    if (inputDateStr) {
        const inputDate = new Date(inputDateStr);
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };
        return inputDate.toLocaleDateString("en-US", options);
    }
    return "";
}


const formatColumnWidths = (columnWidths) => {
    const finalWidths  = columnWidths.map(c=> {
        return {[c["column_name"]] : c["column_width"] + "px"}
    })

    return finalWidths
}

const formatColumnDefaultValues = (columnWidths) => {
    const finalValues = columnWidths
        .filter(c => c["default"])
        .map(c => c["column_name"]);

    return finalValues
}

const formatColumnFormats = (columnWidths) => {
    const finalWidths  = columnWidths.map(c=> {
        return {[c["column_name"].toLocaleLowerCase()] : c["format"]}
    })

    return finalWidths
}

const formatColumnColumnFormats = (columnWidths) => {
    const finalWidths  = columnWidths.map(c=> {
        return {[c["column_name"].toLocaleLowerCase()] : c["column_format"]}
    })

    return finalWidths
}

export {
    parseDate,
    stableSort,
    getComparator,
    formatInvestors,
    formatOriginalDate,
    renderTableCellContent,
    formatColumnWidths,
    formatColumnFormats,
    formatColumnColumnFormats,
    formatColumnDefaultValues
}