import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

import {
    getTotalCompaniesCount,
} from '../../../../store/sourcing/whitespace/whitespaceThunks';
import { selectKeywords, selectTotalCompaniesCount } from '../../../../store/sourcing/whitespace/whitespaceSelectors';

function HeaderActions({ keywords, toggleKeywordSelection, selectedKeywords, withKeywords     = true, changeOptions }) {
    const dispatch = useDispatch();
    const totalCompanies = useSelector(selectTotalCompaniesCount);

    useEffect(() => {
        dispatch(getTotalCompaniesCount());
    }, []);

    return (
        <>
            {withKeywords && (
                <div className="card-header">
                    <div className="card-header--title">
                        {keywords.map((keyword) => (
                            <div
                                key={keyword}
                                className={`badge mr-1 ${selectedKeywords.has(keyword) ? 'badge-primary text-black' : 'badge-neutral-primary text-primary cursor-pointer'}`}
                                onClick={() => toggleKeywordSelection(keyword)}
                            >
                                {keyword}
                            </div>)
                        )}
                    </div>
                </div>
            )}
            <div className='divider' />
            <div className="card-header">
                <div className="card-header--actions">
                    <div>
                        {/*<NavLink to={`/sourcing/companies/company${searchQuery ? searchQuery : ''}`}> */}
                            <Button
                                onClick={changeOptions}
                                variant="contained" className="btn-primary m-1">
                                <span className="btn-wrapper--label">Go to companies</span>
                            </Button>
                        {/*</NavLink>*/}
                        <NavLink to="/sourcing/companies/company">
                            <Button variant="contained" className="btn-primary m-1">
                                <span className="btn-wrapper--label">Go to Research</span>
                            </Button>
                        </NavLink>
                        <div className="font-12 text-primary pt-2">
                            Total Companies: {totalCompanies}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderActions;
