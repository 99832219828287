export function markdownToHTML(markdown) {
    const lines = markdown.split('\n');
    let html = '';
    const listStack = [];
    const blockquoteStack = [];
    let inCodeBlock = false;
    let codeBlockLanguage = '';
    let inTable = false;
    let tableRows = [];
    const referenceLinks = {}; // Collects link references
    const referenceLinkTexts = {}; // Collects link texts when definitions are reversed

    // First pass to collect reference link definitions
    for (let i = lines.length - 1; i >= 0; i--) {
        const line = lines[i].trim();
        const linkReferenceDef = /^\[(.+?)\]:\s*(.+)$/;
        const match = line.match(linkReferenceDef);
        if (match) {
            const id = match[1];
            const value = match[2];
            // Determine if the value is a URL or descriptive text
            if (/^(https?:\/\/|www\.)/i.test(value)) {
                // Value is a URL
                referenceLinks[id] = value;
            } else {
                // Value is descriptive text
                referenceLinkTexts[id] = value;
            }
            lines.splice(i, 1); // Remove the link definition from lines
        }
    }

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];

        // Code Block Handling
        if (/^```/.test(line.trim())) {
            if (!inCodeBlock) {
                inCodeBlock = true;
                codeBlockLanguage = line.trim().slice(3).trim(); // Get language if specified
                html += `<pre><code${codeBlockLanguage ? ` class="language-${codeBlockLanguage}"` : ''}>`;
            } else {
                inCodeBlock = false;
                html += '</code></pre>';
            }
            continue;
        }

        if (inCodeBlock) {
            html += escapeHTML(line) + '\n';
            continue;
        }

        // Table Handling
        if (/^\s*\|.*\|\s*$/.test(line)) {
            inTable = true;
            tableRows.push(line.trim());
            continue;
        }

        if (inTable && !/^\s*\|.*\|\s*$/.test(line)) {
            html += parseTable(tableRows);
            tableRows = [];
            inTable = false;
        }

        // Horizontal Rule
        if (/^(\*\s*\*\s*\*\s*|\-\s*\-\s*\-\s*|\_\s*\_\s*\_\s*)$/.test(line.trim())) {
            html += '<hr />';
            continue;
        }

        // Blockquote Handling
        let blockquoteMatch = line.match(/^(>\s?)+/);
        let currentBlockquoteLevel = blockquoteMatch ? blockquoteMatch[0].split('>').length - 1 : 0;

        if (currentBlockquoteLevel > blockquoteStack.length) {
            while (currentBlockquoteLevel > blockquoteStack.length) {
                html += '<blockquote>';
                blockquoteStack.push('blockquote');
            }
        } else if (currentBlockquoteLevel < blockquoteStack.length) {
            while (currentBlockquoteLevel < blockquoteStack.length) {
                html += '</blockquote>';
                blockquoteStack.pop();
            }
        }

        if (blockquoteMatch) {
            line = line.replace(/^(>\s?)+/, '');
        } else {
            while (blockquoteStack.length) {
                html += '</blockquote>';
                blockquoteStack.pop();
            }
        }

        // List Handling
        const unorderedListMatch = /^(\s*)([-+*])\s+(.*)/.exec(line);
        const orderedListMatch = /^(\s*)(\d+)\.\s+(.*)/.exec(line);

        if (unorderedListMatch || orderedListMatch) {
            const isOrdered = !!orderedListMatch;
            const indent = (unorderedListMatch ? unorderedListMatch[1] : orderedListMatch[1]).length;
            const tag = isOrdered ? 'ol' : 'ul';
            const content = unorderedListMatch ? unorderedListMatch[3] : orderedListMatch[3];

            while (listStack.length > 0 && indent < listStack[listStack.length - 1].indent) {
                html += `</${listStack.pop().tag}>`;
            }

            if (listStack.length === 0 || indent > listStack[listStack.length - 1].indent) {
                html += `<${tag}>`;
                listStack.push({tag, indent});
            } else if (indent === listStack[listStack.length - 1].indent && tag !== listStack[listStack.length - 1].tag) {
                html += `</${listStack.pop().tag}><${tag}>`;
                listStack.push({tag, indent});
            }

            // Process inline markdown in list item
            const listItemContent = processInlineMarkdown(content);
            html += `<li>${listItemContent}</li>`;
            continue;
        } else {
            while (listStack.length) {
                html += `</${listStack.pop().tag}>`;
            }
        }

        // Header Handling
        if (/^(#{1,6})\s+(.*)/.test(line)) {
            const headerMatch = /^(#{1,6})\s+(.*)/.exec(line);
            const level = headerMatch[1].length;
            const content = processInlineMarkdown(headerMatch[2]);
            html += `<h${level}>${content}</h${level}>`;
            continue;
        }

        // Process inline markdown within the line
        const processedLine = processInlineMarkdown(line);
        html += `<p>${processedLine}</p>`;
    }

    // Close any remaining open tags
    while (listStack.length) {
        html += `</${listStack.pop().tag}>`;
    }
    while (blockquoteStack.length) {
        html += '</blockquote>';
        blockquoteStack.pop();
    }
    if (inCodeBlock) {
        html += '</code></pre>';
        inCodeBlock = false;
    }
    if (inTable) {
        html += parseTable(tableRows);
        tableRows = [];
        inTable = false;
    }

    return html.trim();

    // Helper function to process inline markdown
    function processInlineMarkdown(text) {
        // Escape HTML special characters
        text = escapeHTML(text);

        // Replace images first to avoid conflicting with link replacements
        text = text.replace(/!\[([^\]]*)\]\(([^)]+)\)/gim, '<img src="$2" alt="$1" />');
        text = text.replace(/!\[([^\]]+)\]\[([^\]]*)\]/gim, function (match, altText, id) {
            id = id || altText;
            const url = referenceLinks[id];
            if (url) {
                return `<img src="${url}" alt="${altText}" />`;
            }
            return match;
        });

        // Replace inline links and reference links
        text = replaceLinks(text);

        // Bold and Italic
        text = text.replace(/(\*\*\*|___)(.*?)\1/g, '<strong><em>$2</em></strong>');
        // Bold
        text = text.replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>');
        // Italic
        text = text.replace(/(\*|_)(.*?)\1/g, '<em>$2</em>');
        // Strikethrough
        text = text.replace(/~~(.*?)~~/g, '<del>$1</del>');
        // Inline Code
        text = text.replace(/`([^`]+)`/g, '<code>$1</code>');
        return text;
    }

    // Updated helper function to replace links
    function replaceLinks(text) {
        // Replace inline links
        text = text.replace(/\[([^\]]+)\]\(([^)]+)\)/gim, '<a href="$2">$1</a>');

        // Replace reference links
        text = text.replace(/\[([^\]]+)\]\[([^\]]*)\]/gim, function (match, linkText, id) {
            id = id || linkText;
            const url = referenceLinks[id];
            const linkTextOverride = referenceLinkTexts[id];
            if (url) {
                return `<a href="${url}">${linkText}</a>`;
            } else if (linkTextOverride) {
                return `<a href="${escapeHTML(linkText)}">${linkTextOverride}</a>`;
            }
            return match; // Return original text if no matching reference
        });

        return text;
    }

    // Helper function to parse tables (same as before)
    function parseTable(rows) {
        // ... existing code ...
        // (Omitted for brevity; remains unchanged)
    }

    // Helper function to escape HTML special characters (same as before)
    function escapeHTML(text) {
        return text.replace(/[&<>"']/g, function (match) {
            const escapeChars = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#39;',
            };
            return escapeChars[match];
        });
    }
}