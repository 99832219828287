const colorMapping = {
    Red: 'red',
    Green: '#B1F9C5',
    Yellow: '#F9DCB1',
    Other: '#616161',
    Orange: '#ffa500',
}

const subTableColorMapping = {
    'Backlog / Pursuing': '#1C1D1D',
    'Relationship': '#0B15F6',
    'Low Priority / DNP': '#FA0101',
    'Portcos': '#6e3cfa'

}



export  { colorMapping, subTableColorMapping };