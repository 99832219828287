import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Switch, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { Table } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ArrowLeft, ArrowRight, Delete } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { AddCircleOutline } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import { getSyncedData, syncWithCompany } from '../../../store/relationships/relationshipsThunks';
import UniversalSearch from '../../../components/UniversalSearch';
import GenericTable from '../../../components/GenericTable';
import TSkeleton from '../../../components/TSkeleton';
import { useNotification } from '../../../hooks/useNotification';
import { Tooltip } from '@material-ui/core';
import EditIcon from '../../../components/EditIcon/EditIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import { useOwners } from '../../../hooks/useOwners';
import EditPeopleModal from './EditPeopleModal';
import SearchBar from './SearchBar';

import { renderCell } from './utils';
// import TableActions from '../../../components/TInvestors/TableActions';
import TableActions from './TableActions';
import ModalForm from '../../../components/ModalForm';

import {
  selectFPCContacts,
  selectPeople,
  selectPeopleColumnWidths,
  selectSyncedData
} from '../../../store/relationships/relationshipsSlice';
import {
  deletePeople,
  getPeople,
  getPeopleColumnWidths,
} from '../../../store/relationships/relationshipsThunks';
import { DEFAULT_PEOPLE_COLUMNS } from '../../../constants';
import { setPeopleFilterOptions } from '../../../store/filter/filterSlice';
import { useFilter } from '../../../hooks/useFilter';
import { filterCompany } from '../../Sourcing/Companies/helper';

import { defaultColumn, highlight, highLightColumn, tHeadStyle } from '../../Sourcing/utils';
import styles from './styles.module.scss';
import Pagination from '../../../components/Pagination/Pagination';
import usePagination from '../../../hooks/usePagination';
import { formatColumnFormats, formatColumnWidths, parseDate } from '../../../utils/formatting';
import { useStatuses } from '../../../hooks/useStatus';

const rowsPerPageOptions = [25, 50, 100]

export default function People() {
  const dispatch = useDispatch();
    const { rows, cells, columns, isLoading } = useSelector(selectPeople);
    const [selectedColumns, setSelectedColumns] = useState(JSON.parse(localStorage.getItem("selectedPeopleColumns")) || DEFAULT_PEOPLE_COLUMNS);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(false);
    const [columnWidthValues, setColumnWidthValues] = useState(null);
    const [columnFormatValues, setColumnFormatValues] = useState(null);
    const [isCompanySynced, setIsCompanySynced] = useState(false);

    let columnWidths = useSelector(selectPeopleColumnWidths);
    let syncedData = useSelector(selectSyncedData);

    useOwners()
    useStatuses();

    const { filterOptions } = useFilter({});

    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;


    const selectedCells = useMemo(() => cells?.filter((cell) => selectedColumns.includes(cell)), [cells, selectedColumns])


    useEffect(() => {
        dispatch(getPeople());
    }, [])

    useEffect(() => {
        dispatch(getSyncedData());
    }, [])

    useEffect(() => {
        if(syncedData.length) {
            setIsCompanySynced(syncedData?.[0]?.["People Sync Button"])
        }
    }, [syncedData])

    useEffect(() => {
        dispatch(getPeopleColumnWidths());
    }, []);

    useEffect(() => {
        if(columnWidths?.rows?.length) {
            setColumnWidthValues(formatColumnWidths(columnWidths.rows))
            setColumnFormatValues(formatColumnFormats(columnWidths.rows))
        }
    }, [columnWidths]);

    const handleFilterColumns = (selectedColumns) => {
        setSelectedColumns(selectedColumns);
        localStorage.setItem(
            "selectedPeopleColumns",
            JSON.stringify(selectedColumns)
        );
    };

    const handleOpenNotification = (message) => {
        openNotification({
            open: true,
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
            message: message,
        })
    }

    const handleRowDelete = async (tableCinchyId) => {
        await dispatch(deletePeople(tableCinchyId))
        await dispatch(getPeople())
        handleOpenNotification('Row successfully deleted')
    }

    const handleRowUpdate = useCallback((row) => {
        setIsUpdateModalOpen(true);
        setSelectedRow(row);
    }, []);

    const [searchValue, setSearchValue] = useState('');
    const [column, setColumn] = useState('Full Name');

    const filteredRows = useMemo(() => {
        const actualRows = Object.keys(filterOptions).length ? filterCompany(filterOptions, rows)
            : rows

        if(searchValue) {
            return actualRows.filter(item => item[column]?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        }
        return actualRows;
    }, [filterOptions, rows?.length, searchValue, column]);


    const columnsToHighLight = useMemo(() => {
        let options = new Set(Object.keys(filterOptions));
        return selectedCells?.filter((item) => (options.has(item.toLowerCase()) || options.has(item)));
    }, [filterOptions, selectedCells])

    const findValueByKey = (array, key) => {
        const obj = array?.find(item => key in item);
        return obj ? obj[key] : undefined;
    };

    const tableActions = <TableActions
        cells={cells}
        selectedColumns={selectedColumns}
        columns={columns}
        handleSelectColumns={handleFilterColumns}
        openNotification={(message) => handleOpenNotification(message)}
    />

    const getHeadCellStyle = (cell) =>  ({
    ...columnsToHighLight.includes(cell) ? highlight : tHeadStyle,
            minWidth: findValueByKey(columnWidthValues, cell),
    })

    const getCellStyle = (cell) =>  columnsToHighLight.includes(cell) ? highLightColumn : defaultColumn;

    const renderTCell = useCallback(
        (column, row) => renderCell(
            { columnFormat: columnFormatValues,
                column,
                row
            }),[columnFormatValues])

    const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: filteredRows, rowsPerPageValue : rowsPerPageOptions[0] });

    const rowsToDraw = useMemo(() => filteredRows?.slice(
        (currentPage - 1) * rowsPerPage,
        (currentPage - 1) * rowsPerPage + rowsPerPage
    ), [filteredRows, currentPage, rowsPerPage])

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const getActionsCell = useCallback(({ row }) => <div className='d-flex'>
        <Tooltip title="Edit Row">
            <IconButton size="small" aria-label="edit" onClick={() => handleRowUpdate(row)}>
                <EditIcon style={{ color: 'grey' }} fontSize='small' />
            </IconButton>
        </Tooltip>
        <Tooltip title="Delete Row">
            <IconButton size="small" aria-label="delete">
                <DeleteIcon style={{ color: 'black' }} fontSize='small' onClick={() => handleRowDelete(row['Cinchy Id'])} />
            </IconButton>
        </Tooltip>
    </div>, [])

  useEffect(() => {
    if(rows?.length) {
      dispatch(setPeopleFilterOptions(rows));
    }
  }, [rows]);

  const handleSyncWithCompanies = useCallback(
    async () => {
        await dispatch(syncWithCompany())
        openNotification({
            message: "Companies synced successfully",
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
        })
        setIsCompanySynced(true)
    },
    [dispatch]
);

  return (
    <>
      {isLoading ? <TSkeleton/> :
            <div className="table-responsive-md mb-spacing-2-x2 ml-3">
                <UniversalSearch />
                {syncedData.length && <div className={styles.syncContainer}>
                <div className={styles.syncLabelContainer}><label className={styles.syncTitle}>Sync w/ Companies</label>
                <label>Last sync {parseDate(syncedData?.[0]?.["Modified"])}</label></div>
                <div className={styles.syncToggleContainer}>
                <Switch
                    checked={isCompanySynced}
                    onClick={handleSyncWithCompanies}
                    disabled={isCompanySynced}                            
                    className="switch-small toggle-switch-primary ml-auto"
                />
                </div>
                
                </div>}
                <GenericTable
                    title={`People (${filteredRows.length} results)`}
                    rows={rowsToDraw}
                    headCells={selectedCells}
                    tableActions={tableActions}
                    getHeadCellStyle={getHeadCellStyle}
                    getCellStyle={getCellStyle}
                    renderCell={renderTCell}
                    getActionsCell={getActionsCell}
                    searchBar={<SearchBar handleSearch={handleSearch} column={column} setColumn={setColumn} />}
                >
                    <Pagination
                        goToPage={goToPage}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        goToPageValue={goToPageValue}
                        totalPages={totalPages}
                        handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} options={rowsPerPageOptions}
                    />
                </GenericTable>
                <EditPeopleModal
                    row={selectedRow} i
                    isOpen={isUpdateModalOpen}
                    onClose={() => setIsUpdateModalOpen(false)}
                    openNotification={(message) => handleOpenNotification(message)}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{ root: toastrStyle }}
                    open={open}
                    message={message}
                />
            </div>
          }
    </>
  );
}
