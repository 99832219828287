import React from "react";
import GaugeChart from "react-gauge-chart";

export default function CoverageChart({coverageData}) {
  return (
    <>
      {coverageData && <div>
            <div className="py-4 my-2" style={{width: '100%'}}>
              <GaugeChart
                hideText
                id="chartsGauges2A"
                nrOfLevels={5}
                colors={
                  ["#FA0101", "#D46B09", "#FFCC00", "#34C759", "#047230"]
                }
                arcWidth={0.3}
                percent={coverageData["Coverage Score"] ? coverageData["Coverage Score"].split("%")[0] / 100 : 0}
              />
            </div>
            <div style={{textAlign: 'center', marginTop: '-60px'}}>
              <p style={{fontWeight: 'bold', color: `#${coverageData["Coverge Score Color"]}`, fontSize: '30px', marginTop: '10px'}}>{coverageData["Coverage Score"].split("%")[0]}</p>
              <p style={{fontWeight: 'bold', margin: 0}}>FPC Coverage Score</p>
              <p style={{fontWeight: 'bold', color: `#${coverageData["FPC Coverge Score Color"]}`}}>{coverageData["FPC Coverage Score"]}</p>
            </div>
      </div>}
    </>
  );
}
