import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';


import Autocomplete from '@material-ui/lab/Autocomplete';
import { Card, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { whitespaceGroupingByRow } from '../../utils/TGroping';

export const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        zIndex: 501,
        position: 'absolute',
        top: props.top,
        right: props.right,
        minWidth: '220px',
        width: '220px',
        minHeight: '50px',
    }),
    autoComplete: {
        padding: '8px 8px',
    },
    searchInput: {
        '& input': {
            fontFamily: "Quicksand",
            fontSize: '12px',
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: "Quicksand",
            fontSize: '12px',
        },
        '& .MuiInputLabel-shrink': {
            color: 'rgba(110, 60, 250)',
            fontSize: '14px',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(110, 60, 250)',
            },
            '&.Mui-focused .MuiInputLabel-outlined': {
                color: 'rgba(110, 60, 250)',
            }
        },
    },
    option: {
        fontFamily: "Quicksand",
        fontSize: '12px',
    },
}));

const getCompanyNameOptions = async ({ dispatch }) => {
    const { status, response  } = await getData(`${QUERIES.UNIVERSAL_SEARCH}`, dispatch)
    if(status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response);
    }
}

const COMPANY_DETAILS_PATH = '/sourcing/companies/details/';

function UniversalSearch({ top = '64px', right = '16px', parentElement = null }) {
    const classes = useStyles({ top, right });
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const timeout = useRef([]);
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        getCompanyNameOptions({ dispatch }).then(res => {
            setCompanies(res);
        })
    }, []);

    useEffect(() => {
        return () => timeout.current.forEach(id => clearTimeout(id));
    }, [])

    const handleInputChange = (event, newInputValue) => {
        if(!newInputValue.includes("undefined")) {
            setInputValue(newInputValue);
        }
    };
    const handleSelectionChange = (event, value, reason, details) => {
        if (reason === 'select-option' && details && details.option && value["Company Name"]) {
            const companyId = details.option['Cinchy Id'];
            window.open(`${COMPANY_DETAILS_PATH}${companyId}`, '_blank');
            setSelectedCompany(value);
            timeout.current.push(setTimeout(() => {
                setSelectedCompany(null);
                setInputValue('');
                if (inputRef.current) {
                    inputRef.current.blur(); // Blur the input field
                }
            }, 10))
        }
    };

    const SearchBox  = <>
        <Card className={classes.root}>
            {companies?.length === 0 ? <div className='p-1'>
                <Skeleton animation='wave' />
                <Skeleton animation='wave' />
            </div> :
            <Autocomplete
                className={classes.autoComplete}
                freeSolo
                disableClearable
                options={companies}
                getOptionLabel={(option) => `${option['Company Name']} | ${option['Company Website']}`}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleSelectionChange}
                value={selectedCompany}
                renderInput={(params) => (
                    <TextField
                        className={classes.searchInput}
                        {...params}
                        variant='outlined'
                        size='small'
                        label='Search Company'
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        inputRef={inputRef}
                    />
                )}
                classes={{ option: classes.option }}
            />}
        </Card>
    </>

    return createPortal(SearchBox, parentElement || document.body);
}

export default UniversalSearch;