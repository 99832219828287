import { QUERIES, STATUSES } from '../constants';
import { getData } from './sourcing';

export const updateCoveragePriority = async ({ dispatch, cinchyId, priority }) => {
    const queryParams = {
        '@cinchyid': cinchyId,
        '@priority': priority,
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const { response, status } = await getData(`${QUERIES.COVERAGE.PRIORITY_UPDATE}?${queryString}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response
    }
}
export const updateCoverageOwners = async ({ dispatch, cinchyId, owners }) => {
    const queryParams = {
        '@cinchyid': cinchyId,
        '@owners': owners,
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const { response, status } = await getData(`${QUERIES.COVERAGE.PRIORITY_UPDATE}?${queryString}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response
    }
}

export const getCoveragePriorityOptions = async ({ dispatch }) => {
    const { response, status } = await getData(`${QUERIES.COVERAGE.PRIORITY_SELECT}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response
    }
}
export const getCoverageSummary = async ({ dispatch }) => {
    const {status, response} = await getData(QUERIES.COVERAGE.GET, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response;
    }
}