import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles.module.scss";
import KeyCustomersTable from "./KeyCustomersTable";

interface KeyCustomersProps {
	data: any;
	companyId: number;
	handleRefresh: () => void
}

const KeyCustomers = ({ data, companyId, handleRefresh }: KeyCustomersProps) => {
	return (
		<>
			<KeyCustomersTable
				tableData={data}
				companyId={companyId}
				handleRefresh={handleRefresh}
			/>
		</>
	);
};

export default KeyCustomers;
