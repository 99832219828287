import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useHistory } from 'react-router-dom';
import { produce } from 'immer';
import { FilterOptions } from '../types/hooks/useFilter';

import { parseLocationSearch, stringifyObject } from '../helpers/string.helper';
import { selectFilterReset } from '../store/filter/filterSelectors';
import { setIsReset } from '../store/filter/filterSlice';

export type ChangeOptionsType = (
    newOptions: Partial<{
        filterOptions: Partial<FilterOptions>;
    }>,
    params?: {
        isNewOption: boolean;
        path: string;
    },
) => void;

export const useFilter = <T extends Partial<FilterOptions>>(defaultOptions: T) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isReset = useSelector(selectFilterReset);

    const queryOptions = useMemo(() => {
        const currentOptions = parseLocationSearch(location.search);

        let resultOptions = produce(defaultOptions, (draft) => Object.assign(draft, currentOptions));

        const { pageNumber, pageSize, editId, ...rest } = resultOptions;

        const sorterOptions: Partial<FilterOptions> = {
            pageNumber,
            pageSize,
        };

        return { filterOptions: rest, sorterOptions, editId };
    }, [defaultOptions, location.search]);

    const changeOptions = useCallback(
        (newOptions: Partial<typeof queryOptions>, params?: { isNewOption: boolean; path: string }) => {
            const currentOptions = params?.isNewOption ? {} : parseLocationSearch(location.search);

            const { editId } = newOptions;


            const resultOptions = produce(currentOptions, (draft) =>
                Object.assign(draft, {
                    editId,
                    ...newOptions.filterOptions,
                    ...newOptions.sorterOptions,
                }),
            );

            const isEmptyArray = Object.entries(resultOptions).reduce((acc: any, [key, value]) => {
                const isItemNotEmptyArray = Array.isArray(value) && !value?.length;

                if (isItemNotEmptyArray || value === undefined) {
                    return acc;
                }
                acc[key] = value;
                return acc;
            }, {});

            const search = `${params?.path ?? ''}?${stringifyObject(isEmptyArray)}`;
            history.push(search);
        },
        [location.search, history],
    );

    const resetOptions = () => {
        dispatch(setIsReset(true));
        history.push({ search: '' });
    };

    return { ...queryOptions, changeOptions, resetOptions, isReset };
};