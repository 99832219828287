import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { CardContent, Checkbox, Table, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    selectLoading
} from '../../../../store/sourcing/sourcingSlice';
import TSkeleton from '../../../../components/TSkeleton';
import { getWhitespaceCompaniesBySubCategory } from '../../../../store/sourcing/whitespace/whitespaceThunks';
import { selectWhiteSpaceBySubCategory } from '../../../../store/sourcing/whitespace/whitespaceSelectors';
import HeaderActions from "../Common/HeaderActions";
import { CompaniesInvestment, SubTableHead, SubTableBody } from '../Common/CellRenderers';
import { useKeywordFilter } from '../../../../hooks/useKeywordFilter';
import { colorMapping } from '../configs';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    description: {
       maxWidth: 400,
       minWidth: 400,
       overflow: 'hidden',
       textOverflow: 'ellipsis',
       fontSize: '12px',
    },
    scroll: {
        overflowY: 'scroll',
    },
    subTableColumn: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
    },
    dynamicBackgroundColor: {
        backgroundColor: (props) => props.backgroundColor,
    },
    dividerPosition: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    greyBackground: {
        backgroundColor: '#edecec',
        margin: '1px',
    }
}))

const domainCellStyle = {
    backgroundColor: '#C4C4C4',
};

const divideRowsStyle = {
    height: '1rem',
}

const categoryCellStyle = {
    backgroundColor: '#D9D9D9'
}

const secondaryCellStyle = {
    backgroundColor: '#F6F6F6'
}

const emptyCell = {
    maxWidth: '16px'
}

const largeCell = {
    minWidth: '300px'
}

const TSubCategory = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhitespaceCompaniesBySubCategory())
    }, [])

    const {  columns, rows } = useSelector(selectWhiteSpaceBySubCategory);
    const loading = useSelector(selectLoading);

        const modifiedSubTable = useMemo(() => {
        const columnNames = columns?.[6]?.columnName.split(',');
        return columnNames?.reduce((current, item, index) => {
            current[item.trimStart()] = index;
            return current;
        }, {})
    }, [columns])

   const { keywords, selectedKeywords, toggleKeywordSelection, filteredByKeyword } = useKeywordFilter(rows);

    const rowsToDisplay = selectedKeywords.size ? filteredByKeyword : rows;


    return (!columns.length ? <TSkeleton></TSkeleton> : <>
        <HeaderActions
            keywords={keywords}
            selectedKeywords={selectedKeywords}
            toggleKeywordSelection={toggleKeywordSelection}
        />
        <CardContent className={`${classes.scroll} px-0 pt-2 pb-3`}>
            {loading ? <TSkeleton /> : <>
                <Table className="table table-borderless table-hover table-alternate mb-0">
                    <thead>
                    <TableRow>
                        {columns.map(({ columnName }, index) => <TableCell
                            key={`${columnName}-${index}`}
                            style={[1,3].includes(index) || !columnName ? emptyCell : index === 9 ? largeCell : {}}
                            className={`${columnName === 'Sub Category' ? "text-left" : "text-center"}`}>
                            <div className="px-4 font-weight-bold font-12">
                                {index === 6 ? <SubTableHead /> : columnName}
                            </div>
                        </TableCell>)}
                    </TableRow>
                    </thead>
                    <tbody>
                    {Object.keys(rowsToDisplay).map(category => <Fragment key={category}>
                        {rowsToDisplay[category].map((item, index) => <Fragment key={`${item.Domain}-${index}`}>
                            <TableRow>
                                {/*Domain*/}
                                <TableCell style={domainCellStyle} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item.Domain}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Category*/}
                                <TableCell style={categoryCellStyle} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item.Category}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Sub Category*/}
                                <TableCell style={secondaryCellStyle}>
                                    <div className="d-flex">
                                        <Checkbox
                                            color="primary"
                                            id="checkboxProjects15"
                                            className="align-self-center mr-2"
                                        />
                                        <span className="font-weight-bold text-black align-self-center font-12">
                                        {item['Sub Category']}
                                    </span>
                                    </div>
                                </TableCell>
                                {/*Description*/}
                                <TableCell style={secondaryCellStyle} className="left">
                                    <span className={`text-black font-12 d-block ${classes.description}`}>{item['Category Description']}</span>
                                </TableCell>
                                <TableCell className="text-center">
                                    <SubTableBody modifiedSubTable={modifiedSubTable} item={item}/>
                                </TableCell>
                                <TableCell style={{backgroundColor: colorMapping[item.Color]}} className="text-center">
                                </TableCell>
                                <CompaniesInvestment
                                    totalCCount={item['Total Companies Count']}
                                    totalInvestedCount={item['Total Invested Count']}
                                />
                                <td className="text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className={`${classes.greyBackground} font-weight-bold font-12 pr-2`}>
                                            {item['List of Portcos']}
                                        </div>
                                    </div>
                                </td>
                            </TableRow>
                            <TableRow style={divideRowsStyle}>
                                <TableCell style={item.Domain === rows[category]?.[index+1]?.Domain ? domainCellStyle : {}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={item.Category === rows[category]?.[index+1]?.Category ? categoryCellStyle : {}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={item['Sub Category'] === rows[category]?.[index+1]?.['Sub Category'] ? secondaryCellStyle : {}}></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </Fragment>)}
                    </Fragment>)}
                    </tbody>
                </Table>
                <div className="divider mb-3" />
                <div className="text-center">
                </div>
            </>}
        </CardContent>
    </>);
};

export default TSubCategory;