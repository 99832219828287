import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectThemeOptions } from '../store/themeOptions/themeOptionsSlice';

export const useSideBarWidth = () => {
    const { sidebarToggle  } = useSelector(selectThemeOptions);

    const [paginationItemWidth, setPaginationItemWidth] = useState(0);

    useEffect(() => {
        setPaginationItemWidth(sidebarToggle ? 30 : 320);
    }, [sidebarToggle])

    return paginationItemWidth;
}