import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPriorityList } from '../store/sourcing/sourcingThunks';

const usePriority = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPriorityList());
    }, []);
}

export { usePriority };