import React, { useEffect, useState } from "react";
import StatBox from "./StatBox";
import { Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoverageScore,
  getTopMainData,
} from "../../../../store/dashboard/dashboardThunks";
import {
  selectCoverageScore,
  selectTopMainData,
} from "../../../../store/dashboard/dashboardSlice";
import TSkeleton from "../../../../components/TSkeleton";
import styles from "./styles.module.scss";
import CoverageChart from "../../../../example-components/ChartsGauges/ChartsGauges2/index.js";

const OwnersInfo = ({personalName}: any) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const dispatch = useDispatch();
  const { data, isLoading: isTopMainDataLoading } = useSelector(
    selectTopMainData
  );
  const {
    data: coverageScoreData,
    isLoading: isCoverageScoreDataLoading,
  } = useSelector(selectCoverageScore);

  useEffect(() => {
    dispatch(getTopMainData());
    dispatch(getCoverageScore());
  }, []);

  return (
    <>
      <div className={styles.dashboardStyle}>
        <div className={styles.mainSection}>
          {isTopMainDataLoading ? (
            <TSkeleton />
          ) : (
            <div className={styles.mainGridStyle}>
              {data.length > 0 && (
                <>
                  {/* <Button
                    onClick={() => setShowAllRows(!showAllRows)}
                    id="blockElement"
                    className={styles.viewBtn}
                  >
                    {showAllRows ? (
                      <>
                        <span className={`${styles.collapseBtn} font-12`}>
                          Collapse
                        </span>
                        <ArrowDropUp />
                      </>
                    ) : (
                      <>
                        <span className={`${styles.collapseBtn} font-12`}>
                          View All
                        </span>
                        <ArrowDropDown />
                      </>
                    )}
                  </Button> */}
                  <div className={styles.pipelineRow}>
                    <div className={styles.pipelineSection}>
                      <div className={styles.pipelineTitle}>Pipeline</div>
                    </div>
                    <StatBox
                      title="Total Companies"
                      value={data[0]["Total Companies"]}
                      valueColor={null}
                      backgroundColor={null}
                      hasBorder={false}
                    />
                    <StatBox
                      title="Pipeline Coverage"
                      value={data[0]["Pipeline Coverage"]}
                      valueColor={`#${data[0]["Company Hygiene % Text Color"]}`}
                      backgroundColor={null}
                      hasBorder={true}
                      sectionTitle={personalName}
                      sectionColor="#19968e"
                    />
                    <StatBox
                      title="FPC Pipeline Coverage"
                      value={data[0]["FPC Pipeline Coverage"]}
                      valueColor={`#${data[0]["FPC Company Hygiene Text Color"]}`}
                      backgroundColor={null}
                      hasBorder={true}
                      sectionTitle="Forgepoint"
                      sectionColor="#7c10be"
                    />
                  </div>
                  <div className={styles.rowSeparatorStyle}></div>
                  <div className={styles.categoriesRow}>
                    <div className={styles.categorySection}>
                      <div className={styles.pipelineTitle}>Categories ({data[0]["# of Categories"]})</div>
                    </div>
                    <StatBox
                      title="Sweet Spot Cos"
                      value={data[0]["Sweet Spot Cos"]}
                      valueColor={null}
                      backgroundColor="#B7CFFF"
                    />
                    <StatBox
                      title="Category Coverage"
                      value={data[0]["Category Coverage"]}
                      valueColor={`#${data[0]["Coverage % Text Color"]}`}
                      backgroundColor="#B7CFFF"
                      hasBorder={true}
                      sectionColor="#19968e"
                    />
                    <StatBox
                      title="FPC Category Coverage"
                      value={data[0]["FPC Category Coverage"]}
                      valueColor={`#${data[0]["FPC Coverage % Text Color"]}`}
                      backgroundColor="#B7CFFF"
                      hasBorder={true}
                      sectionColor="#7c10be"
                    />
                  </div>
                  <div className={styles.rowSeparatorStyle}></div>
                  <div className={styles.investorsRow}>
                    <div className={styles.investorSection}>
                      <div className={styles.pipelineTitle}>Investors ({data[0]["# of Investors"]})</div>
                    </div>
                    <StatBox
                      title="Sweet Spot Cos"
                      value={data[0]["Sweet Spot Cos1"]}
                      valueColor={null}
                      backgroundColor="#CFBEFD"
                    />
                    <StatBox
                      title="Investor Coverage"
                      value={data[0]["Investor Coverage"]}
                      valueColor={`#${data[0]["Investor Hygiene % Text Color"]}`}
                      backgroundColor="#CFBEFD"
                      hasBorder={true}
                      sectionColor="#19968e"
                    />
                    <StatBox
                      title="FPC Investor Coverage"
                      value={data[0]["FPC Investor Coverage"]}
                      valueColor={`#${data[0]["FPC Investor Hygiene % Text Color"]}`}
                      backgroundColor="#CFBEFD"
                      hasBorder={true}
                      sectionColor="#7c10be"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.activitySection}>
          {isCoverageScoreDataLoading ? (
            <TSkeleton />
          ) : (
            <div className={styles.secondColumnContainer}>
              <CoverageChart
                coverageData={coverageScoreData[0]}
              ></CoverageChart>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnersInfo;
