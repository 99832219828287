import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, makeStyles, TextField } from '@material-ui/core';
import ModalForm from '../ModalForm';
import OpportunityStatusSelect from './OpportunityStatusSelect';
import { updateOpportunity } from './api';

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
        '& input': {
            fontFamily: 'Quicksand'
        }
    }
}));

function EditOpportunityModal({ isOpen, onClose, fieldsData, refreshData }) {
    const classes = useStyles()
    const [companyName, setCompanyName] = useState(fieldsData.name || '');
    const [series, setSeries] = useState(fieldsData.series || '');
    const [status, setOpportunityStatus] = useState(fieldsData.status);
    const [fpcInvestment, setFpcInvestment] = useState(fieldsData.fpcInvestment || '');
    const [roundSize, setRoundSize] = useState( fieldsData.seriesSize || '');
    const [valuation, setValuation] = useState(fieldsData.preValuation || '');

    const dispatch = useDispatch();

    const updateCompany = () => {
        updateOpportunity({ dispatch,
            params: {
                tableCinchyId: fieldsData.tableCinchyId,
                status,
                fpcInvestment,
                series,
                roundSize,
                valuation
            }
        })
            .then(res => {
                refreshData();
                onClose();
            })
    };

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Edit opportunity
                            </h6>
                        </div>
                        <div>
                            <label className={`font-14`}>Company Name</label>
                            <TextField
                                disabled={true}
                                className={classes.textField}
                                variant="outlined"
                                fullWidth
                                label={null}
                                margin="dense"
                                type="text"
                                value={companyName|| ''}
                            />

                            <label className={`font-14`}>Series</label>
                            <TextField
                                type="text"
                                label={null}
                                className={classes.inputField}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                value={series}
                                onChange={(e) => setSeries(e.target.value)}
                            />

                            <label className={`font-14`}>Opportunity Status</label>
                            <OpportunityStatusSelect setName={setOpportunityStatus} selectedStatus={status} />

                            <label className={`font-14`}>Series Size</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={roundSize}
                                onChange={(e) => setRoundSize(e.target.value)}
                            />

                            <label className={`font-14`}>FPC Investment</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={fpcInvestment}
                                onChange={(e) => setFpcInvestment(e.target.value)}
                            />

                            <label className={`font-14`}>Pre-$ Valuation</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={valuation}
                                onChange={(e) => setValuation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={updateCompany}
                    >
                        <span className='font-12'>Update Opportunity</span>
                    </Button>
                </div>
            </ModalForm>
        </>
    );
}

export default EditOpportunityModal;