import React, { useState } from "react";
import { Typography, Button, makeStyles, Tooltip } from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  Edit,
  QueueOutlined,
} from "@material-ui/icons";
import { IOutlook } from "../../utils/dataGrouping";
import PrimaryCompetitorsModal from "../PrimaryCompetitorsModal";
import OutlookModal from "../OutlookModal";
import Outlook from "../Outlook";
import PrimaryCompetitors from "../PrimaryCompetitors";

import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles({
  collapseBtn: {
    backgroundColor: "#6e3cfa",
    color: "#ffffff",
    marginTop: "0.5rem",

    "&:hover": {
      backgroundColor: "#6e3cfa",
      color: "#ffffff",
    },
  },
});

const OverviewSummary = ({ comapnyId, summaryData, outlookData }: any) => {
  const [isShowAllOutlooks, setIsShowAllOutlooks] = useState(false);
  const [showAllSummary, setShowAllSummary] = useState(false);
  const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
  const [selectedOutlook, setSelectedOutlook] = useState<IOutlook | null>(null);

  const classes = useStyles();

  const showAllOutlooks = () => {
    setIsShowAllOutlooks(!isShowAllOutlooks);
  };

  let sortedCompanyOutlooks: any = [];

  if (outlookData.length) {
    sortedCompanyOutlooks = [...outlookData].sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  const openOutlookModal = (outlook: IOutlook | null) => {
    setSelectedOutlook(outlook);
    setIsOutlookModalOpen(true);
  };

  return (
    <section className={styles.summarySection}>
      <div className={styles.section}>
        {summaryData.length && summaryData[0].Description && (
          <div className={styles.group1}>
            <div className="d-flex align-items-center mb-2">
              <Typography className="font-14 font-weight-bold">
                Summary
              </Typography>
              <Button onClick={()=>setShowAllSummary(!showAllSummary)} id="blockElement">
                {showAllSummary ? (
                  <>
                    <span className={`${styles.collapseBtn} font-12`}>
                      Collapse
                    </span>
                    <ArrowDropUp />
                  </>
                ) : (
                  <>
                    <span className={`${styles.collapseBtn} font-12`}>
                      View All
                    </span>
                    <ArrowDropDown />
                  </>
                )}
              </Button>
            </div>
            <div className={styles.summaryContainer} >
              <div className={!showAllSummary ? styles.shortSummaryContainer: ""}>
                <p className="font-12">{summaryData[0].Description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.group}>
          <div className={styles.editable}>
            <div className="d-flex align-items-center">
            <Typography className="mr-2 font-14 font-weight-bold">
              Most Recent Outlook
            </Typography>
              <Tooltip title="Add new outlook" arrow placement="top">
                <Button
                  id="blockElement"
                  className="btn-primary mx-1 p-1 shadow-none border-0 d-inline-flex align-items-center justify-content-center"
                  onClick={() => openOutlookModal(null)}
                >
                  <FontAwesomeIcon
                    icon={["fas", "plus"]}
                    className="font-size-sm"
                  />
                  <span className="ml-1 font-10">Add New</span>
                </Button>
              </Tooltip>
            </div>
            <div>
              {sortedCompanyOutlooks.length > 1 && (
                <Button onClick={showAllOutlooks} id="blockElement">
                  {isShowAllOutlooks ? (
                    <>
                      <span className={`${styles.collapseBtn} font-12`}>
                        Collapse
                      </span>
                      <ArrowDropUp />
                    </>
                  ) : (
                    <>
                      <span className={`${styles.collapseBtn} font-12`}>
                        View All
                      </span>
                      <ArrowDropDown />
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>

          {!!sortedCompanyOutlooks.length && (
            <>
              <div className={styles.outlook}>
                {isShowAllOutlooks ? (
                  sortedCompanyOutlooks.map(
                    (outlook: IOutlook, index: number) => (
                      <Outlook
                        key={index}
                        outlook={outlook}
                        openOutlookModal={() => openOutlookModal(outlook)}
                      />
                    )
                  )
                ) : (
                  <Outlook
                    outlook={sortedCompanyOutlooks[0]}
                    openOutlookModal={() =>
                      openOutlookModal(sortedCompanyOutlooks[0])
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className={styles.section}>

                {!!formattedKeyInvestors.length && (
                    <div className={styles.group}>
                        <Typography className='mb-2 font-14 font-weight-bold'>Key Investors</Typography>
                        <div className={styles.summaryContainer}>
                            <p className="font-12">
                                {formattedKeyInvestors}
                            </p>
                        </div>
                    </div>
                )}

            </div> */}

      <OutlookModal
        isOpen={isOutlookModalOpen}
        companyId={comapnyId}
        selectedOutlook={selectedOutlook}
        setIsOpen={setIsOutlookModalOpen}
      />
    </section>
  );
};

export default OverviewSummary;
