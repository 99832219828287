import DateRange from '../../components/DateRange';
import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';

export const financingsFilterFields = [
    {
        name: 'Company',
        label: 'Company',
        component: MultiselectFormik,
    },
    {
        name: 'Status',
        label: 'Status',
        component: MultiselectFormik,
    },
    {
        name: 'Date',
        label: 'Date',
        component: DateRange,
    },
    {
        name: 'Owners',
        label: 'Owners',
        component: MultiselectFormik,
    },
    {
        name: 'Series',
        label: 'Series',
        component: MultiselectFormik,
    },
    {
        name: 'Stage',
        label: 'Stage',
        component: MultiselectFormik,
    },
    {
        name: 'Investors',
        label: 'Investors',
        component: MultiselectFormik,
    },
    {
        name: 'Miss / Pass / Lost',
        label: 'Miss / Pass / Lost',
        component: MultiselectFormik,
    },
    {
        name: 'Region',
        label: 'Region',
        component: MultiselectFormik,
    },
    {
        name: 'Category',
        label: 'Category',
        component: MultiselectFormik,
    },
    // {
    //     name: 'Date',
    //     label: 'Date',
    //     component: MultiselectFormik,
    // }
]