import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePath = () => {
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem("path", location.pathname + location.search);
    }, [location]);
}

export default usePath;