import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { ArrowLeft, ArrowRight, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import styles from './styles.module.scss';

function Pagination({ goToPage, currentPage, rowsPerPage, goToPageValue, totalPages, handleRowsPerPageChange, handleGoToPageChange, options=[], hasArrows=false ,hasGoToPage=false, inCenter=false, pageSize=5 }) {
    return (
        <div className={`${styles.paginationContainer} ${inCenter ? styles.center : styles.right}`}>
            {options.length > 0 && <div>
                <label htmlFor="mySelect" className="font-16">Rows per page:</label>
                <select
                    className={styles.currentPageInput}
                    id="mySelect"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                >
                    <option value={options[0]}>{options[0]}</option>
                    <option value={options[1]}>{options[1]}</option>
                    <option value={options[2]}>{options[2]}</option>
                </select>
            </div>}

            <div className={styles.pagination}>
                {hasArrows && <IconButton disabled={currentPage === 1}>
                    <KeyboardArrowLeft onClick={() => goToPage(currentPage - pageSize)} />
                </IconButton>}
                
                <IconButton disabled={currentPage === 1}>
                    <ArrowLeft onClick={() => goToPage(currentPage - 1)} />
                </IconButton>

                <span className="font-16">Page {currentPage} of {totalPages}</span>
                <IconButton disabled={currentPage === totalPages}>
                    <ArrowRight onClick={() => goToPage(currentPage + 1)} />
                </IconButton>
                {hasArrows && <IconButton disabled={currentPage === totalPages}>
                    <KeyboardArrowRight onClick={() => goToPage(currentPage + pageSize)} />
                </IconButton>}
                {hasGoToPage && 
                <>
                    <label htmlFor="myInput" className="font-16">Go To Page</label>
                    <input
                        type="text"
                        id="myInput"
                        value={goToPageValue}
                        onChange={handleGoToPageChange}
                        className={styles.currentPageInput}
                /></>}
                
            </div>
        </div>
    );
}

export default Pagination;