import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";

import ModalForm from "../ModalForm";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import styles from "./styles.module.scss";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
}));

const dataArray = [
  [
    "Infrastructure",
    "Infrastructure Operations",
    "Knowledge",
    "Asset Management",
  ],
  [
    "Infrastructure",
    "Infrastructure Operations",
    "Knowledge",
    "Backup and Recovery",
  ],
  [
    "Infrastructure",
    "Infrastructure Security",
    "Cloud Security",
    "Cloud Security Posture",
  ],
  ["Management", "Infrastructure Operations", "Knowledge", "Asset Management"],
  [
    "Management",
    "Infrastructure Operations",
    "Knowledge",
    "Backup and Recovery",
  ],
  [
    "Management",
    "Infrastructure Security",
    "Cloud Security",
    "Cloud Security Posture",
  ],
];

const result = {} as any;

dataArray.forEach((item) => {
  let temp = result;

  item.forEach((key, index) => {
    if (!temp[key]) {
      if (index === item.length - 1) {
        temp[key] = null;
      } else {
        temp[key] = {};
      }
    }
    temp = temp[key];
  });
});

interface GartnerInterviewModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface HubspotCheckbox {
  name: string;
  checked: boolean;
}

const GartnerInterviewModal: React.FC<GartnerInterviewModalProps> = ({
  isOpen = true,
  setIsOpen,
}) => {
  const classes = useStyles();

  const [titleValue, setTitleValue] = useState("");
  const [gartnerValue, setGartnerValue] = useState("");
  const [topicValue, setTopicValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [gotomarketText, setGotomarketText] = useState("");

  const handleTextChange = (value: any, setter: any) => {
    setter(value);
  };

  const handleSave = () => {
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTitleChange = (event: any) => {
    setTitleValue(event.target.value);
  };

  const handleGartnerChange = (event: any) => {
    setGartnerValue(event.target.value);
  };

  const handleTopicChange = (event: any) => {
    setTopicValue(event.target.value);
  };

  const handleDateChange = (event: any) => {
    setDateValue(event.target.value);
  };

  function groupData(data: any) {
    const groupedData = {} as any;

    data.forEach((item: any) => {
      const category = item[0];
      const subCategory = item.slice(1).join(" > ");

      if (!groupedData[category]) {
        groupedData[category] = [];
      }

      groupedData[category].push(subCategory);
    });

    return groupedData;
  }

  function TreeNode({
    label = null,
    children = null,
    topElement = false,
  }: any) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div>
        {label && (
          <div className={styles.subMenuContainer}>
            {children && (
              <div onClick={toggleOpen} style={{ cursor: "pointer" }}>
                {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </div>
            )}
            <span>{label}</span>
          </div>
        )}
        {(isOpen || topElement) && (
          <div className={!topElement ? styles.menuChildContainer : ""}>
            {children?.map((child: any, index: any) =>
              child[1] ? (
                <TreeNode
                  key={index}
                  label={Object.values(child)[0]}
                  children={Object.entries(child[1])}
                />
              ) : (
                <div className={styles.leafNodeContainer}>
                  <input
                    type="checkbox"
                    id="leafNode"
                    name="leafNode"
                    value="yes"
                    className={styles.leafNodeCheckbox}
                  />
                  <TreeNode
                    key={index}
                    label={Object.values(child)[0]}
                    children={null}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }

  const [companyArray, setCompanyArray] = useState([
    "Nike",
    "Adidas",
    "Acumen",
    "Lego",
    "Twix",
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([] as any);

  const handleInputChange = (e: any) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredCompanies = companyArray.filter(
      (company) => company.toLowerCase().includes(term.toLowerCase()) && term
    );

    setSuggestions(filteredCompanies as any);
  };

  const handleSelectCompany = (company: any) => {
    if (!selectedCompanies.includes(company)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
    const filteredCompanies = companyArray.filter((c) => company !== c);
    const filteredSuggestions = suggestions.filter((c) => company !== c);
    setSuggestions(filteredSuggestions);
    setCompanyArray(filteredCompanies as any);
  };

  const handleRemoveClick = (companyToRemove: any) => {
    const updatedSelectedCompanies = selectedCompanies.filter(
      (company: any) => company !== companyToRemove
    );
    setCompanyArray([...companyArray, companyToRemove]);

    setSelectedCompanies(updatedSelectedCompanies);
  };

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>Add Research / Thesis</h6>
          </div>
          <div>
            <div>
              <label htmlFor="inputField">Title</label>
            </div>
            <div>
              <input
                type="text"
                id="inputField"
                value={titleValue}
                onChange={handleTitleChange}
              />
            </div>
          </div>
          <div>
            <label>Tag Technology</label>
            <TreeNode children={Object.entries(result)} topElement={true} />
          </div>
          <div className={styles.searchCompaniesContainer}>
            <div className={styles.searchPartContainer}>
              <label>Tag companies</label>
              <input
                type="text"
                placeholder="Search for companies"
                className={styles.searchBar}
                value={searchTerm}
                onChange={handleInputChange}
              />
              {suggestions.map((company) => (
                <div className={styles.suggestedCompany} key={company}>
                  <span
                    className={styles.suggestedCompanyText}
                    onClick={() => handleSelectCompany(company)}
                  >
                    {company}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.selectedCompaniesContainer}>
              {!!selectedCompanies.length && <label>Selected Companies</label>}
              {selectedCompanies.map((company: string) => (
                <div className={styles.selectedCompany}>
                  <span className={styles.companyName} key={company}>
                    {company}
                  </span>
                  <Close
                    className={styles.closeButton}
                    onClick={() => handleRemoveClick(company)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="inputField">Gartner Analyst</label>
            </div>
            <div>
              <input
                type="text"
                id="inputField"
                value={gartnerValue}
                onChange={handleGartnerChange}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Topic</label>
            </div>
            <div>
              <input
                type="text"
                id="inputField"
                value={topicValue}
                onChange={handleTopicChange}
              />
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="inputField">Date</label>
            </div>
            <div>
              <input
                type="text"
                id="inputField"
                value={dateValue}
                onChange={handleDateChange}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Notes</label>
            </div>
            <div className={styles.internalNotesTextSection}>
              <div>
                <textarea
                  value={gotomarketText}
                  className={styles.fixedTextArea}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setGotomarketText)
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <label>Current</label>
            </div>
            <div>
              <input
                type="checkbox"
                value="yes"
                className={styles.currentCheckbox}
              />
            </div>
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSave}
            >
              send
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default GartnerInterviewModal;
