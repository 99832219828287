import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { sidebarToggleMobile as setSidebarToggleMobile} from '../../../store/themeOptions/themeOptionsSlice';
import clsx from 'clsx';

// TODO CF change icons to MUI
import { Button } from '@material-ui/core';

import { selectThemeOptions } from '../../../store/themeOptions/themeOptionsSlice';

const SidebarHeader = () => {
  const dispatch = useDispatch();
  const { sidebarToggleMobile} = useSelector(selectThemeOptions);
  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  return (
    <>
      <div className="app--header-logo">
        <div className="app-sidebar-logo">
          <NavLink to="/" title="FORGEPOINT" className="app-sidebar-logo">
            <img style={{ width: 200 }} alt="ForgePoint" src="/relationships/logo.png" />
          </NavLink>
        </div>
        <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
      </div>
    </>
  );
};

export default SidebarHeader;
