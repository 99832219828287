import React, { useState } from 'react';
import { Button, Paper, Tooltip } from '@material-ui/core';

import CreateCompanyModal from '../../../components/CreateCompanyModal';
import InsertRowModal from './InsertRowModal';
import ColumnsModal from './ColumnsModal';

import styles from './styles.module.scss';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TableActions({
     columns,
     selectedColumns,
     handleSelectColumns,
     openNotification
}) {

    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);
    const [isCreateCompModalOpen, setIsCreateCompModalOpen] = useState(false);
    const [isInsertModalOpen, setIsInsertModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleOpenNotification = (message) => {
        openNotification(message)
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                {isMenuOpen && <Paper className='p-2'>
                    <div className={styles.actions}>
                        <Button
                            size="medium"
                            onClick={() => setIsColumnsModalOpen(!isColumnsModalOpen)}
                            className='btn-second mr-2'
                        >
                            <span className="btn-wrapper--label font-14">columns</span>
                        </Button>
                        <Button
                            size="medium"
                            onClick={() => setIsCreateCompModalOpen(!isCreateCompModalOpen)}
                            className='btn-second mr-2'
                        >
                            <FontAwesomeIcon
                                icon={["fas", "plus"]}
                                className="font-size-sm"
                            />
                            <span className="btn-wrapper--label font-14">Company</span>
                        </Button>
                        <Button
                            size="medium"
                            onClick={() => setIsInsertModalOpen(true)}
                            className='btn-second mr-2'
                        >
                            <FontAwesomeIcon
                                icon={["fas", "plus"]}
                                className="font-size-sm"
                            />
                            <span className="btn-wrapper--label font-14">new entry</span>
                        </Button>
                    </div>
                </Paper>}
                <Tooltip title={`${isMenuOpen ? 'Hide actions' : 'Financing actions'}`}>
                    <IconButton disableRipple aria-label="company actions" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <CloseIcon fontSize="small"  /> : <FilterListIcon fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </div>

            <CreateCompanyModal
                isOpen={isCreateCompModalOpen}
                onClose={() => setIsCreateCompModalOpen(false)}
            />

            <InsertRowModal
                isOpen={isInsertModalOpen}
                onClose={() => setIsInsertModalOpen(false)}
                openNotification={(message) => handleOpenNotification(message)}
            />

            <ColumnsModal
                isOpen={isColumnsModalOpen}
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
            />
        </>
    );
}
