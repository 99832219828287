import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCompanyCustomer,
  getKeyCustomersColumnWidths,
} from "../../../store/sourcing/sourcingThunks";
import {
  Table,
  Button,
  Tooltip,
  Typography,
  Snackbar,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowLeft,
  ArrowRight,
  Delete,
} from "@material-ui/icons";
import {
  addCompanyCustomer,
  updateCompanyCustomer,
} from "../../../store/sourcing/sourcingThunks";
import { Done, Close } from "@material-ui/icons";
import DatePicker from "../../../example-components/FormsDatepicker/FormsDatepicker1";

import styles from "../styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatContent,
  formatColumnColumnFormats,
  checkFormat,
  FORMATTING_TYPES,
  parseDate,
} from "../../../utils/formatting";
import { selectKeyCustomersColumnWidths } from "../../../store/sourcing/sourcingSlice";
import Pagination from "../../Pagination/Pagination";
import usePagination from "../../../hooks/usePagination";

const KeyCustomersTable = ({
  tableData,
  companyId,
  handleRefresh,
  pagesDisplayed = true,
}: any) => {
  const [keyCustomersData, setKeyCustomersData] = useState([] as any);
  const [rowToUpdate, setRowToUpdate] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);
  const [columnFormatValues, setColumnFormatValues] = useState(null);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  let columnWidths = useSelector(selectKeyCustomersColumnWidths);

  useEffect(() => {
    dispatch(getKeyCustomersColumnWidths());
  }, []);

  useEffect(() => {
    if (columnWidths?.rows?.length) {
      setColumnFormatValues(formatColumnColumnFormats(columnWidths.rows));
    }
  }, [columnWidths]);

  useEffect(() => {
    let tableHeaders = tableData.columns;
    if (tableData.data?.length) {
      tableHeaders = Object.keys(tableData.data[0]);
    }
    setHeaders(tableHeaders);
    setKeyCustomersData(
      tableData.data?.map((row: any) => {
        let finalRow = [] as any;
        tableHeaders.map((header: any) => finalRow.push(row[header]));

        return finalRow;
      })
    );
  }, [tableData.data]);

  const [insertedData, setInsertedData] = useState({} as any);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [updatedRowIndex, setUpdatedRowIndex] = useState(-1);
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;
  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: keyCustomersData });

  const addRow = () => {
    setIsInsertOpen(true);
    let newRow = {} as any;
    headers.slice(3, headers.length).forEach((header: string) => {
      if (checkFormat(
        header,
        columnFormatValues,
        FORMATTING_TYPES.DATE
      )) {
        newRow = { ...newRow, ...{ [header]: parseDate(new Date()) } };
      } else {
        newRow = { ...newRow, ...{ [header]: "" } };
      }
    });
    setInsertedData(newRow);
  };

  const cancelInsert = () => {
    setIsInsertOpen(false);
    setIsUpdateDisabled(false)
  };

  const handleInputChange = useCallback(
    (value: any, columnName: string) => {
      setInsertedData((previous: any) => {
        if (typeof value === "object") {
          previous[columnName] = parseDate(value);
          if(value) {
            if(isNaN(value.getTime())) {
              setIsUpdateDisabled(true)
            } else {
              setIsUpdateDisabled(false)
            }
          } else {
            setIsUpdateDisabled(true)
          }
        } else {
          previous[columnName] = value;
        }
        return { ...previous };
      });
    },
    [setInsertedData]
  );

  const insertRow = useCallback(async () => {
    setIsInsertOpen(false);
    await dispatch(addCompanyCustomer({data: insertedData, companyId: companyId}));
    openNotification({
      message: "Data inserted successfully",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
    handleRefresh();
  }, [insertedData]);

  const handleRowUpdate = useCallback(
    (index: number) => {
      setRowToUpdate([...keyCustomersData[index]]);
      setUpdatedRowIndex(index);
    },
    [keyCustomersData]
  );

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (keyCustomersData[index][1]) {
        await dispatch(deleteCompanyCustomer(keyCustomersData[index][1]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });

      handleRefresh();
    },
    [keyCustomersData]
  );

    const cancelUpdate = useCallback(
      (index: number) => {
        setKeyCustomersData((previous: any) => {
          previous[index] = rowToUpdate;
          return [...previous];
        });
        setUpdatedRowIndex(-1);
        setIsUpdateDisabled(false)
      },
      [rowToUpdate]
    );

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

    const saveUpdate = useCallback(
      async (index: number) => {
        let finalData = {} as any;
        headers.map((col: string, i: number) => {
          finalData = {
            ...finalData,
            ...{ [col]: keyCustomersData[index][i] },
          };
        });

        let isDataFilled = true;

        Object.keys(finalData).forEach((d: any) => {
          if (!finalData[d]) {
            isDataFilled = false;
            return;
          }
        });

        setUpdatedRowIndex(-1);

        await dispatch(updateCompanyCustomer({data: finalData}));
        if (isDataFilled) {
          openNotification({
            message: "Data updated successfully",
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
          });
        }
        handleRefresh();
      },
      [keyCustomersData]
    );

    const handleUpdateChange = useCallback(
      (value: any, rowIndex: number, colIndex: number) => {
        setKeyCustomersData((previous: any) => {
          if (typeof value === "object") {
            if(value) {
              if(isNaN(value.getTime())) {
                setIsUpdateDisabled(true)
              } else {
                setIsUpdateDisabled(false)
              }
            } else {
              setIsUpdateDisabled(true)
            }
            previous[rowIndex][colIndex] = parseDate(value);
          } else {
            previous[rowIndex][colIndex] = value;
          }
          return [...previous];
        });
      },
      []
    );

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <div className={styles.keyCustomersHeader}>
        <Typography
          style={{ whiteSpace: "nowrap" }}
          className="mr-2 font-14 font-weight-bold"
        >
          Key Customers
        </Typography>
        {!isInsertOpen && (
          <div id="blockElement" className={styles.addNewEntryBtnContainer}>
            <Tooltip title="Add new entry" arrow placement="top">
              <Button
                id="blockElement"
                className="btn-primary mx-1 p-1 shadow-none border-0 d-inline-flex align-items-center justify-content-center"
                onClick={addRow}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="font-size-sm"
                />
                <span className="ml-1 font-10">Add New</span>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="table-responsive-md mb-spacing-2-x2">
        <Table
          className={`table table-hover table-striped table-bordered ${styles.table}`}
        >
          <thead className={styles.tableHead}>
            <tr className="font-12">
              {headers?.map(
                  (header: string, index: number) =>
                    index > 2 && <th key={index}>{header}</th>
                )}
              <th id="actions" className={`${styles.actionsColumn} font-12`}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {keyCustomersData?.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
                    ?.map((row: any, index: number) => {
              const rowIndex = (currentPage - 1) * 5 + index;
              return (
                <tr
                  className={
                    (rowIndex !== updatedRowIndex && updatedRowIndex !== -1) ||
                    isInsertOpen
                      ? styles.disabledRow
                      : ""
                  }
                  key={index}
                >
                  {row
                    .slice(0, row.length)
                    .map((col: any, colIndex: number) => {
                      if (colIndex > 2) {
                        return rowIndex !== updatedRowIndex ? (
                          <td key={colIndex} className="font-12">
                            {formatContent(
                              row,
                              col,
                              headers[colIndex],
                              columnFormatValues
                            )}
                          </td>
                        ) : (
                          <td key={colIndex}>
                            {checkFormat(
                              headers[colIndex],
                              columnFormatValues,
                              FORMATTING_TYPES.DATE
                            ) ? (
                              <DatePicker
                                handleSelectDate={(date: any) =>
                                  handleUpdateChange(date, rowIndex, colIndex)
                                }
                                selectedDate={col}
                                isEmpty={isUpdateDisabled}
                              ></DatePicker>
                            ) : (
                              <input
                                type="text"
                                value={col}
                                className={styles.input}
                                onChange={(e) =>
                                  handleUpdateChange(
                                    e.target.value,
                                    rowIndex,
                                    colIndex
                                  )
                                }
                              />
                            )}
                          </td>
                        );
                      }
                    })}
                  <td id="actions" className="text-center">
                    {updatedRowIndex === -1 || rowIndex !== updatedRowIndex ? (
                      <>
                        <img
                          onClick={() => handleRowUpdate(rowIndex)}
                          className={styles.buttonClickable}
                          src="/edit.svg"
                          alt="Company Logo"
                          id="blockElement"
                        />

                        <Delete
                          className={styles.buttonClickable}
                          onClick={() => handleRowDelete(rowIndex)}
                        ></Delete>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 mr-1`}
                          disabled={isUpdateDisabled}
                          onClick={() => saveUpdate(rowIndex)}
                        >
                          <Done/>
                        </Button>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 ml-1`}
                          onClick={() => cancelUpdate(rowIndex)}
                        >
                          <Close/>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            {isInsertOpen && (
              <tr>
                {Object.keys(insertedData).map((col, index) => (
                  <td key={index}>
                    {checkFormat(
                      headers[index + 3],
                      columnFormatValues,
                      FORMATTING_TYPES.DATE
                    ) ? (
                      <DatePicker
                        handleSelectDate={(date: any) =>
                          handleInputChange(date, col)
                        }
                        selectedDate={insertedData[col]}
                        isEmpty={isUpdateDisabled}
                      ></DatePicker>
                    ) : (
                      <input
                        type="text"
                        value={insertedData[col]}
                        className={styles.input}
                        onChange={(e) => handleInputChange(e.target.value, col)}
                      />
                    )}
                  </td>
                ))}
                <td className="text-center">
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 mr-1`}
                    disabled={isUpdateDisabled}
                    onClick={insertRow}
                  >
                    <Done/>
                  </Button>
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 ml-1`}
                    onClick={cancelInsert}
                  >
                    <Close/>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagesDisplayed && (
                  <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                  handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
    </>
  );
};

export default KeyCustomersTable;
