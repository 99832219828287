import React, { useEffect, useState, useCallback } from "react";
import { useSectors } from "../../hooks/useSectors";
import { splitSectors } from "../../utils/formatting";
import SectorSelect from "../SectorSelect";
import TaxonomyModal from "../TaxonomyModal";
import styles from "./styles.module.scss";

interface TaxonomyOverviewTagsProps {
  data: any;
  companyId: number;
  refreshApi: () => void
}

const TaxonomyOverviewTags = ({ data, companyId, refreshApi }: TaxonomyOverviewTagsProps) => {
  const [categoiesTags, setCategoiesTags] = useState([]);
  const [subCategoriesTags, setSubCategoriesTags] = useState([]);
  const [technologyTags, setTechnologyTags] = useState([]);
  const [keyWordsTags, setKeyWordsTags] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useSectors()

  useEffect(() => {
    if (data?.[0]) {
      setCategoiesTags(data?.[0]?.["Category"]?.split(","));
      setSubCategoriesTags(data?.[0]?.["Sub Category"]?.split(","));
      setTechnologyTags(data?.[0]?.["Technology"]?.split(","));
      setKeyWordsTags(data?.[0]?.["Key Words"]?.split(", "));
      setSectors(splitSectors(data?.[0]?.["Sectors"]));
    }
  }, [data]);

  const handleEdit = useCallback(() => {
    setIsModalOpen(true)
  }, []);

  return (
    <>
      <div className={styles.taxonomyTitleContainer}>
        <p className={`${styles.taxonomyText} font-14 font-weight-bold`}>Taxonomy</p>
        <img
            onClick={handleEdit}
            className={styles.editButton}
            src="/edit.svg"
            alt="Company Logo"
            id="blockElement"
          />
      </div>
      <div className={styles.singleSection}>
        <div>
          <p className={`${styles.taxonomySubText} mt-0 font-12`}>Categories</p>
          <div className={styles.taxonomySubSection}>
            {categoiesTags?.map((item: string) => (
              <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.taxonomySubText} font-12`}>Sub-Categories</p>
          <div className={styles.taxonomySubSection}>
            {subCategoriesTags?.map((item: string) => (
              <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.taxonomySubText} font-12`}>Technology</p>
          <div className={styles.taxonomySubSection}>
            {technologyTags?.map((item: string) => (
              <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.taxonomySubText} font-12`}>Key Words</p>
          <div className={styles.taxonomySubSection}>
            {keyWordsTags?.map((item: string) => (
              <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.taxonomySubText} font-12`}>Sectors</p>
          <div className={styles.sectorContainer}>
            <SectorSelect cinchyId={companyId.toString()} sectorsInfo={sectors}></SectorSelect>
          </div>
        </div>
      </div>
      <TaxonomyModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          companyId={companyId}
          refreshApi={refreshApi}
      />
    </>
  );
};

export default TaxonomyOverviewTags;
