import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Button, makeStyles, TextField } from "@material-ui/core";
import {
  updateNote,
} from "../../store/sourcing/sourcingThunks";
import ModalForm from "../ModalForm";
import styles from "./styles.module.scss";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
  inputField: {
    minWidth: "270px",
  },
}));

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  companyId: number;
  refreshApi?: () => void;
  noteValue: string;
};

const EditNoteModal: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  companyId,
  refreshApi,
  noteValue,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setNote(noteValue)
}, [noteValue])

  const handleInputChange = (e: any) => {
    setNote(e.target.value);
  };

  const handleSubmit = useCallback(async () => {
    await dispatch(
      updateNote({
        cinchyId: companyId,
        note: note,
      })
    );
    setIsOpen(false);
    refreshApi && refreshApi();
  }, [note]);

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>Edit Notes</h6>
          </div>
          <div className={styles.container}>
              <TextField
                type="text"
                placeholder="Notes"
                className={classes.inputField}
                variant="outlined"
                margin="dense"
                value={note}
                onChange={handleInputChange}
              />
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};
export default EditNoteModal;
