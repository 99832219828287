import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Toolbar as TB, Typography } from '@material-ui/core';
import { useToolbarStyles } from './styles';

const Toolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, withTitle = true, children, title } = props;

    return (
        <TB
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0
            })}>
            {withTitle && (numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div">
                    {title}
                </Typography>
            ))}
            {children}
        </TB>
    );
};

Toolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

export default Toolbar;