import React, { useState } from "react";

import { Card } from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullWidthDatePicker: {
    width: "100%",
    "& input": {
      fontSize: "12px",
    },
  },
}));

export default function LivePreviewExample({ handleSelectDate, selectedDate, isEmpty = false }) {
  const classes = useStyles();
  const handleDateChange = (date) => {
    handleSelectDate(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedDate}
            error={isEmpty}
            onChange={handleDateChange}
            className={classes.fullWidthDatePicker}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
}
