import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {  Button, makeStyles, TextField  } from '@material-ui/core';

import ModalForm from '../../../components/ModalForm';
import CompanyNameSelect from '../../../components/CreateCompanyModal/CompanyNameSelect';

import DatePicker from '../../../example-components/FormsDatepicker/FormsDatepicker1';
import MPLSelect from './MPLSelect';

import { parseDate } from '../../../utils/formatting';
import { getFinancingsTable, insertFinancingCompany } from '../../../store/sourcing/sourcingThunks';

import styles from '../../Relationships/Customers/styles.module.scss';

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
        '& input': {
            fontFamily: 'Quicksand'
        }
    }
}));

const ADD_FIELDS = [
    { name: 'Company Name', key: '@companyid', component: 'Input', type: 'singleSelect'},
    { name: 'Series', key: '@series', component: 'Input', type: 'text'},
    { name: 'Miss / Pass / Lost', key: '@misspasslost', component: 'Input', type: 'mpl'},
    { name: 'Miss / Pass / Lost Reason', key: '@misspasslostreason', component: 'Input', type: 'text'},
    { name: 'FPC Invested ($M)', key: '@fpcinvested', component: 'Input', type: 'number'},
    { name: 'Round Size ($M)', key: '@totalinvestment', component: 'Input', type: 'number'},
    { name: 'Total Raised ($M)', key: '@totalfunding', component: 'Input', type: 'number'},
    { name: 'Valuation ($M)', key: '@valuation', component: 'Input', type: 'number'},
    { name: 'Date', key: '@date', component: 'Date', type: 'date'},
];

export default function CreateCompanyModal (props) {
    const { isOpen, onClose, openNotification } = props;
    const classes = useStyles();
    const [editedData, setEditedData] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMPL, setSelectedMPL] = useState('');

    const dispatch = useDispatch();


    const editData = useCallback((value, name) => {
        setEditedData((previous) => {
            previous[name] = value;
            return { ...previous };
        });
    }, []);


    const insertCompany = useCallback(async () => {
        await dispatch(insertFinancingCompany({...editedData, '@date': parseDate(selectedDate), '@misspasslost': selectedMPL }));
        onClose();
        await dispatch(getFinancingsTable())
        openNotification("Row successfully added")
    }, [editedData, selectedDate]);

    const handleSelectDate = (date) => {
        setSelectedDate(date);
    };

    const handleSelectMPL = (option) => {
        setSelectedMPL(option);
    };

    const editCompanyName = (option) => editData(option, '@companyid')



    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Add New Entry
                            </h6>
                        </div>
                        <div>
                            {ADD_FIELDS.map(({ type, name, key  }) => (type === 'date' ?  <section key={key}>
                                    <label className='font-14'>{name}</label>
                                    <div className={styles.datePicker}>
                                        <DatePicker
                                            handleSelectDate={(date) => handleSelectDate(date)}
                                            selectedDate={selectedDate}
                                        ></DatePicker>
                                    </div>
                                </section> : type !== 'mpl' ?  <section key={key}>
                                    <label className={`font-14`}>{name}</label>
                                    {type === 'singleSelect' ? <CompanyNameSelect setName={editCompanyName} /> : <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                        label={null}
                                        margin="dense"
                                        type={type}
                                        value={editedData[key] || ''}
                                        onChange={(e) => editData(e.target.value, key)}
                                    />}
                                </section> : <section key={key}>
                                <label className={`font-14`}>{name}</label>
                                <MPLSelect handleSelect={handleSelectMPL} />
                            </section>))
                            }
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={insertCompany}
                        disabled={!editedData['@companyid']}
                    >
                        <span className='font-12'>Insert Company</span>
                    </Button>
                </div>
            </ModalForm>
        </>
    );
}