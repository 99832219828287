import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { insertArr } from "../../store/sourcing/sourcingThunks";
import ModalForm from "../ModalForm";
import { IOutlook } from "../../utils/dataGrouping";
import { Done, Close } from "@material-ui/icons";

import styles from "./styles.module.scss";
import { SingleSelect } from "../SingleSelect/SIngleSelect";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    marginRight: "1rem",

    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },

  item: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    marginTop: '2px',
    '& p': {
        fontSize: '10px',
        color: '#7a7b97',
        fontFamily: 'Quicksand',
        margin: 0,
    },
  }
}));

type ModalProps = {
  isOpen: boolean;
  companyName: any;
  selectedOutlook: IOutlook | null;
  setIsOpen: (isOpen: boolean) => void;
  handleRefresh?: () => void;
};

const ArrModal: React.FC<ModalProps> = ({
  isOpen,
  companyName,
  selectedOutlook,
  setIsOpen,
  handleRefresh,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [arrValue, setArrValue] = useState("");
  const [arrDate, setArrDate] = useState("");
  
  const handleSave = async () => {
    if (!arrValue || !arrDate) {
      return;
    }

    await dispatch(
      insertArr({
        companyId: companyName,
        date: arrDate,
        arr: arrValue,
      })
    );

    handleRefresh && handleRefresh();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClose = () => {
    if (selectedOutlook) {
      setArrValue(selectedOutlook.Outlook);
      setArrDate(moment(selectedOutlook.Date).format("YYYY-MM-DD"));
    } else {
      setArrValue("");
      setArrDate("");
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedOutlook) {
      setArrValue(selectedOutlook.Outlook);
      setArrDate(moment(selectedOutlook.Date).format("YYYY-MM-DD"));
    } else {
      setArrValue("");
      setArrDate("");
    }
  }, [selectedOutlook]);

  const isSaveDisabled =  arrDate === '' || arrValue === '';

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6>Arr</h6>
          </div>
          <div>
            <section>
              <label className={`font-14`}>Date</label>
              <TextField
                className={classes.textField}
                variant="outlined"
                type="date"
                fullWidth
                label={null}
                value={arrDate}
                margin="dense"
                helperText="Required field"
                onChange={(e) => {
                  setArrDate(e.target.value);
                }}
              />
            </section>
            <section>
              <label className={`font-14`}>Arr</label>
              <TextField
                className={classes.textField}
                variant="outlined"
                fullWidth
                label={null}
                value={arrValue}
                margin="dense"
                helperText="Required field"
                onChange={(e) => setArrValue(e.target.value)}
              />
            </section>
          </div>
        </div>
      </div>
      <div className="text-center pb-4">
        <Button
          variant="contained"
          className={classes.saveBtn}
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          <span className="font-12">Save</span>
        </Button>
      </div>
    </ModalForm>
  );
};

export default ArrModal;
