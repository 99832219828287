import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ColumnsModal from '../../ColumnsModal';

export default function TableActions({
    columns,
    selectedColumns, 
    handleSelectColumns ,
    children,
}) {
    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-2">
                <Button
                    size="medium"
                    onClick={() => setIsColumnsModalOpen(!isColumnsModalOpen)}
                    className='btn-second mr-2'
                >
                    <span className="btn-wrapper--label font-14">edit columns</span>
                </Button>
                {children}
            </div>

            <ColumnsModal  
                isOpen={isColumnsModalOpen} 
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
            />

        </>
    );
}
