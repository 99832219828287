import React, { useState, useEffect, ChangeEvent } from "react";
import {
    makeStyles,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItem,
    List,
    Box,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ModalForm from "../ModalForm";
import { getTagOptions } from "../../store/sourcing/sourcingThunks";
import { selectTagOptions } from "../../store/sourcing/sourcingSlice";
import ModalContent from "./ModalContent";
import { TagOption } from "../../interfaces";
import { createTagOption } from "../../store/sourcing/sourcingThunks";
import { removeDublicateTags } from "../../helpers";
import * as NotificationHelper from '../../helpers/notification';

import styles from './styles.module.scss';

const useStyles = makeStyles({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    selectField: {
        width: '100%',
    },
    inputField: {
        marginBottom: '1rem',
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CreateCompanyTagsModal = ({
    isOpen,
    setIsOpen,
}: any) => {
    const [tagName, setTagName] = useState('');
    const [tagType, setTagType] = useState('');
    const [tagCreator, setTagCreator] = useState('');
    const [searchedTags, setSearchedTags] = useState<TagOption[] | null>(null);
    const [error, setError] = useState({error: false, message: ''});
    const [activeTab, setActiveTab] = useState('create');

    const tagOptions: TagOption[] = useSelector(selectTagOptions);
   
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleAddTagName = (e: ChangeEvent<HTMLInputElement>) => {
        if (error.error) {
            setError({error: false, message: ''});
        }
        setTagName(e.target.value);
    };

    const handleAddTagCreator = (e: ChangeEvent<HTMLInputElement>) => {
        setTagCreator(e.target.value);
    };

    const handleSelectTagType = (e: ChangeEvent<any>) => {
        setTagType(e.target.value);
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchedValue = e.target.value;
        
        if (searchedValue && tagOptions.length) {
            const tags = tagOptions.filter(tagOption => tagOption.Tag.toLowerCase().startsWith(searchedValue.toLowerCase()));
            setSearchedTags(tags);
        } else {
            setSearchedTags(null);
        }
    };

    const changeTab = (item: string) => {
        setSearchedTags(null);
        setActiveTab(item)
    };

    const handleSave = async () => {
        const existedTags = tagOptions.map(tag => tag.Tag.toLowerCase());
        if (!existedTags.includes(tagName.toLocaleLowerCase())) {
            await dispatch(createTagOption({
                tagType, 
                tagName, 
                tagCreator
            }));
            dispatch(getTagOptions());
            NotificationHelper.show('Tag successfully added', 'success');
            handleClose()
        } else {
            setError({error: true, message: 'This tag already exists'});
        }
    };

    const handleClose = () => {
        setTagName('');
        setTagType('');
        setTagCreator('');
        setIsOpen(false);
        setSearchedTags(null);
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(getTagOptions());
        }
    }, [isOpen]);

    return (
        <ModalForm open={isOpen} handleClose={handleClose} maxWidth={'sm'} fullWidth={true} fixedHeight={true}>
            <div className="border-0">
                <div className={styles.modalContent}>
                    <div className={styles.modalTitle}>Tags</div>
                    <Box display="flex" justifyContent="center" width="100%">
                        <List component="div" className="nav-line d-flex nav-tabs-primary">
                            <ListItem
                                key={0}
                                button
                                disableRipple
                                selected={activeTab === "create"}
                                onClick={() => changeTab("create")}
                                >
                                    <span>Create</span>
                                    <div className="divider" />
                            </ListItem>
                            <ListItem
                                key={1}
                                button
                                disableRipple
                                selected={activeTab === "view"}    
                                onClick={() => changeTab("view")}
                                >
                                    <span>View</span>
                                    <div className="divider" />
                            </ListItem>
                        </List>
                    </Box>
                    {activeTab === "create" && <><section className={styles.fieldSection}>
                        <FormControl fullWidth variant="outlined" size="small" className={classes.inputField}>
                            <InputLabel>Tag Type</InputLabel>
                            <Select
                                value={tagType}
                                className={classes.selectField}
                                variant="outlined"
                                onChange={handleSelectTagType}
                                label="Tag Type"
                            >
                                {tagOptions && tagOptions.length ? (
                                    removeDublicateTags(tagOptions).map((item, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={item["Tag Type"]}
                                            // className={classes.menuItem}
                                            >
                                                {item["Tag Type"]}
                                            </MenuItem>
                                        )
                                    })
                                ) : (
                                    <></>
                                )}
                            </Select>
                        </FormControl>

                        <TextField
                            variant="outlined"
                            className={classes.inputField}
                            fullWidth
                            label={'Tag Name'}
                            value={tagName}
                            margin="dense"
                            disabled={tagType ? false : true}
                            onChange={handleAddTagName}
                            error={error.error}
                            helperText={error.message}
                        />

                        <TextField
                            variant="outlined"
                            className={classes.inputField}
                            fullWidth
                            label={'Tag Creator'}
                            value={tagCreator}
                            margin="dense"
                            disabled={tagType ? false : true}
                            onChange={handleAddTagCreator}
                        />
                    </section>
                    <div className={styles.actions}>
                        <Button
                            variant="contained"
                            className={classes.saveBtn}
                            onClick={handleSave}
                            disabled={!tagType || !tagName || !tagCreator ? true : false}
                        >
                            Create
                        </Button>
                    </div>
                    </>
                    }
                    {tagOptions.length && activeTab === "view" && (
                        <ModalContent 
                            tags={searchedTags ? searchedTags : tagOptions}
                            handleSearch={handleSearch}
                        />
                    )}                
                </div>

            </div>
        </ModalForm>
    );
};

export default CreateCompanyTagsModal;
