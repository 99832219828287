import React from "react";
import TSkeleton from '../../../components/TSkeleton';

const BarChart = ({data, isLoading, textKey, valueKey, title}) => {

  const styles = {
    chartContainer: {
      border: '1px solid lightgray',
      margin: '20px 0',
      padding: '10px',
      width: '100%',
    },
    bar: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
      height: "50px"
    },
    barLabel: {
      width: "30%",
      textAlign: "right",
      marginRight: "10px",
    },
    barValue: {
      height: "30px",
      backgroundColor: "#166082",
      border: "1px solid white",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: "5px",
      boxSizing: "border-box",
      color: "#fff",
    },
    barContainer: {
        width: '100%',
    }
  };

  return (
    <>
    <><h5 className="font-18">{title}</h5>
    
    {isLoading ? <TSkeleton/> :<div style={styles.chartContainer}>
      {[...data]?.sort((a,b)=>{
        if(a[valueKey] < b[valueKey]) return 1
        if(a[valueKey] > b[valueKey]) return -1
      }).map((item, index) => (
        <div key={index} style={styles.bar}>
          <div style={styles.barLabel} className="font-14">{item[textKey]}</div>
          <div style={styles.barContainer}>
            <div style={{ ...styles.barValue, width: `${item[valueKey]}%`, backgroundColor: `#${item["Color"]}` }}>
              {item[valueKey]}
            </div>
          </div>
        </div>
      ))}
    </div> } </>
    </>
  );
};

export default BarChart;
