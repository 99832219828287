import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
    Button,
    makeStyles,
    TextField,
    MenuItem,
} from '@material-ui/core';
import { 
    getCompanyOutlook, 
    addCompanyOutlook, 
    updateCompanyOutlook,
    addInvestorOutlook
} from '../../store/sourcing/sourcingThunks';
import ModalForm from '../ModalForm';
import { IOutlook } from '../../utils/dataGrouping';
import { Done, Close } from '@material-ui/icons';
import * as NotificationHelper from '../../helpers/notification';
import { PAGE_NAMES } from '../../constants';

import styles from './styles.module.scss';
import { updateCompaniesData, updateFinancingsData, updateFlagsData } from '../../store/sourcing/sourcingSlice';
import LivePreviewExample from "../../example-components/FormsWysiwygEditor/FormsWysiwygEditor1";

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        marginRight: '1rem',

        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },

    item: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
    }
}));

type ModalProps = {
    isOpen: boolean;
    companyId: string | number;
    selectedOutlook: IOutlook | null;
    setIsOpen: (isOpen: boolean) => void;
    handleRefresh?: () => void;
    investorOutlook?: boolean
    activeTab?: string
    index?: number
    pageName?: string
}

const OutlookModal: React.FC<ModalProps> = ({ 
    isOpen, 
    companyId, 
    selectedOutlook, 
    setIsOpen,
    handleRefresh, 
    investorOutlook,
    activeTab,
    index,
    pageName,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [outlookText, setOutlookText] = useState('');
    const [outlookDate, setOutlookDate] = useState('');
    const [isErrors, setIsErrors] = useState({
        date: '',
        text: '',
    })
    const [mode, setMode] = useState('create');

    const handleSave = async () => {
        if (!outlookText || !outlookDate) {
            return;
        }

        if (mode === 'create') {
            if(investorOutlook) {
                await dispatch(addInvestorOutlook({ investorId: companyId, date: moment(outlookDate).format("YYYY-MM-DD"), outlook: outlookText }));
            } else {
            await dispatch(addCompanyOutlook({ companyId, date: moment(outlookDate).format("YYYY-MM-DD"), outlook: outlookText }));
            if(pageName === PAGE_NAMES.COMPANIES) {
                await dispatch(updateCompaniesData({activeTab : activeTab, index: index, data: {
                    "Recent Outlook" : outlookText,
                    "Recent Outlook Date" : moment(outlookDate).format("YYYY-MM-DD"),
                }}))
            } else if(pageName === PAGE_NAMES.FINANCINGS) {
                await dispatch(updateFinancingsData({ activeTab : activeTab, index: index, data: {
                    "Recent Outlook" : outlookText,
                    "Recent Outlook Date" : moment(outlookDate).format("YYYY-MM-DD"),
                }}))
            } else if(pageName === PAGE_NAMES.FLAGS) {
                await dispatch(updateFlagsData({ index: index, data: {
                    "Recent Outlook" : outlookText,
                    "Recent Outlook Date" : moment(outlookDate).format("YYYY-MM-DD"),
                }}))
            }
            }
            NotificationHelper.show('Company outlook successfully created', 'success');
            handleRefresh && handleRefresh()
        } else if (mode === 'edit' && selectedOutlook) {
            await dispatch(updateCompanyOutlook({ companyId: selectedOutlook['Table Cinchy Id'], date: outlookDate, outlook: outlookText }));
            NotificationHelper.show('Company outlook successfully updated', 'success');
        }

        await dispatch(getCompanyOutlook(companyId as number));
        handleClose();
    };

    const handleCancel = () => {
        handleClose();
    };

    const handleClose = () => {
        if (selectedOutlook) { 
            setOutlookText(selectedOutlook.Outlook);
            setOutlookDate(moment(selectedOutlook.Date).format("YYYY-MM-DD"))
        } else {
            setOutlookText("");
            setOutlookDate("");   
        }
        setIsOpen(false);
        setMode('create');
    };

    useEffect(() => {
        if (selectedOutlook) { 
            setOutlookText(selectedOutlook.Outlook);
            setOutlookDate(moment(selectedOutlook.Date).format("YYYY-MM-DD"));
            setMode('edit');
        } else {
            setOutlookText("");
            setOutlookDate(""); 
        }
    }, [selectedOutlook])

    const isSaveDisabled =  outlookText === '' || outlookDate === '';

    return (
        <ModalForm
            open={isOpen}
            handleClose={handleClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6>Outlook</h6>
          </div>
          <div>
            <section>
              <label className={`font-14`}>Date</label>
              <TextField
                className={classes.textField}
                variant="outlined"
                type="date"
                fullWidth
                label={null}
                value={outlookDate}
                margin="dense"
                helperText="Required field"
                onChange={(e) => {
                    setOutlookDate(e.target.value)
                }}
              />
            </section>
            <section>
              <label className={`font-14`}>Outlook</label>
              <TextField
                className={classes.textField}
                variant="outlined"
                fullWidth
                label={null}
                value={outlookText}
                multiline
                margin="dense"
                helperText="Required field"
                onChange={(e) => setOutlookText(e.target.value)}
              />
              {/*<LivePreviewExample />*/}
            </section>
          </div>
        </div>
      </div>
      <div className="text-center pb-4">
        <Button
          variant="contained"
          className={classes.saveBtn}
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          <span className="font-12">Save</span>
        </Button>
      </div>
        </ModalForm>
    )
};

export default OutlookModal;

