import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { StatusSelect } from '../SingleSelect/SIngleSelect';

import { selectCompanyDetailsHubspotSequence } from '../../store/sourcing/sourcingSlice';
import { getData } from '../../api/sourcing';
import { QUERIES } from '../../constants';


function HubspotDropdown({ onChange, companiesPageDropdown }) {
    const [hubspotSequences, setHubspotSequences] = useState([]);
    const [isError, setError] = useState(false);

    const hubspotSequence = useSelector(selectCompanyDetailsHubspotSequence);

    useEffect(() => {
        getData(QUERIES.COMPANY.HUBSPOT_SEQUENCES).then(({ response }) => {
            const sequences = response?.data?.map(sequence => ({ value: sequence?.[0], label: sequence?.[0] }));
            setHubspotSequences(sequences);
            return response;
        }).then(res => {
            if(typeof res === 'undefined') {
                setError(true);
                console.log('Error while loading hubspots');
            }
        })
    }, []);

    return (
        <>
            {isError && <div className='text-danger font-12'>Ups, hubspots are unavailable</div>}
            {hubspotSequences?.length > 0 && (companiesPageDropdown || hubspotSequence !== undefined) ?  <StatusSelect name="Set Hubspot Sequense" defaultValue={{ value: hubspotSequence, label: hubspotSequence }} onChange={onChange} options={hubspotSequences} withoutColor={true} /> :
                !isError && <div className='d-flex justify-content-center'>
                    <CircularProgress color="black" />
                </div>
            }
        </>
    )
}

export default HubspotDropdown;