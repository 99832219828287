import { createAsyncThunk } from '@reduxjs/toolkit';
import {getData, getFilterSelectOptions} from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';

export const getFPCEmployees = createAsyncThunk(
    "filter/FPCEmployees",
    async (_, { dispatch }) => {
        // let response = await getFilterSelectOptions(QUERIES.COMPANY_FILTERS.CRM_OWNER, dispatch);
        return await Promise.all(Object.entries(QUERIES.COMPANY_FILTERS)
                .map(([key, path]) => {
                    return getFilterSelectOptions(path, dispatch).then(({status, response }) => {
                        if (status === STATUSES.SUCCESS) {
                            return ({ [key]: response?.flat?.() });
                        }
                    })
                }))
            .then((res) => {
                return res.reduce((acc, option) => ({ ...acc, ...option }), {})
            })
            .catch(err => {
                console.log(err)
                return Promise.reject(err);
            });
    }
);

export const getTaxonomyOptions = createAsyncThunk('filter/categories', async (_, { dispatch }) => {
    return getData(QUERIES.COMPANY_FILTERS.TAXONOMY, dispatch)
        .then(({status, response}) => {
            if (status === STATUSES.SUCCESS) {
                console.log('Log ::: response ===', response)
                return response;
            }
        })
        .catch(err => {
            console.log(err)
            return Promise.reject(err);
        });
});


//async function to get Taxonomy data
export const getTaxonomyData = async () => {
    return getData(QUERIES.COMPANY_FILTERS.TAXONOMY)
        .then(({status, response}) => {
            if (status === STATUSES.SUCCESS) {
                console.log('Log ::: response ===', response)
                // @ts-ignore
                return response.data.map(item => ({ Technology: item[0], 'Sub Category': item[1], Category: item[2]  }));
            }
        })
        .catch(err => {
            console.log(err)
            return Promise.reject(err);
        });
}

export const getTagFilterOptions = async () => {
    return getData(QUERIES.COMPANY_FILTERS.DEMOGRAPHICS_TAG_OPTIONS)
        .then(({status, response}) => {
            if (status === STATUSES.SUCCESS) {
                console.log('Tag options', response);
                return response?.data?.flat?.();
            }
        })
        .catch(err => {
            console.log(err)
            return Promise.reject(err);
        });
}

type ColumnNamesType = {
    [key: string]: string; // This index signature says that for any string key, the value will be a string.
};

const COLUMN_NAMES: ColumnNamesType = {
    COMPANY_NAME: 'Company',
    STATUS: 'Status',
    FPC_OWNERS: 'Owners',
    FINANCING_SERIES: 'Series',
    FINANCING_STAGE: 'Stage',
    INVESTORS: 'Investors',
    MISS_PASS_LOSS: 'Miss / Pass / Lost',
    REGION: 'Region',
    CATEGORY: 'Category',
}

export const getFinancingsFilters = createAsyncThunk(
    "filter/Financings",
    async (_, { dispatch }) => {
        return await Promise.all(Object.entries(QUERIES.FINANCING_FILTERS)
            .map(([key, path]) => {
                return getFilterSelectOptions(path, dispatch).then(({status, response }) => {
                    if (status === STATUSES.SUCCESS) {
                        return ({ [COLUMN_NAMES[key]]: response?.flat?.()?.filter((item: string) => item)})
                    }
                })
            }))
            .then((res) => {
                return res.reduce((acc, option) => ({ ...acc, ...option }), {})
            })
            .catch(err => {
                console.log(err)
                return Promise.reject(err);
            });
    }
);
