import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TInvestorsMain from '../../components/TInvestors';
import ForgePontTabs from '../../components/Tabs';
import Customers from './Customers';
import People from './People';
import TCompaniesByInvestor from '../../components/TCompaniesByInvestor';

interface ISourcingProps {
    loading: boolean;
}

const Sourcing: FunctionComponent<ISourcingProps> = ({ loading }) => {
    return (<>
        <ForgePontTabs mainPath="relationships">
            <Switch>
            <Route path="/relationships/people" component={People} />
            <Route path="/relationships/customers" component={Customers} />
            <Route path="/relationships/investors/all" component={TCompaniesByInvestor} />
            <Route path="/relationships/investors/eu" component={TCompaniesByInvestor} />
            <Route path="/relationships/investors">
                    <Redirect to="/relationships/investors/all" />
                </Route>
            <Route path="/relationships">
                    <Redirect to="/relationships/people" />
                </Route>
            </Switch>
        </ForgePontTabs>
    </>);
};
export default Sourcing;
