import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRelationshipList } from '../store/sourcing/sourcingThunks';

const useRelationships = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRelationshipList());
    }, []);
}

export { useRelationships };