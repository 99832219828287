import React from 'react';
import { SingleSelectFormik } from '../../components/SingleSelect/SIngleSelect';
import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';
import Range from '../../components/Range';
import TaxonomyFilter from '../../components/TaxonomyFilter';
import FreeSearch from '../../components/OpenTextSearch/OpenTextSearch';
import { INullableFilters, INullableFiltersKeyMuch } from '../../types/hooks/useFilter';
import { CompanyFilterOptions, INullableCompanyFilters } from '../../types/filter/filterByCompanies';
import DateRange from '../../components/DateRange';

export const StaticFilterSelectOptions = {
    'In Sequence?': ['Yes', 'No'],
    // 'Has FPC Relationship': ['Yes', 'No'],
    // 'Has Target Entrepreneurs': ['Yes', 'No']
}

type Field = {
    name: string & keyof CompanyFilterOptions;
    label: string;
    optionName?: string,
    component: React.ComponentType<any>;
}

type TFilterOptions = {
    name: string,
    fields: Field[]
}

type TFields = TFilterOptions[];

export const COMPANY_NAMES_FIELD =     {
        name: 'COMPANY_NAMES',
        fields: [
            {
                name: 'company name',
                label: '',
                optionName: 'Company Name',
                component: MultiselectFormik,

            }
        ]
};
export const companyFilterFields: TFilterOptions[] = [
    // {
    //     name: 'COMPANY_NAMES',
    //     fields: [
    //         {
    //             name: 'company name',
    //             label: '',
    //             optionName: 'company name',
    //             component: MultiselectFormik,
    //
    //         }
    //     ]
    // },
    {
        name: 'Taxonomy',
        fields: [
            {
                name: 'technology',
                label: '',
                optionName: 'TAXONOMY',
                component: TaxonomyFilter,

            },
            {
                name: 'Key Words',
                label: 'Key Words',
                optionName: 'Key Words',
                component: FreeSearch,

            },
            {
                name: 'sectors',
                label: 'Sectors',
                optionName: 'Sectors',
                component: MultiselectFormik
            },
        ]
    },
    {
        name: 'CRM',
        fields: [
            {
                name: 'owner',
                label: 'Owner',
                optionName: 'Owner',
                component: MultiselectFormik
            },
            {
                name: 'status',
                label: 'Status',
                optionName: 'Status',
                component: MultiselectFormik
            },
            {
                name: 'Last Meeting Date',
                label: 'Last Meeting Date',
                optionName: 'Last Meeting Date',
                component: DateRange
            },
            {
                name: 'Last Contact Date',
                label: 'Last Contact Date',
                optionName: 'Last Contact Date',
                component: DateRange
            },
            {
                name: 'Next Meeting Date',
                label: 'Next Meeting Date',
                optionName: 'Next Meeting Date',
                component: DateRange
            },
            {
                name: 'in sequence?',
                label: 'In Sequence?',
                optionName: 'In Sequence?',
                component: SingleSelectFormik
            },
            {
                name: 'Last Sequence Enrolled',
                label: 'Last Sequence Enrolled',
                optionName: 'Last Sequence Enrolled',
                component: FreeSearch
            },
            // {
            //     name: 'last sequence enrolled',
            //     label: 'Last Sequence Enrolled',
            //     // optionName: 'CRM_LAST_SEQUENCE_OPTIONS',
            //     component: Range
            // },
            // {
            //         name: 'months since last sequence',
            //     label: 'Months since last sequence',
            //     component: Range
            // },
        ]
    },
    {
        name: 'Demographics',
        fields: [
            {
                name: 'Region',
                label: 'Region',
                optionName: 'Region',
                component: MultiselectFormik
            },
            {
                name: 'City',
                label: 'City',
                optionName: 'City',
                component: MultiselectFormik
            },
            {
                name: 'Year Founded',
                label: 'Year Founded',
                optionName: 'Year Founded',
                component: Range,
            },
            {
                name: 'Headcount',
                label: 'Headcount',
                component: Range,
            },
            {
                name: 'Headcount growth',
                label: 'Headcount Growth',
                component: Range,
            },
        ]
    },
    {
        name: 'Financing',
        fields: [
            {
                name: 'Total Raised ($M)',
                label: 'Total Raised ($M)',
                component: Range,
            },
            {
                name: 'Recent Valuation ($M)',
                label: 'Recent Valuation ($M)',
                component: Range,
            },
            {
                name: 'Recent Investment Stage',
                label: 'Recent Investment Stage',
                optionName: 'Recent Investment Stage',
                component: MultiselectFormik,
            },
            {
                name: 'Months Since Last Funding',
                label: 'Months Since Last Funding',
                component: Range,
            },
            {
                name: 'Ownership Status',
                label: 'Ownership Status',
                optionName: 'Ownership Status',
                component: MultiselectFormik
            },
            {
                name: 'Total Raised / Last Valuation',
                label: 'Total Raised / Last Valuation',
                component: Range,
            },
            {
                name: 'Total raised / Hedcount',
                label: 'Total raised / Hedcount',
                component: Range,
            },
        ]
    },
    {
        name: 'Investors / People',
        fields: [
            {
                name: 'Investors',
                label: 'Investors',
                optionName: 'Investors',
                component: MultiselectFormik
            },
            {
                name: 'Relationships',
                label: 'Relationships',
                optionName: 'Relationships',
                component: FreeSearch
            },
            {
                name: 'Has FPC Relationship',
                label: 'Has FPC Relationship',
                optionName: 'Has FPC Relationship',
                component: SingleSelectFormik
            },
            {
                name: 'Has Target Entrepreneurs',
                label: 'Has Target Entrepreneurs',
                optionName: 'Has Target Entrepreneurs',
                component: SingleSelectFormik
            },
            // {
            //     name: 'Investor Relationships',
            //     label: 'Investors Relationships',
            //     optionName: 'Investors Relationships',
            //     component: MultiselectFormik
            // },
            // {
            //     name: 'Company Relationships',
            //     label: 'Company Relationships',
            //     optionName: 'Company Relationships',
            //     component: MultiselectFormik
            // },
            // {
            //     name: 'Customer Relationships',
            //     label: 'Customer Relationships',
            //     optionName: 'Customer Relationships',
            //     component: MultiselectFormik
            // },
        ]
    },
    {
        name: 'Financials',
        fields: [
            {
                name: 'Customer count',
                label: 'Customer Count',
                component: Range
            },
            {
                name: 'ARR ($M)',
                label: 'ARR ($M)',
                // optionName: 'ARR',
                component: Range
            },
            {
                name: 'Arr / Headcount',
                label: 'Arr / Headcount',
                // optionName: 'ARR_GROWTH',
                component: Range
            },
            // {
            //     name: 'Saas Metrics As Of Date',
            //     label: 'Saas Metrics As Of Date',
            //     // optionName: 'ARR_GROWTH',
            //     component: Range
            // },
            // {
            //     name: 'cash',
            //     label: 'Cash',
            //     // optionName: 'CASH',
            //     component: Range
            // },
            // {
            //     name: 'cash burned',
            //     label: 'Cash Burned / ARR',
            //     // optionName: 'CASH_BURNED_ARR',
            //     component: Range
            // },
        ]
    },
    {
        name: 'Other',
        fields: [
            {
                name: 'Tags',
                label: 'Tags',
                optionName: 'Tags',
                component: MultiselectFormik
            },
            // {
            //     name: 'Red Flag Count',
            //     label: 'Red Flag Count',
            //     optionName: 'Red Flag Count',
            //     component: Range
            // },
            // {
            //     name: 'Green Flag Count',
            //     label: 'Green Flag Count',
            //     optionName: 'Green Flag Count',
            //     component: Range
            // },
        ]
    }
]

export const includeCompanyNullableFilters: INullableFilters<Partial<CompanyFilterOptions>> = {
    "technology": false,
    "Key Words": false,
    "owner": true,
    "sectors": true,
    "status": true,
    "Last Meeting Date": true,
    "Last Contact Date": true,
    "Next Meeting Date": true,
    "in sequence?": false,
    "Region": true,
    "City": true,
    "Year Founded": true,
    "Headcount": true,
    "Headcount growth": true,
    "Total Raised ($M)": true,
    "Recent Valuation ($M)": true,
    "Recent Investment Stage": true,
    "Months Since Last Funding": true,
    "Ownership Status": true,
    "Total Raised / Last Valuation": true,
    "Total raised / Hedcount": true,
    "Investors": false,
    "Has FPC Relationship": false,
    "Has Target Entrepreneurs": false,
    "Relationships": true,
    "Customer count": true,
    "ARR ($M)": true,
    "Arr / Headcount": true,
    "Saas Metrics As Of Date": true,
    "Tags": false
}

export const nullableCompanyKeyMuch: INullableFiltersKeyMuch<Partial<CompanyFilterOptions>, INullableCompanyFilters> = {
    "technology": 'nullable_technology',
    "owner": 'nullable_owner',
    "sectors": 'nullable_sectors',
    "status": 'nullable_status',
    "Last Meeting Date": 'nullable_Last Meeting Date',
    "Last Contact Date": 'nullable_Last Contact Date',
    "Next Meeting Date": 'nullable_Next Meeting Date',
    "in sequence?": 'nullable_in sequence?',
    "Region": 'nullable_Region',
    "City": 'nullable_City',
    "Year Founded": 'nullable_Year Founded',
    "Headcount": 'nullable_Headcount',
    "Headcount growth": 'nullable_Headcount growth',
    "Total Raised ($M)": 'nullable_Total Raised ($M)',
    "Recent Valuation ($M)": 'nullable_Recent Valuation ($M)',
    "Recent Investment Stage": 'nullable_Recent Investment Stage',
    "Months Since Last Funding": 'nullable_Months Since Last Funding',
    "Ownership Status": 'nullable_Ownership Status',
    "Total Raised / Last Valuation": 'nullable_Total Raised / Last Valuation',
    "Relationships": 'nullable_Relationships',
    "Total raised / Hedcount": 'nullable_Total raised / Hedcount',
    "Investors": 'nullable_Investors',
    "Has FPC Relationship": 'nullable_Has FPC Relationship',
    "Has Target Entrepreneurs": 'nullable_Has Target Entrepreneurs',
    "Customer count": 'nullable_Customer count',
    "ARR ($M)": 'nullable_ARR ($M)',
    "Arr / Headcount": 'nullable_Arr / Headcount',
    "Saas Metrics As Of Date": 'nullable_Saas Metrics As Of Date',
    "Tags": 'nullable_Tags',
}

export const INCLUDE_BLANC = ['status', 'owner'];