import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectKeywords } from '../store/sourcing/whitespace/whitespaceSelectors';

const FilterTypes = {
    default: 'default',
    custom: 'custom',
}

export const useKeywordFilter = (rows, filterType = FilterTypes.default) => {
    const keywords = useSelector(selectKeywords);
    const [filteredByKeyword, setFilteredByKeyword] = useState({});
    const [selectedKeywords, setSelectedKeywords] = useState(new Set());

    useEffect(() => {
        const filteredRows = {}
        if(rows) {
            if(filterType === FilterTypes.default) {
                Object.keys(rows).forEach(category => {
                    filteredRows[category] = rows[category].filter(item => selectedKeywords.has(item['Domain']))
                })
                setFilteredByKeyword(filteredRows);
            } else {
                setFilteredByKeyword(rows.filter(item => selectedKeywords.has(item['Category'])))
            }
        }
    }, [selectedKeywords, rows])

    const toggleKeywordSelection = (keyword) => {
        const newSelectedKeywords = new Set(selectedKeywords);
        if (newSelectedKeywords.has(keyword)) {
            newSelectedKeywords.delete(keyword);
        } else {
            newSelectedKeywords.add(keyword);
        }
        setSelectedKeywords(newSelectedKeywords);
    };

    return { keywords, selectedKeywords, toggleKeywordSelection, filteredByKeyword }
}