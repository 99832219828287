import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';

import KeyMetrics from '../KeyMetrics';
import ContactInfo from '../ContactInfo';
import FundraisingHistory from '../FundraisingHistory';
import FinancialsOverview from '../FinancialsOverview';
import KeyCustomers from '../KeyCustomers';
import TaxonomyOverviewTags from '../TaxonomyOverviewTags';
import CrmOverview from '../CrmOverview';
import InvestorsPeople from '../InvestorsPeople';
import OverviewSummary from '../OverviewSummary';
import OverviewDemographics from '../OverviewDemographics';
import OverviewTable from '../OverviewTable';
import InternalNotes from '../InternalNotes';

import {
  emptyBoardError,
  emptyContactInfoError,
  emptyCRMError,
  emptyDemographicsError,
  emptyFinancialsError,
  emptyFundraisingHistoryError,
  emptyInternalNotesError,
  emptyInvestorsPeopleError,
  emptyKeyMetricsError,
  emptyRelationshipsError,
  emptySummaryError,
  emptyTaxonomyError,
  selectSingleCompanyDetails,
} from "../../store/sourcing/sourcingSlice";
import {
  getSingleEntryDetails,
  getCompanyBoardData,
  getCompanyFinancialsData,
  getHeadcountHistory,
  getCompanyOutlook,
  getCompanyCustomers,
  getPrimaryCompetitorsByCompany,
  getRecentOverview,
  getCompanyContacts,
  getPrimaryContacts,
  getSingleFundraising,
  getFinancings,
  getSingleFundraisingPartOne,
  getSingleFundraisingPartTwo,
  getSecondaryCompetitors,
} from "../../store/sourcing/sourcingThunks";

import styles from "./styles.module.scss";
import Loader from "../Loader";
import Relationships from '../Relationships';
import CompanyContacts from '../CompanyContacts';
import HubspotSequence from '../HubspotSequence';
import Financing from '../Financing';
import SecondaryCompetitors from '../SecondaryCompetitors';
import CompanyDetailsHeader from '../CompanyDetailsHeader';
import PrimaryCompetitors from '../PrimaryCompetitors';

type CompanyOverviewProps = {
  id: number;
};

const CompanyOverview: React.FC<CompanyOverviewProps> = ({ id }) => {
  const dispatch = useDispatch();

  const companyDetails = useSelector(selectSingleCompanyDetails);

  useEffect(() => {
    dispatch(getSingleFundraisingPartOne(id));
    dispatch(getSingleFundraisingPartTwo(id));
    dispatch(getFinancings(id));
    dispatch(getSingleEntryDetails(id));
    dispatch(getCompanyCustomers(id));
    dispatch(getCompanyBoardData(id));
    dispatch(getCompanyFinancialsData(id));
    dispatch(getCompanyOutlook(id));
    dispatch(getHeadcountHistory(id));
    dispatch(getPrimaryCompetitorsByCompany(id));
    dispatch(getSecondaryCompetitors(id));
    dispatch(getRecentOverview(id));
    dispatch(getCompanyContacts(id));
    dispatch(getPrimaryContacts(id))
  }, []);
  
  const refreshBoardAPI = useCallback(() => {
    dispatch(emptyBoardError());
    dispatch(getCompanyBoardData(id));
  }, [dispatch]);

  const refreshKeyMetricsAPI = useCallback(() => {
    dispatch(emptyKeyMetricsError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  // const refreshContactInfoAPI = useCallback(() => {
  //   dispatch(emptyContactInfoError());
  //   dispatch(getSingleEntryDetails(id));
  // }, [dispatch]);
  //
  // const refreshFundraisingHistoryAPI = useCallback(() => {
  //   dispatch(emptyFundraisingHistoryError());
  //   dispatch(getSingleEntryDetails(id));
  // }, [dispatch]);

  const refreshInternalNotesAPI = useCallback(() => {
    dispatch(emptyInternalNotesError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshSummaryAPI = useCallback(() => {
    dispatch(emptySummaryError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshTaxonomyAPI = useCallback(() => {
    dispatch(emptyTaxonomyError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshDemographicsAPI = useCallback(() => {
    dispatch(emptyDemographicsError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshCRMAPI = useCallback(() => {
    dispatch(emptyCRMError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshFinancialsAPI = useCallback(() => {
    dispatch(emptyFinancialsError());
    dispatch(getCompanyFinancialsData(id));
  }, [dispatch]);

  const refreshHeadcountAPI = useCallback(() => {
    dispatch(getHeadcountHistory(id));
  }, [dispatch]);

  const refreshInvestorsPeopleAPI = useCallback(() => {
    dispatch(emptyInvestorsPeopleError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshRelationshipsAPI = useCallback(() => {
    dispatch(emptyRelationshipsError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  const refreshCompanyContactsAPI = useCallback(() => {
    dispatch(getCompanyContacts(id));
    dispatch(getPrimaryContacts(id))
  }, [dispatch]);

  const refreshFinancingsAPI = useCallback(() => {
    dispatch(getSingleFundraisingPartOne(id));
    dispatch(getSingleFundraisingPartTwo(id));
    dispatch(getFinancings(id));
  }, [dispatch]);

  const refreshKeyCustomersAPI = useCallback(() => {
    dispatch(getCompanyCustomers(id));
  }, [dispatch]);

  const handleGeneratePdf = () => {
    // TODO
  }

  return (
    <>
      <div id="overview">
        <div>
          <Grid container={true}>
            <Grid className={styles.column} xs={5}>
            <CompanyDetailsHeader generatePdf={handleGeneratePdf}></CompanyDetailsHeader>
              <ContactInfo contactData={companyDetails?.contactInfo?.data} contactDataExtra={companyDetails?.contactInfoExtra} companyId={id} handleRefresh={refreshCompanyContactsAPI}/>
              <FinancialsOverview tableData={companyDetails?.financials.data} handleRefresh={refreshFinancialsAPI} companyId={id}/>
                <FundraisingHistory
                  part1={companyDetails?.fundraisingHistory?.part1}
                  part2={companyDetails?.fundraisingHistory?.part2}
                  companyId={id}
                ></FundraisingHistory>
              <Loader
                condition={!!companyDetails.internalNotes?.data?.length}
                title="Internal Notes"
                error={companyDetails.internalNotes?.error}
                handleRefresh={refreshInternalNotesAPI}
              >
                <InternalNotes companyId={id} data={companyDetails?.internalNotes?.data} />
              </Loader>
            </Grid>
            <Grid className={styles.secondColumn} xs={7}>
              <Loader
                  condition={!!companyDetails.keyMetrics.data?.length}
                  title="Key Metrics"
                  error={companyDetails.keyMetrics?.error}
                  handleRefresh={refreshKeyMetricsAPI}
                >
                <KeyMetrics data={companyDetails?.keyMetrics?.data} />
              </Loader>
              <Loader
                condition={!!companyDetails.summary.data?.length}
                title="Summary"
                error={companyDetails.summary?.error}
                handleRefresh={refreshSummaryAPI}
              >
                <OverviewSummary
                  comapnyId={id}
                  summaryData={companyDetails.summary.data}
                  outlookData={companyDetails.companyOutlook.data}
                />
              </Loader>
              <Grid container={true}>
                <Grid className={styles.column} xs={6}>
                  <Loader
                    condition={
                      companyDetails.investorsPeople.data?.length !== 0
                    }
                    title="Investors"
                    error={companyDetails.investorsPeople?.error}
                    handleRefresh={refreshInvestorsPeopleAPI}
                  >
                    <InvestorsPeople data={companyDetails?.investorsPeople.data} />
                  </Loader>
                  <Loader
                    condition={!!companyDetails.taxonomy?.data?.length}
                    title="Taxonomy"
                    error={companyDetails.taxonomy?.error}
                    handleRefresh={refreshTaxonomyAPI}
                  >
                    <TaxonomyOverviewTags data={companyDetails?.taxonomy.data} companyId={id} refreshApi={refreshTaxonomyAPI} />
                  </Loader>
                  <Loader
                    condition={
                      companyDetails.relationships.data?.length !== 0
                    }
                    title="Investors"
                    error={companyDetails.relationships?.error}
                    handleRefresh={refreshRelationshipsAPI}
                  >
                    <Relationships data={companyDetails?.relationships.data} />
                  </Loader>
                </Grid>
                <Grid className={styles.column} xs={6}>
                  <PrimaryCompetitors companyId={id} primaryCompetitors={companyDetails.primaryCompetitors.data}/>
                  <SecondaryCompetitors tableData={companyDetails.secondaryCompetitors}/>
                  <Loader
                    condition={companyDetails.crm.data?.length !== 0}
                    title="CRM"
                    error={companyDetails.crm?.error}
                    handleRefresh={refreshCRMAPI}
                  >
                    <CrmOverview data={companyDetails?.crm.data} companyId={id}/>
                  </Loader>
                  <Loader
                    condition={
                      !!companyDetails.demographics?.data?.length
                    }
                    title="Demographics"
                    error={companyDetails.demographics?.error}
                    handleRefresh={refreshDemographicsAPI}
                  >
                    <OverviewDemographics
                      demographics={companyDetails.demographics.data}
                      headcountHistory={companyDetails.headcountHistory}
                      handleRefresh={refreshHeadcountAPI}
                      companyId={id}
                    />
                  </Loader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Loader
            condition={companyDetails.financings.columns.length !== 0}
            title="Financing"
        >
            <Financing tableData={companyDetails?.financings} companyId={id} handleRefresh={refreshFinancingsAPI}></Financing>
        </Loader>
        <Loader
            condition={companyDetails.companyContacts.columns.length !== 0}
            title="Company contacts"
        >
            <CompanyContacts tableData={companyDetails?.companyContacts} handleRefresh={refreshCompanyContactsAPI} companyId={id}/>
        </Loader>
          <KeyCustomers data={companyDetails?.keyCustomers.data}  companyId={id} handleRefresh={refreshKeyCustomersAPI}/>
        <Grid container={true}>
          <Grid xs={6}>
              <span className={`${styles.sectionTitle} mt-2 font-14 font-weight-bold`}>Board</span>
              <OverviewTable tableData={companyDetails?.board?.data} />
          </Grid>
        </Grid>
        {/*TODO CR-7 Comment out this two sections*/}
        {/*<RecentOverview overviewData={companyDetails.recentOverview}/>*/}
        {/*<CompaniesResearch id={id}></CompaniesResearch>*/}
      </div>
    </>
  );
}

export default CompanyOverview;
