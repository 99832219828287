import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useOwners } from '../../../hooks/useOwners';
import { selectOwners } from '../../../store/sourcing/sourcingSlice';

function OwnersSearch({ setSelectedValues, selectedValues }) {
    const [ownerOptions, setOwnerOptions] = useState([]);

    useOwners();
    const ownersData = useSelector(selectOwners);

    useEffect(() => {
        setOwnerOptions(ownersData.map(ownerData => ({ label: ownerData['Name Only'], value: ownerData['Name Only'] })));
    }, [ownersData])

    return (
        <Select
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary25: "rgba(110, 60, 250, 0.05)",
                    primary: "#6e3cfa",
                },
            })}
            styles={{
                input: (providedValue) => ({
                    ...providedValue,
                    zIndex: 1000,
                    fontSize: '12px',
                }),
                placeholder: (providedValue) => ({
                    ...providedValue,
                    margin: "0 4px",
                    zIndex: 1000,
                    fontSize: '12px',
                }),
                control: (base) => ({
                    ...base,
                    width: "300px",
                    //marginLeft: "10px",
                    zIndex: 1000,
                }),
                option: (provided) => ({
                    ...provided,
                    fontSize: 12,
                }),
            }}
            options={ownerOptions}
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            placeholder="Filter by Owner"
            value={ownerOptions?.filter((option) =>
                selectedValues?.includes(option.value)
            )}
            onChange={(selected) => {
                const values = (selected || []).map(({ value }) => value);
                console.log(selected, 'selectedOwners');
                setSelectedValues(values);
            }}
        />
    );
}

export default OwnersSearch;