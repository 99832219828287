import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Button, makeStyles, TextField } from "@material-ui/core";
import styles from "./styles.module.scss";
import ModalForm from "../../../components/ModalForm";
import DatePicker from '../../../example-components/FormsDatepicker/FormsDatepicker1';
import MPLSelect from './MPLSelect';
import { parseDate } from '../../../utils/formatting';
import {COLUMN_NAMES} from './utils';
import {
  updateCompanyFinancing
} from '../../../store/sourcing/sourcingThunks';
import { updateFinancingsData } from "../../../store/sourcing/sourcingSlice";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#562FCC",
    },
  },
  inputField: {
    minWidth: "270px",
  },
}));

const ADD_FIELDS = [
  { name: 'Company', key: '@companyname', component: 'Input', type: 'text'},
  { name: 'Series', key: '@series', component: 'Input', type: 'text'},
  { name: 'Miss / Pass / Lost', key: '@misspasslost', component: 'Input', type: 'mpl'},
  { name: 'Miss / Pass / Lost Reason', key: '@misspasslostreason', component: 'Input', type: 'text'},
  { name: 'FPC Invested ($M)', key: '@fpcinvested', component: 'Input', type: 'number'},
  { name: 'Round Size ($M)', key: '@totalinvestment', component: 'Input', type: 'number'},
  { name: 'Total Raised ($M)', key: '@totalfunding', component: 'Input', type: 'number'},
  { name: 'Valuation ($M)', key: '@valuation', component: 'Input', type: 'number'},
  { name: 'Date', key: '@date', component: 'Date', type: 'date'},
];

const EditFieldModal = ({
  isOpen,
  setIsOpen,
  tableCinchyId,
  refreshApi,
  editedName,
  editedValue,
  index,
  activeTab
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fieldValue, setFieldValue] = useState("");
  const [fieldName, setFieldName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editedData, setEditedData] = useState({});

  const handleClose = () => {
    setIsOpen(false);
    setFieldValue(editedValue)
    setSelectedDate(editedValue)
  };

  useEffect(() => {
    setFieldValue(editedValue)
    setSelectedDate(editedValue)
  }, [editedValue])

  useEffect(() => {
    setEditedData(ADD_FIELDS.reduce((curr, item) => {
        curr[item.key] = '';
        return curr;
    }, {}));
    const field = ADD_FIELDS.find(field => field.name === editedName);
    setFieldName(field ? field.key : null);
  }, [editedName])

  const handleInputChange = (e) => {
    setFieldValue(e.target.value);
  };

  const handleSubmit = useCallback(async () => {
    await dispatch(
      updateCompanyFinancing({...editedData,
        '@tablecinchyid': tableCinchyId,
        '@date': COLUMN_NAMES.DATE === editedName ? parseDate(selectedDate) : "",
        [fieldName]: fieldValue,
      })
    );
    setIsOpen(false);
      await dispatch(updateFinancingsData({ activeTab : activeTab, index: index, data: {
        [editedName] : fieldValue,
      }}))
  }, [fieldValue]);

  const handleMPLSelect = useCallback(async (option) => {
    setFieldValue(option)
  }, [fieldValue]);

  const handleDateSelect = useCallback((date) => {
    setSelectedDate(date)
  }, []);

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      // fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>Edit {editedName}</h6>
          </div>
          <div className={styles.container}>
              {COLUMN_NAMES.MISS_PASS_LOST !== editedName && COLUMN_NAMES.DATE !== editedName && <TextField
                type="text"
                placeholder={editedName}
                className={classes.inputField}
                variant="outlined"
                margin="dense"
                value={fieldValue}
                onChange={handleInputChange}
              />}
              {COLUMN_NAMES.DATE === editedName && 
              <DatePicker
                handleSelectDate={(date) => handleDateSelect(date)}
                selectedDate={selectedDate}
              ></DatePicker>}
          </div>
          {COLUMN_NAMES.MISS_PASS_LOST === editedName && <div className={styles.selectContainer}>
            <MPLSelect handleSelect={(option) => handleMPLSelect(option)} defaultValue={fieldValue} />
          </div> }
          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};
export default EditFieldModal;
