import { RootState } from '../../';

export const selectWhiteSpace = (state: RootState) => state.whitespace;
export const selectWhiteSpaceByCategory = (state: RootState) =>
    state.whitespace.categories;
export const selectWhiteSpaceBySubCategory = (state: RootState) =>
    state.whitespace.subCategories;
export const selectWhiteSpaceByTechnology = (state: RootState) =>
    state.whitespace.technologies;
export const selectWhiteSpaceTaggingDetails = (state: RootState) =>
    state.whitespace.taggingDetails;
export const selectTaxonomyCoreElements = (state: RootState) =>
    state.whitespace.taxonomy.coreElements;
export const selectTaxonomyOtherElements = (state: RootState) =>
    state.whitespace.taxonomy.otherElements;
export const selectTotalCompaniesCount = (state: RootState) =>
    state.whitespace.totalCompanies;
export const selectKeywords = (state: RootState) =>
    state.whitespace.keywords;