/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import _ from 'lodash';
import { decamelize } from '.';

export const pickWithDefaults = (o: any, picks: any): Object => {
  const keys = Object.keys(picks);
  const obj = o;

  return keys.reduce((acc, key: string) => {
    const value = obj[key];
    let defaultVal = picks[key];

    if (_.isPlainObject(defaultVal)) {
      defaultVal = pickWithDefaults(value, picks[key]);
    }

    return Object.assign(acc, {
      [key]: value || defaultVal,
    });
  }, {});
};

export const setObjectValue = <T extends object, U extends keyof T>(
  obj: T,
  key: U,
  value: any
) => {
  obj[key] = value;
};

export const getObjectValue = <T extends object, U extends keyof T>(
  obj: T,
  key: U
) => obj[key];

export const convertToFormData = (obj: object) =>
  Object.entries(obj).reduce((formData, [key, value]) => {
    formData.append(decamelize(key), value);
    return formData;
  }, new FormData());
