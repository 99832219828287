import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

import { useNotification } from '../../hooks/useNotification';
import GenericSingleSelect from '../GenericSingleSelect';
import { updateCoveragePriority } from '../../api/coverage';

const UpdateCoveragePriority = ({ cinchyId, defaultValue, name, options }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const dispatch = useDispatch();


    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    useEffect(() => {
        if(selectedOption) {
            updateCoveragePriority({ cinchyId: cinchyId, priority: selectedOption, dispatch }).then(() => {
                openNotification({
                    open: true,
                    message: 'Priority Successfully updated',
                });
            })
        }
    }, [selectedOption, cinchyId]);


    return (
        <>
            <GenericSingleSelect onChange={setSelectedOption} name={name} options={options} defaultValue={defaultValue} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    )
}

export default UpdateCoveragePriority;