import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ModalForm from "../ModalForm";

import styles from "./styles.module.scss";
import { selectCompanyIntelligence } from "../../store/sourcing/sourcingSlice";
import { getCompanyIntelligence } from "../../store/sourcing/sourcingThunks";
import { parseDate } from "../../utils/formatting";

type IntelligenceModalProps = {
  id: number;
  company: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const IntelligenceModal: React.FC<IntelligenceModalProps> = ({
  id,
  company,
  isOpen,
  setIsOpen,
}) => {
  const dispatch = useDispatch();
  const [intelligenceData, setIntelligenceData] = useState(null);
  let data = useSelector(selectCompanyIntelligence);

  useEffect(() => {
    if (id) {
      dispatch(getCompanyIntelligence(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data) {
      setIntelligenceData(data?.rows?.[0]);
    }
  }, [data, dispatch]);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
    >
      {intelligenceData && <div className={styles.container}>
        <p className="font-16 mb-3 mt-3 ml-2">{company}</p>
        <div className={styles.singleSection}>
          <p className="font-14 mb-1 mt-2">Product Summary</p>
          <p className="font-12 mb-1">
            {parseDate(intelligenceData?.["product_summary date"])}
          </p>
          {intelligenceData?.["product_summary"] && <div className={styles.textSection}>
            <p className={`${styles.textArea} font-12`}>
              {intelligenceData?.["product_summary"]} 
            </p>
          </div>}
        </div>
        <div className={styles.singleSection}>
          <p className="font-14 mb-1 mt-2">Recent News</p>
          <p className="font-12 mb-1">
            {parseDate(intelligenceData?.["recent_news date"])}
          </p>
          {intelligenceData?.["recent_news"] && <div className={styles.textSection}>
            <p
              className={`${styles.textArea} font-12`}
            >
              {intelligenceData?.["recent_news"]}
              </p>
          </div>}
        </div>
        <div className={styles.singleSection}>
          <p className="font-14 mb-1 mt-2">Recent Outlook</p>
          <p className="font-12 mb-1">
            {`${parseDate(intelligenceData?.["recent_outlook date"])} - ${intelligenceData?.["recent_outlook author"] || ""}`}
          </p>
          {intelligenceData?.["recent_outlook"] && <div className={styles.textSection}>
            <p
              className={`${styles.textArea} font-12`}
            >{intelligenceData?.["recent_outlook"]}</p>
          </div>}
        </div>
      </div>}
    </ModalForm>
  );
};

export default IntelligenceModal;
