import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk, RootState } from '..';

const themeOptionsSlice = createSlice({
  name: 'themeOptions',
  initialState: {
    sidebarFixed: true,
    sidebarFooter: true,
    sidebarShadow: false,
    sidebarStyle: 'app-sidebar--dark',
    sidebarUserbox: true,
    sidebarToggleMobile: false,
    sidebarToggle: true,
    sideBarWidth: 0,

    // Header

    headerFixed: true,
    headerShadow: true,
    headerBgTransparent: true,
    headerSearchHover: false,
    headerDrawerToggle: false,

    // Main content

    contentBackground: '',
    themeConfiguratorToggle: false,

    // Footer

    footerFixed: false,
    footerShadow: false,
    footerBgTransparent: true,

    // Page title

    pageTitleStyle: '',
    pageTitleBackground: '',
    pageTitleShadow: false,
    pageTitleIconBox: true,
    pageTitleDescription: true,
  },
  reducers: {
    sidebarToggleMobile: (state, action) => {
      state.sidebarToggleMobile = action.payload;
    },

    sidebarToggle: (state, action) => {
      state.sidebarToggle = action.payload;
    },
    setSidebarToggle: (state) => {
      state.sidebarToggle = !state.sidebarToggle
    },

    headerDrawerToggle: (state, action) => {
      state.headerDrawerToggle = action.payload;
    },

    headerSearchHover: (state, action) => {
      state.headerSearchHover = action.payload;
    },
  },
});

export const {
  sidebarToggle,
  sidebarToggleMobile,
  headerDrawerToggle,
  headerSearchHover,
} = themeOptionsSlice.actions;

export const setSidebarToggleMobile = (toggle: boolean): AppThunk => (
  dispatch: AppDispatch
) => {
  dispatch(sidebarToggleMobile(toggle));
};

export const setSidebarToggle = (toggle: boolean): AppThunk => (
  dispatch: AppDispatch
) => {
  dispatch(sidebarToggle(toggle));
};

export const setHeaderDrawerToggle = (toggle: boolean): AppThunk => (
  dispatch: AppDispatch
) => {
  dispatch(headerDrawerToggle(toggle));
};

export const setHeaderSearchHover = (toggle: boolean): AppThunk => (
  dispatch: AppDispatch
) => {
  dispatch(headerSearchHover(toggle));
};

export const selectThemeOptions = (state: RootState) => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sideBarWidth: state.ThemeOptions.sideBarWidth,

});

export default themeOptionsSlice;
