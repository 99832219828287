import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyComps,
  getCompanyListedComps,
  getSingleEntryDetails,
} from "../../store/sourcing/sourcingThunks";
import {
  emptyTaxonomyError,
  selectCompanyComps,
  selectSingleCompanyDetails,
  selectCompanyListedComps,
} from "../../store/sourcing/sourcingSlice";
import Loader from "../Loader";
import styles from "./styles.module.scss";
import Accordion from "../Accordion";
import Pagination from "../Pagination/Pagination";
import usePagination from "../../hooks/usePagination";

function formatLargeNumber(number: any) {
  if (!number) {
    return "";
  }
  if (number >= 1000000) {
    return `$${(number / 1000000).toFixed(1)}M`;
  } else {
    return `$${number}`;
  }
}

export default function CompaniesComp({ id }: any) {
  const [categoriesTags, setCategoriesTags] = useState([]);
  const [subCategoriesTags, setSubCategoriesTags] = useState([]);
  const [technologyTags, setTechnologyTags] = useState([]);

  const [listedCompRows, setListedCompRows] = useState([]);
  const [listedCompCells, setListedCompCells] = useState([]);

  const dispatch = useDispatch();

  const { rows, cells } = useSelector(selectCompanyComps);
  const listedCompData = useSelector(selectCompanyListedComps);
  const companyDetails = useSelector(selectSingleCompanyDetails);

  const largeComps = usePagination({ rows: listedCompRows });
  const companyComps = usePagination({ rows });

  useEffect(() => {
    if (!!companyDetails.taxonomy?.data?.length) {
      setCategoriesTags(
        companyDetails.taxonomy?.data?.[0]?.["Category"]?.split(",")
      );
      setSubCategoriesTags(
        companyDetails.taxonomy?.data?.[0]?.["Sub Category"]?.split(",")
      );
      setTechnologyTags(
        companyDetails.taxonomy?.data?.[0]?.["Technology"]?.split(",")
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    dispatch(getSingleEntryDetails(id));
    dispatch(getCompanyComps(id));
    dispatch(getCompanyListedComps(id));
  }, []);

  useEffect(() => {
    if (listedCompData) {
      setListedCompRows(listedCompData["rows"]);
      setListedCompCells(listedCompData["cells"]);
    }
  }, [listedCompData]);

  const refreshTaxonomyAPI = useCallback(() => {
    dispatch(emptyTaxonomyError());
    dispatch(getSingleEntryDetails(id));
  }, [dispatch]);

  return (
    <>
      <div id="comps">
        <Loader
          condition={!!companyDetails.taxonomy?.data?.length}
          title="Taxonomy"
          error={companyDetails.taxonomy?.error}
          handleRefresh={refreshTaxonomyAPI}
        >
          <div className={styles.taxonomySectionContainer}>
            <div className={styles.cyberResearchTitleContainer}>
              <p className={styles.cyberResearchText}>Taxonomy</p>
            </div>
            <div className={styles.cyberResearchCardContainer}>
              <Grid container={true}>
                <Grid className={styles.singleSection} xs={4}>
                  <div>
                    <p className="font-12">Categories</p>
                    <div className={styles.taxonomySubSection}>
                      {categoriesTags?.map((item: string) => (
                        <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
                      ))}
                    </div>
                  </div>
                </Grid>
                <Grid className={styles.singleSection} xs={4}>
                  <div>
                    <p className="font-12">Sub-Categories</p>
                    <div className={styles.taxonomySubSection}>
                      {subCategoriesTags?.map((item: string) => (
                        <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
                      ))}
                    </div>
                  </div>
                </Grid>
                <Grid className={styles.singleSection} xs={4}>
                  <div>
                    <p className="font-12">Technology</p>
                    <div className={styles.taxonomySubSection}>
                      {technologyTags?.map((item: string) => (
                        <p className={`${styles.taxonomyTag} font-12`}>{item}</p>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Loader>
        <Accordion title="Named Competitors">
          <div className="table-responsive-md mb-spacing-2-x2">
            <div className={styles.tableContainer}>
              <Table
                className={`table table-hover table-striped table-bordered ${styles.table}`}
              >
                <thead className={`${styles.tableHead} ${styles.stickyHeader}`}>
                  <tr>
                    {listedCompCells?.slice(1).map(
                      (header: any, index: number) =>
                        index !== 0 && (
                          <th
                            className={`${styles.column} ${header["label"] === "Description" && styles.wideCell}
                            ${header["label"] === "Investors" && styles.wideCell}
                            ${header["label"] === "Technology" && styles.wideCell} font-12`}
                            key={index}
                          >
                            {header["label"]}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {listedCompRows
                    ?.slice(
                      (largeComps.currentPage - 1) * 5,
                      (largeComps.currentPage - 1) * 5 + 5
                    )
                    ?.map((row: any, index: number) => {
                      return (
                        <tr key={index}>
                          {Object.keys(row)
                            ?.slice(1, -2)
                            .map((col: any, colIndex: number) => {
                              if (colIndex !== 0) {
                                return (
                                  <td
                                    key={colIndex}
                                    className={`${styles.col} font-12`}
                                  >
                                    {col === "total_raised"
                                      ? formatLargeNumber(row[col])
                                      : col === "technology"
                                      ? <div className={styles.tagContainer}> {row[col].split("|")?.map((item: string) => (
                                        <p className={`${styles.technologyTag} font-12`}>{item}</p>
                                      ))} </div>: row[col]}
                                  </td>
                                );
                              }
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <Pagination goToPage={largeComps.goToPage} rowsPerPage={largeComps.rowsPerPage} currentPage={largeComps.currentPage} goToPageValue={largeComps.goToPageValue} totalPages={largeComps.totalPages}
                     handleRowsPerPageChange={largeComps.handleRowsPerPageChange} handleGoToPageChange={largeComps.handleGoToPageChange}/>
          </div>
        </Accordion>
        <Accordion title="Well Funded Tech Comps">
        <div className="table-responsive-md mb-spacing-2-x2">
          <div className={styles.tableContainer}>
            <Table
              className={`table table-hover table-striped table-bordered ${styles.table}`}
            >
              <thead className={`${styles.tableHead} ${styles.stickyHeader}`}>
                <tr>
                  {cells?.slice(1).map(
                    (header: any, index: number) =>
                      index !== 0 && (
                        <th className={`${styles.column} ${header["label"] === "Desctiption" && styles.wideCell}
                        ${header["label"] === "Investors" && styles.wideCell}
                        ${header["label"] === "Technology" && styles.wideCell} font-12`} key={index}>
                          {header["label"]}
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {rows
                  ?.slice((companyComps.currentPage - 1) * 5, (companyComps.currentPage - 1) * 5 + 5)
                  ?.map((row: any, index: number) => {
                    return (
                      <tr key={index}>
                        {Object.keys(row)
                          ?.slice(1, -2)
                          .map((col: any, colIndex: number) => {
                            if (colIndex !== 0) {
                              return (
                                <td
                                  key={colIndex}
                                  className={`${styles.col} font-12`}
                                >
                                  {col === "total_raised"
                                      ? formatLargeNumber(row[col])
                                      : col === "technology"
                                      ? <div className={styles.tagContainer}> {row[col].split("|")?.map((item: string) => (
                                        <p className={`${styles.technologyTag} font-12`}>{item}</p>
                                      ))} </div>: row[col]}
                                </td>
                              );
                            }
                          })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          <Pagination goToPage={companyComps.goToPage} rowsPerPage={companyComps.rowsPerPage} currentPage={companyComps.currentPage} goToPageValue={companyComps.goToPageValue} totalPages={companyComps.totalPages}
                     handleRowsPerPageChange={companyComps.handleRowsPerPageChange} handleGoToPageChange={companyComps.handleGoToPageChange}/>
        </div>
        </Accordion>
      </div>
    </>
  );
}
