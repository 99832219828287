import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import ModalForm from '../ModalForm';
import { useNotification } from '../../hooks/useNotification';


import { getHubspotLists } from '../../store/sourcing/sourcingThunks';
import HubspotDropdown from '../HubspotDropdown/HubspotDropdown';
import { QUERIES, STATUSES } from '../../constants';
import { getData } from '../../api/sourcing';

import styles from './styles.module.scss';

// @ts-ignore
export const addHubspotSequence = async ({ sequence, cinchyId }) => {
    const queryParams = {
        'cinchyid': cinchyId,
        'hubspotsequence': sequence,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const { response, status }  = await getData(`${QUERIES.COMPANY.UPDATE_HUBSPOT_SEQUENCES}?${queryString}`);
    if(status === STATUSES.SUCCESS) {
        // @ts-ignore
        return response.payload;
    }
}


const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    container: {
        height: '300px'
    }
}));

interface HubspotModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedRowsIds: string[];
}

const HubspotModal: React.FC<HubspotModalProps> = ({
    isOpen = true,
    setIsOpen,
    selectedRowsIds,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selectedHubspot, setSelectedHubspot] = useState<string>('');
    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;


    const handleSave = () => {
        // Here you can send checkedColumns to the server
        selectedRowsIds.forEach((cinchyId) => {
            addHubspotSequence({
                sequence: selectedHubspot,
                cinchyId,
            }).then(() => {
                openNotification({
                    open: true,
                    message: 'Hubspot Sequence Successfully added',
                });
            })
        });
        handleClose();
    };
    
    const handleClose = () => {
        setIsOpen(false);
    };
    
    useEffect(() => {
        if (isOpen) {
            dispatch(getHubspotLists());
        }
    }, [isOpen]);

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={handleClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className={`${classes.container} border-0`}>
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6 className={styles.modalTitle}>
                                Select Hubspot Sequence
                            </h6>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <HubspotDropdown onChange={setSelectedHubspot} companiesPageDropdown={true}/>
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={handleSave}
                        disabled={!selectedHubspot}
                    >
                        <span className='font-12'>Add Hubspot Sequence</span>
                    </Button>
                </div>
            </ModalForm>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    )
};

export default HubspotModal;
