import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Button, Typography } from '@material-ui/core';

import { Dropdown } from '../../components/DropDown';
import Loader from '../../components/Loader';

import { useFilter } from '../../hooks/useFilter';

import { getFinancingsFilters } from '../../store/filter/filterThunks';
import { selectFinancingsFilters } from '../../store/filter/filterSelectors';

import { financingsFilterFields } from '../../constants/form/filterByFinancings.constants';
import { generateSelectContext } from '../helper';

export const FilterByFinancings = ({ options }) => {
    const { changeOptions, resetOptions, isReset, filterOptions } = useFilter({});

    const dispatch = useDispatch();
    const selectOptions = useSelector(selectFinancingsFilters);

    useEffect(() => {
        dispatch(getFinancingsFilters());
    }, [])
    const handleCompanyFilter = (filterOptions) => {
        changeOptions({ filterOptions });
    }

    return (
        <Formik
            initialValues={{...filterOptions, owner: ''}}
            onSubmit={handleCompanyFilter}>
            {(props) => (
                <Form>
                    <Typography
                        component="div"
                        className="pb-2 text-uppercase font-weight-bold text-primary font-size-sm">
                        <span>Filter by</span>
                    </Typography>
                    {Object.keys(selectOptions || {}).length > 0 ? <>
                        <>
                            {
                                financingsFilterFields.map(({ name, component , label}) => {
                                    let multiSelectOptions = {};
                                    if(selectOptions[name]) {
                                        multiSelectOptions = {
                                            options: generateSelectContext(Array.from(selectOptions[name] || []))
                                        }
                                    }
                                    const isSelected = options.includes(name)
                                    return (
                                        <Dropdown title={name} key={name} isSelected={isSelected}>
                                            <>
                                                <Field label="" name={name} component={component} isSelected={isSelected}  isReset={isReset} {...multiSelectOptions} />
                                            </>
                                        </Dropdown>
                                    )
                                })
                            }
                        </>
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn-neutral-second w-50 mr-1"
                                onClick={async () => {
                                    await resetOptions();
                                    props.resetForm();
                                }}>
                                Reset
                            </Button>
                            <Button className="btn-neutral-primary w-50 ml-1" type='submit'>
                                Apply
                            </Button>
                        </div>
                    </> : <Loader/>}
                    <div className="divider mt-2 mb-2" />
                </Form>
            )}
        </Formik>
    )
}

export default FilterByFinancings;