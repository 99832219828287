import React from 'react';
import { METRICS_COLOR_MAP } from '../../../constants';
import styles from './styles.module.scss';

const MetricIndicator = ({ color="#F5F5F5", label="label"}) => {
    return (
        <div className={styles.metricIndicator}>
            <div className={styles.badge} style={{backgroundColor: METRICS_COLOR_MAP[color?.toLocaleLowerCase()]}}></div>
            <span className={styles.label}>{label}</span>
        </div>
    );
};

export default MetricIndicator;
