import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Card, CardContent, Typography, makeStyles, Switch, Tooltip } from '@material-ui/core';
import { deleteRecentNewsItem, getRecentNews, getRecentNewsSources, pullRecentNews } from '../../api/sourcing';
import { formatDate } from '../../utils/formatting';
import ProgressBar from './ProgressBar';
import TSkeleton from '../TSkeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    card: {
        paddingBottom: theme.spacing(2),
        background: 'rgba(110, 60, 250, 0.05)',
        marginBottom: '24px',
    },
    dateAndDelete: {
       display: 'flex',
        alignItems: 'center',
    },
    deleteButton: {
        marginLeft: 'auto',
    },
    content: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    newsSection: {
        margin: '8px',
        flexBasis: '60%',
        paddingTop: 0,
        padding: theme.spacing(2),
        background: 'white',
    },
    sourcesSection: {
        margin: '8px',
        flexBasis: '40%',
        paddingTop: 0,
        padding: theme.spacing(2),
        background: 'white',
    },
    title: {
       fontWeight: 'bold',
       color: 'black'
    },
    link: {
        color: '#053CFF !important'
    },
    auto:  {
        width: '100%',
        textAlign: 'center',
        background: 'rgba(110, 60, 250, 0.05)',
    }
}));

const NewsCard = ({ date, news, sources, handleRowDelete }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className='p-0'>
                <div className={classes.dateAndDelete}>
                    <Typography className={`${classes.title} font-14`} >&nbsp;{formatDate(date)}</Typography>
                    <Tooltip title="Delete News">
                        <IconButton className={classes.deleteButton} size="small" aria-label="delete">
                            <DeleteIcon fontSize='small' onClick={() => handleRowDelete()} />
                        </IconButton>
                    </Tooltip>
                </div>
                <Grid container className={classes.content}>
                    <Grid item className={classes.newsSection}>
                        <Typography className={`${classes.title} font-12 pb-2 pt-2`} >News</Typography>
                        <Typography className='font-12'>{news}</Typography>
                    </Grid>
                    <Grid item className={classes.sourcesSection}>
                        <Typography className={`${classes.title} font-12 pb-2 pt-2`} >Sources</Typography>

                        {sources.map(({ Source, Date, URL, Title }) => <>
                            <div className='d-flex align-items-center'>
                                <Typography className={`${classes.title} font-12`}>{Source}&nbsp;</Typography>
                                <Typography className='font-10'>|&nbsp;{formatDate(Date)}</Typography>
                            </div>
                            <a className={`${classes.link} font-12`} href={URL}>{Title}</a>
                        </>)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const RecentNews = ({ id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNewsPulled, setIsNewsPulled] = useState(false);


    useEffect(() => {
        pullNews().then(res => {
            console.log('res =', res);
        });
    }, [])

    const pullNews = async () => {
        Promise.all([getRecentNews({ dispatch, cinchyId: id }), getRecentNewsSources({ dispatch, cinchyId: id })])
            .then(res => {
                const filtered = res?.[0].filter(item => (item.News && item.News !== 'N/A'))
                setNews(filtered.map((item) => ({ ...item, sources: res?.[1]?.filter(source => source['News Cinchy Id'] === item['Cinchy Id'])})))
            }).then(() => setIsLoading(false));
    }

    const handlePullRecentNews = () => {
        setIsNewsPulled(true)
        pullRecentNews({ dispatch, cinchyId: id }).then(res => console.log('Log ::: res ===', res))
    }

    const handleRowDelete = async (cinchyId) => {
        deleteRecentNewsItem({ cinchyId, dispatch }).then(() => pullNews())
    }

   if(isLoading) return <>
       <ProgressBar />
       <TSkeleton></TSkeleton>
   </>

    return (
        <Grid container>
            {news.length > 0 ? <>
                <div className="mb-3">
                    <label className={`${classes.title} font-14 mr-1`}>Pull Recent News</label>
                    <Switch
                        checked={isNewsPulled}
                        onClick={handlePullRecentNews}
                        disabled={isNewsPulled}
                        className="switch-small toggle-switch-primary ml-auto"
                    />
                </div>
                {news.map(({ Created, News, sources, 'Cinchy Id': cinchyId }) => <NewsCard
                date={Created}
                news={News}
                sources={sources}
                handleRowDelete={() => handleRowDelete(cinchyId)}
            />)}
            </> : <div className={classes.auto}>No news available</div>}
        </Grid>
    );
};

export default RecentNews;
