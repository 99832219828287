import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    getTaxonomyCoreElements,
    getTaxonomyOtherElements,
    getTotalCompaniesCount,
    getWhitespaceCompaniesByCategory,
    getWhitespaceCompaniesBySubCategory,
    getWhitespaceCompaniesByTechnology,
    getWhitespaceTaggingDetails
} from './whitespaceThunks';

type WT = {
    rows: any[];
    columns: {};
}

export type TWhitespaceState = {
    taxonomy: {
        coreElements: any[];
        otherElements: any[];
    },
    categories: WT;
    subCategories: WT;
    technologies: WT;
    taggingDetails: WT;
    keywords: string[];
    totalCompanies? : number | null;
    error: null | string;
    loading: boolean;

};

const initialState: TWhitespaceState = {
    taxonomy: {
        coreElements: [],
        otherElements: [],
    },
    categories: {
        rows: [],
        columns: [],
    },
    subCategories: {
        rows: [],
        columns: [],
    },
    technologies: {
        rows: [],
        columns: [],
    },
    taggingDetails: {
        rows: [],
        columns: [],
    },
    keywords: [],
    totalCompanies: null,
    error: '',
    loading: false,
};

const whitespaceSlice = createSlice({
    name: 'whitespace',
    initialState,
    reducers: {
        resetError: (state) => {
            state.loading = true;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
        //Whitespace by category
        .addCase(getWhitespaceCompaniesByCategory.pending, (state) => {
        state.loading = true;
        })
        .addCase(getWhitespaceCompaniesByCategory.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                const { keywords, ...rest } = action.payload;
                state.categories = rest;
                state.keywords = keywords;
            }
        )
        .addCase(getWhitespaceCompaniesByCategory.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

        //Whitespace by sub-category
        .addCase(getWhitespaceCompaniesBySubCategory.pending, (state) => {
            state.loading = true;
        })
        .addCase(getWhitespaceCompaniesBySubCategory.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                const { keywords, ...rest } = action.payload;
                state.subCategories = rest;
                state.keywords = keywords;
            }
        )
        .addCase(getWhitespaceCompaniesBySubCategory.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

        //Whitespace by technology
        .addCase(getWhitespaceCompaniesByTechnology.pending, (state) => {
            state.loading = true;
        })
        .addCase(getWhitespaceCompaniesByTechnology.fulfilled,
            (state, action: PayloadAction<any>) => {
                const { keywords, ...rest } = action.payload;
                state.technologies = rest;
                state.keywords = keywords;
            }
        )
        .addCase(getWhitespaceCompaniesByTechnology.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

        .addCase(getTaxonomyCoreElements.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            getTaxonomyCoreElements.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.taxonomy.coreElements = action.payload;
            }
        )
        .addCase(getTaxonomyCoreElements.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

        .addCase(getTaxonomyOtherElements.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            getTaxonomyOtherElements.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.taxonomy.otherElements = action.payload;
            }
        )
        .addCase(getTaxonomyOtherElements.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

        //Whitespace by tagging details
        .addCase(getWhitespaceTaggingDetails.pending, (state) => {
            state.loading = true;
        })
        .addCase(getWhitespaceTaggingDetails.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                const { keywords, ...rest } = action.payload;
                state.taggingDetails = rest;
                state.keywords = keywords;
            }
        )
        .addCase(getWhitespaceTaggingDetails.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })

         .addCase(getTotalCompaniesCount.pending, (state) => {
            state.loading = true;
        })
        .addCase(getTotalCompaniesCount.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.totalCompanies = action.payload;
            }
        )
        .addCase(getTotalCompaniesCount.rejected, (state) => {
            state.loading = false;
            state.error = 'Something went wrong';
        })
    },
})

export const {
    resetError
} = whitespaceSlice.actions

export default whitespaceSlice.reducer;