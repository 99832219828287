import { IOption } from '../components/SingleSelect/SIngleSelect';

export const generateSelectContext = (options: Array<string>) => {
    console.log('Log ::: setOptions ===', options);
    return [...options].reduce((acc: {[key: string]: IOption}, option) => {
        acc[option as keyof typeof acc] = {
            label: option,
            value: option
        };

        return acc;
    }, {});
}