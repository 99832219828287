import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData } from '../../../api/sourcing';
import { QUERIES, STATUSES } from '../../../constants';
import {
    extractCategoryKeywords,
    groupByCategory,
    groupWhitespaceCategoryColumns,
    whitespaceGroupingByRow
} from '../../../utils/TGroping';

export const getWhitespaceCompaniesByCategory = createAsyncThunk(
    "sourcing/getWhitespaceCompaniesByCategory",
    async (_, { dispatch }) => {
        let { response, status, message } = await getData(QUERIES.WHITESPACE.CATEGORY, dispatch);
        if (status === STATUSES.SUCCESS) {
            return ({
                rows: groupByCategory(whitespaceGroupingByRow(response), 'Domain'),
                keywords: extractCategoryKeywords(response, 1),
                columns: groupWhitespaceCategoryColumns(response.schema, [1]),
            });
        }
        return Promise.reject(message);
    }
);
export const getWhitespaceCompaniesBySubCategory = createAsyncThunk(
    "sourcing/getWhitespaceCompaniesBySubCategory",
    async (_, { dispatch }) => {
        const { response, status, message } = await getData(QUERIES.WHITESPACE.SUB_CATEGORY, dispatch);
        if (status === STATUSES.SUCCESS) {
            let rowsByDomain = whitespaceGroupingByRow(response).filter(item => item.Domain);
            let rowsBySubCategory = groupByCategory(rowsByDomain, 'Category');

            return {
                rows: rowsBySubCategory,
                keywords: extractCategoryKeywords(response, 0),
                columns: groupWhitespaceCategoryColumns(response.schema, [1,3])
            };
        }
        return Promise.reject(message);

    }
);
export const getWhitespaceCompaniesByTechnology = createAsyncThunk(
    "sourcing/getWhitespaceCompaniesByTechnology",
    async (_, { dispatch }) => {
        const { response, status, message } = await getData(QUERIES.WHITESPACE.TECHNOLOGY, dispatch);
        if (status === STATUSES.SUCCESS) {
            let rows = whitespaceGroupingByRow(response).filter(item => item.Domain);
            let rowsBySubCategory = groupByCategory(rows, 'Sub Category');
            let keywords = extractCategoryKeywords(response, 0);
            return {
                rows: rowsBySubCategory,
                keywords,
                columns: groupWhitespaceCategoryColumns(response.schema, [1,3,5])
            };
        }
        return Promise.reject(message);
    }
);
export const getWhitespaceTaggingDetails = createAsyncThunk(
    "sourcing/getWhitespaceTaggingDetails",
    async (_, { dispatch }) => {
        const { response, status, message } = await getData(QUERIES.WHITESPACE.TAGGING_DETAILS, dispatch);
        if (status === STATUSES.SUCCESS) {
            // @ts-ignore
            let rows = whitespaceGroupingByRow(response).filter(item => item.Technology);
            // let rowsBySubCategory = groupByCategory(rows, 'Sub Category');
            let keywords = extractCategoryKeywords(response, 0);
            [1,3,5].forEach(index => {
                response.schema.splice(index, 0, { columnName: '', type: 'String'  });
            })
            return {
                rows,
                keywords,
                columns: response.schema
            };
        }
        return Promise.reject(message);
    }
);

export const getTaxonomyCoreElements = createAsyncThunk(
    "sourcing/getTaxonomyCoreElements",
    async (_, { dispatch }) => {
        const { response, status, message} = await getData(QUERIES.WHITESPACE.TAXONOMY_CORE, dispatch);
        if (status === STATUSES.SUCCESS) {
            return whitespaceGroupingByRow(response);
        }
        return Promise.reject(message);
    }
);

export const getTaxonomyOtherElements = createAsyncThunk(
    "sourcing/getTaxonomyOtherElements",
    async (_, { dispatch }) => {
        const { response, status, message} = await getData(QUERIES.WHITESPACE.TAXONOMY_OTHER, dispatch);
        if (status === STATUSES.SUCCESS) {
            return whitespaceGroupingByRow(response);
        }
        return Promise.reject(message);
    }
);

export const getTotalCompaniesCount = createAsyncThunk(
    "sourcing/getTaxonomyElements",
    async (_, { dispatch }) => {
        let { response, status, message } = await getData(QUERIES.WHITESPACE.TOTAL_COMPANIES, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response?.data?.[0]?.[0];
        }
        return Promise.reject(message);
    }
);