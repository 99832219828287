import queryString from 'query-string';
// export function parseQueryParams(search: string): Record<string, string> {
//     const params = new URLSearchParams(search);
//     const queryParams: Record<string, string> = {};
//
//     params.forEach((value, key) => {
//         queryParams[key] = value;
//     });
//
//     return queryParams;
// }
//
// export function stringifyQueryParams(queryParams: Record<string, string>): string {
//     const params = new URLSearchParams();
//
//     for (const key in queryParams) {
//         if (queryParams.hasOwnProperty(key)) {
//             params.set(key, queryParams[key]);
//         }
//     }
//
//     return params.toString();
// }
export const stringifyObject = (object = {}) => {
    return queryString.stringify(object, {
        arrayFormat: 'bracket',
        arrayFormatSeparator: '|',
        skipEmptyString: true,
        skipNull: true,
    });
};

export type LocationSearch = {[key: string]: any}

export const parseLocationSearch = (locationSearch: string): LocationSearch => {
    return queryString.parse(locationSearch, {
        parseNumbers: true,
        arrayFormat: 'bracket',
        arrayFormatSeparator: '|',
        parseBooleans: true
    });
};
