import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Button, Typography } from '@material-ui/core';

import { Dropdown } from '../../components/DropDown';
import Loader from '../../components/Loader';

import { useFilter } from '../../hooks/useFilter';

import { getFPCEmployees } from '../../store/filter/filterThunks';
import { selectPeopleFilters } from '../../store/filter/filterSelectors';

import { peopleFilterFields } from '../../constants/form/filterByRelationshipsPeople.constants';
import { generateSelectContext } from '../helper';

type CType = {
    options: string[];
}

const FilterByRelationshipPeople = ({ options }: CType) => {
    const [checked, setChecked] = useState(true);
    const { changeOptions, resetOptions, isReset, filterOptions } = useFilter({});

    const dispatch = useDispatch();
    const selectOptions = useSelector(selectPeopleFilters);


    useEffect(() => {
        dispatch(getFPCEmployees());
    }, [])
    
    // @ts-ignore
    const handleCompanyFilter = (filterOptions) => {
        changeOptions({ filterOptions });
    }

    const toggle = () => {
        setChecked(!checked);
    };
    

    return (
        <Formik
            initialValues={{...filterOptions, owner: ''}}
            onSubmit={handleCompanyFilter}>
            {(props) => (
                <Form>
                    <Typography
                        component="div"
                        className="pb-2 text-uppercase font-weight-bold text-primary font-size-sm">
                        <span>Filter by</span>
                    </Typography>
                    {selectOptions ? <>
                        <>
                            {
                                peopleFilterFields.filter(({ name }) => selectOptions?.[name]?.size).map(({ name, component , label}) => {
                                    let multiSelectOptions = {};
                                    if(selectOptions[name]) {
                                        multiSelectOptions = {
                                            options: generateSelectContext(Array.from(selectOptions[name] || []))
                                        }
                                    }
                                    const isSelected = options.includes(name)
                                    return (
                                        <Dropdown title={label} key={name} isSelected={isSelected}>
                                            <>
                                                <Field label="" name={name} component={component}  isReset={isReset} {...multiSelectOptions} />
                                            </>
                                        </Dropdown>
                                    )
                                })
                            }
                        </>
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn-neutral-second w-50 mr-1"
                                onClick={() => {
                                    resetOptions();
                                    props.resetForm();
                                }}>
                                Reset
                            </Button>
                            <Button className="btn-neutral-primary w-50 ml-1" type='submit'>
                                Apply
                            </Button>
                        </div>
                    </> : <Loader/>}
                    <div className="divider mt-2 mb-2" />
                </Form>
            )}
        </Formik>
    )
}

export default FilterByRelationshipPeople;