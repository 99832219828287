import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { CardContent, Table, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { selectLoading } from '../../../../store/sourcing/sourcingSlice';
import TSkeleton from '../../../../components/TSkeleton';
import { getWhitespaceTaggingDetails } from '../../../../store/sourcing/whitespace/whitespaceThunks';
import { selectWhiteSpaceTaggingDetails } from '../../../../store/sourcing/whitespace/whitespaceSelectors';
import HeaderActions from '../Common/HeaderActions';
import { useKeywordFilter } from "../../../../hooks/useKeywordFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    description: {
       maxWidth: 320,
       overflow: 'hidden',
       textOverflow: 'ellipsis',
    },
    scroll: {
        overflowY: 'scroll',
    },
    greyBackground: {
        backgroundColor: '#edecec',
        margin: '1px',
    }
}))

const emptyCell = {
    maxWidth: '16px'
}

const categoryStyle = {
    backgroundColor: 'black',
    color: 'white'
}

const subCategoryStyle = {
    backgroundColor: '#D9D9D9'
}


const secondaryStyle = {
    backgroundColor: '#F5F5F5',
    maxWidth: '50%',
}

const emptyRow = {
    height: '16px',
}

const TTaggingDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhitespaceTaggingDetails())
    }, [])

    const {  columns, rows } = useSelector(selectWhiteSpaceTaggingDetails);
    const loading = useSelector(selectLoading);

    const { keywords, selectedKeywords, toggleKeywordSelection, filteredByKeyword } = useKeywordFilter(rows, 'custom');

    const rowsToDisplay = selectedKeywords.size ? filteredByKeyword : rows;

    return (!columns.length ? <TSkeleton></TSkeleton> : <>
        <HeaderActions
            keywords={keywords}
            selectedKeywords={selectedKeywords}
            toggleKeywordSelection={toggleKeywordSelection}
        />
        <CardContent className={`${classes.scroll} px-0 pt-2 pb-3`}>
            {loading ? <>
                <TSkeleton />
            </> : <>
                <Table className="table table-borderless table-hover table-alternate mb-0">
                    <thead>
                    <tr>
                        {columns.map(({ columnName }, index) => <TableCell
                            key={`${columnName}-${index}`}
                            style={[1,3,5].includes(index) ? emptyCell : {}}
                            // className={`${columnName === 'Technology' ? "text-left" : "text-center"}`}>
                            className={`${"text-center"}`}>
                            <div className="text-black font-12 font-weight-bold px-4">
                                {columnName}
                            </div>
                        </TableCell>)}
                    </tr>
                    </thead>
                    <tbody>
                    {rowsToDisplay.map((item, index) => <Fragment key={`${item['Category']}-${index}`}>
                        <TableRow>
                            <TableCell style={categoryStyle} className="text-center">
                                <div className="font-12 font-weight-bold">{item['Category']}</div>
                            </TableCell>
                            <TableCell></TableCell>
                            {/*Category*/}
                            <TableCell style={subCategoryStyle} className="text-center">
                                <div className="font-12 font-weight-bold text-black">{item['Sub Category']}</div>
                            </TableCell>
                            <TableCell></TableCell>
                            {/*Sub Category*/}
                            <TableCell style={secondaryStyle} className="text-center">
                                <div className="font-12 font-weight-bold text-black">{item['Technology']}</div>
                            </TableCell>
                            <TableCell></TableCell>
                            {/*Investment*/}
                            <TableCell style={secondaryStyle} className="text-left font-12">
                                <div className="d-flex align-items-center">
                                    <div className="text-black font-12 pr-2">
                                        {item['Key Words']}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow style={emptyRow}>
                            <TableCell style={item['Category'] === rows[index+1]?.['Category'] ? categoryStyle : {}}></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={item['Sub Category'] === rows[index+1]?.['Sub Category'] ? subCategoryStyle : {}}></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={item['Technology'] === rows[index+1]?.['Technology'] ? secondaryStyle : {}}></TableCell>
                        </TableRow>
                    </Fragment>)}
                    </tbody>
                </Table>
                <div className="divider mb-3" />
                <div className="text-center">
                </div>
            </>}
        </CardContent>
    </>);
};

export default TTaggingDetails;