import { setLastPath } from '../../helpers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import api from '../../api';
import {
  isAuthenticated,
  getToken,
  setToken,
  removeToken, removeCinchyUser,
} from '../../helpers/auth';
import { setObjectValue } from '../../helpers/objectHelpers';
import { IPayloadAction, IError, IUser, IOperators } from '../../interfaces';
import { AppDispatch, AppThunk, RootState } from '..';
import * as NotificationHelper from '../../helpers/notification';

interface IUserState {
  data?: IUser;
  operators?: IOperators[];
  isAuthenticated: boolean;
  loading: boolean;
  error?: IError;
}

const initialState: IUserState = {
  data: undefined,
  operators: undefined,
  isAuthenticated: isAuthenticated(),
  loading: false,
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
    },
    requestSuccess: (
      state,
      action: PayloadAction<IPayloadAction<IUserState> | null>
    ) => {
      if (action.payload) {
        const { data, key } = action.payload;
        setObjectValue(state, key, data);
      }

      state.error = undefined;
      state.loading = false;
    },
    requestError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    authError: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
      state.loading = false;
    },

    logoutError: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
      state.loading = false;
    },

    logoutSuccess: (state) => {
      state.data = undefined;
      state.isAuthenticated = false;
      state.loading = false;
    },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestError,
  authError,
  logoutError,
  logoutSuccess,
} = userSlice.actions;

export const authenticate = (
  username: string,
  password: string
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(requestStart());

  try {
    const body = {
      "signInCredentials": `client_secret=89dd8239-31f0-4264-8c9e-706fe40a7f11&client_id=fp_frontend&username=${username}&password=${password}&grant_type=password`
    }

    const response = await fetch('https://aphpse8fpa.execute-api.eu-north-1.amazonaws.com/dev/auth/login', {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(body)
    }).then(res => res.json()).then(res => {

      setToken(res.access_token);

      dispatch(
        requestSuccess({
          data: true,
          key: 'isAuthenticated',
        })
      );
      // }, err => {
      //   JSON.stringify(err);
      //   console.log('Log ::: err ===', err)
      // })
    })
  } catch (e) {
    dispatch(authError((e as AxiosError).response?.data.error));
    NotificationHelper.show('Incorrect login or password', 'error');
    console.log('Log ::: error ===', (e as AxiosError));
  }
};

export const logout = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(requestStart());

  try {
    dispatch(logoutSuccess());
    removeToken();
    removeCinchyUser();
    setLastPath()
    window.location.href = "/silent-refresh"
  } catch (e) {
    dispatch(logoutError('Error'));
  }
};

export const getUser = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(requestStart());

    const {
      data: { data },
    } = await api.post(
      '/api/v1/auth/me',
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );

    dispatch(
      requestSuccess({
        data,
        key: 'data',
      })
    );
  } catch (e) {
    dispatch(requestError((e as AxiosError).response?.data.error));
  }
};

export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;

export default userSlice;
