import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';
import { SingleSelectFormik } from '../../components/SingleSelect/SIngleSelect';
import TaxonomyFilter from '../../components/TaxonomyFilter';

//Initial shape
// {columnName: "Cinchy Id", type: "Int64"}
// {columnName: "Title", type: "String"}
// {columnName: "Attachment", type: "String"}
// {columnName: "Date", type: "DateTime"}
// {columnName: "Companies", type: "String"}
// {columnName: "Companies Cinchy Id", type: "String"}
// {columnName: "Type", type: "String"}
// {columnName: "Owner", type: "String"}
// {columnName: "Current", type: "Boolean"}
// {columnName: "Technology", type: "String"}
// {columnName: "Sub Category", type: "String"}
// {columnName: "Category", type: "String"}

export const researchFilterFields = [
    {
        name: 'Title',
        label: 'Title',
        component: MultiselectFormik,
    },
    {
        name: 'Technology',
        label: 'Technology',
        component: TaxonomyFilter,
    },
    {
        name: 'Companies',
        label: 'Companies',
        component: MultiselectFormik,
    },
    {
        name: 'Type',
        label: 'Type',
        component: MultiselectFormik,
    },
    {
        name: 'Owner',
        label: 'Owner',
        component: MultiselectFormik,
    },
    {
        name: 'Current',
        label: 'Current',
        component: SingleSelectFormik,
    },
    // {
    //     name: 'Date',
    //     label: 'Date',
    //     component: MultiselectFormik,
    // }
]
export const ResearchStaticOptions = {
    Current: new Set(['Yes', 'No'])
}