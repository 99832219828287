import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Card,
  Button,
  TextField,
  List,
  ListItem,
  Tooltip,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import clsx from "clsx";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { Delete } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SectorOption } from "../../../interfaces";
import { MoonLoader } from "react-spinners";
import {
  deleteSector,
  getSectorsList,
} from "../../../store/sourcing/sourcingThunks";
import { useNotification } from "../../../hooks/useNotification";
import Snackbar from "@material-ui/core/Snackbar";

import styles from "../styles.module.scss";

type ModalContentProps = {
  sectors: SectorOption[];
  handleSearch: (e: any) => void;
};

const ModalContent: React.FC<ModalContentProps> = ({ sectors, handleSearch }) => {
  const [inputBg, setInputBg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const { notificationData, openNotification } = useNotification();
  const { open, message, toastrStyle, vertical, horizontal } = notificationData;

  const dispatch = useDispatch();

  const toggleInputBg = () => setInputBg(!inputBg);

  const handleDeleteSectorOption = async (sectorId: string) => {
    setIsRequestPending(true);
    await dispatch(deleteSector(sectorId));
    handleSearch("");
    await dispatch(getSectorsList());
    setIsRequestPending(false);
    openNotification({
      message: "Sector option successfully deleted",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
  };

  const itemsPerPage = 4;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    setTotalPages(Math.ceil(sectors.length / itemsPerPage));
  }, [sectors]);

  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  return (
    <>
      <Card className="card-box w-100 mt-4">
          <>
            <div
              className={clsx(
                "d-flex transition-base align-items-center justify-content-between py-0 px-4",
                { "bg-secondary": !inputBg }
              )}
            ></div>
            <div
              className={clsx(
                "d-flex align-items-center transition-base px-4 py-1",
                { "py-3 bg-secondary": inputBg }
              )}
            >
              <div className="search-wrapper w-100">
                <TextField
                  variant="outlined"
                  size="small"
                  className="bg-white w-100"
                  classes={{ root: "input-border-0" }}
                  id="search-sector"
                  placeholder="Search sectors..."
                  onFocus={toggleInputBg}
                  onBlur={toggleInputBg}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon className={styles.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="divider" />

            <List component="div" className="list-group-flush">
              {sectors
                .slice(startIndex, endIndex)
                .map((sector: any, index: number) => {
                  return (
                    <ListItem
                      key={index}
                      button
                      disableRipple
                      onClick={(e: any) => e.preventDefault()}
                      className="d-flex bg-white justify-content-between align-items-center py-3"
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="font-weight-bold font-size-sm text-black">
                            {sector["Sector"]}
                          </div>

                          <div className="d-flex align-items-center font-size-xs"></div>
                        </div>
                      </div>

                      <div>
                        <Tooltip title="Delete Sector" arrow placement="top">
                          <Delete
                            className={styles.buttonClickable}
                            onClick={() =>
                              handleDeleteSectorOption(sector["Cinchy Id"])
                            }
                        ></Delete>
                        </Tooltip>
                      </div>
                    </ListItem>
                  );
                })}
            </List>

            <div
              className={clsx(
                "d-flex justify-content-between mt-3 mb-3",
                styles.pagination
              )}
            >
              <Button
                size="small"
                className="btn-primary px-3"
                disabled={currentPage === 1 ? true : false}
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              >
                <span className="btn-primary">
                  <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                </span>
              </Button>

              <div className={styles.currentPage}>{currentPage}</div>

              <Button
                size="small"
                className="btn-primary px-3"
                disabled={currentPage === totalPages ? true : false}
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                </span>
              </Button>
            </div>
          </>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ root: toastrStyle }}
        open={open}
        message={message}
      />
    </>
  );
};

export default ModalContent;
