import React, { FunctionComponent, Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import SuspenseLoading from './components/SuspenseLoading';
import Layout from './Layout';

const RoutesWrapper: FunctionComponent = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Layout />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesWrapper;
