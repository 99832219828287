import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export type MaxWidthProp = false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;

interface ModalFormProps {
    open: boolean;
    handleClose: () => void;
    maxWidth?: MaxWidthProp;
    fullWidth?: boolean;
    fixedHeight?: boolean;
}

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        position: 'absolute',
        right: '10px',
        top: '5px', 
    },
}));

const ModalForm: React.FC<ModalFormProps> = ({
    open,
    handleClose,
    maxWidth = false,
    fullWidth = false,
    fixedHeight = false,
    children
}) => {
    const classes = useStyles();

    return (
        <Dialog
            classes={{ paper: 'modal-content' }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            PaperProps={
                fixedHeight
                  ? {
                      style: {
                        height: '570px',
                        overflow: 'auto',
                        position: 'relative'
                      },
                    }
                  : {}
              }       
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title2"
        >
            <Button 
                className={classes.closeBtn}
                onClick={handleClose}
            >
                <Close />
            </Button>
            <DialogContent className="p-0">
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default ModalForm;
