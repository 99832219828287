import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { formatEmptyCell } from '../../../utils/utils';
import {
    formatInvestors,
    formatMultiple,
    FORMATTING_TYPES,
    formatTotalRaised,
    statuses,
    removeEmailFromOwner, formatDate
} from '../../../utils/formatting';
import StatusSelect from '../../../components/StatusSelect';
import OwnersSelect from '../../../components/OwnersSelect';
import FPCIPriorities from '../../../components/FPCIPrio';
import styles from './styles.module.scss';

export const COLUMN_NAMES = {
    STATUS: 'Status',
    WEBSITE: 'Website',
    COMPANY_NAME: 'Company',
    SERIES: 'Series',
    MISS_PASS_LOST: 'Miss / Pass / Lost',
    OWNERS: 'Owners',
    ARR: 'ARR ($M)',
    DATE: 'Date',
    RECENT_OUTLOOK: 'Recent Outlook',
    VALUATION: 'Valuation ($M)',
    TOTAL_RAISED: 'Total Raised ($M)',
    RECENT_OUTLOOK_DATE: 'Recent Outlook Date',
    FCP_INVESTED: 'FPC Invested ($M)',
    ROUND_SIZE: 'Round Size ($M)',
    INVESTORS: 'Investors',
    CATEGORY: 'Category',
    CINCHY_ID: 'Company Cinchy Id',
    TABLE_CINCHY_ID: 'Table Cinchy Id',
    TECHNOLOGY: 'Technology',
}
//columnFormat .forEch is not defined
export const renderCell = ({
   column,
   row,
   columnFormat,
   handleAddRecentOutlook,
   handleAddArrOutlook,
   handleAddTaxonomy,
   handleCompanyWebsiteSplit,
   addEditIcon,
   index
}) => {
    let lowerCasedColumnName = column.toLocaleLowerCase()
    let returnedValue = formatEmptyCell(row[column])
    if (column === COLUMN_NAMES.COMPANY_NAME) {
        return <div className="d-flex align-items-center relative pr-1">
                {handleCompanyWebsiteSplit(formatEmptyCell(row[column]), row[COLUMN_NAMES.CINCHY_ID], row[column])}
                    <div className={styles.boxContainer}>
                        <div
                            style={{
                            background: `#${row["Company Name2"].split("|")[1].trim()}`,
                            }}
                            className={styles.fpcScoreBoxContainer}
                        >
                            <span className={styles.fpcScore} style={{ fontSize: "12px" }}>
                            {row["Company Name2"].split("|")[0]}
                            </span>
                        </div>
                        <div
                            className={styles.flagColor}
                            style={{
                            background: `#${row["Company Name2"].split("|")[2].trim()}`,
                            }}
                        ></div>
                    </div>
                </div>
    } else if (column === COLUMN_NAMES.SERIES) {
        return addEditIcon(formatEmptyCell(row[column]), column, row[COLUMN_NAMES.TABLE_CINCHY_ID], row[column], index)
    } else if (column === COLUMN_NAMES.ROUND_SIZE || column === COLUMN_NAMES.TOTAL_RAISED || column === COLUMN_NAMES.VALUATION) {
        return addEditIcon(formatEmptyCell(formatTotalRaised(row[column])), column, row[COLUMN_NAMES.TABLE_CINCHY_ID], row[column], index)
    } else if (column === COLUMN_NAMES.TECHNOLOGY) {
        return (
        <div className={styles.technologyCell}>
            {formatInvestors(row[column], styles['highlight-investors'])}
            <Tooltip title="Edit Category" arrow placement="top">
                <Button
                    className={styles.cellEditBtn}
                    onClick={(e) => handleAddTaxonomy(row['Company Cinchy Id'])}
                >
                    <Edit className={styles.editBtn}/>
                </Button>
            </Tooltip>
        </div>)
    }
    /* eslint-disable no-unused-expressions */
    columnFormat?.forEach(c => {
        if (c[lowerCasedColumnName] === FORMATTING_TYPES.LINK) {
            returnedValue = <a href={row[column]?.startsWith('http') ? row[column] : `http://${row[column]}`}
                               target="_blank" onClick={handleUrlClick}>{formatEmptyCell(row[column])}</a>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.STATUSSELECT) {
            returnedValue = <StatusSelect cinchyId={row['Company Cinchy Id']}
                                          defaultValue={{label: row[column], value: row[column]}}/>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.OWNERSELECT) {
            const refactoredData = removeEmailFromOwner(row[column])
            returnedValue = <OwnersSelect key={row['Table Cinchy Id']} affinityId={row["Company Cinchy Id"]}
                                          ownersInfo={refactoredData}></OwnersSelect>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.DATE) {
            returnedValue = addEditIcon(formatEmptyCell(formatDate(row[column])), column, row[COLUMN_NAMES.TABLE_CINCHY_ID], row[column], index)
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.MISSPASSLOSSSELECT) {
             returnedValue = addEditIcon(formatEmptyCell(row[column]), column, row[COLUMN_NAMES.TABLE_CINCHY_ID], row[column], index)
        } else if(c[lowerCasedColumnName] === FORMATTING_TYPES.PRIORITY_SELECT) {
            // @ts-ignore
            returnedValue = <FPCIPriorities key={row['Company Cinchy Id']} cinchyId={row['Company Cinchy Id']} defaultValue={{ label: row[column], value: row[column] }} />
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.MILLIONS) {
            returnedValue = formatEmptyCell(formatTotalRaised(row[column]))
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDCOMMA) {
            returnedValue = formatInvestors(row[column], styles['highlight-investors'])
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.MULTIPLE) {
            returnedValue = formatEmptyCell(formatMultiple(row[column]))
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.OUTLOOKSELECT) {
            returnedValue = (<>
                {formatEmptyCell(row[column])}
                <Button
                    size="small"
                    className={`${styles.addRecentOutlook} btn-neutral-primary mb-2 mr-1`}
                >
                    <AddIcon
                        onClick={() =>
                            handleAddRecentOutlook(
                                row["Company Cinchy Id"],
                                index
                            )
                        }
                        className={
                            styles.addRecentOutlookButton
                        }
                    ></AddIcon>
                </Button>
            </>)
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.ARRSELECT) {
            returnedValue = (<>
                {formatEmptyCell(formatTotalRaised(row[column]))}
                <Button
                    size="small"
                    className={`${styles.addRecentOutlook} btn-neutral-primary mb-2 mr-1`}
                >
                    <AddIcon
                        onClick={() =>
                            handleAddArrOutlook(
                                row["Company Cinchy Id"]
                            )
                        }
                        className={
                            styles.addRecentOutlookButton
                        }
                    ></AddIcon>
                </Button>
            </>)
        }
    })

    return returnedValue
}

 function handleUrlClick(event) {
    event.stopPropagation();
};