import { Dispatch } from 'react';
import axios from 'axios';


// Fake API call
// TODO: REMOVE WHEN API WILL BE READY
import {API_QUERY_URL, API_RELATIONSHIP_URL, QUERIES, STATUSES, API_COMPANIES_URL, API_AFFINITY_URL} from '../constants';
import { getAuthHeader } from '../helpers/auth';
import { logout } from '../store/user/userSlice';
import {whitespaceGroupingByRow} from "../utils/TGroping";

const fakeApiRequest = async (data: any) => {
    return new Promise<{ response: any; status: string }>((resolve) => {
        setTimeout(() => {
            // placeholder data
            resolve({ response: data, status: ''});
        }, 2000);
    });
};

const getData = async (path: string, dispatch?: Dispatch<any>, callback?: (data: any) => void) => {
    try {
        const response = await axios.get(`${API_QUERY_URL}${path}`, { headers: getAuthHeader() });
        return { status: STATUSES.SUCCESS, response: callback ? callback(response.data) : response.data };
    } catch (e) {
        dispatch?.(logout());
        return { status: STATUSES.ERROR, message: e.message }
    }
}
const getFilterSelectOptions = async (path: string, dispatch?: Dispatch<any>, callback?: (data: any) => void) => {
    try {
        return axios.get(`${API_QUERY_URL}${path}`, { headers: getAuthHeader() })
            .then(res => {
                if(path === QUERIES.COMPANY_FILTERS.TAXONOMY) {
                    // @ts-ignore
                    let data = whitespaceGroupingByRow(res?.data);
                    return { status: STATUSES.SUCCESS, response: callback ? callback(data) : data }
                }
                return { status: STATUSES.SUCCESS, response: callback ? callback(res.data?.data) : res.data?.data }
            });
    } catch (e) {
        dispatch?.(logout());
        return Promise.reject({ status: STATUSES.ERROR, message: e.message });
    }
}


const getRelationshipData = async (path: string, dispatch?: Dispatch<any>, callback?: (data: any) => void) => {
    try {
        const response = await axios.get(`${API_RELATIONSHIP_URL}${path}`, { headers: getAuthHeader() });
        return { status: STATUSES.SUCCESS, response: callback ? callback(response.data) : response.data };
    } catch (e) {
        dispatch?.(logout());
        return { status: STATUSES.ERROR, message: e.message }
    }
}

const getAffinityData = async (path: string, dispatch?: Dispatch<any>, callback?: (data: any) => void) => {
    try {
        const response = await axios.get(`${API_AFFINITY_URL}${path}`, { headers: getAuthHeader() });
        return { status: STATUSES.SUCCESS, response: callback ? callback(response.data) : response.data };
    } catch (e) {
        dispatch?.(logout());
        return { status: STATUSES.ERROR, message: e.message }
    }
}

const getCompaniesData = async (path: string, dispatch?: Dispatch<any>, callback?: (data: any) => void) => {
    try {
        const response = await axios.get(`${API_COMPANIES_URL}${path}`, { headers: getAuthHeader() });
        return { status: STATUSES.SUCCESS, response: callback ? callback(response.data) : response.data };
    } catch (e) {
        dispatch?.(logout());
        return { status: STATUSES.ERROR, message: e.message }
    }
}


const getFlagDetailsOptions = async () => {
    const { response, status } = await getData(QUERIES.FLAGS.FILTER_OPTIONS);
    if (status === STATUSES.SUCCESS) {
        return response.data;
    }
}

// @ts-ignore
const getRecentNews = async ({ dispatch, cinchyId }) => {
    const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.RECENT_NEWS}?@cinchyid=${cinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response)
    }
}

// @ts-ignore
const deleteRecentNewsItem = async ({ dispatch, cinchyId }) => {
    return getData(`${QUERIES.COMPANY_DETAILS.RECENT_NEWS_DELETE}?@tablecinchyid=${cinchyId}`, dispatch);
}

// @ts-ignore
const pullRecentNews = async ({ dispatch, cinchyId }) => {
    const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.PULL_RECENT_NEWS}?@cinchyid=${cinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        // return whitespaceGroupingByRow(response)
    }
}

// @ts-ignore
const getRecentNewsSources = async ({ dispatch, cinchyId }) => {
    const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.RECENT_NEWS_SOURCES}?@cinchyid=${cinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response)
    }
}

export {
    fakeApiRequest,
    getData,
    getRelationshipData,
    getFilterSelectOptions,
    getCompaniesData,
    getFlagDetailsOptions,
    getAffinityData,
    getRecentNews,
    getRecentNewsSources,
    deleteRecentNewsItem,
    pullRecentNews,
}