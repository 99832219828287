import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectOwners } from "../../store/sourcing/sourcingSlice";
import isEqual from 'lodash/isEqual';
import {
  updateOwners,
  updatePeopleOwners,
  updateInvestorOwners
} from "../../store/sourcing/sourcingThunks";
import Snackbar from "@material-ui/core/Snackbar";

interface OwnersSelectProps {
  ownersInfo: any;
  affinityId: string;
  peopleSelect?: boolean;
  investorSelect?: boolean;
  customUpdateOwners?: null | ((args: any) => void);
  width?: string
}

const OwnersSelect = ({ affinityId, ownersInfo, peopleSelect, investorSelect, customUpdateOwners = null, width = '300px' }: OwnersSelectProps) => {
  const [ownerNames, setOwnerNames] = useState([]);
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([] as any);
  const [ownerSelectOptions, setOwnerSelectOptions] = useState([] as any);

  const ownersData = useSelector(selectOwners);

  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleUpdateOwners = useCallback(
    async (values) => {
      if (values) {
        const finalOwners = values?.join(";");
        if(customUpdateOwners) {
          customUpdateOwners({ cinchyId: affinityId, owners: finalOwners, dispatch });
          openNotification({
            message: "Data updated successfully",
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
          });
          return;
        }
        // TODO CR-9 Refactor this after
        peopleSelect ? await dispatch(
          updatePeopleOwners({ affinityId: affinityId, owners: finalOwners })
        ) : investorSelect ? await dispatch(
          updateInvestorOwners({ affinityId: affinityId, owners: finalOwners })
        ) :  await dispatch(
          updateOwners({ affinityId: affinityId, owners: finalOwners })
        );
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
    },
    [dispatch, affinityId]
  );

  useEffect(() => {
    // TODO CF => Add Owners select
    setOwnerNames(ownersData?.map((o: any) => o["Tag"].split("<")[0].trim()));
  }, [ownersData]);

  useEffect(() => {
    setSelectedValues(ownersInfo?.map?.((item: string) => item.trim()));
  }, [ownersInfo]);

  useEffect(() => {
    setOwnerSelectOptions(
      ownerNames?.map((option: any) => ({
        value: option,
        label: option,
      }))
    );
  }, [ownerNames]);
  
  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "rgba(110, 60, 250, 0.05)",
            primary: "#6e3cfa",
          },
        })}
        styles={{
          input: (providedValue) => ({
            ...providedValue,
          }),
          placeholder: (providedValue) => ({
            ...providedValue,
            margin: "0 4px",
          }),
          control: (base) => ({
            ...base,
            width,
            marginLeft: "10px",
          }),
        }}
        options={Object.values(ownerSelectOptions || {})}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        placeholder="Select Owner"
        value={ownerSelectOptions?.filter((option: any) =>
          selectedValues?.includes(option.value)
        )}
        onChange={(selected) => {
          const values = (selected || []).map(({ value }) => value.trim());
          handleUpdateOwners(values);
          setSelectedValues(values);
        }}
      />
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return isEqual(prevProps.ownersInfo,nextProps.ownersInfo)
};

export default React.memo(OwnersSelect, areEqual);
