import React, { useState } from 'react';
import Select from 'react-select';
import { customStyles } from '../../utils/coloredSelectStyles';

export const GenericSingleSelect = ({
    options = {},
    name,
    defaultValue,
    onChange,
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue || { label: '', value: '' });

    const handleChange = (option) => {
        onChange(option.value);
        setSelectedOption(option)
    }

    return (
        <div style={{ margin: '5px 0', width: '100%' }}>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={customStyles}
                name={name}
                options={Object.values(options)}
                isMulti={false}
                isSearchable
                value={selectedOption}
                onChange={handleChange}
            />
        </div>
    );
};

export default GenericSingleSelect;