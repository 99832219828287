import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

function MenuSection({ menuItems, activeTab, name, type = 'relative' }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Element = type === 'relative' ? NavLink : 'a';

    return (
        <div>
            <Button
                onMouseEnter={handleMouseEnter}
                className={`m-2 ${activeTab ? 'btn-second' : ''}`}
            >
                {name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                onClose={handleClose}
                MenuListProps={{
                    onMouseLeave: handleClose
                }}
            >
                {menuItems.map(({ name, path }) => (
                    <MenuItem key={name}>
                        <Element
                            onClick={handleClose}
                            className='font-12 text-black link'
                            {...(type === 'relative' ? { to: path } : { href: path, target: "_blank", rel: "noopener noreferrer" })}
                        >
                            {name}
                        </Element>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MenuSection;
