import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from "react-redux";

import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { StatusSelect as  SingleSelect } from '../SingleSelect/SIngleSelect';
import { useNotification } from '../../hooks/useNotification';
import { selectRelationshipOptions } from '../../store/sourcing/sourcingSlice';

type TRelationshipSelect = {
    name: string;
    cinchyId: string;
    options: Record<string, any>;
    defaultValue: { label: string, value: string };
    // onChange: Dispatch<SetStateAction<string>> | ((value: string | number | null) => void)
}

const updateRelationship = async ({ affinityorgid, relationship }: { affinityorgid: string, relationship: string }) => {
    const queryParams = {
        '@cinchyid': affinityorgid,
        '@relationship': relationship,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const { response, status: resStatus }  = await getData(`${QUERIES.RELATIONSHIP_LIST.UPDATE}?${queryString}`);
    if(resStatus === STATUSES.SUCCESS) {
        // @ts-ignore
        return response.payload;
    }
}


const RelationshipSelect = ({ cinchyId, defaultValue, name } : TRelationshipSelect) => {
    const [selectedRelationship, setSelectedRelationship] = useState<string>('');
    const [ownerSelectOptions, setOwnerSelectOptions] = useState([] as any);

    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    const priorityData = useSelector(selectRelationshipOptions);

    useEffect(() => {
        setOwnerSelectOptions(
            priorityData?.map((option: any) => ({
            value: option["Options"],
            label: option["Options"],
          }))
        );
      }, [priorityData]);

    useEffect(() => {
        if(selectedRelationship) {
            updateRelationship({ affinityorgid: cinchyId, relationship: selectedRelationship }).then(() => {
                openNotification({
                    open: true,
                    message: 'Relationship Successfully updated',
                });
            })
        }
    }, [selectedRelationship]);


    return (
        <>
            <SingleSelect onChange={setSelectedRelationship} name={name} options={ownerSelectOptions} defaultValue={defaultValue} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    )
}

export default RelationshipSelect;