import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Button, Typography } from '@material-ui/core';

import { Dropdown } from '../../components/DropDown';
import Loader from '../../components/Loader';

import { useFilter } from '../../hooks/useFilter';

import { selectCompaniesFilters } from '../../store/filter/filterSelectors';
import { generateSelectContext } from '../helper';
import {
    COMPANY_NAMES_FIELD,
    companyFilterFields, includeCompanyNullableFilters, nullableCompanyKeyMuch
} from '../../constants/form/filterByCompany.constants';
import { capitalizeFirstLetter } from '../../pages/Sourcing/Companies/helper';
import { CompanyFilterOptions, INullableCompanyFilters } from '../../types/filter/filterByCompanies';
import { QUERIES, STATUSES } from '../../constants';
import { whitespaceGroupingByRow } from '../../utils/TGroping';
import { getData } from '../../api/sourcing';
import { useDispatch } from 'react-redux';

const getCompanyNameOptions = async ({ dispatch }:any) => {
    const { status, response  } = await getData(`${QUERIES.UNIVERSAL_SEARCH}`, dispatch)
    if(status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response);
    }
}

const FilterByCompany = () => {
    const dispatch = useDispatch();
    const [companyAndWebsites, setCompanyAndWebsites] = useState([] as any[]);
    const { changeOptions, resetOptions, isReset, filterOptions } = useFilter<Partial<CompanyFilterOptions & INullableCompanyFilters>>({});

    const selectOptions = useSelector(selectCompaniesFilters);

    const handleCompanyFilter = (filterOptions: Partial<CompanyFilterOptions>) => {
        changeOptions({ filterOptions });
    }

    useEffect(() => {
        getCompanyNameOptions({ dispatch }).then(res => {
            if(res?.length) {
                setCompanyAndWebsites(res?.map((r: any) => {
                    return r["Company Name"] + " | " + r["Company Website"];
                })); 
            }
        })
    }, []);

    const companyNames = COMPANY_NAMES_FIELD.fields?.[0];
    const options = generateSelectContext( companyAndWebsites || []);

    return (
        <Formik
            initialValues={{...filterOptions}}
            onSubmit={handleCompanyFilter}>
            {({ values, resetForm, setFieldValue }) => (
                <Form>
                    {selectOptions?.['Company Name'].size > 0 && <>
                        <Typography
                            component="div"
                            className="pb-2 text-uppercase font-weight-bold text-primary font-size-sm">
                            <span>Add companies to view</span>
                        </Typography>
                        <Field
                            label={companyNames.label}
                            name={companyNames.name}
                            component={companyNames.component}
                            isReset={isReset}
                            options={options}
                        />
                    </>}
                    <Typography
                        component="div"
                        className="pb-2 text-uppercase font-weight-bold text-primary font-size-sm">
                        <span>Filter by</span>
                    </Typography>
                    {selectOptions ? <>
                        <>
                            {
                                selectOptions &&  companyFilterFields.map(({ name, fields }) =>
                                    <Dropdown title={name} key={name}>
                                        <>
                                            {
                                                fields.map(({ component, name, label, ...otherOptions}) => {
                                                    const fieldProps = {};
                                                    if(otherOptions.optionName) {
                                                        const multiSelectOptions = {
                                                            options: generateSelectContext(selectOptions?.[otherOptions.optionName as keyof typeof selectOptions] || [])
                                                        }
                                                        Object.assign(fieldProps, multiSelectOptions)
                                                    }
                                                    return  (
                                                        <Fragment key={name}>
                                                            <Field
                                                                name={name}
                                                                label={label}
                                                                menuPlacement={label === 'Tags' && 'top'}
                                                                {...fieldProps}
                                                                isReset={isReset}
                                                                component={component}
                                                                selectedRange={{ [name]: filterOptions[capitalizeFirstLetter(name)]}}
                                                            />
                                                            {
                                                                includeCompanyNullableFilters[name] &&
                                                                    <div className='d-flex align-center'>
                                                                        <label className='font-12' htmlFor={nullableCompanyKeyMuch[name]}>Include NULL&nbsp;&nbsp;</label>
                                                                        <input
                                                                            id={nullableCompanyKeyMuch[name]}
                                                                            type='checkbox'
                                                                            name={nullableCompanyKeyMuch[name]}
                                                                            checked={!!values?.[nullableCompanyKeyMuch[name] as keyof typeof values]}
                                                                            onChange={(e: any) => {
                                                                                setFieldValue(nullableCompanyKeyMuch[name] as keyof typeof values, e?.target?.checked);
                                                                            }}
                                                                            // disabled={!Object.keys(filterOptions).includes(name)}
                                                                        />
                                                                    </div>
                                                            }
                                                        </Fragment>
                                                    )

                                                })
                                            }
                                        </>
                                    </Dropdown>
                                )
                            }
                        </>
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn-neutral-second w-50 mr-1"
                                onClick={async () => {
                                    await resetOptions(); // Wait for resetOptions to complete
                                    resetForm();
                                }}>
                                Reset
                            </Button>
                            <Button className="btn-neutral-primary w-50 ml-1" type='submit'>
                                Apply
                            </Button>
                        </div>
                    </> : <Loader/>}
                    <div className="divider mt-2 mb-2" />
                </Form>
            )}
        </Formik>
    )
}

export default FilterByCompany;