import React, { useState, useEffect, ChangeEvent } from "react";
import { makeStyles, TextField, Button, ListItem, List, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ModalForm from "../ModalForm";
import {
  getSectorsList,
  insertSector,
} from "../../store/sourcing/sourcingThunks";
import { selectSectors } from "../../store/sourcing/sourcingSlice";
import { useNotification } from "../../hooks/useNotification";
import ModalContent from "./ModalContent";
import { SectorOption } from "../../interfaces";
import Snackbar from "@material-ui/core/Snackbar";

import styles from "./styles.module.scss";

const useStyles = makeStyles({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
  selectField: {
    width: "100%",
  },
  inputField: {
    marginBottom: "1rem",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CreateSectorsModal = ({ isOpen, setIsOpen }: any) => {
  const [sector, setSector] = useState("");
  const [searchedSectors, setSearchedSectors] = useState<SectorOption[] | null>(
    null
  );
  const [error, setError] = useState({ error: false, message: "" });
  const [activeTab, setActiveTab] = useState('create');

  const sectorOptions = useSelector(selectSectors);
  const { notificationData, openNotification } = useNotification();
  const { open, message, toastrStyle, vertical, horizontal } = notificationData;

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAddSectorCreator = (e: ChangeEvent<HTMLInputElement>) => {
    setSector(e.target.value);
  };

  const handleSearch = (e: any) => {
    const searchedValue = e?.target?.value;

    if (searchedValue && sectorOptions.length) {
      const filteredSectors = sectorOptions.filter((sectorOption: any) =>
        sectorOption["Sector"]
          ?.toLowerCase()
          .startsWith(searchedValue.toLowerCase())
      );
      setSearchedSectors(filteredSectors);
    } else {
      setSearchedSectors(null);
    }
  };

  const changeTab = (item: string) => {
    setActiveTab(item)
    setSearchedSectors(null)
  };

  const handleSave = async () => {
    const existedSectors = sectorOptions.map((sector: any) =>
    sector["Sector"]?.toLocaleLowerCase()
    );
    if (!existedSectors.includes(sector.toLocaleLowerCase())) {
      await dispatch(insertSector(sector));
      dispatch(getSectorsList());
      openNotification({
        message: "Sector successfully added",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });
      handleClose();
    } else {
      setError({ error: true, message: "This sector already exists" });
    }
  };

  const handleClose = () => {
    setSector("");
    setError({ error: false, message: "" });
    setIsOpen(false);
    setSearchedSectors(null)
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getSectorsList());
    }
  }, [isOpen]);

  return (
    <>
      <ModalForm
        open={isOpen}
        handleClose={handleClose}
        maxWidth={"sm"}
        fullWidth={true}
        fixedHeight={true}
      >
        <div className="border-0">
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>Sectors</div>
              <Box display="flex" justifyContent="center" width="100%">
                  <List component="div" className="nav-line d-flex nav-tabs-primary">
                      <ListItem
                          key={0}
                          button
                          disableRipple
                          selected={activeTab === "create"}
                          onClick={() => changeTab("create")}
                          >
                            <span>Create</span>
                            <div className="divider" />
                      </ListItem>
                      <ListItem
                          key={1}
                          button
                          disableRipple
                          selected={activeTab === "view"}
                          onClick={() => changeTab("view")}
                          >
                            <span>View</span>
                            <div className="divider" />
                      </ListItem>
                </List>
            </Box>
            {activeTab === "create" && <><section className={styles.fieldSection}>
              <TextField
                variant="outlined"
                className={classes.inputField}
                fullWidth
                label={"Sector"}
                value={sector}
                margin="dense"
                error={error.error}
                onChange={handleAddSectorCreator}
                helperText={error.message}
              />
            </section>
            <div className={styles.actions}>
              <Button
                variant="contained"
                className={classes.saveBtn}
                onClick={handleSave}
                disabled={!sector}
              >
                Create
              </Button>
            </div></>}

            {sectorOptions.length && activeTab === "view" && (
              <ModalContent
                sectors={searchedSectors ? searchedSectors : sectorOptions}
                handleSearch={handleSearch}
              />
            )}
          </div>
        </div>
      </ModalForm>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ root: toastrStyle }}
        open={open}
        message={message}
      />
    </>
  );
};

export default CreateSectorsModal;
