/* eslint-disable @typescript-eslint/no-explicit-any */
import PubSub from 'pubsub-js';
import { Color } from '@material-ui/lab/Alert';

const NOTIFICATION = 'NOTIFICATION';

export type Severity = Color;
export type showFunc = (message: string, severity?: Severity) => any;
type cbFunc = (data: { severity: Severity; message: string }) => void;
type unsubscribeFunc = () => void;
type subscribeFunc = (cb: cbFunc) => unsubscribeFunc;

export const show: showFunc = (message: string, severity): boolean =>
  PubSub.publish(NOTIFICATION, { message, severity });

export const subscribe: subscribeFunc = (cb) => {
  const token: string = PubSub.subscribe(
    NOTIFICATION,
    (msg: string, data?: any): void => cb(data)
  );

  return () => {
    PubSub.unsubscribe(token);
  };
};
