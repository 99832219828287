import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {CardContent, Checkbox, Table, TableCell, TableRow} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    selectLoading
} from '../../../../store/sourcing/sourcingSlice';
import TSkeleton from '../../../../components/TSkeleton';
import { getWhitespaceCompaniesByTechnology } from '../../../../store/sourcing/whitespace/whitespaceThunks';
import { selectWhiteSpaceByTechnology } from '../../../../store/sourcing/whitespace/whitespaceSelectors';
import { colorMapping, subTableColorMapping } from '../configs';
// import HeaderActions from '../Common/HeaderActions';
import {CompaniesInvestment, SubTableHead, SubTableBody} from '../Common/CellRenderers';
import {useKeywordFilter} from "../../../../hooks/useKeywordFilter";
import HeaderActions from "../Common/HeaderActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    description: {
       maxWidth: 400,
       minWidth: 400,
       overflow: 'hidden',
       textOverflow: 'ellipsis',
       fontSize: '12px',
    },
    scroll: {
        overflowY: 'scroll',
    },
    subTableColumn: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
    },
    dynamicBackgroundColor: {
        backgroundColor: (props) => props.backgroundColor,
    },
    dividerPosition: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    greyBackground: {
        backgroundColor: '#edecec',
        margin: '1px',
    }
}))

const domainStyleTechnology = {
    backgroundColor: '#E5C3EE'
}

const categoryStyle = {
    backgroundColor: '#C4C4C4'
};

const secondaryCellStyle = {
    backgroundColor: '#F6F6F6'
}

const subCategoryStyle = {
    backgroundColor: '#D9D9D9'
}

const divideRowsStyle = {
    height: '1rem',
}

const emptyCell = {
    maxWidth: '16px'
}

const largeCell = {
    minWidth: '300px'
}

const TTechnology = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhitespaceCompaniesByTechnology())
    }, [])

    const {  columns, rows } = useSelector(selectWhiteSpaceByTechnology);
    const loading = useSelector(selectLoading);

    const modifiedSubTable = useMemo(() => {
        const columnNames = columns?.[8]?.columnName.split(',');
        return columnNames?.reduce((current, item, index) => {
            current[item.trimStart()] = index;
            return current;
        }, {})
        // columns['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos'];

    }, [columns])

    const { keywords, selectedKeywords, toggleKeywordSelection, filteredByKeyword } = useKeywordFilter(rows);

    const rowsToDisplay = selectedKeywords.size ? filteredByKeyword : rows;

    console.log('Log ::: rowsToDisplay ===', rowsToDisplay);

    return (<>
        {!columns.length ? <TSkeleton></TSkeleton> : <>
       <HeaderActions
           keywords={keywords}
           selectedKeywords={selectedKeywords}
           toggleKeywordSelection={toggleKeywordSelection}
       />
        <CardContent className={`${classes.scroll} px-0 pt-2 pb-3`}>
            {loading ? <>
                <TSkeleton />
            </> : <>
                <Table className="table table-borderless table-hover table-alternate mb-0">
                    <thead>
                    <tr>
                        {columns.map(({ columnName }, index) => <TableCell
                            style={[1,3,5].includes(index) || !columnName ? emptyCell : index === 11 ? largeCell : {}}
                            className={`${columnName === 'Technology' ? "text-left" : "text-center"}`}>
                            <div className="px-4 font-weight-bold font-12">
                                {index === 8 ? <SubTableHead /> : columnName}
                            </div>
                        </TableCell>)}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(rowsToDisplay).map(subCategory => <>
                        {rowsToDisplay[subCategory].map((item, index) => <>
                            <TableRow>
                                {/*Domain*/}
                                <TableCell style={domainStyleTechnology} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item.Domain}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Category*/}
                                <TableCell style={categoryStyle} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item.Category}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Sub Category*/}
                                <TableCell style={subCategoryStyle} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item['Sub Category']}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Technology*/}
                                <TableCell style={secondaryCellStyle}>
                                    <div className="d-flex">
                                        <Checkbox
                                            color="primary"
                                            id="checkboxProjects15"
                                            className="align-self-center mr-2"
                                        />
                                        <span className="font-weight-bold text-black align-self-center font-12">
                                        {item.Technology}
                                    </span>
                                    </div>
                                </TableCell>
                                {/*Description*/}
                                <TableCell style={secondaryCellStyle} className="left">
                                    <span className={`text-black d-block ${classes.description}`}>{item['Category Description']}</span>
                                </TableCell>
                                <TableCell className="text-center">
                                    <SubTableBody modifiedSubTable={modifiedSubTable} item={item}/>
                                </TableCell>
                                <TableCell style={{backgroundColor: colorMapping[item.Color]}} className="text-center">
                                </TableCell>
                                <CompaniesInvestment
                                    totalCCount={item['Total Companies Count']}
                                    totalInvestedCount={item['Total Invested Count']}
                                />
                                {/*Investment*/}
                                <TableCell className="text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className={`${classes.greyBackground} font-weight-bold font-12 pr-2`}>
                                            {/*{' '}*/}
                                            {item['List of Portcos']}
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow style={divideRowsStyle}>
                                <TableCell style={item.Domain === rows[subCategory]?.[index+1]?.Domain ? domainStyleTechnology : {}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={item.Category === rows[subCategory]?.[index+1]?.Category ? categoryStyle : {}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={item['Sub Category'] === rows[subCategory]?.[index+1]?.['Sub Category'] ? subCategoryStyle : {}}></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </>)}
                    </>)}
                    </tbody>
                </Table>
                <div className="divider mb-3" />
                <div className="text-center">

                </div>
            </>}
        </CardContent>
        </>}
    </>);
};

export default TTechnology;