import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable from '../../../../components/GenericTable';
import TSkeleton from '../../../../components/TSkeleton';
import { formatEmptyCell } from "../../../../utils/utils";
import {
  selectCategoryCoverage} from '../../../../store/dashboard/dashboardSlice';
import {
  getCategoryCoverage} from '../../../../store/dashboard/dashboardThunks';

import styles from './styles.module.scss';
import usePagination from '../../../../hooks/usePagination';
import Pagination from '../../../../components/Pagination/Pagination';
import { defaultColumn } from '../../../Sourcing/utils';
import { useFPCIPriorities } from "../../../../hooks/useFPCIPriorities";
import FPCIPriorities from "../../../../components/FPCIPrio";
import OwnersSelect from "../../../../components/OwnersSelect";
import { useOwners } from "../../../../hooks/useOwners";

export default function CategoryCoverage() {
  const dispatch = useDispatch();
    const { rows, cells, isLoading } = useSelector(selectCategoryCoverage);

    useFPCIPriorities();
    useOwners();

    useEffect(() => {
        dispatch(getCategoryCoverage());
    }, [])
   
    const getCellStyle = (cell: any, index: number) =>  {
      return cell==='Coverage %' ? {
      color: `#${rows[(currentPage - 1) * 5 + index]['Coverage % Text Color']}`,
      fontWeight: 'bold',
    } : defaultColumn}
  
    const renderTCell = useCallback((column, row) => {
      const defaultValue = formatEmptyCell(row[column]);
      switch (column) {
        case "Priority":
          return (
            <FPCIPriorities
              key={row["Cinchy Id"]}
              cinchyId={row["Cinchy Id"]}
              defaultValue={{ label: row[column], value: row[column] }}
              name=""
            />
          );
        case "Owners":
          const refactoredData = row[column] ? row[column].split(";") : ""
          return (
            <OwnersSelect
              key={row["Cinchy Id"] || row["cinchy id"]}
              affinityId={row["Cinchy Id"] || row["cinchy id"]}
              ownersInfo={refactoredData}
            ></OwnersSelect>
          );
        default:
          return defaultValue;
      }
    }, []);

    const { currentPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows, rowsPerPageValue : 5});

    const rowsToDraw = useMemo(() => rows?.slice(
        (currentPage - 1) * 5,
        (currentPage - 1) * 5 + 5
    ), [rows, currentPage])

  return (
    <>
      {isLoading ? <TSkeleton/> :
            <div className={`table-responsive-md mb-spacing-2-x2 ml-3 mr-3`}>
                <GenericTable
                    title={`Category Coverage (${rows?.length || 0} results)`}
                    rows={rowsToDraw}
                    fixedColumnHint=""
                    headCells={cells?.filter(
                      (_: any, index: number) => index !== 0 && index !== 3
                    )}
                    tableActions={null}
                    getHeadCellStyle={null}
                    getCellStyle={getCellStyle}
                    renderCell={renderTCell}
                    getActionsCell={null}
                    searchBar={null}
                    scrollable={true}
                >
                    <Pagination
                        goToPage={goToPage}
                        rowsPerPage={5}
                        currentPage={currentPage}
                        goToPageValue={goToPageValue}
                        totalPages={totalPages}
                        handleRowsPerPageChange={handleRowsPerPageChange} 
                        handleGoToPageChange={handleGoToPageChange}
                    />
                </GenericTable>
            </div>
          }

    </>
  );
}
