import { getData } from '../../../api/sourcing';
import { QUERIES, STATUSES } from '../../../constants';

const getCircleBackNotes = async ({dispatch}) => {
    const { response, status} = await getData(QUERIES.CIRCLEBACK_NOTES.GET_NOTES, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response
    }
}

const deleteCircleBackNote = async ({ dispatch, cinchyId }) => {
    const { status} = await getData(`${QUERIES.CIRCLEBACK_NOTES.DELETE}?%40cinchyid=${cinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

// - Design: https://www.figma.com/design/QGS7D2KgGpo59wRhfrdo1D/FPC-Application?node-id=5145-464&node-type=frame&t=Yx1DYXf1OMK9X446-0
//  - Section: Sourcing à Meeting Notes
//  - Select API: https://forgepoint.cinchy.net/API/Front End/Circleback Notes
//  - Edit APIs:
//          - Update Meeting Title: https://forgepoint.cinchy.net/API/Front End/Update - Circleback Meeting Title?%40cinchyid=&%40name=
//          - Update Notes: https://forgepoint.cinchy.net/API/Front End/Update - Circleback Notes?%40cinchyid=&%40notes=
//          - Update Company Name: drop-down that inserts Cinchy Id (like on the “add new financings” section)
//          - Update company name: https://forgepoint.cinchy.net/API/Front End/Update - Circleback Company?%40cinchyid=1&%40companycinchyid=318357
//  - Delete APIs
//          - https://forgepoint.cinchy.net/API/Front End/Delete - Circleback Notes?%40cinchyid=

const updateMeetingTitle = async ({ dispatch, cinchyId, name }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_MEETING_TITLE}?%40cinchyid=${cinchyId}&%40name=${name}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

const updateNotes = async ({ dispatch, cinchyId, notes }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_NOTES}?%40cinchyid=${cinchyId}&%40notes=${notes}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

const updateCompanyName = async ({ dispatch, cinchyId, companyCinchyId }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_COMPANY_NAME}?%40cinchyid=${cinchyId}&%40companycinchyid=${companyCinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}


export { updateNotes, updateCompanyName, deleteCircleBackNote, getCircleBackNotes }
