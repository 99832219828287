import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Done } from "@material-ui/icons";
import styles from "./styles.module.scss";
import {
  addCompanyContacts, updateCompanyContacts,
} from "../../store/sourcing/sourcingThunks";
import { useDispatch } from "react-redux";

const ContactInfo = ({ contactData, contactDataExtra, companyId, handleRefresh }: any) => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [firstNameText, setFirstNameTextText] = useState("");
  const [firstNamePreviousText, setFirstNamePreviousText] = useState("");
  const [lastNameText, setLastNameText] = useState("");
  const [lastNamePreviousText, setLastNamePreviousText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [titlePreviousText, setTitlePreviousText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [emailPreviousText, setEmailPreviousText] = useState("");
  const [tableId, setTableId] = useState("");

  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const save = () => {
    setFirstNamePreviousText(firstNameText);
    setLastNamePreviousText(lastNameText);
    setTitlePreviousText(titleText);
    setEmailPreviousText(emailText);
    if(tableId) {
      dispatch(
        updateCompanyContacts({
            "First Name": firstNameText,
            "Last Name": lastNameText,
            "Title": titleText,
            "Email": emailText,
            "Primary Contact": 'true',
            "Table Cinchy Id": tableId  
        })
      );
    } else {
      dispatch(
        addCompanyContacts({
          data: {
            "First Name": firstNameText,
            "Last Name": lastNameText,
            "Title": titleText,
            "Email": emailText,
            "Primary Contact": 'true',
          },
          companyId: companyId,
        })
      );
    }
    setTimeout(() => {
      handleRefresh();
    }, 3000);
    toggleEditable();
  };

  const cancel = () => {
    setFirstNameTextText(firstNamePreviousText);
    setLastNameText(lastNamePreviousText);
    setTitleText(titlePreviousText);
    setEmailText(emailPreviousText);

    toggleEditable();
  };

  const handleTextChange = (value: any, setter: any) => {
    setter(value);
  };

  useEffect(() => {
    if (contactData?.length) {
      setFirstNameTextText(contactData?.[0]?.["First Name"]);
      setFirstNamePreviousText(
        contactData?.[0]?.["First Name"]
      );

      setLastNameText(contactData?.[0]?.["Last Name"]);
      setLastNamePreviousText(
        contactData?.[0]?.["Last Name"]
      );

      setTitleText(contactData?.[0]?.["Title"]);
      setTitlePreviousText(contactData?.[0]?.["Title"]);

      setEmailText(contactData?.[0]?.["Email"]);
      setEmailPreviousText(contactData?.[0]?.["Email"]);

      setTableId(contactData?.[0]?.["Table Cinchy Id"])
    }
  }, [contactData]);

  return (
    <>
      <section className={styles.headerSection}>
        <p className={`${styles.contactInfoText} font-14 font-weight-bold`}>
          Demographics | Primary Contact Info
        </p>
        {/* Comment editing contact info */}
        {!isEditable && (
          <img
            onClick={toggleEditable}
            className={styles.editButton}
            src="/edit.svg"
            alt="Company Logo"
            id="blockElement"
          />
        )}
        {isEditable && (
          <Button
            size="small"
            id="blockElement"
            className={`${"btn-neutral-primary"} mb-2`}
            onClick={save}
          >
            <Done/>
          </Button>
        )}
        {isEditable && (
          <Button
            size="small"
            id="blockElement"
            className={`${"btn-neutral-primary"} mb-2 ml-2`}
            onClick={cancel}
          >
            <Close/>
          </Button>
        )}
      </section>
      <section className={styles.mainSection}>
      <div className={styles.primaryContact}>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Founded</p>
            <div>
                <textarea
                  value={contactDataExtra?.[0]?.["Founded"]}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Region</p>
            <div>
              
                <textarea
                  value={contactDataExtra?.[0]?.["Region"]}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
             
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Country</p>
            <div>
              
                <textarea
                  value={contactDataExtra?.[0]?.["Country"]}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>City</p>
            <div>
              
                <textarea
                  value={contactDataExtra?.[0]?.["City"]}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              
            </div>
          </div>
        </div>
        <div className={styles.primaryContact}>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>First Name</p>
            <div>
              {isEditable ? (
                <textarea
                  value={firstNameText}
                  className={`${`${styles.editableTextArea} font-12`} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setFirstNameTextText)
                  }
                />
              ) : (
                <textarea
                  value={firstNameText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Last Name</p>
            <div>
              {isEditable ? (
                <textarea
                  value={lastNameText}
                  className={`${`${styles.editableTextArea} font-12`} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setLastNameText)
                  }
                />
              ) : (
                <textarea
                  value={lastNameText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Title</p>
            <div>
              {isEditable ? (
                <textarea
                  value={titleText}
                  className={`${`${styles.editableTextArea} font-12`} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setTitleText)
                  }
                />
              ) : (
                <textarea
                  value={titleText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <p className={`${styles.taxonomySubText} font-12`}>Email Address</p>
            <div>
              {isEditable ? (
                <textarea
                  value={emailText}
                  className={`${`${styles.editableTextArea} font-12`} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setEmailText)
                  }
                />
              ) : (
                <textarea
                  value={emailText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInfo;
