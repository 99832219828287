import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TableCell, TableHead as TH, TableRow, Tooltip } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InfoIcon from '@material-ui/icons/Info';
import { tHeadStyle } from './styles';

function TableHead(props) {
    const {
        headCells,
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        getHeadCellStyle,
        fixedColumnHint,
        actionsColumn
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TH>
            <TableRow>
                {/*<TableCell*/}
                {/*    style={tHeadStyle}*/}
                {/*    // className={clsx(classes.headerCell)}*/}
                {/*    // padding="checkbox"*/}
                {/*>*/}
                {/*  /!*<Checkbox*!/*/}
                {/*  /!*  className={clsx(classes.checkboxCell)}*!/*/}
                {/*  /!*  indeterminate={numSelected > 0 && numSelected < rowCount}*!/*/}
                {/*  /!*  checked={rowCount > 0 && numSelected === rowCount}*!/*/}
                {/*  /!*  onChange={onSelectAllClick}*!/*/}
                {/*  /!*  inputProps={{ 'aria-label': 'select all desserts' }}*!/*/}
                {/*/>*/}
                {/*</TableCell>*/}
                {actionsColumn && <TableCell
                    className={classes.firstColumn}
                    style={tHeadStyle}
                    align='center'
                >
                    <Tooltip title={fixedColumnHint || "Row Actions"} placement="top-start">
                        <InfoIcon/>
                    </Tooltip>
                </TableCell>}
                {headCells.map((headCell, index) => (
                    <TableCell
                        className={clsx(classes.headerCell, index === 0 && actionsColumn && classes.secondColumn, index === 0 && classes.z102)}
                        style={getHeadCellStyle?.(headCell)}
                        key={headCell}
                        align="left"
                        // padding={index === 1 ? 'none' : 'default'}
                        sortDirection={orderBy === headCell ? order : false}>
                {/*        <TableSortLabel*/}
                {/*            style={getHeadCellStyle?.(headCell)}*/}
                {/*            className={classes.sortCell}*/}
                {/*            active={orderBy === headCell}*/}
                {/*            direction={orderBy === headCell ? order : 'asc'}*/}
                {/*            onClick={createSortHandler(headCell)}>*/}
                {/*            {headCell}*/}
                {/*            {orderBy === headCell ? (*/}
                {/*                <span className={classes.visuallyHidden}>*/}
                {/*  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
                {/*</span>*/}
                {/*            ) : null}*/}
                {/*        </TableSortLabel>*/}
                        {headCell}
                    </TableCell>
                ))}
            </TableRow>
        </TH>
    );
}

TableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

export default TableHead;