import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Auth from './pages/Auth';
import LeftSidebar from './layout/LeftSidebar';
import Sourcing from "./pages/Sourcing";
import Relationships from './pages/Relationships/Relationships';
import usePath from './hooks/usePath';
import Dashboard from './pages/Dashboard/Dashboard';

const Layout: React.FC = () => {
  usePath()
  return (
    <>
      <LeftSidebar>
        <Switch>
          {/* Homepage  */}
          <Route exact path="/" component={Home} />
          <Route exact path="/silent-refresh" component={Auth} />
          {/* Sourcing  */}
          <Route path="/sourcing" component={Sourcing} />
          {/* Relationships  */}
          <Route path="/relationships" component={Relationships} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </LeftSidebar>
    </>
  );
};

export default Layout;
