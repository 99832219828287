import jwtDecode, { JwtPayload as JwtInitialPayload } from 'jwt-decode';
import { CINCHY_USER, TOKEN } from '../constants';

type getAuthHeaderObj = {
  Authorization: string;
};
interface JwtPayload extends JwtInitialPayload {
  roles?: Array<string>;
}

export const setToken = (token: string): void => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = (): string | null => localStorage.getItem(TOKEN);

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN);
};

export const removeCinchyUser = (): void => {
  localStorage.removeItem(CINCHY_USER);
};

export const getAuthHeader = (): getAuthHeaderObj => ({
  Authorization: `Bearer ${getToken()}`,
});

export const isAuthenticated = (): boolean => !!getToken();

export const getUserRoles = (): Array<string> => {
  const token = getToken() || '';

  if (!token) {
    return [];
  }

  const decoded = jwtDecode<JwtPayload>(token);

  return decoded.roles || [];
};

// export const getHomeScreenRoute = (): string => {
//   const roles = getUserRoles();

//   const firstRole = roles[0];

//   const homeScreenRoute = ROUTES.find(
//     ({ canviewList, isHomeScreen }) =>
//       canviewList?.includes(firstRole) && isHomeScreen
//   )?.pathname as string;

//   return homeScreenRoute;
// };

export const canView = (canViewRoles?: Array<string>): boolean => {
  if (canViewRoles === undefined) {
    return true;
  }

  const userRoles = getUserRoles();

  return userRoles.some((r: string) => canViewRoles.includes(r));
};
