import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import { useField } from 'formik';

import { getTaxonomyData } from '../../store/filter/filterThunks';
import DataTree from '../DataTree';


const generateClassName = createGenerateClassName({
    seed: 'tax',
    generate: (rule, styleSheet) => `${styleSheet.options.name}-${rule.key}-${Math.random().toString(36)}`
});

export default function TaxonomyFilter({ field: optionsField, isReset }) {
    const [field, , helpers] = useField(optionsField);
    const [technologiesTree, setTechnologiesTree] = useState({});
    const [selectedTechnologies, setSelectedTechnologies] = useState([]);

    const isLoading = Object.keys(technologiesTree).length === 0;


    useEffect(() => {
    
    }, [isReset])

    useEffect(() => {
        getTaxonomyData().then(data => {
            const tree = createTechnologyTree(data);
            setTechnologiesTree(tree);
        })
    }, [isReset])

    useEffect(() => {
        if (selectedTechnologies.length) {
            helpers.setValue(selectedTechnologies);

        }
    }, [selectedTechnologies]);

    useEffect(() => {
        let selected = [];

        // Retrieve checked technologies
        Object.values(technologiesTree).forEach((category) => {
            Object.values(category.data).forEach((subCategory) => {
                Object.values(subCategory.data).forEach((tech) => {
                    if (tech.checked) {
                        selected.push(tech.tech)
                    }
                })
            })
        })
        setSelectedTechnologies(selected);
    }, [technologiesTree]);



    const handleCheckTechnology = (event, level, key1, key2, key3) => {
        const updated = { ...technologiesTree };
        const checked = event.target.checked;

        if (level === 0) {
            updated[key1].checked = checked;
            for (let key2 of Object.keys(updated[key1].data)) {
                updated[key1].data[key2].checked = checked;

                updated[key1].data[key2].data = updated[key1].data[key2].data.map((item) => {
                    return { ...item, checked }
                })
            }
        } else if (level === 1) {
            updated[key1].data[key2].checked = checked;
            updated[key1].data[key2].data = updated[key1].data[key2].data.map((item) => {
                return { ...item, checked }
            });

            if (!checked) {
                updated[key1].checked = checked;
            }

        } else if (level === 2) {
            updated[key1].data[key2].data = updated[key1].data[key2].data.map((item) => {
                if (key3 === item.tech) {
                    return { ...item, checked };
                }
                return item;
            });
        }

        setTechnologiesTree(updated);
    };

    const createTechnologyTree = (technologyList) => {
        const nestedObject = {};

        technologyList.forEach((item) => {
            const { Category, 'Sub Category': SubCategory, Technology } = item;

            if (!nestedObject[Category]) {
                nestedObject[Category] = { data: {}, checked: false };
            }

            if (!nestedObject[Category].data[SubCategory]) {
                nestedObject[Category].data[SubCategory] = { data: [], checked: false };
            }

            nestedObject[Category].data[SubCategory].data.push({ tech: Technology, checked: false });
        });

        return nestedObject;
    };

    return <>
        <StylesProvider generateClassName={generateClassName}>
            {isLoading ? <div className='d-flex justify-content-center'>
                <CircularProgress style={{color: '#6e3cfa'}} />
            </div> : <DataTree styles={{ fontSize: 'font-12' }} tree={technologiesTree} handleChange={handleCheckTechnology} />}
        </StylesProvider>
    </>
}