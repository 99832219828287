import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTagOptions } from '../store/sourcing/sourcingThunks';

const useTags = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTagOptions());
    }, []);
}

export { useTags };