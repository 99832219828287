import { useEffect, useState } from 'react';

const usePagination = ({ rows, rowsPerPageValue = 5 }) => {
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageValue);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [goToPageValue, setGoToPageValue] = useState(1);

    useEffect(() => {
        setTotalPages(Math.ceil(rows?.length / rowsPerPage));
    }, [rowsPerPage, rows]);

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
        setGoToPageValue(1);
    };

    const goToPage = (page) => {
        if (page < 1 || isNaN(page)) {
            setCurrentPage(1);
            setGoToPageValue("");
        } else if (page > Math.ceil(rows.length / rowsPerPage)) {
            setCurrentPage(Math.ceil(rows.length / rowsPerPage));
            setGoToPageValue(Math.ceil(rows.length / rowsPerPage))
        } else {
            setCurrentPage(page);
            setGoToPageValue(page);
        }
    };

    const handleGoToPageChange = (event) => {
        if (!isNaN(event.target.value)) {
            goToPage(parseInt(event.target.value))
        }
    };

    return { currentPage, rowsPerPage, totalPages, goToPage, goToPageValue, handleRowsPerPageChange, handleGoToPageChange };
}

export default usePagination;