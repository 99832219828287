import React, {useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCoverageChart,
  selectHygieneChart,
} from "../../../store/dashboard/dashboardSlice";
import {
  getCoverageChartData,
  getHygieneChartData,
} from "../../../store/dashboard/dashboardThunks";

import BarChart from "./BarChart";

export default function Charts() {
  const dispatch = useDispatch();
  const { data: chartData, isLoading: isChartLoading } = useSelector(
    selectCoverageChart
  );
  const { data: hygieneData, isLoading: isHygieneLoading } = useSelector(
    selectHygieneChart
  );

  useEffect(() => {
    dispatch(getCoverageChartData());
    dispatch(getHygieneChartData());
  }, []);

  return (
    <>
      <div>
        <BarChart
          data={chartData}
          isLoading={isChartLoading}
          textKey="Technology"
          valueKey="Coverage %"
          title="High priority Category Coverage"
        />
        <BarChart
          data={hygieneData}
          isLoading={isHygieneLoading}
          textKey="Owners"
          valueKey="FPC Company Hygiene"
          title="Pipeline Hygiene"
        />
      </div>
    </>
  );
}
