//Initial shape
// {columnName: "Company Cinchy Id", type: "Int64"}
// {columnName: "Customer LinkedIn ID", type: "String"}
// {columnName: "Customer Name", type: "String"}
// {columnName: "Company Name", type: "String"}
// {columnName: "Company Status", type: "String"}
// {columnName: "ARR as of Date", type: "DateTime"}
// {columnName: "ARR", type: "Decimal"}
// {columnName: "Use Case", type: "String"}
// {columnName: "Champion Title", type: "String"}
// {columnName: "Competitors If Competitive Process", type: "String"}
// {columnName: "Replaced If Rip And Replace", type: "String"}
// {columnName: "Category", type: "String"}
// {columnName: "Sub Category", type: "String"}
// {columnName: "Technology", type: "String"}

import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';
import Range from '../../components/Range';

export const customersFilterFields = [
    {
        name: 'customer name',
        label: 'Customer Name',
        component: MultiselectFormik,
    },
    {
        name: 'company name',
        label: 'Company Name',
        component: MultiselectFormik,
    },
    {
        name: 'company status',
        label: 'Company Status',
        component: MultiselectFormik,
    },
    {
        name: 'replaced if rip and replace',
        label: 'Rip and Replaced?',
        component: MultiselectFormik,
    },
    {
        name: 'arr',
        label: 'ARR',
        component: Range,
    },
    // {
    //     name: 'Bake Off?',
    //     label: 'Bake Off?', // NO DATA AVAILABLE
    //     component: 'YES/NO',
    // },
    {
        name: 'category',
        label: 'Category',
        component: MultiselectFormik,
    },
]