export const DEFAULT_PAGE_SIZE = 10;

export const TOKEN = "token";

export const CINCHY_USER = "cinchyUser";

export const LANG = "i18nextLng";

export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

export const MATERIAL_UI_DATE_FORMAT = "YYYY-MM-DDThh:mm";

export const DATE_FORMAT = "yyyy-MM-dd";

export const ISO_9601_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY, HH:mm";

export const DATE_TIME_FORMAT = "HH:mm";

export const DATABLE_DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const LANGUAGES = {
  HY: "hy",
  EN: "en",
};

export const MODEL_NOT_FOUND_ERROR_CODE = 4004;

export const USER_ROLES = {
  ADMIN: "admin",
  ROLE1: "role1",
  ROLE2: "role2",
  ROLE3: "role3",
};

export const TEXTAREA = {
  ROWS: 5,
  MAX_LENGTH: 600,
};

export const lastPathExpirationMinutes = 1440

// TODO: change it to error.code from the backend
export const UNAUTHORIZED = "Unauthorized";

export const PAGINTAION_PER_PAGE = 10;

export const KEY_METRICS = [
  "Key Metrics Green",
  "Key Metrics Red",
  "Key Metrics - ARR",
  "Key Metrics - Headcount",
  "Key Metrics - Total Raised",
  "Key Metrics - ARR Growth",
  "Key Metrics - Headcount Growth",
  "Key Metrics - Last Valuation",
  "Key Metrics - ARR / Headcount",
  "Key Metrics - Total Raised / Last Valuation",
  "Key Metrics - Key Investor Count",
  "Key Metrics - Months Since Last Raise",
  "Key Metrics - Total Raised / Headcount",
  "Key Metrics - Key People Count",
  "Key Metrics Color - ARR",
  "Key Metrics Color - ARR Growth",
  "Key Metrics Color - Headcount",
  "Key Metrics Color - Headcount Growth",
  "Key Metrics Color - ARR / Headcount",
  "Key Metrics Color - Months Since Last Raise",
  "Key Metrics Color - Total Raised",
  "Key Metrics Color - Total Raised / Headcount",
  "Key Metrics Color - Last Valuation",
  "Key Metrics Color - Total Raised / Last Valuation",
  "Key Metrics Color - Key Investor Count",
  "Key Metrics Color - Key People Count",
];

export const FUNDRAISING_HISTORY_1 = [
  "Pitchbook Id",
  "Recent Investment Stage",
  "Recent Financing Date",
  "Recent Financing Amount",
  "Recent Valuation",
];

export const FUNDRAISING_HISTORY_1_NEW_COLUMNS = [
  "Pitchbook Id",
  "Recent Financing",
  "Date",
  "Amount",
  "Post-$ Valuation",
];

export const FUNDRAISING_HISTORY_2 = [
  "Total Raised",
  "Last Known Valuation",
  "Last Known Valuation Date",
];

export const FUNDRAISING_HISTORY_2_NEW_COLUMNS = [
  "Total Raised",
  "Last Known Valuation",
  "LK Valuation Date",
];

export const INVESTORS = ["Investors", "Key Investors"];

export const SUMMARY = [
  "Description",
  "Most Recent Outlook",
  "Primary Competitors",
];

export const TAXONOMY = [
  "Category",
  "Sub Category",
  "Technology",
  "Key Words",
  "Sectors"
];

export const RELATIONSHIPS = [
  "Company Relationships",
  "Target Entrepreneurs",
  "Investor Relationships",
];

export const CRM_CH = [
  "Next Meeting Date",
  "Last Contact Date",
  "Last Meeting Date",
  "Sequence Status",
  "Last Sequence Enrolled",
  "Future Contact Date",
];

export const CRM = [
  "Days Since Last Contact",
  "Days Since Last Meeting",
  "In Sequence",
  "Last Sequence Enrolled",
];

export const INTERNAL_NOTES = [
  "What Problem Are They Solving",
  "Product Differentiation",
  "Why Right Team / Product / Strategy / Timing",
  "Why Do They Lose",
  "Exit Opportunities",
];

export const INTERNAL_NOTES_NEW_COLUMNS = [
  "What Problem Are They Solving",
  "Product Differentiation",
  "Why Right Team / Product / Strategy / Timing",
  "Why Do They Lose",
  "Exit Opportunities",
];

export const DEMOGRAPHICS = [
  'Headcount',
  'YoY Headcount Growth',
  'Headcount History',
  'Founded',
  'Region',
  'Country',
  'City',
  'LinkedIn URL',
  'Pitchbook URL',
];

export const CONTACT_INFO = [
  'Founded',
  'Region',
  'Country',
  'City',
];

export const OWNERS_INFO = [
  'Company Name',
  'Owner',
  'Status',
  'Priority',
  "Affinity Org ID",
  "Website",
  "Cinchy Id",
  "Hubspot URL",
  "Affinity URL",
  "FPC Score",
  "FPC Score Color",
  "LinkedIn URL",
  "Pitchbook URL",
  "Flag"
];

export const ERROR_TYPE = {
  EMPTY_DATA: 'empty_data',
  SERVER_ERROR: 'server_error'
};

const API_QUERY_URL = 'https://forgepoint.cinchy.net/API/Front End/';
const API_RELATIONSHIP_URL = 'https://forgepoint.cinchy.net/API/Relationship Management/'
const API_COMPANIES_URL = 'https://forgepoint.cinchy.net/API/Companies/'
const API_AFFINITY_URL = 'https://forgepoint.cinchy.net/API/Affinity API/'

export const API_UPDATE_STATUS = 'https://sandbox.cinchy.net/forgepoint/Query/Execute?queryId=360'

const QUERIES = {
  UNIVERSAL_SEARCH: 'Select - Universal Search',
  RELATIONSHIPS: {
    PEOPLE: 'Select - People',
    PEOPLE_COLUMNS: 'Select - People Edit Columns',
    PEOPLE_ADD: 'Insert - People',
    PEOPLE_UPDATE: 'Update - People',
    PEOPLE_DELETE: 'Delete - People',
    FPC_CONTACT: 'Select - List of FPC Employees',
    FPC_CONTACT_ADD: 'Insert - People FPC Contact',
    CUSTOMERS: 'Select - Customers',
    CUSTOMERS_ADD: 'Insert Customers',
    CUSTOMERS_UPDATE: 'Update Customer',
    CUSTOMERS_DELETE: 'Delete Customers',
    INVESTORS: {
      GET: 'Select - Investors',
      UPDATE: 'Update - Investors',
      RELATIONSHIP: 'Drop Down - Relationship-Select',
      PRIORITY: 'Drop Down - Priority-Select',
    },
    COMPANY_SYNC: {
      GET: 'Select - People Company Sync',
      UPDATE: 'Update - People Company Sync',
    },
  },
  COVERAGE: {
    GET: 'Select - Coverage Summary',
    PRIORITY_UPDATE: 'Update - Category Priority',
    PRIORITY_SELECT: 'Select - Category Priority',
    OWNER_UPDATE: 'Update - Category Owners',
  },
  WHITESPACE: {
    CATEGORY: 'White Space - Categories',
    SUB_CATEGORY: 'White Space - Sub Categories',
    TECHNOLOGY: 'White Space - Technology',
    TAXONOMY_OTHER: 'Select - Taxonomy Other',
    TAXONOMY_CORE: 'Select - Taxonomy Core',
    TAGGING_DETAILS: 'Taxonomy Tagging Detail',
    TOTAL_COMPANIES: 'Select - White Space Total Cos',
  },
  RESEARCH: {
    SELECT: 'Select - Research',
    CREATE: 'Insert - Research',
    UPDATE: 'Update - Research',
    DELETE: 'Delete - Research',
    GET_LIST_OF_TECHNOLOGIES: 'Select - List of Technologies',
    GET_LIST_OF_COMPANIES: 'Select - List of Company Names',
    GET_RESEARCH_ID: 'Select - Research Cinchy Id',
    TAGGING_RESEARCH_WITH_TECHNOLOGY: 'Insert - Research Category',
  },
  // - Design: https://www.figma.com/design/QGS7D2KgGpo59wRhfrdo1D/FPC-Application?node-id=5145-464&node-type=frame&t=Yx1DYXf1OMK9X446-0
  //  - Section: Sourcing à Meeting Notes
  //  - Select API: https://forgepoint.cinchy.net/API/Front End/Circleback Notes
  //  - Edit APIs:
  //          - Update Meeting Title: https://forgepoint.cinchy.net/API/Front End/Update - Circleback Meeting Title?%40cinchyid=&%40name=
  //          - Update Notes: https://forgepoint.cinchy.net/API/Front End/Update - Circleback Notes?%40cinchyid=&%40notes=
  //          - Update Company Name: drop-down that inserts Cinchy Id (like on the “add new financings” section)
  //  - Delete APIs
  //          - https://forgepoint.cinchy.net/API/Front End/Delete - Circleback Notes?%40cinchyid=
  CIRCLEBACK_NOTES: {
    GET_NOTES: 'Circleback Notes',
    UPDATE_MEETING_TITLE: 'Update - Circleback Meeting Title',
    UPDATE_MEETING_NOTES: 'Update - Circleback Notes',
    UPDATE_COMPANY_NAME: 'Update - Circleback Company',
    DELETE: 'Delete - Circleback Notes',
  },
  DASHBOARD: {
    TROUBLE_BREAKING_IN: 'Dashboard - Trouble Breaking In',
    DASHBOARD_INVESTORS: 'Select - Dashboard Investors',
    CATEGORY_COVERAGE: 'Dashboard - Category Coverage',
    COVERAGE_CHART: 'FPC High Priority Coverage Dash',
    HYGIENE_CHART: 'FPC Individual Hygiene Dash',
    PERSONAL_DASHBOARD_NAME: 'Select - Personal Dashboard Name',
    TOP_MAIN: 'Personal Dashboard Top 16',
    ACTIVITY: 'Dashboard - Companies Met Logged',
    ACTIVE_COMPANIES: 'Dashboard - Active',
    COVERAGE_SCORE: 'Dashboard - Coverage Score',
  },
  COMPANY: {
    BY_COMPANY: 'Companies',
    PRIORITY_BACKLOG: 'Select - Companies Priority Backlog',
    TROUBLE_BREAKING_IN: 'Select - Companies Trouble Breaking',
    LAST_WEEKS_MEETINGS: 'Select - Companies LW Meetings',
    NEXT_WEEKS_MEETINGS: 'Select - Companies NW Meetings',
    RECENT_UPDATES: 'Select - Companies Recent Outlook',
    PRIMARY_CONTACTS: 'Select - Companies Need Contacts',
    FPCI: 'Companies - FPCI',
    SWEET_SPOT: 'Sweet Spot',
    BY_INVESTOR: 'Companies by Investor Summary',
    BY_INVESTOR_EU: 'Companies by Investor Summary - EU',
    BY_INVESTOR_DETAIL: 'Companies by Investor Detail',
    BY_INVESTOR_DETAIL_EU: 'Companies by Investor Detail - EU',
    COMPANIES_TABLE_COLUMNS: 'Select - Companies Edit Columns',
    ENRICH_CONTACT_DATA: 'Enrich Contact Data',
    SET_OUT_OF_SCOPE: 'Update - Out of Scope?',
    EDIT_COLUMNS: 'Select - Companies Edit Columns',
    HUBSPOT_SEQUENCES: 'Select - Hubspot Sequence Options',
    UPDATE_HUBSPOT_SEQUENCES: 'Update - Hubspot Sequence',
    ADD_COMPANY: 'Insert - Add New Company',
    COLUMN_WIDTHS: 'Select - Companies Edit Columns',
    EDIT_COMPANY_NAME: 'Update - Company Name',
    INVESTOR_COLUMN_WIDTHS: 'Select - Investors Edit Columns',
    EDIT_NOTE: 'Update - Investor Notes',
    FPCI_PRIORITIES: 'Select - FPCI Priority Options',
    FPCI_ENGAGEMENT: 'Select - FPCI Engagement',
    UPDATE_FPCI_PRIORITIES: 'Update - FPCI Priority',
    UPDATE_ENGAGEMENT: 'Update - FPCI Engagement',
  },
  COMPANY_DETAILS: {
    RECENT_NEWS: 'Select - Company News',
    RECENT_NEWS_DELETE: 'Delete Recent News',
    PULL_RECENT_NEWS: 'Update - Trigger Recent News',
    RECENT_NEWS_SOURCES: 'Select - Company News Sources',
    // STATUS_AND_OWNER: 'Status and Owner Query',
    DESCRIPTION: 'Description Query',
    COMPANY_OUTLOOK: {
      GET: 'Company Outlook Query',
      ADD: 'Outlook Insert Query',
      ADD_INVESTOR: 'Insert - Investor Outlook',
      UPDATE: 'Update Company Outlook',
      DELETE: 'Delete Outlook',
    },
    UPDATE_STATUS: 'Update Status',
    PRIMARY_COMPETITORS: {
      GET: 'Primary Competitors Query',
      ADD: 'Primary Competitors Insert',
      DELETE: 'Delete Primary Competitor',
    },
    // KEY_INVESTORS: 'Key Investors',//
    // INVESTORS: 'Investors', //
    // TAXONOMY: 'Company Detail - Taxonomy',//
    INTERNAL_NOTES: {
      GET: 'Internal Notes',
      UPDATE: 'Update Internal Notes',
    }, //
    HEADCOUNT: {
      INSERT: 'Insert - Headcount',
      UPDATE: 'Update - Headcount',
      DELETE: 'Delete - Headcount',
    },
    BOARD_MEMBER: 'Board Member Query', //
    FINANCIALS: 'Company Detail - Financials',
    FINANCIALS_ADD: 'Insert Financials',
    FINANCIALS_UPDATE: 'Update Financials',
    FINANCIALS_DELETE: 'Delete Financials',
    FINANCIALS_COLUMN_WIDTHS: 'Select - Co Det Financials Edit Col',
    SINGLE_ENTRY: 'Company Detail Query - Single Entry',
    HEADCOUNT_HISTORY: 'Headcount History',
    KEY_CUSTOMERS: {
      GET: 'Company Detail - Customers',
      ADD: 'Insert New Customer',
      UPDATE: 'Update Customer',
      DELETE: 'Delete Customers',
      COLUMN_WIDTHS: "Select - Co Detail Cust Edit Column",
    },
    BENCHMARKS: {
      GET: 'Benchmarks Query',
    },
    ARR: {
      INSERT: 'Insert - ARR'
    },
    PRIMARY_RESEARCH: "Select - Company Detail Research",
    COMPS: "Select - Large Comps by Tech",
    LISTED_COMPS: "Select - Listed Comps",
    CONTACT_GET: "Select - Primary Company Contact",
    CONTACT_UPDATE: "Update - Primary Contact Info",
    FUTURE_CONTACT_DATE_UPDATE: "Update - Future Contact Date",
    RECENT_OVERVIEW: "Select - Company Update",
    SECONDARY_COMPETITORS_GET: "Select - Secondary Competitors",
    FUNDRAISING: {
      SINGLE_SELECT: "Select - Financing Company Detail",
      SELECT: "Select - Financing Chart Co Detail",
      SELECT_ONE: "Select - Co Det Fund History Row1",
      SELECT_TWO: "Select - Co Det Fund History Row2",
      ADD: "Insert - Company Financings",
      UPDATE: "Update - Financings",
      DELETE: "Delete - Financings",
      COLUMN_WIDTHS: "Select - Co Det Financings Edit Col",
    },
    FUNDRAISING_COLUMN_WIDTHS: 'Select - Company Det Edit Columns',
    FUNDRAISING_HISTORY_UPDATE: "Update - Fundraising Histrory",
    COMPANY_CONTACTS: {
      GET: "Select - All Company Contacts",
      ADD: "Insert - Company Contacts",
      UPDATE: "Update - Company Contacts",
      DELETE: "Delete - Company Contacts",
    },
    FINANCING_SYNC: {
      GET: 'Select - Recent Finance Refresh',
      UPDATE: 'Refresh Company Financing',
    },
  },
  PRIMARY_COMPETITORS: {
    GET_SINGLE: 'Primary Competitors Query',
    GET_LIST: 'List of Companies',
    LIST: 'Names for Primary Competitors',
  },
  TAGS: {
    GET_TAG_OPTIONS: 'https://sandbox.cinchy.net/forgepoint/API/Companies/View Tag Options',
    VIEW_TAG_OPTIONS: 'View Tag Options',
    CREATE_TAG_OPTION: 'Insert Tag Option',
    DELETE_TAG_OPTION: 'Delete Tag Option',
    GET: 'Select - Company Tags',
    ADD: 'Insert Company Tag',
    DELETE: 'Delete Company Tag',
    UPDATE: "Update - Tags",
  },
  COMPANY_FILTERS:  {
    TAXONOMY: 'Select - List of Technologies',
    CRM_OWNER: 'Select - List of FPC Employees',
    CRM_STATUS: 'Select - CRM Status Filter Options',
    CRM_LAST_SEQUENCE_OPTIONS: 'Select - Last Sequence Options',
    DEMOGRAPHICS_REGION_OPTIONS: 'Select - Region Options',
    DEMOGRAPHICS_COMPANY_CITY: 'Select - Company City Filter',
    DEMOGRAPHICS_OWNERSHIP_STATUS: 'Select - Ownership Status Filter',
    DEMOGRAPHICS_INVESTOR_NAMES: 'Select - Investor Name Options',
    DEMOGRAPHICS_KEY_INVESTOR: 'Select - Key Investor Filter',
    DEMOGRAPHICS_PEOPLE: 'Select - People Filter Options',
    DEMOGRAPHICS_TAG_OPTIONS: 'Select - Tag Filter Options',
  },
  FINANCING_FILTERS: {
    COMPANY_NAME: 'Filter - Company Names',
    STATUS: 'Filter - Status',
    FPC_OWNERS: 'Owners Filter Option Name Only',
    FINANCING_SERIES: 'Filter - Financing Series',
    FINANCING_STAGE: 'Filter - Financing Stage',
    INVESTORS: 'Filter Options - Investors',
    MISS_PASS_LOSS: 'Filter - Miss Pass Loss Options',
    REGION: 'Filter - Region Options',
    CATEGORY: 'White Space - Category Filter',
  },
  RESEARCH_THESES: {
      TAXONOMY: 'Select - List of Technologies',
      OWNER: 'Select - Filter for Research Owner',
      TYPE: 'Select - Type Filter',
      COMPANIES: 'Select - Research Company Options',
    },
  RELATIONSHIPS_CUSTOMERS: {
    TAXONOMY: 'Select - List of Technologies',
  },
  RELATIONSHIPS_PEOPLE: {
    CURRENT_EXPERIENCE: 'Select - Filter Current ppl exp',
    PAST_EXPERIENCE: 'Select - Filer past ppl exp',
  },
  KEY_WORDS: {
    SELECT: "Select - List of Key Words",
    INSERT: "Insert - Company Key Words",
    UPDATE: "Update - Company Key Word",
    GET_COMPANY_KEY_WORDS: 'Select - Options Key Word Update',
  },
  OWNERS: {
    SELECT: "FPC Owners - Options",
    UPDATE: "Update - FPC Owners",
    UPDATEPEOPLE: "Update - FPC Owners People",
    UPDATEINVESTOR: "Update - Investor Owners",
  },
  STATUSES: {
    SELECT: "Filter - Status",
  },
  SECTORS: {
    SELECT: "Sector Options",
    UPDATE: "Update - Sector",
    INSERT: "Insert - Sector Options",
    DELETE: "Delete - Sector Options"
  },
  RELATIONSHIP_LIST: {
    SELECT: "Drop Down - Relationship-Select",
    UPDATE: "Update - Investor Relationship",
  },
  PRIORITY_LIST: {
    SELECT: "Drop Down - Priority-Select",
    UPDATE: "Update - Investor Priority",
  },
  PIPELINE_STATUS: {
    SELECT: "Select - FPC Opp Status Options",
  },
  FLAGS: {
    OVERDUE: "Select - Flags",
    UPCOMING: "Select - Upcoming Flags",
    FILTER_OPTIONS: 'Filter - Flags Options',
    COLUMN_WIDTHS: 'Select - Flags Edit Columns',
  },
  OPPORTUNITIES: {
    PASS: "Select - FPC Opps Pass",
    TEN_PAGER: "Select - FPC Opps 10 Pager",
    COMING_TO_MARKET: "Select - FPC Opps Coming to Market",
    TRACKING: "Select - FPC Opps Tracking",
    ONE_PAGER: "Select - FPC Opps 1 Pager",
    FINAL_PRESO: "Select - FPC Opps Final Preso",
    DELETE: "Delete - FPC Opportunities",
    UPDATE: "Update - FPC Opportunities",
    INSERT: "Insert  - FPC Opportunities",
    KANBAN: "Select - Opportunities Kanban",
  },
  TOPIC_INSIGHTS: {
    SELECT: "Topic Insights",
    TRACKING: "Topic Search - Tracking Cos",
    FUNDED: "Topic Search - Well Funded Cos",
  },
  FINANCING: {
    FINANCINGS: 'Select - Financings',
    EQUITY: 'Select - Financings Equity',
    MA: 'Select - Financings Mergers and Acq',
    OUT_OF_BUSINESS: 'Select - Financings Out of Business',
    LAST_WEEK_FINANCING: 'Select - Last Week Financing',
    LAST_WEEK_MA: 'Select - Last Week M&A',
    COLUMNS: 'Select - Financings Edit Columns',
    DELETE: 'Delete - Financings',
    INSERT: 'Insert - Company Financings',
    UPDATE: 'Update - Financings',
    COLUMN_WIDTHS: 'Select - Financings Edit Columns',
    MISS_PASS_LOSS: 'Filter - Miss Pass Loss Options',
  },
  INTELLIGENCE: 'Select - Intelligence Pop Up',
}

const STATUSES = {
  SUCCESS: 'Success',
  ERROR: 'Error',
}

export { API_QUERY_URL, QUERIES, STATUSES, API_RELATIONSHIP_URL, API_COMPANIES_URL, API_AFFINITY_URL }

export const DEFAULT_COLUMNS = [
  "Cinchy Id",
  "Company Name",
  "Website",
  "Owner",
  "Total Raised",
  'Affinity Org Id',
];

export const TAB_CATEGORIES = [
  'whitespace',
  'companies',
  'financings',
  'investors'
]

export const EDITING_MODE = {
  IDLE: 'idle',
  CREATE: 'create',
  UPDATE: 'update',
}

export const PAGE_NAMES = {
  COMPANIES: 'COMPANIES',
  FLAGS: 'FLAGS',
  FINANCINGS: 'FINANCINGS',
}

export const PRIMARY_COMPETITORS_DISPLAY_COUNT = 3;
export const SECONDARY_COMPETITORS_DISPLAY_COUNT = 3;

export const DEFAULT_COMPANIES_BY_INVESTORS_COLUMNS = [
  'cinchy id', 'investor id', 'investor name',
  'investor type', 'investor linkedin url', 'priority', 'coverage %', 'owners', 'relationship status',
  'fpc co-investments', 'recent outlook date', 'recent outlook', 'total company count', 'sweet spot company count', 'need intro company count', 'trouble breaking in count', 'fpc connections', 'notes'
];

export const DEFAULT_COMPANIES_BY_INVESTORS_DETAIL_COLUMNS = [
  'cinchy id', 'company name', 'company name2' ,'flag', 'description',
  'linkedin url', 'pitchbook url', 'affinity org id', 'owner', 'status', 'priority',
  'months since last contact', 'months since last meeting', 'in sequence?',
  'months since last sequence', 'headcount', 'headcount growth', 'region',
  'city', 'year founded', 'total raised ($m)', 'months since last funding',
  'recent valuation ($m)', 'last funding date', 'total raised / last valuation',
  'total raised / headcount', 'ownership status', 'customer count', 'arr',
  'arr / headcount', 'saas metrics as of date', 'category', 'sub category',
  'technology', 'key words', 'recent investment stage', 'green flag count',
  'red flag count', 'tags', 'fpc score'
];

export const DEFAULT_INVESTORS_COLUMNS = [
  'investor id', 'investor name', 'investor type', 'investor linkedin url',
  'target investor tag', 'total companies count', 'notes', 'people', 'fpc connections',
  'portfolio companies', '"trouble breaking in" companies', '"pursuing" companies'
];

export const DEFAULT_PEOPLE_COLUMNS = [
  'Full Name', 'Current Experiences', 'Past Experiences',
  'FPC Contact', 'City', 'State', 'FPC Advisory Board', 'FPC Employee',
  'Notes', 'LinkedIn URL'
];

export const DEFAULT_CUSTOMER_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'customer linkedin id', 'customer name',
  'company name', 'company status', 'arr as of date', 'arr', 'use case',
  'champion title', "competitors if competitive process", "replaced if rip and replace",
  "category", "sub category", "technology"  
];

export const DEFAULT_FINANCING_COLUMNS = ['Series', 'Stage', 'Date', 'Miss / Pass / Lost', 'Miss / Pass / Lost Reason', 'Round Size', 'FPC Invested ($M)', 'Valuation ($M)', 'ARR Multiple', 'Status', 'Owners', 'Investors', 'Total Raised ($M)', 'Recent Outlook', 'Recent Outlook Date', 'Months Since Last Contact', 'Category', 'Headcount', 'Headcount Growth', 'ARR ($M)', 'ARR as of Date', 'Country', 'Region', 'Year Founded'];

export const DEFAULT_FLAGS_OVERDUE_COLUMNS = [
  'flag color', 'company cinchy id', 'affinity organization id','company', 'company website', 'owner', 'status', 'flag detail',
  'recent outlook', 'recent outlook date', 'last contact / meeting date', 'flag date'
];

export const DEFAULT_FLAGS_UPCOMING_COLUMNS = [
  'flag color', 'company cinchy id', 'affinity organization id', 'company', 'company website', 'owner', 'status', 'flag detail',
  'recent outlook', 'recent outlook date', 'last contact / meeting date', 'flag date'
];

export const DEFAULT_PASS_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const DEFAULT_TEN_PAGER_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const DEFAULT_ONE_PAGER_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const DEFAULT_FINAL_PRESO_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const DEFAULT_COMING_TO_MARKET_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const DEFAULT_TRACKING_COLUMNS = [
  'table cinchy id', 'company cinchy id', 'pipeline status', 'company name', 'series', 'round size ($m)',
  'fpc investment ($m)', 'valuation ($m)', 'owners',
  'company status', 'headcount', 'arr ($m)', 'recent outlook',
  'recent outlook date', 'region', 'category'
];

export const METRICS: Record<string, string> = {
  'ARR': '$',
  'Headcount': '',
  'ARR / Headcount': '$',
  'Total Raised': '$',
  'Last Valuation': '',
  'Key Investor Count': '',
  'ARR Growth': '%',
  'Headcount Growth': '%',
  'Months Since Last Raise': '',
  'Total Raised / Headcount': '$',
  'Total Raised / Last Valuation': '',
  'Key People Count': '',
};

export const METRICS_COLOR_MAP: { [key: string]: string } = {
  "green": "#B5DCC5",
  "red": "#E7BCBC",
  "yellow": "#F9E6B6",
  "grey": "#F5F5F5",
};

export const TREE_LEVELS = {
  ZERO: 0,
  FIRST: 1,
  SECOND: 2,
};

export const DEFAULT_RESEARCH_COLUMNS = [
  "cinchy id", "title", "attachment", "date", "companies", 
  "companies cinchy id", "type", "owner", "current", "technology", 
  "sub category", "category",
];

export const BACKGROUND_COLOR_CODES = {
  'Grey': 'rgba(128, 128, 128, 0.2)',
  'Yellow': 'rgba(255, 255, 0, 0.2)',
  'Orange': 'rgba(255, 191, 0, 0.2)',
  'Blue': 'rgba(19, 153, 252, 0.2)',
  'Green': 'rgba(0, 128, 0, 0.2)',
  'Red': 'rgba(255, 0, 0, 0.1)',
  'Purple': 'rgba(125, 13, 195, 0.2)',
  'Dark Red': 'rgba(255, 87, 51, 0.3)',
}

export const COLOR_CODES = {
  'Grey': 'rgba(128, 128, 128)',
  'Yellow': 'rgb(243,205,10)',
  'Orange': 'rgb(255,106,0)',
  'Blue': 'rgba(19, 153, 252)',
  'Green': 'rgba(0, 128, 0)',
  'Red': 'rgba(255, 0, 0)',
  'Purple': 'rgba(125, 13, 195)',
  'Dark Red': 'rgb(139,0,0)',
  '34C759': '#34C759',
  'FF9500': '#FF9500',
  'D03428': '#D03428',
}
