export type TWhitespaceColumn = {
    type: string;
    columnName: string;
}

type TWhitespaceDataItem = string[];

type TWhitespace = {
    schema: TWhitespaceColumn[];
    data: TWhitespaceDataItem[];
}

type TItem  = {
    Domain: string;
    Category: string;
    'Sub Category': string;
    'Backlog Count': string;
    'Category Description': string;
    'List of Portcos': string;
    'Low Priority Count': string;
    'Portfolio Count': string;
    'Relationship Count': string;
    'Total Companies Count': string;
    'Total Invested Count': string;
}

type TByCategory =  Record<string, TItem[]>;

const whitespaceGroupingByRow = (data: TWhitespace) => {
    return data?.data.map((item) => {
        return data?.schema.reduce((current, { columnName }, index) => {
            // @ts-ignore
            current[columnName] = item[index];
            return current;
        }, {} as TItem);
    });
}

const getColumns = (schema: any) => {
    return schema.map((s: any)=>s["columnName"])
}

const extractCategoryKeywords = (data: TWhitespace, index: number = 1) => {
    return Array.from(new Set(data.data.map(arr => arr[index])));
}


const groupedColumns = ['Total Companies Count', 'Total Invested Count'];
const addedColumns = [{ columnName: '', type: 'String'  },{ columnName: 'Companies/Investment($M)', type: 'string'}]
const groupWhitespaceCategoryColumns = (columns: TWhitespaceColumn[], emptyIndexes: number[] = []) => {
    let newColumns = columns.filter(item => !groupedColumns.includes(item.columnName), [] as TWhitespaceColumn[]);

    newColumns.splice(newColumns.length - 2, 1, ...addedColumns);
    emptyIndexes.forEach(index => {
        newColumns.splice(index, 0, { columnName: '', type: 'String'  });
    })
    return newColumns;
}

const WSDescriptionsColumns = (descriptions: TWhitespace) => {
    const descriptionsChanged: Record<string, string[]> = descriptions.schema.reduce((current, item, index) => {
        const columnName = item.columnName;

        if (!current[columnName]) {
            current[columnName] = [];
        }

        const columnData = descriptions.data.map((elem) => elem[index]);
        current[columnName].push(...columnData);

        return current;
    }, {} as Record<string, any>);

    return descriptionsChanged;
}

const groupByCategory = (data: TItem[], category: keyof TItem) => {
    return data.reduce((current, item) => {
        if (!current[item[category]]) {
            current[item[category]] = [];
        }
        current[item[category]].push(item);
        return current;
    } , {} as TByCategory);
}

const groupBySubCategory = (data: TByCategory, subCategory: keyof TItem) => {
    return Object.keys(data).reduce((current, item) => {
        const category = item as keyof TByCategory;
        current[category] = groupByCategory(data[category], subCategory);
        return current;
    }, {} as Record<keyof TByCategory, TByCategory>);
}

const groupArrayBySubCategory = (data: TItem[], subCategory: keyof TItem) => {
    // return data.reduce((current, item) => {
    //     const category = item as keyof TByCategory;
    //     current[category] = groupByCategory(data[category], subCategory);
    //     return current;
    // }, {} as Record<keyof TByCategory, TByCategory>);
}

export {
    whitespaceGroupingByRow,
    WSDescriptionsColumns,
    groupByCategory,
    groupBySubCategory,
    extractCategoryKeywords,
    groupArrayBySubCategory,
    groupWhitespaceCategoryColumns,
    getColumns
};
