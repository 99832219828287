/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useMemo, useRef} from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';

import SideBarFilters from '../../../components/SideBarFilters';

import { setSidebarToggleMobile, selectThemeOptions, sidebarToggle as toggleSidebar } from '../../../store/themeOptions/themeOptionsSlice';
import { setSideBarWidth } from '../../../features/themeOptions/themeOptionsSlice';

const activeSidebarPaths = [
    '/sourcing/companies/company',
    '/sourcing/companies/sweet-spot',
    '/sourcing/companies/priority-backlog',
    '/sourcing/companies/trouble-breaking-in',
    '/sourcing/companies/last-weeks-meetings',
    '/sourcing/companies/next-weeks-meetings',
    '/sourcing/companies/recent-updates',
    '/sourcing/companies/needs-primary-contact',
    '/sourcing/research', '/sourcing/financings/all',
    '/sourcing/financings/equity',
    '/sourcing/financings/m&a',
    '/sourcing/financings/out-of-business',
    '/sourcing/financings/last-week-financing',
    '/sourcing/financings/last-week-ma',
    '/relationships/customers',
    '/relationships/people'
];

const Sidebar = () => {
    const dispatch = useDispatch();
    const { sidebarToggleMobile, sidebarToggle, sidebarStyle, sidebarShadow, sideBarWidth,  } = useSelector(selectThemeOptions);
    const location = useLocation();
    const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

    const sideBar = useRef(null);

    const handleResize = () => {
        if (sideBar.current) {
            // @ts-ignore
            const { width, height } = sideBar.current?.getBoundingClientRect?.();
            dispatch(setSideBarWidth(Math.floor(width)));
        }
    };

    useEffect(() => {
        // Initial size calculation
        handleResize();

        // Attach event listener on mount
        if (sideBar.current) {
            // @ts-ignore
            sideBar.current.addEventListener('resize', handleResize);
        }

        // Detach event listener when the component is unmounted
        return () => {
            if (sideBar.current) {
                // @ts-ignore
                sideBar.current.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    useEffect(() => {
        console.log(sideBar, 'ref');
        // @ts-ignore
        const offsetFromTop = sideBar.current?.getBoundingClientRect?.()?.width || 0;
        // @ts-ignore
        dispatch(setSideBarWidth(Math.floor(sideBar.current)));

    }, [sideBar.current])

  const handleToggleSidebar = () => {
      dispatch(toggleSidebar(!sidebarToggle));
  };

  const hideSidebarToggle = useMemo(() => !activeSidebarPaths.includes(location.pathname), [location.pathname])
  
  useEffect(() => {
      if(hideSidebarToggle) {
          dispatch(toggleSidebar(true))
      }
  }, [hideSidebarToggle])

  return (
    <>
       <>
            <div
                ref={sideBar}
                className={clsx('app-sidebar', sidebarStyle, {
                    'app-sidebar--shadow': sidebarShadow,
                })}
            >
                <div className="app-sidebar--content sidebar-content-box-shadow">
                    {/*// Collapse menu*/}
                    {!hideSidebarToggle && <Tooltip title={`${sidebarToggle ? 'Expand' : 'Collapse'} sidebar`} placement="right" arrow>
                        <Button
                            onClick={handleToggleSidebar}
                            className="expand-sidebar-btn btn btn-sm collapse-expand-btn"
                        >
                            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
                        </Button>
                    </Tooltip>}
                    {!sidebarToggle && <SideBarFilters />}
                </div>
            </div>
            <div
                onClick={() => toggleSidebarMobile()}
                className={clsx('p-0 app-sidebar-overlay', {
                    'is-active': sidebarToggleMobile,
                })}
            />
        </>
    </>
  );
};
export default Sidebar
