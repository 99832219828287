import { useCallback, useState } from 'react';

const DEFAULT_NOTIFICATION = {
    open: false,
    vertical: "top",
    horizontal: "right",
    toastrStyle: "toastr-primary",
    message: "This is a toastr/snackbar notification!",
}

export const useNotification = () => {
    const [notificationData, setNotificationData] = useState(DEFAULT_NOTIFICATION);

    const openNotification = useCallback((newState) => {
        setNotificationData({ open: true, ...newState });
        const timer = setTimeout(() => {
            setNotificationData({ ...notificationData, open: false });
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return { openNotification, notificationData };

}