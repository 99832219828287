import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
    Button,
    makeStyles,
    TextField,
    MenuItem,
} from '@material-ui/core';
import {
    addPrimaryCompetitors,
    getPrimaryCompetitorsByCompany,
    getPrimaryCompetitorsList,
    deletePrimaryCompetitor,
} from '../../store/sourcing/sourcingThunks';
import { selectPrimaryCompetitorsList } from '../../store/sourcing/sourcingSlice';
import ModalForm from '../ModalForm';
import { HighlightOff, AddCircleOutline } from '@material-ui/icons';
import styles from './styles.module.scss';
import PagedSelect from '../PagedSelect';
import { BeatLoader } from 'react-spinners';
import * as NotificationHelper from '../../helpers/notification';
import { SingleSelect } from '../SingleSelect/SIngleSelect';

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    item: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type PrimaryCompetitor = {
    'Table Cinchy Id': number,
    'Company Cinchy Id': number,
    'Primary Competitors': string
}

type ModalProps = {
    isOpen: boolean;
    companyId: string | number;
    primaryCompetitors: PrimaryCompetitor[];
    setIsOpen: (isOpen: boolean) => void;
}

const PrimaryCompetitorsModal: React.FC<ModalProps> = ({
    isOpen,
    companyId,
    primaryCompetitors,
    setIsOpen,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newCompetitor, setNewCompetitor] = useState<string>('');
    const [existingError, setExistingError] = useState<boolean>(false);

    const primaryCompetitorsList = useSelector(selectPrimaryCompetitorsList);

    const primaryCompetitorsOptions = primaryCompetitorsList?.map((company: any) => ({
        value: company["Company Name"],
        label: company["Company Name"],
      }));

    const handleAddNewCompetitor = async () => {
        if (!newCompetitor) {
            return;
        }
        const addedCompetitor = primaryCompetitorsList.find((p: any)=>p["Company Name"]===newCompetitor)
        const existingCompetitor = primaryCompetitors.find((p: any)=> {
            return p["Company Cinchy Id"] == addedCompetitor["Cinchy Id"]
        })
        if(!existingCompetitor) {
            setExistingError(false)
            await dispatch(addPrimaryCompetitors({
                companyId: companyId,
                competitors: addedCompetitor["Cinchy Id"]
            }));
            
            dispatch(getPrimaryCompetitorsByCompany(companyId as number));
            NotificationHelper.show('Competitor successfully added', 'success');
        } else {
            setExistingError(true)
        }
    };

    const deleteCompetitor = async (tableRowId: number) => {
       await dispatch(deletePrimaryCompetitor({id: tableRowId}));
       dispatch(getPrimaryCompetitorsByCompany(companyId as number));
       NotificationHelper.show('Competitor successfully deleted', 'success');
    };

    const handleClose = () => {
        setNewCompetitor('');
        setIsOpen(false);
        setExistingError(false)
    };

    useEffect(() => {
        dispatch(getPrimaryCompetitorsList());
    }, []);

    return (
        <ModalForm
            open={isOpen}
            handleClose={handleClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="border-0">
                <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center mb-4">
                        <h6 className={styles.modalTitle}>
                            Edit Primary Competitors
                        </h6>
                    </div>

                    <div>Existed Primary Competitors:</div>
                    <ul className={styles.itemsList}>
                        {!!primaryCompetitors.length && primaryCompetitors.map((competitor: any, index: number) => {    
                            return (
                                <MenuItem
                                    key={index}
                                    value={competitor}
                                    className={classes.item}
                                >
                                    <span className={styles.itemLabel}>{competitor['Primary Competitors']}</span>
                                    <HighlightOff
                                        color="error"
                                        onClick={(e) => {
                                            deleteCompetitor(competitor['Table Cinchy Id']);
                                        }}
                                    />
                                </MenuItem>
                            )
                        })}
                    </ul>

                    {primaryCompetitorsList.length ? (
                        <>
                              <div>Select new Primary Competitor</div>
                              <div className={styles.selectContainer}>
                                  <SingleSelect
                                      onChange={setNewCompetitor}
                                      name="Status"
                                      options={primaryCompetitorsOptions}
                                  />
                                  {existingError && <p className={styles.error}>Selected competitor already exists</p>}
                              </div>
                        </>  
                        ) : (
                            <div className={styles.loader}><BeatLoader /></div>
                        )
                    }

                    <div className="text-center">
                        <Button
                            variant="contained"
                            className={classes.saveBtn}
                            disabled={!newCompetitor}
                            onClick={handleAddNewCompetitor}
                        >
                           Submit
                        </Button>
                    </div>
                </div>
            </div>
        </ModalForm>
    )
};
export default PrimaryCompetitorsModal;
