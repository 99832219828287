import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../api/sourcing";
import { QUERIES, STATUSES } from "../../constants";
import { whitespaceGroupingByRow } from "../../utils/TGroping";

export const getCoverageChartData= createAsyncThunk(
  "dashboard/getCoverage",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.COVERAGE_CHART,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return response
    }
  }
);

export const getTopMainData= createAsyncThunk(
  "dashboard/getTopMainData",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.TOP_MAIN,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return response
    }
  }
);

export const getActivityData= createAsyncThunk(
  "dashboard/getActivityData",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.ACTIVITY,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return response
    }
  }
);

export const getHygieneChartData= createAsyncThunk(
  "dashboard/getHygieneChartData",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.HYGIENE_CHART,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return response
    }
  }
);

export const getCoverageScore = createAsyncThunk(
  "sourcing/getCoverageScore",
  async (_, { dispatch }) => {
      const { response, status }  = await getData(QUERIES.DASHBOARD.COVERAGE_SCORE, dispatch);
      if(status === STATUSES.SUCCESS) {
          const result = whitespaceGroupingByRow(response);
          return result;
      }
  }
);

export const getPersonalDashboardName= createAsyncThunk(
  "dashboard/getPersonalDashboardName",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.PERSONAL_DASHBOARD_NAME,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return response
    }
  }
);

export const getTroubleBreakingIn = createAsyncThunk(
  "dashboard/getTroubleBreakingIn",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.TROUBLE_BREAKING_IN,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return {
        rows: response,
        cells: Object.keys(response[0]),
      };
    }
  }
);

export const getDashboardInvestors = createAsyncThunk(
  "dashboard/getDashboardInvestors",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.DASHBOARD_INVESTORS,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return {
        rows: response,
        cells: Object.keys(response[0]),
      };
    }
  }
);

export const getCategoryCoverage = createAsyncThunk(
  "dashboard/getCategoryCoverage",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.CATEGORY_COVERAGE,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return {
        rows: response,
        cells: Object.keys(response[0]),
      };
    }
  }
);

export const getActiveCompanies = createAsyncThunk(
  "dashboard/getActiveCompanies",
  async (_, { dispatch }) => {
    const { response, status } = await getData(
      QUERIES.DASHBOARD.ACTIVE_COMPANIES,
      dispatch
    );
    if (status === STATUSES.SUCCESS) {
      return {
        rows: response,
        cells: Object.keys(response[0]),
      };
    }
  }
);

