export const highlight = {
    backgroundColor: 'rgb(86, 47, 204)',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: 'unset',
}
export const highLightColumn = {
    backgroundColor: 'rgba(110, 60, 250, 0.05)',
}
export const defaultColumn = {
    backgroundColor: 'white',
}
export const tHeadStyle = {
    backgroundColor: '#6e3cfa',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: 'unset',
}