import React, { useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import ColumnsModal from '../ColumnsModal';
import AddResearchModal from '../AddResearchModal';
import styles from './styles.module.scss';
import GartnerInterviewModal from '../GartnerInterviewModal';

export default function ResearchTableActions({
    // filteredRows,
    // cells,
    selectedColumns,
    columns,
    handleSelectColumns,
}) {
    
    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);
    const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);
    const [isGartnerInterviewModalOpen, setIsGartnerInterviewOpen] = useState(false);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-4">
                <div className={styles.actions}>
                    <Button
                        size="small"
                        onClick={() => setIsColumnsModalOpen(!isColumnsModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">edit columns</span>
                    </Button>
                    <Button
                        size="small"
                        onClick={() => setIsResearchModalOpen(!isResearchModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">add research</span>
                    </Button>
                    {/* <Button
                        size="small"
                        onClick={() => setIsGartnerInterviewOpen(!isGartnerInterviewModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label">gartner interview</span>
                    </Button> */}
                </div>
            </div>

            <ColumnsModal  
                isOpen={isColumnsModalOpen} 
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
            />
            
            <AddResearchModal 
                isOpen={isResearchModalOpen} 
                setIsOpen={setIsResearchModalOpen}
            />

            <GartnerInterviewModal 
                isOpen={isGartnerInterviewModalOpen} 
                setIsOpen={setIsGartnerInterviewOpen}
            />
        </>
    );
}
