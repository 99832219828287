import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {  
  getPeople,
  getCustomers,
  getInvestors,
  updateInvestor,
  getFPCContactList,
  getPeopleColumnWidths,
  getSyncedData,
} from './relationshipsThunks';

interface RelationshipsState {
  people: any;
  loading: boolean;
  errors: any;
  customers: any;
  investors: any;
  fpcContacts: any;
  peopleColumnWidths: any;
  customerColumns: any;
  syncedData: any;
}

const initialState: RelationshipsState = {
  people: {rows: [], columns: [], isLoading: false, columnWidths: []},
  peopleColumnWidths: [],
  customers: {
    rows: [],
    cells: [],
  },
  customerColumns: [],
  investors: { data: [], error: '', columns: [], loading: false },
  loading: false,
  errors: [],
  fpcContacts: [],
  syncedData: [],
};

const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeople.pending, (state) => {
        state.people.isLoading = true;
      })
      .addCase(
        getPeople.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.people = action.payload;
          state.people.isLoading = false;
        }
      )
      .addCase(getPeople.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPeopleColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPeopleColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.peopleColumnWidths = action.payload;
        }
      )
      .addCase(getPeopleColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })
      .addCase(getCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCustomers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.customers = action.payload;
          state.customerColumns = action.payload?.cells
          .map((column: {[key: string]: string}) => ({ Category: 'Columns', 'Column Name': column.label} ))
          .filter((column: any) => column["Column Name"].toLowerCase() !== 'table cinchy id' && column["Column Name"].toLowerCase() !== 'customer name'
          && column["Column Name"].toLowerCase() !== 'company cinchy id');
        }
      )
      .addCase(getCustomers.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getInvestors.pending, (state) => {
        state.investors.loading = true;
      })
      .addCase(getInvestors.fulfilled, (state, action) => {
        state.investors.loading = false;
        state.investors.data = action.payload;
        const investorsColumns = action.payload!.cells;
        const columns = investorsColumns
          .map((column: {[key: string]: string}) => ({ Category: 'Columns', 'Column Name': column.label} ));
        const filteredColuns = columns
          .filter((column) => column["Column Name"].toLowerCase() !== 'investor id' && column["Column Name"].toLowerCase() !== 'investor name');
        state.investors.columns = filteredColuns;
      })
      .addCase(getInvestors.rejected, (state) => {
        state.investors.loading = false;
        state.investors.error = "Something went wrong";
      })

      .addCase(updateInvestor.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvestor.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
      })
      .addCase(updateInvestor.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFPCContactList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFPCContactList.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.fpcContacts = action.payload;
      })
      .addCase(getFPCContactList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSyncedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSyncedData.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.syncedData = action.payload;
      })
      .addCase(getSyncedData.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const selectPeople = (state: RootState) => state.relationships.people;
export const selectCustomers = (state: RootState) => state.relationships.customers;
export const selectInvestors = (state: RootState) => state.relationships.investors.data;
export const selectInvestorsLoading = (state: RootState) => state.relationships.investors.loading;
export const selectInvestorsColumns = (state: RootState) => state.relationships.investors.columns;
export const selectPeopleColumnWidths = (state: RootState) => state.relationships.peopleColumnWidths;
export const selectCustomerColumns = (state: RootState) => state.relationships.customerColumns;
export const selectLoading = (state: RootState) => state.relationships.loading;
export const selectFPCContacts = (state: RootState) => state.relationships.fpcContacts;
export const selectSyncedData = (state: RootState) => state.relationships.syncedData;

export default sourcingSlice.reducer;
