import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Checkbox, TableCell, TableHead as TH, TableRow } from '@material-ui/core';

import { highlight, tHeadStyle } from '../../pages/Sourcing/utils';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { findValueByKey } from '../../utils/formatting';

export function TableHead(props) {
    const {
        classes,
        onSelectAllClick,
        selected,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
        columnsToHighLight,
        columnWidths
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const selectedColumns = useMemo(() => headCells.filter(item => selected.includes(item['Column Name'])), [selected, headCells])

    return (
        <TH className={classes.tableHead}>
            <TableRow>
                <TableCell
                    style={{ backgroundColor: '#6e3cfa', position: 'sticky', zIndex: '102', left: 0 }}
                    padding="checkbox"
                >
                    <Checkbox
                        style={{color: '#fff'}}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                {selectedColumns?.map((headCell) => (
                    <TableCell
                        style={{
                            ...columnsToHighLight.includes(headCell?.['Column Name']) ? highlight : tHeadStyle,
                            minWidth: findValueByKey(columnWidths, headCell?.['Column Name']),
                            }} 
                        key={headCell.id}
                        align='left'
                        padding='none'
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={clsx(classes.headerCell,
                            headCell?.['Column Name'] === "Cinchy Id" && classes.displayCell, 'font-14',
                            headCell?.['Column Name'] === "Description" && classes.descriptionCell,
                            headCell?.['Column Name'] === "Affinity Org Id" && classes.displayCell,
                            headCell?.['Column Name'] === "Company Name" && classes.left,
                            headCell?.['Column Name'] === "Company Name" && classes.zIndex102,
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell?.['Column Name']}
                            direction={orderBy === headCell?.['Column Name'] ? order : 'asc'}
                            onClick={createSortHandler(headCell?.['Column Name'])}
                            className={classes.sortIcon}
                        >
                            {headCell?.['Column Name']}
                            {orderBy === headCell?.['Column Name'] ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TH>
    );
}