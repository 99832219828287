import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFinancingsFilters, getFPCEmployees } from './filterThunks';
import { StaticFilterSelectOptions } from '../../constants/form/filterByCompany.constants';
import { generateOptions } from '../../utils/dataGrouping';
import { ResearchStaticOptions } from '../../constants/form/fiilterByResearch.constants';
import { PeopleStaticOptions } from '../../constants/form/filterByRelationshipsPeople.constants';

export type FilterState = {
    company: any;
    companies: any;
    research: any;
    customers: any;
    people: any;
    financings: any;
    error: null | string;
    loading: boolean;
    isReset: boolean;
};

const initialState: FilterState = {
    company: null,
    companies: null,
    research: null,
    customers: null,
    people: null,
    financings: null,
    error: '',
    loading: false,
    isReset: false,
};

// TODO CR-8 Remove after filters final change
const OWNERS = ['Alberto Yepez', 'Andrew McClure', 'Casilda Obieta', 'Connie Qian', 'Don Dixon', 'Ernie Bio', 'Leoncio Casusol', 'Manoj Ramachandran', 'Reynaldo Kirton', 'Shane Shook', 'Jimmy Park', 'Kathryn Shih', 'Chris Jackson'];

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        resetError: (state) => {
            state.loading = true;
            state.error = null;
        },
        setIsReset: (state, action) => {
              state.isReset = action.payload;
        },
        setFilterOption: (state, action) => {
            state.companies = {...state.companies, ...action.payload}
        },
        setCompaniesFilterOptions: (state, action) => {
            // @ts-ignore
            let options = action.payload.cells.map(item => item['Column Name']).reduce((curr, item) => {
                // @ts-ignore
                curr[item] = new Set([]);
                return curr;
            }, {})
            // @ts-ignore
            options = action.payload.rows.reduce((current, element) => {
                Object.entries(element).forEach(([key, value]) => {
                    if(value) {
                        if((key === 'Owner' || key === 'Investors' || key === 'Tags') && value) {
                            // @ts-ignore
                            const owners = value?.split(',')?.map((item: string) => item?.split(' <')?.[0])
                            // @ts-ignore
                            current[key]?.add(...owners || [])
                        } else if(key === 'Sectors' && value) {
                            // @ts-ignore
                            const sectors = value?.split(';')?.map((item: string) => item?.trim())
                            // @ts-ignore
                            sectors.forEach(sector => current[key]?.add(sector));
                        } else {
                            current[key]?.add(value);
                        }
                    }
                })
                return current;
            }, options)
            state.companies = { ...state.companies, ...options, 'Owner': new Set(OWNERS), ...StaticFilterSelectOptions };

        },
        setResearchFilterOptions: (state, action) => {
            let options = Object.keys(action.payload?.[0]).reduce((curr, item) => {
                // @ts-ignore
                curr[item] = new Set([]);
                return curr;
            }, {})
            // @ts-ignore
            options = action.payload.reduce((current, element) => {
               Object.entries(element).forEach(([key, value]) => {
                    if(value) {
                        if(key === 'Owner' && value) {
                            // @ts-ignore
                            current[key]?.add(...(value?.split(',') || value))
                        } else {
                            current[key]?.add(value);
                        }
                    }
               })
                return current;
            }, options)
            
            console.log('Log ::: options ===', options)

            state.research = {...options, ...ResearchStaticOptions};
        },
        setPeopleFilterOptions: (state, action) => {
            state.people = {...generateOptions(action.payload, ['fpc contact']), ...PeopleStaticOptions};
        },
        setCustomerFilterOptions: (state, action) => {
            state.customers = {...generateOptions(action.payload, ['']), ...PeopleStaticOptions};
        },
    },
    extraReducers: builder => {
        builder
            //Whitespace by category
            .addCase(getFPCEmployees.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFPCEmployees.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loading = false;
                    state.company =  {...(state?.company || {}), ...StaticFilterSelectOptions, ...action.payload }
                }
            )
            .addCase(getFPCEmployees.rejected, (state) => {
                state.loading = false;
                state.error = 'Something went wrong';
            })

            // Financings filters
            .addCase(getFinancingsFilters.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFinancingsFilters.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loading = false;
                    state.financings =  { ...action.payload }
                }
            )
            .addCase(getFinancingsFilters.rejected, (state) => {
                state.loading = false;
                state.error = 'Something went wrong';
            })
    }
})

export const {
    setIsReset,
    setCompaniesFilterOptions,
    setResearchFilterOptions,
    setPeopleFilterOptions,
    setCustomerFilterOptions,
} = filterSlice.actions

export default filterSlice.reducer;