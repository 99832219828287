import React from 'react';
import Select from 'react-select';

function CoveragePrioritySearch({ setSelectedValues, selectedValues, options }) {
    return (
        <Select
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary25: "rgba(110, 60, 250, 0.05)",
                    primary: "#6e3cfa",
                },
            })}
            styles={{
                input: (providedValue) => ({
                    ...providedValue,
                    zIndex: 1000,
                    fontSize: '12px',
                }),
                placeholder: (providedValue) => ({
                    ...providedValue,
                    margin: "0 4px",
                    zIndex: 1000,
                    fontSize: '12px',
                }),
                control: (base) => ({
                    ...base,
                    width: "300px",
                    marginLeft: "10px",
                    zIndex: 1000,
                }),
                option: (provided) => ({
                    ...provided,
                    fontSize: 12,
                }),
            }}
            options={options}
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            placeholder="Filter by Priority"
            value={options?.filter((option) =>
                selectedValues?.includes(option.value)
            )}
            onChange={(selected) => {
                const values = (selected || []).map(({ value }) => value);
                setSelectedValues(values);
            }}
        />
    );
}

export default CoveragePrioritySearch;