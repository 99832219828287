import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';

import CompaniesTableActions from '../CompaniesTableActions';
import HubspotModal from '../HubspotModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { useToolbarStyles } from './styles';
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {
        numSelected,
        selectedCompaniesIds,
        handleCreateCompany,
        filterResultsCount,
        handleSelectColumns,
        rows,
        cells,
        selectedColumns,
        columns,
        handleSearch,
    } = props;

    return (
        <>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}>

                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div">
                    <span className='font-18'>Companies</span>
                    {filterResultsCount > 0 && <span>{` (${filterResultsCount} results)`}</span>}
                </Typography>
                {isMenuOpen && <Paper className='p-2'>
                    <Button
                        size="small"
                        onClick={() => handleCreateCompany(true)}
                        className='btn-second mr-2'
                    >
                        <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="font-size-sm"
                        />
                        <span className="btn-wrapper--label font-14">company</span>
                    </Button>
                    <CompaniesTableActions
                        selectedCompaniesIds={selectedCompaniesIds}
                        rows={rows}
                        cells={cells}
                        selectedColumns={selectedColumns}
                        columns={columns}
                        handleSelectColumns={handleSelectColumns}
                    />
                    <Button
                        size="small"
                        onClick={() => setIsHubspotModalOpen(!isHubspotModalOpen)}
                        className='btn-second mr-2'
                        disabled={numSelected === 0}
                    >
                        <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="font-size-sm"
                            style={{ marginRight: '5px' }}
                        />
                        Hubspot Sequence
                    </Button>
                </Paper>}
                <Tooltip title={`${isMenuOpen ? 'Hide actions' : 'Company actions'}`}>
                    <IconButton disableRipple aria-label="company actions" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <CloseIcon fontSize="small"  /> : <FilterListIcon fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <div className="w-100 pl-3">
                <TextField
                    size="small"
                    className="w-100 font-12"
                    classes={{ root: 'input-border-0' }}
                    id="search-tag"
                    placeholder="Search..."
                    onChange={handleSearch}
                    InputProps={{
                        style: {
                            fontFamily: 'Quicksand, sans-serif', // Change to your desired font
                            fontSize: '14px', // Adjust font size
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>


            <HubspotModal
                isOpen={isHubspotModalOpen}
                setIsOpen={setIsHubspotModalOpen}
                selectedRowsIds={props.selectedCompaniesIds}
            />
        </>
    );
};