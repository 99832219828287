import React, { useEffect, useState, ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MoonLoader } from 'react-spinners';
import { WithTranslation } from 'react-i18next';

type StyleObject = {
  [key: string]: string | number;
}

interface SuspenseLoadingProps {
  text?: WithTranslation;
  extraStyles?: StyleObject;
}

const SuspenseLoading = (props: SuspenseLoadingProps): ReactElement => {
  const [show, setShow] = useState(false);
  const { text, extraStyles } = props;

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="d-flex align-items-center flex-column justify-content-center text-center py-3"
              style={extraStyles ? extraStyles : {}}
            >
              <div className="d-flex align-items-center flex-column px-4">
                <MoonLoader color="var(--primary)" loading />
              </div>
              <div className="text-muted text-center pt-3 font-18">
                {text}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SuspenseLoading;
