import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { getFlagDetailsOptions } from '../../../api/sourcing';

function FlagDetailsSearch({ setSelectedFlagDetails, selectedFlagDetails }) {
    const [flagDetailOptions, setFlagDetailOptions] = useState([]);

    useEffect(() => {
        getFlagDetailsOptions().then(response => {
            setFlagDetailOptions(response?.flat()?.map(item => ({ label: item, value: item })))
        })
    }, []);

    return (
        <Select
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary25: "rgba(110, 60, 250, 0.05)",
                    primary: "#6e3cfa",
                },
            })}
            styles={{
                input: (providedValue) => ({
                    ...providedValue,
                    zIndex: 103,
                    fontSize: '12px',
                }),
                placeholder: (providedValue) => ({
                    ...providedValue,
                    margin: "0 4px",
                    zIndex: 103,
                    fontSize: '12px',
                }),
                control: (base) => ({
                    ...base,
                    width: "300px",
                    marginLeft: "10px",
                    zIndex: 103,
                }),
                option: (provided) => ({
                    ...provided,
                    fontSize: 12,
                }),
            }}
            options={flagDetailOptions}
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            placeholder="Filter by Flag Detail"
            value={flagDetailOptions?.filter((option) =>
                selectedFlagDetails?.includes(option.value)
            )}
            onChange={(selected) => {
                const values = (selected || []).map(({ value }) => value);
                setSelectedFlagDetails(values);
            }}
        />
    );
}

export default FlagDetailsSearch;