import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, TableBody, TableCell, TableRow, TextField, Typography} from '@material-ui/core';
import { Table } from '@material-ui/core';
import { HighlightOff, Delete } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { AddCircleOutline, ArrowLeft, ArrowRight } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import DatePicker from '../../../example-components/FormsDatepicker/FormsDatepicker1';

import ModalForm from '../../../components/ModalForm';
import TableActions from '../../../components/TInvestors/TableActions';

import {
  selectCustomers,
  selectCustomerColumns,
} from '../../../store/relationships/relationshipsSlice';
import {
  deleteCustomers,
  getCustomers,
  insertCustomer,
  updateCustomer,
} from '../../../store/relationships/relationshipsThunks';
import { selectCompaniesByCompany } from '../../../store/sourcing/sourcingSlice';
import { getCompaniesByCompany } from '../../../store/sourcing/sourcingThunks';

import { DEFAULT_CUSTOMER_COLUMNS } from '../../../constants';
import { setCustomerFilterOptions } from '../../../store/filter/filterSlice';
import { useFilter}  from '../../../hooks/useFilter';
import { filterCompany } from '../../Sourcing/Companies/helper';
import { formatEmptyCell } from "../../../utils/utils";

import styles from './styles.module.scss';
import { defaultColumn, highlight, highLightColumn, tHeadStyle } from '../../Sourcing/utils';
import {formatOriginalDate, parseDate} from "../../../utils/formatting";
import usePagination from '../../../hooks/usePagination';
import Pagination from '../../../components/Pagination/Pagination';

const rowsPerPageOptions = [25, 50, 100]

export default function Customers() {
  const [customersData, setCustomersData] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);
  const [isOpenInsertModal, setIsOpenInsertModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [editedData, setEditedData] = useState({} as any);
  const [updatedTableId, setUpdatedTableId] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [arrError, setArrError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  // const [filteredRows, setFilteredRows] = useState([]);
  const [filteredCells, setFilteredCells] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem("selectedCustomerColumns") as string) ||
      DEFAULT_CUSTOMER_COLUMNS
  );

  const dispatch = useDispatch();

  const { rows, cells } = useSelector(selectCustomers);
  let companies = useSelector(selectCompaniesByCompany);
  const tableColumns = useSelector(selectCustomerColumns);

  useEffect(() => {
    if(rows?.length) {
      dispatch(setCustomerFilterOptions(rows));
    }
  }, [rows]);


  useEffect(() => {
    dispatch(getCustomers(DEFAULT_CUSTOMER_COLUMNS));
    // TODO CR-4 why are we getting companies on customers page?
    dispatch(getCompaniesByCompany());
  }, []);

  useEffect(() => {
    setCompanyNames(companies?.rows?.map((r: any) => r["Company Name"]));
  }, [companies?.rows]);

  useEffect(() => {
    if (rows?.length) {
      const tableHeaders = cells.map((c: any) => c.label.toLowerCase());
      setHeaders(tableHeaders);
      setCustomersData(
        rows.map((row: any) => {
          let finalRow = [] as any;
          tableHeaders.map((header: any) => finalRow.push(row[header]));

          return finalRow;
        })
      );
    }
  }, [rows, cells]);


  const { filterOptions } = useFilter({});

  const rowsToDisplay = useMemo(() => {
    // @ts-ignore
    const rowsToDisplay = (Object.keys(filterOptions).length && rows?.length) ? filterCompany(filterOptions,  rows) : rows;
    const filtered = rowsToDisplay?.map((row: any) => {
      let filteredRow = {} as any;
      for (let [key, value] of Object.entries(row)) {
        if (selectedColumns.includes(key.toLowerCase())) {
          filteredRow[key] = value;
        }
      }
      return filteredRow;
    });
    return filtered || [];
  }, [filterOptions, selectedColumns]);

  // useEffect(() => {
  //   const filtered = rows?.map((row: any) => {
  //     let filteredRow = {} as any;
  //     for (let [key, value] of Object.entries(row)) {
  //       if (selectedColumns.includes(key.toLowerCase())) {
  //         filteredRow[key] = value;
  //       }
  //     }
  //     return filteredRow;
  //   });
  //   setFilteredRows(filtered);
  // }, [selectedColumns, rows]);


  useEffect(() => {
    setFilteredCells(
      cells?.filter((cell: any) => {
        for (let value of Object.values(cell)) {
          const val = value as string;
          if (selectedColumns.includes(val.toLowerCase())) {
            return true;
          }
        }
        return false;
      })
    );
  }, [cells, selectedColumns]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;

  const refreshCustomersAPI = useCallback(() => {
    dispatch(getCustomers(DEFAULT_CUSTOMER_COLUMNS));
  }, [dispatch]);

  const handleFilterColumns = (columns: any) => {
    const selectedColumns = [
      "table cinchy id",
      "customer name",
      "company cinchy id",
      ...columns,
    ];
    setSelectedColumns(selectedColumns);
    localStorage.setItem(
      "selectedCustomerColumns",
      JSON.stringify(selectedColumns)
    );
  };

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  const addCustomer = useCallback(async () => {
    if (!isNaN(editedData["arr"])) {
      const formatedDate = parseDate(selectedDate);
      const finalData = editedData;
      finalData["asofdate"] = formatedDate;

      const filteredCompany = companies.rows.filter(
        (r: any) => r["Company Name"] === selectedCompany
      );
      if (filteredCompany[0]) {
        finalData["companyid"] = filteredCompany[0]["Cinchy Id"];
        await dispatch(insertCustomer(finalData));

        openNotification({
          message: "Data inserted successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
        setArrError("");
        setCompanyError("");
        closeInsertForm();
        refreshCustomersAPI();
      } else {
        setCompanyError("Company should be selected");
      }
    } else {
      setArrError("Only number is allowed");
    }
  }, [editedData, selectedDate, companies.rows]);

  const changeCustomer = useCallback(async () => {
    const finalData = editedData;
    if (!isNaN(finalData["arr"])) {
      const formatedDate = parseDate(selectedDate);
      finalData["cinchyid"] = updatedTableId + "";
      finalData["asofdate"] = formatedDate;

      await dispatch(updateCustomer(finalData));

      openNotification({
        message: "Data updated successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });
      closeUpdateForm();
      setArrError("");
      refreshCustomersAPI();
    } else {
      setArrError("Only number is allowed");
    }
  }, [editedData, updatedTableId, selectedDate]);

  const closeInsertForm = useCallback(() => {
    setEditedData({});
    setIsOpenInsertModal(false);
    setArrError("");
    setCompanyError("");
    setIsUpdateDisabled(false)
  }, []);

  const closeUpdateForm = () => {
    setEditedData({});
    setIsOpenUpdateModal(false);
    setArrError("");
    setIsUpdateDisabled(false)
  };

  const handleRowInsert = useCallback(() => {
    setIsOpenInsertModal(true);
  }, []);

  const handleRowUpdate = useCallback(
    (index) => {
      headers.forEach((h: any, i: number) => {
        if (h === "table cinchy id") {
          setUpdatedTableId(customersData[index][i]);
        } else if (h === "arr") {
          setEditedData((previous: any) => {
            previous["arr"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "arr as of date") {
          setEditedData((previous: any) => {
            setSelectedDate(customersData[index][i]);
            return { ...previous };
          });
        } else if (h === "champion title") {
          setEditedData((previous: any) => {
            previous["champion"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "competitors if competitive process") {
          setEditedData((previous: any) => {
            previous["competitors"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "customer linkedin id") {
          setEditedData((previous: any) => {
            previous["linkedinid"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "customer name") {
          setEditedData((previous: any) => {
            previous["customername"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "replaced if rip and replace") {
          setEditedData((previous: any) => {
            previous["replaced"] = customersData[index][i];
            return { ...previous };
          });
        } else if (h === "use case") {
          setEditedData((previous: any) => {
            previous["usecase"] = customersData[index][i];
            return { ...previous };
          });
        }
      });
      setIsOpenUpdateModal(true);
    },
    [customersData]
  );

  const editData = useCallback((value: string, name: string) => {
    setEditedData((previous: any) => {
      previous[name] = value;
      return { ...previous };
    });
    if(name === "customername") {
      if(value) {
        setIsUpdateDisabled(false)
      } else {
        setIsUpdateDisabled(true)
      }
    }
  }, []);

  const handleSelectDate = (date: Date) => {
    setSelectedDate(date);
  };

  const handleCompanyInputChange = (e: any) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 3) {
                const filteredCompanies = companyNames?.filter((p: any) =>
        p?.toLowerCase().includes(term.toLowerCase())
      );
      setSelectedCompanies(filteredCompanies as any);
    } else {
      setSelectedCompanies([]);
    }
  };

  const deleteSavedSearchValue = () => {
    setSelectedCompany("");
    setSearchTerm("");
    setSelectedCompanies([]);
  };

  const columnsToHighLight = useMemo(() => {
    let options = new Set(Object.keys(filterOptions));
    return selectedColumns.filter((item: string) => options.has(item));
  }, [filterOptions, selectedColumns])

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (customersData[index][0]) {
        await dispatch(deleteCustomers(customersData[index][0]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });

      refreshCustomersAPI();
    },
    [customersData, rowsToDisplay]
  );

  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: rowsToDisplay, rowsPerPageValue : rowsPerPageOptions[0] });

  return (
    <>
      <div className="table-responsive-md mb-spacing-2-x2 ml-3">
        <TableActions
          selectedColumns={selectedColumns}
          columns={tableColumns}
          handleSelectColumns={handleFilterColumns}
        >
          <Button
              size="medium"
              className={styles.addNewEntryBtn}
              variant="contained"
              onClick={handleRowInsert}
          >
            <AddCircleOutline className={styles.addIcon} />
            <span className="font-12">Add new entry</span>
          </Button>
        </TableActions>
        <div className={styles.tableContainer}>
          <Table
            className={`table table-hover table-bordered ${styles.table}`}
          >
            <thead className={styles.tableHead}>
              <tr>
                {filteredCells?.map(
                  (header: any, index: number) =>
                    index !== 0 &&
                    index !== 1 && (
                      <TableCell
                          // @ts-ignore
                          style={columnsToHighLight.includes(header?.label?.toLowerCase()) ? highlight : tHeadStyle}
                          className={`${styles.column} font-14`}
                          key={index}
                      >
                        {header.label}
                      </TableCell>
                    )
                )}
                <th id="actions" className={`${styles.actionsColumn} font-14`}>
                  Actions
                </th>
              </tr>
            </thead>
            <TableBody>
              {(!!rowsToDisplay?.length && !!filteredCells?.length) &&
                rowsToDisplay
                  .slice(
                    (currentPage - 1) * rowsPerPage,
                    (currentPage - 1) * rowsPerPage + rowsPerPage
                  )
                  ?.map((row: any, index: number) => {
                    const rowIndex = (currentPage - 1) * rowsPerPage + index;
                    return (
                      <TableRow hover key={index}>
                        {Object.keys(row).map((col: any, colIndex: number) => {
                          const value = row[col];
                          if (colIndex !== 0 && colIndex !== 1) {
                            return (
                                // @ts-ignore
                                <td
                                  key={colIndex}
                                  className={`${styles.col} font-14`}
                                  // @ts-ignore
                                  style={columnsToHighLight.includes(filteredCells[colIndex]?.["label"]?.toLowerCase()) ? highLightColumn : defaultColumn}
                              >
                                {filteredCells[colIndex]?.["label"] ===
                                "ARR as of Date"
                                  ? formatEmptyCell(formatOriginalDate(value)) : filteredCells[colIndex]?.["label"] ===
                                  "Customer LinkedIn ID" ? <a href={value} style={{color: '#008cff'}} target="_blank">{formatEmptyCell(value)}</a>
                                  : formatEmptyCell(value)}
                              </td>
                            );
                          }
                        })}
                        <td id="actions" className="text-center">
                          <>
                            <img
                              onClick={() => handleRowUpdate(rowIndex)}
                              className={styles.buttonClickable}
                              src="/edit.svg"
                              alt="Company Logo"
                              id="blockElement"
                            />
                            <Delete
                              className={styles.buttonClickable}
                              onClick={() => handleRowDelete(rowIndex)}
                            ></Delete>
                          </>
                        </td>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </div>

        <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                     handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} options={rowsPerPageOptions}/>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
      <ModalForm
        open={isOpenInsertModal}
        handleClose={closeInsertForm}
        maxWidth="sm"
        fullWidth={true}
      >
        <div className="border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <div className="text-center mb-4">
              <h6 className={styles.modalTitle}>Add Customer</h6>
            </div>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Customer Name</label>
              <div className={styles.errorContainer}>
                <TextField
                  variant="outlined"
                  className={styles.inputField}
                  fullWidth
                  label={null}
                  value={editedData["customername"]}
                  error={isUpdateDisabled}
                  onChange={(e) => editData(e.target.value, "customername")}
                />        
                <Typography variant="body2" color="error">*</Typography>
              </div>
            </section>
            <>
              <div className={styles.searchPartContainer}>
                <label>Company</label>
                {!selectedCompany ? (
                  <input
                    type="text"
                    className={styles.inputField}
                    value={searchTerm}
                    onChange={handleCompanyInputChange}
                  />
                ) : (
                  <div className={styles.selectedCompanyContainer}>
                    <p>{selectedCompany}</p>
                    <HighlightOff
                      color="error"
                      className={styles.removeCompany}
                      onClick={deleteSavedSearchValue}
                    />
                  </div>
                )}
              </div>
              {!selectedCompany && (
                <>
                  {searchTerm.length >= 3 && (
                    <div className={styles.selectedCompaniesContainer}>
                      {selectedCompanies.map((company: string) => (
                        <p
                          className={styles.companyListItem}
                          onClick={() => setSelectedCompany(company)}
                        >
                          {company}
                        </p>
                      ))}
                    </div>
                  )}

                  <p className={companyError ? styles.errorStyle : ""}>
                    {companyError}
                  </p>
                </>
              )}
            </>
            <section className={styles.fieldSection}>
              <label className={styles.label}>As of Date</label>
              <DatePicker
                handleSelectDate={(date: any) => handleSelectDate(date)}
                selectedDate={selectedDate}
              ></DatePicker>
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Arr</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["arr"]}
                onChange={(e) => editData(e.target.value, "arr")}
              />
              <p className={arrError ? styles.errorStyle : ""}>{arrError}</p>
            </section>

            <section className={styles.fieldSection}>
              <label className={styles.label}>Champion Title</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["championtitle"]}
                onChange={(e) => editData(e.target.value, "championtitle")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Competitors</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["competitors"]}
                onChange={(e) => editData(e.target.value, "competitors")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Customer LinkedIn</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["customerlinkedin"]}
                onChange={(e) => editData(e.target.value, "customerlinkedin")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Replaced</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["replaced"]}
                onChange={(e) => editData(e.target.value, "replaced")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Use case</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["usecase"]}
                onChange={(e) => editData(e.target.value, "usecase")}
              />
            </section>

            <div className="text-center">
              <Button
                variant="contained"
                className={styles.saveBtn}
                onClick={addCustomer}
                disabled={isUpdateDisabled}
              >
                send
              </Button>
            </div>
          </div>
        </div>
      </ModalForm>
      <ModalForm
        open={isOpenUpdateModal}
        handleClose={closeUpdateForm}
        maxWidth="sm"
        fullWidth={true}
      >
        <div className="border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <div className="text-center mb-4">
              <h6 className={styles.modalTitle}>Edit Customer</h6>
            </div>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Customer Name</label>
              <div className={styles.errorContainer}>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["customername"]}
                error={isUpdateDisabled}
                onChange={(e) => editData(e.target.value, "customername")}
              />
              <Typography variant="body2" color="error">*</Typography>
              </div>
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>As of Date</label>
              <div className={styles.datePicker}>
                <DatePicker
                  handleSelectDate={(date: any) => handleSelectDate(date)}
                  selectedDate={selectedDate}
                ></DatePicker>
              </div>
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Arr</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["arr"]}
                onChange={(e) => editData(e.target.value, "arr")}
              />
              <p className={arrError ? styles.errorStyle : ""}>{arrError}</p>
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Champion Title</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["champion"]}
                onChange={(e) => editData(e.target.value, "champion")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Competitors</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["competitors"]}
                onChange={(e) => editData(e.target.value, "competitors")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Customer LinkedIn</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["linkedinid"]}
                onChange={(e) => editData(e.target.value, "linkedinid")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Replaced</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["replaced"]}
                onChange={(e) => editData(e.target.value, "replaced")}
              />
            </section>
            <section className={styles.fieldSection}>
              <label className={styles.label}>Use case</label>
              <TextField
                variant="outlined"
                className={styles.inputField}
                fullWidth
                label={null}
                value={editedData["usecase"]}
                onChange={(e) => editData(e.target.value, "usecase")}
              />
            </section>

            <div className="text-center">
              <Button
                variant="contained"
                className={styles.saveBtn}
                onClick={changeCustomer}
                disabled={isUpdateDisabled}
              >
                send
              </Button>
            </div>
          </div>
        </div>
      </ModalForm>
    </>
  );
}
