import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    deleteFinancingsRow,
    getFinancingColumnWidths,
    getFinancingsTable
 } from '../../../store/sourcing/sourcingThunks';
import {
    setActiveTab,
    selectActiveTab,
    selectFinancingsByTab,
    selectFinancingsColumnWidths
} from '../../../store/sourcing/sourcingSlice';

import TableActions from './TableActions';
import Pagination from '../../../components/Pagination/Pagination';
import OutlookModal from '../../../components/OutlookModal';
import ArrModal from '../../../components/ArrModal';
import TSkeleton from '../../../components/TSkeleton';
import UniversalSearch from '../../../components/UniversalSearch';
import GenericTable from '../../../components/GenericTable';
import InfoIcon from '@material-ui/icons/Info';
import EditFinancingsModal from './EditFinancingsModal';
import EditFieldModal from './EditFinancingsFieldModal';
import SearchBar from './SearchBar';

import { useNotification } from '../../../hooks/useNotification';
import { useOwners } from '../../../hooks/useOwners';
import { useFPCIPriorities } from '../../../hooks/useFPCIPriorities';

import usePagination from '../../../hooks/usePagination';
import Snackbar from '@material-ui/core/Snackbar';
import { useFilter } from '../../../hooks/useFilter';

import { formatColumnFormats, formatColumnWidths, FORMATTING_TYPES, formatColumnDefaultValues } from '../../../utils/formatting';
import { defaultColumn, highlight, highLightColumn, tHeadStyle } from '../utils';
import { filterCompany } from '../Companies/helper';
import { renderCell } from './utils';
import IntelligenceModal from '../../../components/IntelligenceModal';
import { useStatuses } from '../../../hooks/useStatus';
import { PAGE_NAMES } from '../../../constants';
import styles from './styles.module.scss';
import TaxonomyModal from '../../../components/TaxonomyModal';

const rowsPerPageOptions = [25, 50, 100]

const TABS = {
    'all': 'FINANCINGS',
    'equity': 'EQUITY',
    'm&a': 'MA',
    'out-of-business': 'OUT_OF_BUSINESS',
    'last-week-financing': 'LAST_WEEK_FINANCING',
    'last-week-ma': 'LAST_WEEK_MA',
}

const financingsDefaults = {
    rows: [],
    cells: [],
    columns: [],
}
function TFinancings() {
    const dispatch = useDispatch();
    const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
    const [isArrModalOpen, setIsArrModalOpen] = useState(false);
    const [isTaxonomyModalOpen, setIsTaxonomyModalOpen] = useState(false);
    const [currentCompanyId, setCurrentCompanyId] = useState("");
    const [currentCompanyName, setCurrentCompanyName] = useState("");
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isIntelligenceModalOpen, setIsIntelligenceModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(false);
    const [columnWidthValues, setColumnWidthValues] = useState(null);
    const [columnFormatValues, setColumnFormatValues] = useState(null);
    const [defaultColumnValues, setDefaultColumnValues] = useState(null);
    const activeTab = useSelector(selectActiveTab);
    const { data, isLoading } = useSelector(selectFinancingsByTab(activeTab)) || financingsDefaults;
    const { rows, cells, columns } = data || financingsDefaults
    const [selectedColumns, setSelectedColumns] = useState(JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)) || defaultColumnValues);
    const [isEditFieldModalOpen, setIsEditFieldModalOpen] = useState(false);
    const [editUpdatedId, setEditUpdatedId] = useState(null);
    const [editedIndex, setEditedIndex] = useState(null)
    const [editedUpdatedName, setEditedUpdatedName] = useState(null);
    const [editedUpdatedValue, setEditedUpdatedValue] = useState(null);
    const [updatedIndex, setUpdatedIndex] = useState(null);

    let columnWidths = useSelector(selectFinancingsColumnWidths);

    const location = useLocation();
    const { filterOptions } = useFilter({});
    useOwners();
    useStatuses();
    useFPCIPriorities();

    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;


    const selectedCells = useMemo(() => cells?.filter(cell => selectedColumns.includes(cell)), [cells, selectedColumns])

    useEffect(() => {
        const activeTabs = Object.keys(TABS);
        const selectedTab = activeTabs.find(tab => location.pathname.includes(tab));
        if(selectedTab) {
            if(activeTab !== TABS[selectedTab]) {
                dispatch(setActiveTab(TABS[selectedTab]))
                setSelectedColumns(JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)
                ) || defaultColumnValues)
            }
        } else {
            dispatch(setActiveTab(TABS['all']))
        }
    }, [location.pathname, activeTab, defaultColumnValues])

    useEffect(() => {
        if(activeTab) {
            dispatch(getFinancingsTable(activeTab));
        }
    }, [activeTab, dispatch]);
    

    useEffect(() => {
        dispatch(getFinancingColumnWidths());
    }, []);

    useEffect(() => {
        if(columnWidths?.rows?.length) {
            setColumnWidthValues(formatColumnWidths(columnWidths.rows))
            setColumnFormatValues(formatColumnFormats(columnWidths.rows))
            setDefaultColumnValues(formatColumnDefaultValues(columnWidths.rows))
        }
    }, [columnWidths]);

    useEffect(() => {
        setSelectedColumns(JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)
                ) || defaultColumnValues)
    }, [defaultColumnValues]);

    const handleFilterColumns = (selectedColumns) => {
        setSelectedColumns(selectedColumns);
        localStorage.setItem(
            `selectedColumns${activeTab}`,
            JSON.stringify(selectedColumns)
        );
    };

    const handleOpenNotification = (message) => {
        openNotification({
            open: true,
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
            message: message,
        })
    }

    const handleRowDelete = async (tableCinchyId) => {
        await dispatch(deleteFinancingsRow(tableCinchyId))
        await dispatch(getFinancingsTable(activeTab))
        handleOpenNotification('Row successfully deleted')
    }

    const handleAddRecentOutlook = useCallback((companyId, index) => {
        setIsOutlookModalOpen(true);
        setCurrentCompanyId(companyId);
        setUpdatedIndex(index)
    }, []);

    const handleAddArrOutlook = useCallback((companyId) => {
        setIsArrModalOpen(true);
        setCurrentCompanyId(companyId);
    }, []);

    const handleAddTaxonomy = useCallback((companyId) => {
        setIsTaxonomyModalOpen(true);
        setCurrentCompanyId(companyId);
    }, []);

    const handleRowUpdate = useCallback((row) => {
        setIsUpdateModalOpen(true);
        setSelectedRow(row);
    }, []);

    const handleIntelligenceOpen = useCallback((companyId, company) => {
        setIsIntelligenceModalOpen(true);
        setCurrentCompanyId(companyId);
        setCurrentCompanyName(company);
    }, []);

    const [searchValue, setSearchValue] = useState('');
    const [column, setColumn] = useState('Company');

    const filteredRows = useMemo(() => {
        const actualRows = Object.keys(filterOptions).length ? filterCompany(filterOptions, rows)
            : rows
        if(searchValue) {
            return actualRows.filter(item => item[column]?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        }
        return actualRows;
    }, [filterOptions, rows?.length, searchValue, column]);


    const columnsToHighLight = useMemo(() => {
        let options = new Set(Object.keys(filterOptions));
        return selectedCells?.filter((item) => (options.has(item.toLowerCase()) || options.has(item)));
    }, [filterOptions, selectedCells])

    const findValueByKey = (array, key) => {
        const obj = array?.find(item => key in item);
        return obj ? obj[key] : undefined;
    };

    const handleOnClickColumn = (columnName, cinchyId) => {
        columnFormatValues.forEach(c=>{
            if(c[columnName.toLocaleLowerCase()] === FORMATTING_TYPES.LINKCOMPANY) {
                handleOpenCompanyDetails(cinchyId)
            }
        })
    };

    const handleOpenCompanyDetails = (companyId) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    const tableActions = <TableActions
        cells={cells}
        selectedColumns={selectedColumns}
        columns={columns}
        handleSelectColumns={handleFilterColumns}
        openNotification={(message) => handleOpenNotification(message)}
    />

    const getHeadCellStyle = (cell) =>  ({
    ...columnsToHighLight.includes(cell) ? highlight : tHeadStyle,
            minWidth: findValueByKey(columnWidthValues, cell),
    })

    const getCellStyle = (cell) =>  columnsToHighLight.includes(cell) ? highLightColumn : defaultColumn;

    const handleCompanyWebsiteSplit = useCallback((value, id) => {
        const [companyName, website] = value.split(' | ');
        return <div className="mr-5">
                 <span className={styles.hoverBlack} onClick={() => handleOpenCompanyDetails(id)} style={{ cursor: 'pointer', color: 'black', display: 'block', fontSize: '14px', fontWeight: 'bold' }}>
                    {companyName}
                 </span>
                 <span  style={{ cursor: 'pointer', color: 'black' }}>
                    <a href={website?.startsWith('http') ? website : `http://${website}`}
                        target="_blank" className={styles.hoverBlack} style={{ color: 'grey', fontSize: '12px' }}>{website}</a>
                 </span>
               </div>
    }, []);

    const handleEditField = (value, id, columnName, index) => {
        setEditUpdatedId(id)
        setEditedUpdatedName(columnName)
        setEditedUpdatedValue(value)
        setIsEditFieldModalOpen(true)
        setEditedIndex(index)
    };

    const addEditIcon = useCallback((displayedValue, columnName, id, value, index) => {
        return <>
            <div className={styles.editableCell}>
                    {displayedValue}
                    <Tooltip title={`Edit ${columnName}`} arrow placement="top">
                        <Button
                            className={styles.cellEditBtn}
                            onClick={(e) => handleEditField(value, id, columnName, index)}
                        >
                        <Edit className={styles.editBtn}/>
                        </Button>
                    </Tooltip>
            </div>
        </>
    }, []);

    const renderTCell = useCallback(
        (column, row, index) => renderCell(
            { columnFormat: columnFormatValues,
                handleAddRecentOutlook,
                handleAddArrOutlook,
                handleAddTaxonomy,
                handleCompanyWebsiteSplit,
                addEditIcon,
                column,
                row,
                index
            }),[columnFormatValues])

    const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: filteredRows, rowsPerPageValue : rowsPerPageOptions[0] });

    const rowsToDraw = useMemo(() => filteredRows?.slice(
        (currentPage - 1) * rowsPerPage,
        (currentPage - 1) * rowsPerPage + rowsPerPage
    ), [filteredRows, currentPage, rowsPerPage])

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const getActionsCell = useCallback(({ row }) => <div className='d-flex'>
        {/* <Tooltip title="Edit Row">
            <IconButton size="small" aria-label="edit" onClick={() => handleRowUpdate(row)}>
                <EditIcon style={{ color: 'grey' }} fontSize='small' />
            </IconButton>
        </Tooltip> */}
        <Tooltip title="Delete Row">
            <IconButton size="small" aria-label="delete">
                <DeleteIcon fontSize='small' onClick={() => handleRowDelete(row['Table Cinchy Id'])} />
            </IconButton>
        </Tooltip>
        <Tooltip title="Intelligence">
            <IconButton size="small" aria-label="delete">
                <InfoIcon fontSize='small' onClick={() => handleIntelligenceOpen(row["Company Cinchy Id"], row["Company"])} />
            </IconButton>
        </Tooltip>
    </div>, [])

    return (
        <>
            {isLoading ? <TSkeleton/> :
            <div className="table-responsive-md mb-spacing-2-x2 ml-3">
                <UniversalSearch />
                <GenericTable
                    title={`Financings (${filteredRows.length} results)`}
                    rows={rowsToDraw}
                    headCells={selectedCells}
                    tableActions={tableActions}
                    getHeadCellStyle={getHeadCellStyle}
                    getCellStyle={getCellStyle}
                    renderCell={renderTCell}
                    getActionsCell={getActionsCell}
                    searchBar={<SearchBar handleSearch={handleSearch} column={column} setColumn={setColumn} />}
                >
                    <Pagination
                        goToPage={goToPage}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        goToPageValue={goToPageValue}
                        totalPages={totalPages}
                        handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} options={rowsPerPageOptions}
                    />
                </GenericTable>
                <OutlookModal
                    isOpen={isOutlookModalOpen}
                    companyId={currentCompanyId}
                    selectedOutlook={null}
                    setIsOpen={setIsOutlookModalOpen}
                    handleRefresh={() => dispatch(getFinancingsTable(activeTab))}
                    activeTab={activeTab}
                    index={updatedIndex}
                    pageName={PAGE_NAMES.FINANCINGS}
                />
                <ArrModal
                    isOpen={isArrModalOpen}
                    companyName={currentCompanyId}
                    selectedOutlook={null}
                    setIsOpen={setIsArrModalOpen}
                    handleRefresh={() => dispatch(getFinancingsTable(activeTab))}
                />
                {isTaxonomyModalOpen && <TaxonomyModal
                isOpen={isTaxonomyModalOpen}
                setIsOpen={setIsTaxonomyModalOpen}
                companyId={currentCompanyId}
                refreshApi={() => {
                    dispatch(getFinancingsTable(activeTab))
                }}
            />}
                <EditFinancingsModal
                    row={selectedRow} i
                    isOpen={isUpdateModalOpen}
                    onClose={() => setIsUpdateModalOpen(false)}
                    openNotification={(message) => handleOpenNotification(message)}
                />
                <IntelligenceModal
                    id={currentCompanyId}
                    company={currentCompanyName}
                    isOpen={isIntelligenceModalOpen}
                    setIsOpen={() => setIsIntelligenceModalOpen(false)}>
                </IntelligenceModal>
                {setIsEditFieldModalOpen && <EditFieldModal
                    isOpen={isEditFieldModalOpen}
                    setIsOpen={setIsEditFieldModalOpen}
                    tableCinchyId={editUpdatedId}
                    refreshApi={() => dispatch(getFinancingsTable(activeTab))}
                    editedValue={editedUpdatedValue}
                    editedName={editedUpdatedName}
                    index={editedIndex}
                    activeTab={activeTab}
                />}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{ root: toastrStyle }}
                    open={open}
                    message={message}
                />
            </div>}
        </>
    );
}

export default TFinancings;