import React, { useState, useEffect } from 'react';
import {
    Button,
    makeStyles,
} from '@material-ui/core';

import SuspenseLoading from '../../../components/SuspenseLoading';
import ModalCheckbox from '../../../components/ColumnsModal/ModalCheckbox';
import ModalForm from '../../../components/ModalForm';

import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
}));

const ColumnsModal= ({
       isOpen,
       setIsOpen,
       columns,
       selectedColumns,
       handleSelectColumns,
}) => {
    const [checkedColumns, setCheckedColumns] = useState(null);

    const classes = useStyles();

    const initCheckedColumns = () => {
        const groupedData = columns.reduce((result, item) => {
            const { Category, 'Column Name': columnName, ...rest } = item;
            if (!result[Category]) {
                result[Category] = [];
            }
            const checked = selectedColumns.includes(columnName);
            result[Category].push({ 'Column Name': columnName, checked, ...rest });
            return result;
        }, {});

        setCheckedColumns(groupedData);
    };

    const handleCheckboxChange = (category, columnName) => {
        setCheckedColumns((prevData) => {
            return {
                ...prevData,
                [category]: prevData[category].map((item) => {
                if (item['Column Name'] === columnName) {
                    return { ...item, checked: !item.checked };
                }
                return item;
            }),
        };
        });
    };

    const handleSave = () => {
        let onlyCheckedColumns = [];

        for (let category of Object.values(checkedColumns || {})) {

            if (Array.isArray(category) && category.length) {
                const checkedColumns = category
                    .filter(item => item.checked)
                    .map(item => item['Column Name']);
                onlyCheckedColumns = [...onlyCheckedColumns, ...checkedColumns];
            }
        }

        handleSelectColumns(onlyCheckedColumns);
        handleClose();
    };

    const handleClose = () => {
        setCheckedColumns(null);
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            initCheckedColumns();
        }
    }, [isOpen]);

    return (
        <ModalForm
            open={isOpen}
            handleClose={handleClose}
            maxWidth={false}
            fullWidth={false}
        >
            <div className="border-0">
                <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center">
                        <h6 className={styles.modalTitle}>
                            Select  columns to appear in the Financings Table
                        </h6>
                    </div>

                    <div className={styles.container}>
                        {checkedColumns ? Object.keys(checkedColumns).map((category, index) => (
                            <div key={category} className={`${styles.category} ${index === 1 ? styles.customBackground : ''}`}>
                                <h2 className={styles.categoryTitle}>{category}</h2>
                                <ul className={styles.columnsList}>
                                    {checkedColumns[category].map((item, index) => (
                                        <ModalCheckbox
                                            key={index}
                                            label={item['Column Name']}
                                            checked={item.checked}
                                            handleChange={() => handleCheckboxChange(category, item['Column Name'])}
                                        />
                                    ))}
                                </ul>
                            </div>
                        )) : (<div className={styles.loadingSpinner}><SuspenseLoading /></div>)}
                    </div>

                    <div className="text-center">
                        <Button
                            variant="contained"
                            className={classes.saveBtn}
                            onClick={handleSave}
                        >
                            save
                        </Button>
                    </div>
                </div>
            </div>
        </ModalForm>
    )
};

export default ColumnsModal;
