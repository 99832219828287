import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles.module.scss";
import HeadcountHistory from "../HeadcountHistory";

function formatToPercentage(number: Number) {
  if (typeof number !== "number") {
    return "";
  }

  const formattedPercentage = Math.round(number) + "%";
  return formattedPercentage;
}

const OverviewDemographics = ({
  demographics,
  headcountHistory,
  handleRefresh,
  companyId,
}: any) => {
  let filteredHeadcountData = headcountHistory.map(
    (headcount: any, index: number) => {
      return {
        TableId: headcount["Table Cinchy Id"],
        Date: headcount.Date,
        Headcount: headcount.Headcount,
      };
    }
  );

  return (
    <section className={styles.demographics}>
      <div className={styles.container}>
        <div className={`${styles.headcountTable}`}>
          <HeadcountHistory
            tableData={filteredHeadcountData}
            companyId={companyId}
            handleRefresh={handleRefresh}
          ></HeadcountHistory>
        </div>
      </div>
    </section>
  );
};

export default OverviewDemographics;
