import React, { useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import ExportCSV from '../ExportCSV';
import InvestorColumnsModal from '../InvestorColumnsModal';
import styles from './styles.module.scss';

export default function InvestorTableActions({ rows, cells, columns, selectedColumns, handleSelectColumns, detailColumns
    ,selectedDetailColumns
    ,handleDetailSelectColumns  }) {
    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);

    const generateCSVTableHeaders = (cells) => {
        return cells?.map(cell => ({id: 'id', label: cell.label, key: cell.label.toLowerCase()}));
    };

    const openColumnsModal = useCallback(
        () => {
            setIsColumnsModalOpen(!isColumnsModalOpen)
        },[isColumnsModalOpen]
    );

    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-4">
                <div className={styles.actions}>
                <Button
                    size="large"
                    onClick={openColumnsModal}
                    className='btn-second mr-2'
                >
                    <span className="btn-wrapper--label font-14">edit columns</span>
                </Button>
                </div>
                <div className={styles.actions}>
                    <ExportCSV 
                        data={rows}
                        headers={generateCSVTableHeaders(cells)}
                        filename={'companies.csv'}
                    >
                        <Button>
                            <span className={`${styles.exportBtnLabel} font-12`}>
                                export CSV
                            </span>
                        </Button>
                    </ExportCSV>
                </div> 
            </div>
            <InvestorColumnsModal  
                isOpen={isColumnsModalOpen} 
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
                detailColumns={detailColumns}
                selectedDetailColumns={selectedDetailColumns}
                handleDetailSelectColumns={handleDetailSelectColumns}
            />
        </>
    );
}
