import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Paper, Table, TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";

import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import {
 formatDate,
} from "../../../utils/formatting";
import {formatEmptyCell} from "../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import {deleteCircleBackNote, getCircleBackNotes, updateCompanyName, updateNotes} from "./api";
import {useDispatch} from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import {markdownToHTML} from "../../../utils/markdownToHTML";
import InfoIcon from "@material-ui/icons/Info";
import CompanyNameSelect from "../../../components/DragAndDrop/CompanyNameSelect";
import {STATUSES} from "../../../constants";
import EdiNotesModal from "./EdiNotesModal";
import {Edit} from "@material-ui/icons";

const useRowStyles = makeStyles({
    root: {
        '& th': {
            padding: '8px',
            background: '#6e3cfa',
            color: 'white'
        },
    },
    headerCell: {
        backgroundColor: '#6e3cfa',
        height: '20px',
        lineHeight: '10px',
        color: '#fff !important',
        borderRight: '1px solid #fff',
        padding: '1rem',
        whiteSpace: 'nowrap',
        fontWeight: 'normal !important',
        // fontSize: '12px',
        fontFamily: 'Quicksand',
        '&:last-child': {
            padding: '1rem !important',
        },
        '& span:hover': {
            color: '#fff !important',
            cursor: 'default',
        },
        '& span:focus': {
            color: '#fff !important',
        },
    },
    tableCell: {
        backgroundColor: 'white',
        // minWidth: '150px',
        maxWidth: '400px',
        borderRight: '1px solid #eeeff8',
        padding: '8px',
        fontFamily: 'Quicksand',
        fontSize: '14px',

        '&:last-child': {
            // padding: '1rem !important',
        },
    },
    searchHighlight: {
        backgroundColor: 'rgba(110, 60, 250)',
        color: 'white',
    },
    highlighted: {
        background: '#EDECEC',
        marginRight: "5px"
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    technologyTag: {
        margin: '5px',
        backgroundColor: '#EDECEC',
    },
    cellEditBtn: {
        position: 'absolute',
        top: '8px',
        right: '0',
        // visibility: 'hidden'
    },
    editBtn: {
        width: '0.7em',
        height: '0.7em',
        color: 'grey'
    },
    addRecentOutlook: {
        position: 'absolute !important',
        right: '0',
        bottom: '0',
    },
    addRecentOutlookButton: {
        height: '15px !important',
        width: '15px !important',
    },
    markdown: {
        '& h4': {
            fontSize: '1rem',
        },
        fontFamily: 'Quicksand !important',
    },
    companyName: {
        width: '240px',
    },
});


const CirclebackNotes = () => {
    const classes = useRowStyles();
    const [openNoteId, setOpenNoteId] = useState(false);
    const [updatedNote, setUpdatedNote] = useState('');
    const [isEditNotesOpen, setIsEditNotesOpen] = useState(false);
    const [notes, setNotes] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        getCircleBackNotes({ dispatch }).then(res => setNotes(res))
    }, [])

    const openNote = useMemo(() => notes.find(note => note['Cinchy Id'] === openNoteId)?.Notes, [openNoteId, notes]);
    

    const handleOpenDetails = (cinchyId) => {
        if (openNoteId === cinchyId) {
            setOpenNoteId(false)
        } else {
            setUpdatedNote(notes.find(note => note[cinchyId] === openNoteId)?.Notes)
            setOpenNoteId(cinchyId)
        }
    }

    const handleDeleteNote = (cinchyId) => {
        deleteCircleBackNote({ dispatch, cinchyId }).then(res => {
            if (res.status === STATUSES.SUCCESS) {
                setNotes(notes.filter(note => note['Cinchy Id'] !== cinchyId))
            }
        })
    }

    const handleEditCompanyName = (cinchyId) => (value, option) => {
        console.log('Log ::: arg ===', value, option, cinchyId);

        updateCompanyName({ dispatch, cinchyId: cinchyId, companyCinchyId: option['Cinchy Id'] }).then(res => {
            if (res.status === STATUSES.SUCCESS) {
                setNotes(notes.map(note => {
                    if (note['Cinchy Id'] === option['Cinchy Id']) {
                        note['Company Name'] = value;
                    }
                    return note;
                }))
            }
        })
    }

    const handleCloseEditNotesModal = () => {
        console.log('Log ::: 11111 ===')
        setIsEditNotesOpen(false);
    }

    const handleOpenEditNotesModal = () => {
        setIsEditNotesOpen(true);
    }

    const handleSaveNote = ({ notes }) => {
        updateNotes({ dispatch, cinchyId: openNoteId, notes }).then(() => {
            setIsEditNotesOpen(false);
        })
    }


    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead stickyHeader
                               className={classes.root}
                               aria-labelledby="tableTitle"
                               size='medium'
                               aria-label="enhanced table">
                        <TableRow>
                            <TableCell className={classes.headerCell} style={{ width: '20px' }}/>
                            <TableCell className={classes.headerCell}  align='center' style={{ width: '20px' }}>
                                <Tooltip title="Delete Note">
                                    <IconButton size="small" aria-label="delete">
                                        <InfoIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={`${classes.headerCell} font-14`} align="left">Date</TableCell>
                            <TableCell className={`${classes.headerCell} font-14`} align="left">Meeting Title</TableCell>
                            <TableCell className={`${classes.headerCell} font-14`} align="left">Company Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notes?.map((row) => (<Fragment key={row['Cinchy Id']}>
                            <TableRow className={classes.root}>
                                <TableCell className={classes.tableCell}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleOpenDetails(row['Cinchy Id'])}
                                    >
                                        {openNoteId === row['Cinchy Id'] ? <IndeterminateCheckBoxIcon fontSize='small' /> : <AddBoxIcon fontSize='small' />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <IconButton
                                        aria-label="delete row"
                                        size="small"
                                        onClick={() => handleDeleteNote(row['Cinchy Id'])}
                                    >
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </TableCell>
                                <TableCell className={`${classes.tableCell} font-14`} align="left">{formatEmptyCell(formatDate(row.Date))}</TableCell>
                                <TableCell className={`${classes.tableCell} font-14`} align="left">{formatEmptyCell(row.Name)}</TableCell>
                                {/*<TableCell className={`${classes.tableCell} font-14`} align="left">{formatEmptyCell(row.Name)}</TableCell>*/}
                                <TableCell className={`${classes.tableCell} ${classes.companyName} font-14`} align="left"><CompanyNameSelect setName={handleEditCompanyName(row['Cinchy Id'])} defaultValue={row['Company Name']} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 , position: 'relative' }} colSpan={9}>
                                    <Collapse in={openNoteId === row['Cinchy Id']} timeout="auto" unmountOnExit>
                                        {/*<Tooltip title="Edit Key Words" arrow placement="top">*/}
                                        {/*    <Button*/}
                                        {/*        className={classes.cellEditBtn}*/}
                                        {/*        onClick={handleOpenEditNotesModal}*/}
                                        {/*    >*/}
                                        {/*        <Edit className={classes.editBtn}/>*/}
                                        {/*    </Button>*/}
                                        {/*</Tooltip>*/}
                                        <Box margin={1}>
                                            <div className={classes.markdown} dangerouslySetInnerHTML={{ __html: markdownToHTML(row.Notes) }} />
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </Fragment>))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EdiNotesModal
                isOpen={isEditNotesOpen}
                handleSave={handleSaveNote}
                note={markdownToHTML(updatedNote || '')}
                setUpdatedNote={setUpdatedNote}
                handleClose={handleCloseEditNotesModal}
            />
        </>
    );
};

export default CirclebackNotes;
