import {
  Action,
  AnyAction,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import logger from "redux-logger";
import userSlice from './user/userSlice';
import sourcingReducer from './sourcing/sourcingSlice';
import themeOptionsSlice from './themeOptions/themeOptionsSlice';
import whitespaceReducer from './sourcing/whitespace/whitespaceSlice';
import relationshipsReducer from './relationships/relationshipsSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import filterSlice from "./filter/filterSlice";

const store = configureStore({
  reducer: {
    ThemeOptions: themeOptionsSlice.reducer,
    user: userSlice.reducer,
    whitespace: whitespaceReducer,
    sourcing: sourcingReducer,
    relationships: relationshipsReducer,
    filters: filterSlice,
    dashboard: dashboardReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger)
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export type MapDispatch = ThunkDispatch<RootState, void, AnyAction>;
