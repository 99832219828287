import React, { useState, useEffect } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import * as NotificationHelper from '../../helpers/notification';

export default function Notification(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<NotificationHelper.Severity>(
    'error'
  );
  const [message, setMessage] = useState('');

  useEffect(
    () =>
      NotificationHelper.subscribe(({ severity = 'error', message }) => {
        setOpen(true);
        setSeverity(severity);
        setMessage(message);
      }),
    []
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        className="mb-1 mt-1"
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
