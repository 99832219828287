import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import HubspotDropdown from "../HubspotDropdown";
import { addHubspotSequence } from "../HubspotModal";
import { selectSingleCompanyDetails } from "../../store/sourcing/sourcingSlice";

const useStyles = makeStyles({
  checkbox: {
    color: "#6e3cfa",
    "&.Mui-checked": {
      color: "#6e3cfa",
    },
  },
  hubspotDropdown: {
    display: "flex",
    alignItems: "center",
  },
});

const HubspotSequence = ({ companyId }: any) => {
  const classes = useStyles();
  const [selectedHubspot, setSelectedHubspot] = useState<string>("");

  useEffect(() => {
    if (selectedHubspot) {
      addHubspotSequence({ sequence: selectedHubspot, cinchyId: companyId });
    }
  }, [selectedHubspot]);

  return (
    <>
      <div className={classes.hubspotDropdown}>
        {/*<FormControlLabel*/}
        {/*    control={*/}
        {/*      <Checkbox*/}
        {/*          className={`${classes.checkbox} font-10`}*/}
        {/*          checked={isChecked}*/}
        {/*          onChange={handleChange}*/}
        {/*          name="checkedB"*/}
        {/*          disabled={isChecked}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={*/}
        {/*      <span style={labelStyle}>*/}
        {/*    Enrich Contact Data*/}
        {/*  </span>*/}
        {/*    }*/}
        {/*    id="blockElement"*/}
        {/*/>*/}
        <span className="font-12 text-nowrap mr-2">Select Hubspot Sequence</span>
        <HubspotDropdown onChange={setSelectedHubspot} companiesPageDropdown={false} />
      </div>
    </>
  );
};

export default HubspotSequence;
