import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./styles.module.scss";
import { ERROR_TYPE } from "../../constants";
import { Refresh } from "@material-ui/icons";
//import RefreshIcon from '@mui/icons-material';

export default function Loader({
  children,
  condition,
  title,
  error = null,
  handleRefresh = null,
}: any) {
  return (
    <>
      {!condition && (
        <Card className="card-box mt-4">
          <div className="card-header">
            <div className="card-header--title font-18">{title}</div>
          </div>

          <CardContent className="p-3">
            {!error && (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            )}
            {ERROR_TYPE.EMPTY_DATA === error && (
              <p className="font-12">No Data</p>
            )}
            {ERROR_TYPE.SERVER_ERROR === error && (
              <div className={styles.errorContainer}>
                <p className='font-14'>Server error please try again</p>
                <Refresh className={styles.refreshIcon} onClick={handleRefresh}></Refresh>
              </div>
            )}
          </CardContent>
        </Card>
      )}
      {condition && <> {children}</>}
    </>
  );
}
