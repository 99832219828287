import { makeStyles } from '@material-ui/core/styles';

export const tHeadStyle = {
    backgroundColor: '#6e3cfa',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: 'unset',
}
export const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        zIndex: 200,
        minHeight: '50px',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: 'black',
                backgroundColor: 'rgba(110, 60, 250, 0.05)'
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 40%',
        fontFamily: 'Quicksand',
        fontSize: '18px',
    }
}));
export const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750,
    },
    sortCell: {
        '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
            color: 'white !important',
        },
    },
    tableCell: {
        backgroundColor: 'white',
        minWidth: '150px',
        borderRight: '1px solid #eeeff8',
        padding: '1rem',
        fontFamily: 'Quicksand',
        fontSize: '14px',

        '&:last-child': {
            padding: '1rem !important',
        },
        position: 'relative !important',
    },
    cellEditBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
    editBtn: {
        width: '0.7em',
        height: '0.7em',
        color: 'grey'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },
    currentPageInput: {
        border: '#6e3cfa 1px solid',
        color: '#6e3cfa',
        borderRadius: '4px',
        margin: '4px'
    },
    displayCell: {
        display: 'none',
    },
    descriptionCell: {
        minWidth: '400px',
    },
    companyNameCell: {
        cursor: 'pointer',
    },
    tableHead: {
        backgroundColor: '#6e3cfa',
        height: '20px',
        lineHeight: '10px'
    },
    fixedCell: {
        position: 'sticky !important',
        zIndex: 101,
        left: 0
    },
    left: {
        position: 'sticky !important',
        zIndex: 100,
        left: '46px',
    },
    whiteBackground: {
        backgroundColor: 'white'
    },
    zIndex102: {
        zIndex: 102,
    },

    '@global': {
        '.MuiTableSortLabel-icon': {
            color: '#fff !important'
        },
    },
    sortIcon: {
        color: '#fff !important',
    },
    headerCell: {
        backgroundColor: '#6e3cfa',
        height: '20px',
        lineHeight: '10px',
        color: '#fff !important',
        borderRight: '1px solid #fff',
        padding: '1rem',
        whiteSpace: 'nowrap',
        fontWeight: 'normal !important',
        // fontSize: '12px',
        fontFamily: 'Quicksand',
        '&:last-child': {
            padding: '1rem !important',
        },
        '& span:hover': {
            color: '#fff !important',
            cursor: 'default',
        },
        '& span:focus': {
            color: '#fff !important',
        },
    },
    statusCell: {
        minWidth: '250px',
    },
    tagsList: {
        minWidth: '250px',
        paddingLeft: 0,
    },
    tag: {
        padding: '0.5rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        border: '1px solid #e0e0e0',
        borderRadius: '0.4rem',
        marginBottom: '0.2rem',
        backgroundColor: '#fff',
    },
    highlighted: {
        background: '#EDECEC',
        marginRight: "5px"
    },
    searchHighlight: {
        backgroundColor: 'rgba(110, 60, 250)',
        color: 'white',
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    technologyTag: {
        margin: '5px',
        backgroundColor: '#EDECEC',
    },
    label: {
        fontSize: '1rem',
        textTransform: 'capitalize',
        marginLeft: '0.3rem',
    },
    selectField: {
        maxWidth: '300px',
        width: '100%',
        marginTop: '-15px',
        marginLeft: '5px'
    },
    emptyData: {
        textAlign: 'center'
    },
    checkboxCell: {
        color: '#fff',
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#fff'
        }
    },
    checkbox: {
        color: '#6e3cfa',
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#6e3cfa'
        }
    },
    tableRow: {
        '&.MuiTableRow-root.Mui-selected': {
            backgroundColor: 'white',
        },
    },
    tooltip: {
        fontFamily: 'Quicksand',
    },
    firstColumn: {
        position: 'sticky',
        zIndex: '102',
        left: 0,
        width: '50px'
    },
    secondColumn: {
        position: 'sticky !important',
        zIndex: 100,
        left: props => props.left || '84px',
    },
    z102: {
        zIndex: 102,
    }
}));