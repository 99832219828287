import React, {useEffect, useRef, useState} from 'react';
import {Grid, Card, Button} from '@material-ui/core';


import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import UniversalSearch from '../../components/UniversalSearch';
import logo from './../../assets/images/logo-example/newlogo.svg'
import styles from './styles.module.scss';

export default function Home() {
  const sourcingRef = useRef(null);
  const [isSourcingRefReady, setSourcingRefReady] = useState(false);

  useEffect(() => {
    if (sourcingRef.current) {
      setSourcingRefReady(true);
    }
  }, [sourcingRef.current]);

  return (
    <>
      <div>
        <div className={styles.logoContainer}>
          <img
            className={styles.companyLogo}
            src={logo}
            alt="Company Logo"
          />
        </div>
        <div className={styles.infoContainer}>
          <p className="font-24">Intelligence Platform</p>
          <NavLink to="/dashboard">
              <Button variant="contained" className={`${cn(styles.dashboardButton, styles.button)} btn-primary mb-2 font-14`}>
                      Dashboard
              </Button>
            </NavLink>
        </div>
        <Grid container={true} className={styles.cardsContainer}>
          <Grid item className="p-3" lg={4}>
            <Card className={styles.card}>
              <p className={`${styles.sourcingTitle}`}>Sourcing</p>
              <div ref={sourcingRef} className={cn(styles.sectionContainer, styles.firstSection)}>
                {isSourcingRefReady && <UniversalSearch top='40px' right='unset' parentElement={sourcingRef.current} />}
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  View and prioritize all technologies within Forgepoint’s taxonomy
                </p>
                  <NavLink to="/sourcing/coverage">
                  <Button variant="contained" className={`${styles.button} btn-primary mb-1 font-14`}>
                    Coverage
                  </Button>
                </NavLink>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Systematically prioritize and reach out to companies
                </p>
                  <NavLink to="/sourcing/companies/company">
                  <Button variant="contained" className={`${styles.button} btn-primary mb-1 font-14`}>
                    Companies
                  </Button>
                </NavLink>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Track Financings of companies within our database
                </p>
                <NavLink to="/sourcing/financings/all">
                  <Button variant="contained" className={`${styles.button} btn-primary mb-1 font-14`}>
                    Financings
                  </Button>
                </NavLink>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Meta-data driven flags to facilitate sourcing hygiene
                </p>
                <NavLink to="/sourcing/hygiene">
                  <Button variant="contained" className={`${styles.button} btn-primary mb-1 font-14`}>
                    Hygiene
                  </Button>
                </NavLink>
              </div>
              {/*TODO [CR5 Demo request] comment opportunities*/}
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Track Opportunities as they progress through our pipeline
                </p>
                <NavLink to="/sourcing/opportunities">
                  <Button variant="contained" className={`${styles.button} btn-primary mb-1 font-14`}>
                    Opportunities
                  </Button>
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item className="p-3" lg={4}>
            <Card className={styles.card}>
              <p className={`${styles.relationshipsTitle}`}>Relationships</p>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Manage FPC's network connections
                </p>
                <NavLink to="/relationships/people">
                  <Button variant="contained" className={`${cn(styles.relationshipButton, styles.button)} mb-1 font-14`}>
                    People
                  </Button>
                </NavLink>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Target, track and manage investor relationships
                </p>
                <NavLink to="/relationships/investors/all">
                  <Button variant="contained" className={`${cn(styles.relationshipButton, styles.button)} mb-1 font-14`}>
                    Investors
                  </Button>
                </NavLink>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Track customer relationships
                </p>
                <NavLink to="/relationships/customers">
                  <Button variant="contained" className={`${cn(styles.relationshipButton, styles.button)} mb-1 font-14`}>
                    Customers
                  </Button>
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item className="p-3" lg={4}>
            <Card className={styles.card}>
              <p className={`${styles.researchTitle}`}>Research</p>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Query internal and external data, get live results with sources
                </p>
                <Button variant="contained" className={`${cn(styles.researchButton, styles.button)} mb-1 font-14`}>
                  <a target='blank' className={styles.linkButton} href="https://forgepoint.photoninsights.com/">Forgepoint Live</a>  
                </Button>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Search and query internal documents and proprietary research
                </p>
                <Button variant="contained" className={`${cn(styles.researchButton, styles.button)} mb-1 font-14`}>
                  <a target='blank' className={styles.linkButton} href="https://forgepoint.photoninsights.com/document-insight">DocuInsights</a>  
                </Button>
              </div>
              <div className={cn(styles.sectionContainer, styles.topBorder)}>
                <p className={`${styles.section} font-14`}>
                  Auto-generate tailored diligence reports on specific companies
                </p>
                <Button variant="contained" className={`${cn(styles.researchButton, styles.button)} mb-1 font-14`}>
                  <a target='blank' className={styles.linkButton} href="https://forgepoint.photoninsights.com/diligence-suite">DD Button</a>  
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
