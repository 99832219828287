import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import styles from './styles.module.scss';
import { SECONDARY_COMPETITORS_DISPLAY_COUNT } from "../../constants";

type PrimaryCompetitorsProps = {
    tableData: any,
}

const SecondaryCompetitors: React.FC<PrimaryCompetitorsProps> = ({
    tableData,
}) => {
    const [competitors, setCompetitors] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    useEffect(() => {
        setCompetitors(isCollapsed
            ? tableData.slice(0, SECONDARY_COMPETITORS_DISPLAY_COUNT)
            : tableData)
    }, [isCollapsed, tableData]);

    const handleOnClickCompetitor = (companyId: any) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    return (
        <div>
            <div className={styles.editable}>
                <Typography className='mr-2 font-14 font-weight-bold'>Secondary Competitors</Typography>
                <div>
                    <Button
                        id="blockElement"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? (
                            <><span className={`${styles.collapseBtn} font-12`}>Show All</span><ArrowDropDown /></>
                        ) : (
                            <><span className={`${styles.collapseBtn} font-12`}>Collapse</span><ArrowDropUp /></>
                        )}
                    </Button>
                </div>
            </div>

            <div className={styles.summaryContainer}>
                {competitors?.map((competitor: any, index: number) => {
                    return <p onClick={() => handleOnClickCompetitor(competitor['Company Cinchy Id'])}
                    className={`${styles.competitors} cursor-pointer font-12`} key={index}>{competitor["Primary Competitors"]}</p>
                })}
            </div>
        </div>
    )
}

export default SecondaryCompetitors;