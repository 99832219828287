//@ts-nocheck
import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import clsx from 'clsx';

import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header';
import Footer from '../Footer';

import { RootState } from '../../store';
import {useLocation} from "react-router";

const LeftSidebar: FunctionComponent<ConnectedProps<typeof connector>> = ({
  children,
  sidebarToggle,
  sidebarToggleMobile,
  sidebarFixed,
  headerFixed,
  headerSearchHover,
  headerDrawerToggle,
  footerFixed,
  contentBackground,
}) => {
    const location = useLocation();
    return  (
        <>
            {location.pathname === '/' ? <>
                    <Header/>
                    {children}
                </>:
            <div
                className={clsx('app-wrapper', contentBackground, {
                    'header-drawer-open': headerDrawerToggle,
                    'app-sidebar-collapsed': sidebarToggle,
                    'app-sidebar-mobile-open': sidebarToggleMobile,
                    'app-sidebar-fixed': sidebarFixed,
                    'app-header-fixed': headerFixed,
                    'app-footer-fixed': footerFixed,
                    'search-wrapper-open': headerSearchHover,
                })}
            >
                <div>
                    <Sidebar />
                </div>
                <div className="app-main">
                    <Header />
                    <div className="app-content">
                        <div className="app-content--inner p-0">
                            <div className="app-content--inner__wrapper">{children}</div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>}
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
});

const connector = connect(mapStateToProps);

export default connect(mapStateToProps)(LeftSidebar);
