import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, getRelationshipData } from "../../api/sourcing";
import { QUERIES, STATUSES } from "../../constants";
import { UpdateInvestorParams } from "../../interfaces";
import { getColumns, whitespaceGroupingByRow } from "../../utils/TGroping";
import { changeDataShape, selectNecessaryColumns } from "../../utils/dataGrouping";

export const getPeople = createAsyncThunk(
  "relationships/getPeople",
  async (_, { dispatch }) => {
    const { response, status } = await getData(QUERIES.RELATIONSHIPS.PEOPLE, dispatch);
    const { response: editColumns, status: columnsStatus } = await getData(QUERIES.RELATIONSHIPS.PEOPLE_COLUMNS, dispatch);
    if(status === STATUSES.SUCCESS && columnsStatus === STATUSES.SUCCESS) {
        const rows = whitespaceGroupingByRow(response);
        const columns = whitespaceGroupingByRow(editColumns);
        const cells = getColumns(response.schema);

        return {
            rows,
            cells,
            columns
        };
    }
  }
);

export const getPeopleColumnWidths = createAsyncThunk(
  "sourcing/getPeopleColumnWidths",
  async (_, { dispatch }) => {
      const { response, status } = await getData(QUERIES.RELATIONSHIPS.PEOPLE_COLUMNS, dispatch);
      if(status === STATUSES.SUCCESS) {
          const result = changeDataShape(response);
          return result
      }
  }
);

export const getCustomers = createAsyncThunk(
  "relationships/getCustomers",
  async (columns: string[], { dispatch }) => {
    const { response, status } = await getData(QUERIES.RELATIONSHIPS.CUSTOMERS, dispatch);
    if (status === STATUSES.SUCCESS) {
      const data = changeDataShape(response);
      const filteredData = selectNecessaryColumns(data, columns);
      return filteredData;
    }
  }
);

export const getPDF = createAsyncThunk(
    'sourcing/getInvestors',
    async (columns: string[], { dispatch }) => {
        const url = 'https://sandbox.cinchy.net/forgepoint/api/files/v1.0/Research/Research/16/Files?fileId=11'

        fetch(url)
            .then(response => response.blob())
            .then(pptxBlob => {
                // Handle the .pptx blob here
            })
            .catch(error => {
                console.error("Error fetching .pptx file:", error);
            });
    }
);

export const getFPCContactList = createAsyncThunk(
  "relationships/getFPCContactList",
  async () => {
    const { response, status } = await getData(QUERIES.RELATIONSHIPS.FPC_CONTACT);
    if (status === STATUSES.SUCCESS) {
      const result = whitespaceGroupingByRow(response);
      return result;
    }
  }
);

export const syncWithCompany = createAsyncThunk(
  "relationships/syncWithCompany",
  async () => {
    const queryParams = {
      "@update": 'true',
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${QUERIES.RELATIONSHIPS.COMPANY_SYNC.UPDATE}?${queryString}`;
    const { response, status } = await getData(url);
    if (status === STATUSES.SUCCESS) {
      const result = whitespaceGroupingByRow(response);
      return result;
    }
  }
);

export const getSyncedData = createAsyncThunk(
  "relationships/getSyncedData",
  async () => {
    const { response, status } = await getData(QUERIES.RELATIONSHIPS.COMPANY_SYNC.GET);
    if (status === STATUSES.SUCCESS) {
      const result = whitespaceGroupingByRow(response);
      return result;
    }
  }
);

export const deletePeople = createAsyncThunk(
  "sourcing/deletePeople",
  async (tableCinchyid: string) => {
    try {
      const queryParams = {
        "@cinchyid": tableCinchyid,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${QUERIES.RELATIONSHIPS.PEOPLE_DELETE}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete people.");
    }
  }
);

export const deleteCustomers = createAsyncThunk(
  "sourcing/deleteCustomers",
  async (tableCinchyid: string) => {
    try {
      const queryParams = {
        "@cinchyid": tableCinchyid,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${QUERIES.RELATIONSHIPS.CUSTOMERS_DELETE}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete customers.");
    }
  }
);

interface InsertPeopleParams {
  fpcadvisoryboard: string;
  fpcemployee: string;
  personurl: string;
  fullName: string;
  notes: string
}

export const insertPeople = createAsyncThunk(
  "sourcing/insertPeople",
  async (data) => {
    try {
      // @ts-ignore
      const queryString = new URLSearchParams(data).toString();
      const url = `${QUERIES.RELATIONSHIPS.PEOPLE_ADD}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete financials.");
    }
  }
);

interface InsertFPCContactParams {
  fpccontact: string;
  peoplecinchyid: string;
}

export const insertFPCContact = createAsyncThunk(
  "sourcing/insertPeople",
  async (data: InsertFPCContactParams) => {
    try {
      const queryParams = {
        "@fpccontact": data["fpccontact"],
        "@peoplecinchyid": data["peoplecinchyid"],
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${QUERIES.RELATIONSHIPS.FPC_CONTACT_ADD}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to insert contacts.");
    }
  }
);

interface UpdatePeopleParams {
  fpcadvisoryboard: string;
  fpcemployee: string;
  cinchyid: string;
  fullName: string;
  notes: string
}

export const updatePeople = createAsyncThunk(
  "sourcing/updatePeople",
  async (data: UpdatePeopleParams) => {
    try {
      // @ts-ignore
      const queryString = new URLSearchParams(data).toString();
      const url = `${QUERIES.RELATIONSHIPS.PEOPLE_UPDATE}?${queryString}`;

      const { response, status } = await getRelationshipData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete financials.");
    }
  }
);

export const getInvestors = createAsyncThunk(
  'sourcing/getInvestors',
  async (columns: string[], { dispatch }) => {
      const url = QUERIES.RELATIONSHIPS.INVESTORS.GET;

      const { response, status }  = await getData(url, dispatch);
      if (status === STATUSES.SUCCESS) {
          const data = changeDataShape(response);
          
          const filteredData = selectNecessaryColumns(data, columns);
          return filteredData;
      }
  }
);

export const updateInvestor = createAsyncThunk(
  "sourcing/updateInvestor",
  async (data: UpdateInvestorParams) => {

    const queryParams = {
      "@cinchyid": String(data['cinchyid']),
      "@linkedin": data['linkedin'],
      "@notes": data['notes'],
      "@target": String(data['target']),
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${QUERIES.RELATIONSHIPS.INVESTORS.UPDATE}?${queryString}`;

    const { response, status } = await getRelationshipData(url);
    if (status === STATUSES.SUCCESS) {
      return response;
    }
  }
);

interface InsertCustomerParams {
  arr: string;
  asofdate: string;
  championtitle: string;
  companyid: string;
  competitors: string;
  customerlinkedin: string;
  customername: string;
  replaced: string;
  usecase: string;
}

export const insertCustomer = createAsyncThunk(
  "sourcing/insertCustomer",
  async (data: InsertCustomerParams) => {
    try {
      const queryParams = {
        "@arr": data["arr"],
        "@asofdate": data["asofdate"],
        "@championtitle": data["championtitle"] || "",
        "@companyid": data["companyid"],
        "@competitors": data["competitors"] || "",
        "@customerlinkedin": data["customerlinkedin"] || "",
        "@customername": data["customername"] || "",
        "@replaced": data["replaced"] || "",
        "@usecase": data["usecase"] || "",
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${QUERIES.RELATIONSHIPS.CUSTOMERS_ADD}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete financials.");
    }
  }
);

interface UpdateCustomerParams {
  arr: string;
  asofdate: string;
  champion: string;
  cinchyid: string;
  competitors: string;
  linkedinid: string;
  customername: string;
  replaced: string;
  usecase: string;
}

export const updateCustomer = createAsyncThunk(
  "sourcing/updateCustomer",
  async (data: UpdateCustomerParams) => {
    try {
      const queryParams = {
        "@arr": data["arr"],
        "@asofdate": data["asofdate"],
        "@champion": data["champion"] || "",
        "@cinchyid": data["cinchyid"],
        "@competitors": data["competitors"] || "",
        "@linkedinid": data["linkedinid"] || "",
        "@customername": data["customername"] || "",
        "@replaced": data["replaced"] || "",
        "@usecase": data["usecase"] || "",
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${QUERIES.RELATIONSHIPS.CUSTOMERS_UPDATE}?${queryString}`;

      const { response, status } = await getData(url);
      if (status === STATUSES.SUCCESS) {
        return response.payload;
      }
    } catch (error) {
      throw new Error("Failed to delete financials.");
    }
  }
);
