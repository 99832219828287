import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { whitespaceGroupingByRow } from '../../utils/TGroping';

export const getOpportunitiesKanban = async ({dispatch}) => {
    const {response, status} = await getData(QUERIES.OPPORTUNITIES.KANBAN, dispatch);
    if (status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response)
    }
}

export const deleteOpportunity = async ({ dispatch, tableCinchyId }) => {
    const { status} = await getData(`${QUERIES.OPPORTUNITIES.DELETE}?%40tablecinchyid=${tableCinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

export const addOpportunity = async ({ dispatch, params }) => {
    const { companyName, series, status,  fpcInvestment, roundSize, valuation } = params;
    const { status: reqStatus} = await getData(`${QUERIES.OPPORTUNITIES.INSERT}?%40companyname=${companyName}&%40fpcinvestment=${fpcInvestment}&%40roundsize=${roundSize}&%40series=${series}&%40status=${status}&%40valuation=${valuation}`, dispatch);
    if (reqStatus === STATUSES.SUCCESS) {
        return status;
    }
}

const DEFAULT_PARAMS = { tableCinchyId: '', fpcInvestment: '', series: '', status: '', roundSize: '', valuation: '' };
export const updateOpportunity = async ({ dispatch, params = DEFAULT_PARAMS }) => {
    const { tableCinchyId, series, status,  fpcInvestment, roundSize, valuation } = { ...DEFAULT_PARAMS, ...params };
    const { status: reqStatus} = await getData(`${QUERIES.OPPORTUNITIES.UPDATE}?%40fpcinvestmen=&%40tablecinchyid=${tableCinchyId}&%40fpcinvestment=${fpcInvestment}&%40roundsize=${roundSize}&%40series=${series}&%40pipelinestatus=${status}&%40valuation=${valuation}`, dispatch);
    if (reqStatus === STATUSES.SUCCESS) {
        return status;
    }
}