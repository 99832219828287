import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {Grid, List, ListItem, Paper} from '@material-ui/core';

import WhitespaceCategoryButtons from '../WhitespaceCategoryButtons';
import { TAB_CATEGORIES } from '../../constants';
import { makeStyles } from "@material-ui/core/styles";

import { useSideBarWidth } from '../../hooks/useSideBarWidth';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    border0: {
        borderRadius: 0,
    },
}));

const tabs = {
    sourcing: [
    {
        name: 'Coverage',
        pathname: 'coverage',
        id: 2
    },
    {
        name: 'Companies',
        pathname: 'companies',
        id: 9
    },
    {
        name: 'Financings',
        pathname: 'financings',
        id: 6,
    },
    {
        name: 'Hygiene',
        pathname: 'hygiene',
        id: 4,
    },
    // {
    //     name: 'Whitespace',
    //     pathname: 'whitespace',
    //     id: 1
    // },
    {
        name: 'Opportunities',
        pathname: 'opportunities',
        id: 5,
    },
    {
        name: 'Circleback Notes',
        pathname: 'circleback-notes',
        id: 3,
    },
    ],
    relationships: [{
        name: 'People',
        pathname: 'people',
        id: 0
    },{
        name: 'Investors',
        pathname: 'investors',
        id: 1,
    }, { name: 'Customers',
        pathname: 'customers',
        id: 2
    }
    ],
    dashboard: [{
        name: 'Personal',
        pathname: 'personal',
        id: 0
    },{
        name: 'Forgepoint',
        pathname: 'forgepoint',
        id: 1,
    }
    ]
}

export default function ForgePontTabs({ mainPath, children }) {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(tabs[mainPath][0].id);
    const [category, setCategory] = useState(null);
    const { pathname } = history.location;
    const classes = useStyles();
    
    const subTabsVisible = useMemo(
        () => TAB_CATEGORIES.includes(category) && !pathname.includes("details"),
        [category, pathname])

    useEffect(() => {
        const activeTabName = pathname?.split('/')?.[2];
        const activeTabId = tabs[mainPath].find(item => item.pathname === activeTabName)?.id || tabs[mainPath][0].id
        setActiveTab(activeTabId)
        setCategory(activeTabName)
    }, [pathname])

    const navigate = (item) => {
        if (activeTab !== item.id) {
            const newPath = `/${mainPath}/${item.pathname}`;
            history.replace(newPath);
        }
    };

    const sideBarWidth = useSideBarWidth();

    return (
        <>
            <Grid container spacing={10}>
                <Grid item lg={12}>
                    <Paper
                        className={`${classes.border0} shadow-xxl p-3 height-100-vh`}
                        // className={`${classes.border0} shadow-xxl p-3 height-100-vh fixed`}
                        style={{ width: `calc(100vw - ${sideBarWidth}px)` }}
                    >
                        <List component="div" className="nav-line d-flex nav-tabs-primary">
                            {tabs[mainPath].map(item =>
                                <ListItem
                                    key={item.id}
                                    button
                                    disableRipple
                                    selected={activeTab === item.id}
                                    onClick={() => navigate(item)}>
                                    <span>{item.name}</span>
                                    <div className="divider" />
                                </ListItem>)}
                        </List>
                        {/*//TODO change after demo (as this is grdon)*/}
                        {subTabsVisible && <WhitespaceCategoryButtons category={category} mainPath={mainPath}/>}
                        {pathname.includes('tagging-details') && <WhitespaceCategoryButtons category={category} />}
                        {children}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
