import React from 'react';
import { CSVLink } from 'react-csv';

interface ExportCSVProps {
    data: Record<string, any>[];
    headers: { label: string; key: string }[];
    filename: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({
    data,
    headers,
    filename,
    children,
}) => {
    return (
        <CSVLink
            data={data}
            headers={headers}
            filename={filename}
        >
           {children}
        </CSVLink>
    );
};

export default ExportCSV;
