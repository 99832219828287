import React, {useEffect} from 'react';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: 38,
        outline: '0 !important',
        position: 'relative',
        backgroundColor: 'hsl(0, 0%, 100%)',
        borderColor: 'hsl(0, 0%, 80%)',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        marginBottom: 4,
        paddingLeft: 12,
        transition: 'border-color 0.3s ease',
        "&:focus": {
            borderColor: '#6e3cfa',
        },
    },
}));

const OpenTextSearch = (props) => {
    const classes = useStyles();
    return (
        <div>
            <label className='font-16 mb-1' htmlFor="text-search">{props.label}</label>
            <input
                className={classes.root}
                value={props.value}
                onChange={props.onChange}
                type="text" placeholder="Search"
            />
        </div>
    )
}


const FreeSearch = ({ onChange, name, label, options, isReset, ...props }) => {
    // @TODO: figure out type
    // @ts-ignore
    const [field, , helpers] = useField(props.field);
    console.log('Log ::: prpops ===', props);

    useEffect(() => {
        if(isReset) {
            helpers.setValue('');
        }
    }, [isReset]);

    return (
        <OpenTextSearch
            {...props}
            name={name}
            label={label}
            value={field.value}
            options={options}
            onChange={(e) => {
                helpers.setValue(e.target.value);
            }}
            onBlur={() => {
                helpers.setTouched(true);
                props.onBlur && props.onBlur();
            }}
        />
    );
};

export default FreeSearch;;