import React, { useState } from 'react';
import HeaderSection from './HeaderSection';
import MainSection from './MainSection';
import Benchmarks from './Benchmarks';

import styles from './styles.module.scss';

interface IKeyMetricsItem {
    [key: string]: string;
}

interface KeyMetricsProps {
    data: IKeyMetricsItem[];
}

const KeyMetrics: React.FC<KeyMetricsProps> = ({ data }) => {
    const [isBenchmarksOpen, setIsBenchmarksOpen] = useState(false);
    
    const headerKeyMetrics = {
        green: data[0]['Key Metrics Green'],
        red: data[0]['Key Metrics Red']  
    };

    return (
        <div className={styles.keyMetricsCard}>
            <HeaderSection 
                headerKeyMetrics={headerKeyMetrics}
                setIsBenchmarksOpen={setIsBenchmarksOpen}    
            />
            <MainSection 
                keyMetricsData={data}
            />
            <Benchmarks 
                isOpen={isBenchmarksOpen}
                setIsOpen={setIsBenchmarksOpen}
            />
        </div>
    );
};

export default KeyMetrics;
