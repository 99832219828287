import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExportCSV from '../ExportCSV';
import styles from '../CompaniesTableActions/styles.module.scss';


const portalRoot = document.getElementById('csv-root') || document.body;

const useStyles = makeStyles((theme) => ({
    portalContainer: {
        position: 'absolute',
        top: 50,
        right: 0,
        zIndex: 1000,
        padding: 8,
    },
}));

function CSVPortal({ cells, rows, selectedCompaniesIds }) {
    const classes = useStyles();

    const generateCSVTableHeaders = (cells) => {
        return cells?.map(cell => ({id: 'id', label: cell?.['Column Name'], key: cell?.['Column Name']}));
    };

    const generateCSVTableRows = (rows) => {
        return rows?.filter(row => selectedCompaniesIds.includes(row["Cinchy Id"]));
    };

    const portalElement = document.createElement('div');


    useEffect(() => {
        portalRoot.appendChild(portalElement);

        return () => {
            portalRoot.removeChild(portalElement);
        };
    }, [portalElement]);

    const portalContent =  <div className={classes.portalContainer}>
        <ExportCSV
            data={generateCSVTableRows(rows)}
            headers={generateCSVTableHeaders(cells)}
            filename={'companies.csv'}
        >
            <Button>
                <span className={`${styles.exportBtnLabel} font-12`}>
                    export CSV
                </span>
            </Button>
        </ExportCSV>
    </div>


    return ReactDOM.createPortal(portalContent, portalElement);
}

export default CSVPortal;