import React from 'react';
import ReactQuill from 'react-quill';

export default function LivePreviewExample({ value, setValue }) {

  return (
    <>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        placeholder="Write note here..."
      />
    </>
  );
}
