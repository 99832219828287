import React, { Fragment, useEffect, useMemo, useState} from 'react';
import { useDispatch} from 'react-redux';
import { CardContent, Table, TableCell, TableRow} from "@material-ui/core";
import { makeStyles} from '@material-ui/core/styles';

import CoveragePrioritySearch from '../../../components/CoveragePrioritySearch';
import UpdateCoveragePriority from '../../../components/UpdateCoveragePirority';
import OwnersSelect from '../../../components/OwnersSelect';
import TSkeleton from '../../../components/TSkeleton';
import OwnersSearch from '../Flags/OwnersSearch';

import { getCoveragePriorityOptions, getCoverageSummary, updateCoverageOwners} from '../../../api/coverage';
import { useOwners} from '../../../hooks/useOwners';
import { groupByCategory} from '../../../utils/TGroping';
import UniversalSearch from '../../../components/UniversalSearch';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    description: {
        maxWidth: 400,
        minWidth: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '12px',
    },
    scroll: {
        overflowY: 'scroll',
    },
    subTableColumn: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
    },
    dynamicBackgroundColor: {
        backgroundColor: (props) => props.backgroundColor,
    },
    dividerPosition: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    greyBackground: {
        backgroundColor: '#edecec',
        margin: '1px',
    }
}));

const domainCellStyle = {
    backgroundColor: '#C4C4C4',
};

const divideRowsStyle = {
    height: '1rem',
}

const categoryCellStyle = {
    backgroundColor: '#D9D9D9'
}

const secondaryCellStyle = {
    backgroundColor: '#F6F6F6'
}

const emptyCell = {
    maxWidth: '16px'
}

const largeCell = {
    minWidth: '300px'
}

const midCell = {
    minWidth: '180px'
}

const smallCell = {
    maxWidth: '90px'
}


const groupCoverage = (columns, emptyIndexes = [2]) => {
    emptyIndexes.forEach(index => {
        columns.splice(index, 0, '');
    })
    return columns;
}

function  Coverage(props) {
    const dispatch = useDispatch();
    const [coverage, setCoverage] = useState([]);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [priorityOptions, setPriorityOptions] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getCoverageSummary({ dispatch }).then(res =>  {
            setCoverage(groupByCategory(res, 'Category'));
            const columns = Object.keys(res[0]).filter(column => column !== 'Color' && column !== 'Cinchy Id');
            setColumns(groupCoverage(columns));
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        getCoveragePriorityOptions({ dispatch }).then(res => {
            setPriorityOptions(res.map(item => ({ value: item['Priority Option'], label: item['Priority Option'], ...item })))
        })
    }, [])


    useOwners();

    const filteredCoverage = useMemo(() => {
        if (selectedOwners.length === 0 && selectedPriority.length === 0) {
            return coverage;
        }
        return Object.keys(coverage).reduce((acc, category) => {
            acc[category] = coverage[category].filter(item => {
                const owners = item.Owners?.split(';')
                const ownerIncluded = owners?.some(owner => selectedOwners.includes(owner))
                const ownerMatches = selectedOwners.length > 0 ? ownerIncluded : true;
                const selectedPriorityMatches = selectedPriority.length > 0 ? selectedPriority.includes(item["Priority"]) : true;
                return ownerMatches && selectedPriorityMatches;
            })
            return acc;
        }, {})
    }, [coverage, selectedOwners, selectedPriority])


    return (
        <>
            <UniversalSearch />
            <CardContent className={`${classes.scroll} px-0 pt-2 pb-3`}>
                {loading ? <>
                    <TSkeleton />
                </> : <>
                    <div className='d-flex'>
                        <OwnersSearch selectedValues={selectedOwners} setSelectedValues={setSelectedOwners} />
                        <CoveragePrioritySearch selectedValues={selectedPriority} setSelectedValues={setSelectedPriority} options={priorityOptions} />
                    </div>
                    <Table className="table table-borderless table-hover table-alternate mb-0">
                        <thead>
                        <tr>
                            {columns.filter(item => !['Category', 'FPC Company'].includes(item)).map((column, index) => <TableCell
                                key={`${column}-${index}`}
                                style={!column ? emptyCell : column === 'Technology' ? largeCell : column === 'Coverage %' ? smallCell : {}}
                                className="text-center"
                            >
                                <div className="font-weight-bold font-12">
                                    {column}
                                </div>
                            </TableCell>)}
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(coverage).map(category => <Fragment key={category}>
                            {filteredCoverage[category].map((item, index) => <Fragment key={`${item.Category}-${Math.random()}`}>
                                <TableRow>
                                    {/*Category*/}
                                    {/*<TableCell style={domainCellStyle} className="text-center">*/}
                                    {/*    <div className="font-weight-bold text-black font-12">{item.Category}</div>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell></TableCell>*/}
                                    {/*Sub Category*/}
                                    <TableCell style={categoryCellStyle} className="text-center">
                                        <div className="font-weight-bold text-black font-12">{item['Sub Category']}</div>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    {/*Technology*/}
                                    <TableCell style={secondaryCellStyle}>
                                        <div className="d-flex justify-content-center">
                                            <span className="font-weight-bold text-black align-self-center font-12 text-center">
                                                {item['Technology']}
                                            </span>
                                        </div>
                                    </TableCell>
                                    {/*Description*/}
                                    <TableCell style={secondaryCellStyle} className="left">
                                        <span className={`text-black font-12 d-block ${classes.description}`}>{item['Category Description']}</span>
                                    </TableCell>
                                    <TableCell style={midCell} className="text-center">
                                        {/*{item['Owners']}*/}
                                        <OwnersSelect affinityId={item['Cinchy Id']} customUpdateOwners={updateCoverageOwners} ownersInfo={item['Owners'].split(';')} />
                                    </TableCell>
                                    <TableCell style={midCell} className="text-center">
                                        {/*{item['Priority']}*/}
                                        <UpdateCoveragePriority cinchyId={item['Cinchy Id']} options={priorityOptions} defaultValue={{ label: item['Priority'], value: item['Priority'] }}/>
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {item['Sweet Spot Companies']}
                                    </TableCell>
                                    <TableCell style={{color: 'white', maxWidth: '80px'}} className="text-center">
                                        <span style={{ backgroundColor: `#${item.Color}`, padding: '4px' }}>
                                            {item['Coverage %']}%
                                        </span>
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className={`${classes.greyBackground} font-weight-bold font-12 pr-2`}>
                                                {item['List of Portcos']}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={divideRowsStyle}>
                                    <TableCell></TableCell>
                                </TableRow>
                            </Fragment>)}
                        </Fragment>)}
                        </tbody>
                    </Table>
                    <div className="divider mb-3" />
                    <div className="text-center">
                    </div>
                </>}
            </CardContent>
        </>
    );
}

export default Coverage;