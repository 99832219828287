import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { formatEmptyCell } from "../../../utils/utils";
import styles from "./styles.module.scss";
import {
  selectTopicInsights,
  selectTopicInsightsFunded,
  selectTopicInsightsTracking,
} from "../../../store/sourcing/sourcingSlice";
import {
  getTopicInsights,
  getTopicInsightsFunding,
  getTopicInsightsTracking,
} from "../../../store/sourcing/sourcingThunks";
import Accordion from "../../../components/Accordion";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const changeOptions = {
  null: "--",
  true: "Yes",
  false: "No",
};

function extractString(value: string) {
  // @ts-ignore
  return changeOptions[value] || value;
}

export default function TopicInsights() {
  const [inputValue, setInputValue] = useState("");
  const [trackingRows, setTrackingRows] = useState([]);
  const [trackingCells, setTrackingCells] = useState([]);
  const [totalTrackingPages, setTotalTrackingPages] = useState(1);
  const [currentTrackingPage, setCurrentTrackingPage] = useState(1);
  const [fundedRows, setFundedRows] = useState([]);
  const [fundedCells, setFundedCells] = useState([]);
  const [totalFundedPages, setTotalFundedPages] = useState(1);
  const [currentFundedPage, setCurrentFundedPage] = useState(1);

  const searchedResult = useSelector(selectTopicInsights);
  const dispatch = useDispatch();

  const { rows: trackingRowsData, cells: trackingCellsData } = useSelector(
    selectTopicInsightsTracking
  );

  const { rows: fundedRowsData, cells: fundedCellsData } = useSelector(
    selectTopicInsightsFunded
  );

  useEffect(() => {
    setTrackingRows(trackingRowsData);
    setTrackingCells(trackingCellsData);
  }, [trackingRowsData, trackingCellsData]);

  useEffect(() => {
    setFundedRows(fundedRowsData);
    setFundedCells(fundedCellsData);
  }, [fundedRowsData, fundedCellsData]);

  useEffect(() => {
    setTotalTrackingPages(Math.ceil(trackingRows?.length / 5 || 1));
  }, [trackingRows]);

  useEffect(() => {
    setTotalFundedPages(Math.ceil(fundedRows?.length / 5 || 1));
  }, [fundedRows]);

  const handleSubmit = async () => {
    //await dispatch(getTopicInsights(inputValue));
    await dispatch(getTopicInsightsTracking(inputValue));
    await dispatch(getTopicInsightsFunding(inputValue));
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const goToTrackingPage = (page: number) => {
    setCurrentTrackingPage(page);
  };

  const goToFundedPage = (page: number) => {
    setCurrentFundedPage(page);
  };

  return (
    <>
      <div className={styles.searchContainer}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className={styles.searchInput}
          placeholder="Search"
        />
        <Button
          variant="contained"
          className={styles.saveBtn}
          onClick={handleSubmit}
          disabled={!inputValue}
        >
          <span>Submit</span>
        </Button>
      </div>
      <div className={styles.searchedTextContainer}>
        <Accordion title="Why is it a Challenge?">
          <div className={styles.searchedRow}>
            <div className={styles.searchedPartContainer}>
              long text lknaksjn aksdjnak aksjndk kajsndk
            </div>
            <div className={styles.searchedPartContainer}>Recources</div>
          </div>
        </Accordion>
        <Accordion title="Competitive Landscape">
          <div className={styles.searchedRow}>
            <div className={styles.searchedPartContainer}>
              long text lknaksjn aksdjnak aksjndk kajsndk
            </div>
            <div className={styles.searchedPartContainer}>Recources</div>
          </div>
        </Accordion>
        <Accordion title="Recent News">
          <div className={styles.searchedRow}>
            <div className={styles.searchedPartContainer}>
              long text lknaksjn aksdjnak aksjndk kajsndk
            </div>
            <div className={styles.searchedPartContainer}>Recources</div>
          </div>
        </Accordion>
      </div>
      {fundedRows?.length && (
        <>
          <Typography className="mb-2 font-14 font-weight-bold">
            Established Companies
          </Typography>
          <div className="table-responsive-md mb-spacing-2-x2">
            <div className={styles.tableContainer}>
              <Table
                className={`table table-hover table-striped table-bordered ${styles.table}`}
              >
                <thead className={`${styles.tableHead} ${styles.stickyHeader}`}>
                  <tr>
                    {fundedCells?.slice(1).map((header: any, index: number) => (
                      <th
                        className={`${
                          header["label"] === "Description"
                            ? styles.largeColumn
                            : styles.column
                        } font-14`}
                        key={index}
                      >
                        {header["label"]}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fundedRows
                    ?.slice(
                      (currentTrackingPage - 1) * 5,
                      (currentTrackingPage - 1) * 5 + 5
                    )
                    ?.map((row: any, index: number) => {
                      return (
                        <tr key={index}>
                          {Object.keys(row)
                            ?.slice(1, -1)
                            .map((col: any, colIndex: number) => {
                              return (
                                <td>
                                  {formatEmptyCell(extractString(row[col]))}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <IconButton
                  disabled={currentFundedPage === 1}
                  onClick={() => goToFundedPage(currentFundedPage - 1)}
                >
                  <ArrowLeft />
                </IconButton>

                <span>
                  Page {currentFundedPage} of {totalFundedPages}
                </span>
                <IconButton
                  disabled={currentFundedPage === totalFundedPages}
                  onClick={() => goToFundedPage(currentFundedPage + 1)}
                >
                  <ArrowRight />
                </IconButton>
              </div>
            </div>
          </div>
        </>
      )}
      {trackingRows && (
        <>
          <Typography className="mb-2 font-14 font-weight-bold">
            FPC Tracking
          </Typography>
          <div className="table-responsive-md mb-spacing-2-x2">
            <div className={styles.tableContainer}>
              <Table
                className={`table table-hover table-striped table-bordered ${styles.table}`}
              >
                <thead className={`${styles.tableHead} ${styles.stickyHeader}`}>
                  <tr>
                    {trackingCells
                      ?.slice(1)
                      .map((header: any, index: number) => (
                        <th
                          className={`${
                            header["label"] === "Description"
                              ? styles.largeColumn
                              : styles.column
                          } font-14`}
                          key={index}
                        >
                          {header["label"]}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {trackingRows
                    ?.slice(
                      (currentTrackingPage - 1) * 5,
                      (currentTrackingPage - 1) * 5 + 5
                    )
                    ?.map((row: any, index: number) => {
                      return (
                        <tr key={index}>
                          {Object.keys(row)
                            ?.slice(1, -1)
                            .map((col: any, colIndex: number) => {
                              return (
                                <td>
                                  {formatEmptyCell(extractString(row[col]))}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <IconButton
                  disabled={currentTrackingPage === 1}
                  onClick={() => goToTrackingPage(currentTrackingPage - 1)}
                >
                  <ArrowLeft />
                </IconButton>

                <span>
                  Page {currentTrackingPage} of {totalTrackingPages}
                </span>
                <IconButton
                  disabled={currentTrackingPage === totalTrackingPages}
                  onClick={() => goToTrackingPage(currentTrackingPage + 1)}
                >
                  <ArrowRight />
                </IconButton>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
