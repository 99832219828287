import React, { useState } from "react";
import { Table } from "@material-ui/core";
import styles from "./styles.module.scss";
import IconButton from "@material-ui/core/IconButton";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { formatEmptyCell } from "../../utils/utils";
import Pagination from "../Pagination/Pagination";
import usePagination from "../../hooks/usePagination";

function formatLargeNumber(number: any) {
  if (!number) {
    return "";
  }
  if (number >= 1000000) {
    return `$${(number / 1000000).toFixed(1)}M`;
  } else {
    return `$${number}`;
  }
}

function formatDate(inputDate: any) {
  const quarterPattern = /^[1-4]{1}Q [1-9]{1}\d{3}$/;
  if (!quarterPattern.test(inputDate)) {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  return inputDate;
}

const OverviewTable = ({
  tableData,
  calculateTotal = null,
  currencySign = null,
  pagesDisplayed = true,
}: any) => {
  // table headers

  let headers = [] as any;
  if (tableData[0]) {
    headers = Object.keys(tableData[0]);
  }

  // table rows
  const rows = tableData.map((row: any, index: number) => (
    <tr key={index}>
      {headers.map((header: any, colIndex: number) => {
        return (
          <td className="font-12" key={colIndex}>
            {header === "Amount" ||
            header === "Total Raised" ||
            header === "Last Known Valuation" ||
            header === "Post-$ Valuation"
              ? formatEmptyCell(formatLargeNumber(row[header]))
              : header === "Date" || header === "LK Valuation Date"
              ? formatEmptyCell(formatDate(row[header]))
              : formatEmptyCell(row[header])}
          </td>
        );
      })}
    </tr>
  ));

  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows });

  // total
  let totalRow = null;
  if (calculateTotal && calculateTotal.length > 0) {
    const totals = headers.map((header: any, index: number) => {
      if (calculateTotal.includes(header)) {
        const total = tableData.reduce((acc: any, row: any) => {
          let cellValue = row[header];

          // Checking if the cell value is a string with characters, remove extra characters
          if (cellValue && typeof cellValue === "string") {
            cellValue = parseFloat(cellValue.replace(/[^\d.-]/g, ""));
          } else {
            cellValue = 0;
          }
          return acc + cellValue;
        }, 0);

        return total;
      }
      return "";
    });

    totalRow = (
      <tr className={styles.totalRow}>
        {totals.map((total: any, index: number) => {
          if (index === 0 && total === "") {
            return (
              <td className="font 12" key={index}>
                Total
              </td>
            );
          }

          return (
            <td className="font 12" key={index}>
              {total !== ""
                ? `${currencySign ? currencySign : ""}${total}`
                : ""}
            </td>
          );
        })}
      </tr>
    );
  }

  const rowsToShow = rows.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)

  return (
    <>
      <div className="mt-3 table-responsive-md mb-spacing-2-x2">
        <Table className="table table-hover table-striped table-bordered">
          <thead className={styles.tableHead}>
            <tr>
              {headers.length ? (
                headers.map((header: any, index: number) => (
                  <th className="font-12" key={index}>
                    {header}
                  </th>
                ))
              ) : (
                <th className={styles.emptyHeader}></th>
              )}
            </tr>
          </thead>
          <tbody>
            {rowsToShow}
            {totalRow}
          </tbody>
        </Table>
        {pagesDisplayed && (
          <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
          handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        )}
      </div>
    </>
  );
};

export default OverviewTable;
