import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardContent, Checkbox, Table, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { selectLoading } from '../../../../store/sourcing/sourcingSlice';
import TSkeleton from '../../../../components/TSkeleton';
import { getWhitespaceCompaniesByCategory } from '../../../../store/sourcing/whitespace/whitespaceThunks';
import { selectWhiteSpaceByCategory } from '../../../../store/sourcing/whitespace/whitespaceSelectors';
import HeaderActions from '../Common/HeaderActions';
import { CompaniesInvestment, SubTableBody, SubTableHead } from '../Common/CellRenderers';
import { colorMapping } from '../configs';
import {parseLocationSearch, stringifyObject} from "../../../../helpers/string.helper";
import {produce} from "immer";
import {useHistory, useLocation} from "react-router-dom";
import {getTaxonomyData} from "../../../../store/filter/filterThunks";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    description: {
       maxWidth: 400,
       minWidth: 400,
       overflow: 'hidden',
       textOverflow: 'ellipsis',
       fontSize: '12px',
    },
    scroll: {
        overflowY: 'scroll',
    },
    subTableColumn: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
    },
    dynamicBackgroundColor: {
        backgroundColor: (props) => props.backgroundColor,
    },
    dividerPosition: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    greyBackground: {
        backgroundColor: '#edecec',
        margin: '1px',
    }
}))

const divideRowsStyle = {
    height: '16px',
}

const domainCellStyle = {
    backgroundColor: '#C4C4C4'
};


const tableRowStyle = {
   backgroundColor: 'white',
}

const secondaryCellStyle = {
    backgroundColor: '#F6F6F6'
}

const emptyCell = {
    maxWidth: '16px'
}

const largeCell = {
    minWidth: '300px'
}

const TMainCategory = () => {
    const [selectedTechnologies, setSelectedTechnologies ] = useState(new Set());
    const [taxonomyData, setTaxonomyData ] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const changeOptions = useCallback(
        (esim, params = { path: '/sourcing/companies/company' }) => {
        const currentOptions = params?.isNewOption ? {} : parseLocationSearch(location.search);


            const newOptions = { technology: taxonomyData.reduce((acc, item) => {
            if(selectedTechnologies.has(item.Category)) {
                acc.push(item.Technology);
            }
            return acc;

            }, [])
        };



        const { editId } = newOptions;

        const resultOptions = produce(currentOptions, (draft) =>
            Object.assign(draft, {
                editId,
                ...newOptions.filterOptions,
                ...newOptions.sorterOptions,
            }),
        );

            const isEmptyArray = Object.entries({ ...newOptions }).reduce((acc, [key, value]) => {
            const isItemNotEmptyArray = Array.isArray(value) && !value?.length;

            if (isItemNotEmptyArray || value === undefined) {
                return acc;
            }
            acc[key] = value;
            return acc;
        }, {});

        const search = `${params?.path ?? ''}?${stringifyObject(isEmptyArray)}`;
        history.push(search);
    },
    [location.search, history, taxonomyData, selectedTechnologies],
);

    useEffect(() => {
        dispatch(getWhitespaceCompaniesByCategory())
    }, [])

    const {  columns, rows } = useSelector(selectWhiteSpaceByCategory);

    const modifiedSubTable = useMemo(() => {
        const columnNames = columns?.[4]?.columnName.split(',');
        return columnNames?.reduce((current, item, index) => {
            current[item.trimStart()] = index;
            return current;
        }, {})
        // columns['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos'];

    }, [columns])

    useEffect(() => {
        getTaxonomyData().then(data => {
            setTaxonomyData(data);
        })
    }, []);

    console.log('setTaxonomyData', selectedTechnologies);

    const handleCheckboxChange = useCallback((category) => {
        setSelectedTechnologies((prevSelectedTechnologies) => {
            const newSelectedTechnologies = new Set(prevSelectedTechnologies);
                if (newSelectedTechnologies.has(category)) {
                    newSelectedTechnologies.delete(category);
                } else {
                    newSelectedTechnologies.add(category);
                }
                return newSelectedTechnologies;
        });
    }, []);
    

    const loading = useSelector(selectLoading);

    return (!columns.length ? <TSkeleton></TSkeleton> : <>
        <HeaderActions withKeywords={false}  changeOptions={changeOptions}/>
        <div className="divider"></div>
        <CardContent className={`${classes.scroll} px-0 pt-2 pb-3`}>
            {loading ? <TSkeleton/> : <>
                <Table className="table table-borderless table-hover table-alternate mb-0">
                    <thead>
                    <TableRow>
                        {columns.map(({ columnName }, index) => <TableCell
                            key={`${columnName}-${index}`}
                            style={index === 1 || !columnName ? emptyCell : index === 7 ? largeCell : {}}
                            className={`${columnName === 'Category' ? "text-left" : "text-center"}`}>
                            <div className="px-4 font-weight-bold font-12">
                                {index === 4 ? <SubTableHead /> : columnName}
                            </div>
                        </TableCell>)}
                    </TableRow>
                    </thead>
                    <tbody>
                    {Object.keys(rows).map(domain => <Fragment key={domain}>
                        {rows[domain].map((item, index) => <Fragment key={`${item['Category Description']}-${index}`}>
                            <TableRow style={tableRowStyle}>
                                {/*Domain*/}
                                <TableCell style={domainCellStyle} className="text-center">
                                    <div className="font-weight-bold text-black font-12">{item.Domain}</div>
                                </TableCell>
                                <TableCell></TableCell>
                                {/*Category*/}
                                <TableCell style={secondaryCellStyle}>
                                    <div className="d-flex">
                                        <Checkbox
                                            color="primary"
                                            id={`checkbox-${item.Category}`}
                                            className="align-self-center mr-2"
                                            checked={selectedTechnologies.has(item.Category)}
                                            onChange={() => handleCheckboxChange(item.Category)}
                                        />
                                        <span className="font-weight-bold text-black align-self-center font-12">
                                        {item.Category}
                                    </span>
                                    </div>
                                </TableCell>
                                {/*Description*/}
                                <TableCell style={secondaryCellStyle} className="left">
                                    <span className={`d-block text-black ${classes.description}`}>{item['Category Description']}</span>
                                </TableCell>
                                <TableCell className="text-center">
                                    <SubTableBody modifiedSubTable={modifiedSubTable} item={item}/>
                                </TableCell>
                                <TableCell style={{backgroundColor: colorMapping[item.Color]}} className="text-center">
                                </TableCell>
                                <CompaniesInvestment
                                    totalCCount={item['Total Companies Count']}
                                    totalInvestedCount={item['Total Invested Count']}
                                />
                                {/*Investment*/}
                                <TableCell className="text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className={`${classes.greyBackground} font-weight-bold font-12`}>
                                            {/*{' '}*/}
                                            {item['List of Portcos']}
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow style={divideRowsStyle}>
                                <TableCell style={item.Domain === rows[domain]?.[index+1]?.Domain ? domainCellStyle : {}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={item.Category === rows[domain]?.[index+1]?.Category ? secondaryCellStyle : {}}></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </Fragment>)}
                    </Fragment>)}
                    </tbody>
                </Table>
                <div className="divider mb-3" />
                <div className="text-center">
                    {/*// TODO CF => EL add pagination*/}
                </div>
            </>}
        </CardContent>
    </>);
};

export default TMainCategory;