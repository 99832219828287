import { AsYouType } from 'libphonenumber-js';
import moment from 'moment';
import numberFormat from 'format-number';
import humps from 'humps';
import hyLocale from 'date-fns/locale/hy';
import enLocale from 'date-fns/locale/en-US';
import {
  LANGUAGES,
  DEFAULT_DATE_FORMAT,
  MATERIAL_UI_DATE_FORMAT,
  ISO_9601_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  LANG,
  lastPathExpirationMinutes,
} from '../constants';
import { TagOption } from '../interfaces';

export const reformatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }

  return new AsYouType('AM').input(phoneNumber);
};

export const reformatDate = (
  date: string,
  dateFormat: string,
  formatedDateFormat: string = DEFAULT_DATE_FORMAT
): string => moment(date, dateFormat).format(formatedDateFormat);

export const reformatClosedDate = (date: string): string =>
  moment(date, ISO_9601_DATE_FORMAT).format(DEFAULT_DATE_TIME_FORMAT);

export const reformatNumber = (
  num: number | string | null,
  fixedPoint: number | null = null
): string => {
  if (num == null) {
    return '';
  }

  let n = +num;

  if (fixedPoint) {
    n = +n.toFixed(fixedPoint);
  }

  return numberFormat()(n);
};

export const getISO9601Date = (date: string): string =>
  moment(date, MATERIAL_UI_DATE_FORMAT).utc().format(ISO_9601_DATE_FORMAT);

export const roundUpNumber = (num: number | string | null): number => {
  if (!num) {
    return NaN;
  }

  return Math.ceil(+num);
};

export const reformatNumberWithCeil = (num: number | string | null): string =>
  reformatNumber(roundUpNumber(num));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const camelizeKeys = (data: object | object[]): any =>
  humps.camelizeKeys(data);

export const decamelize = (data: string): string => humps.decamelize(data);

export const camelize = (data: string): string => humps.camelize(data);

export const decamelizeKeys = (data: object | object[]): object =>
  humps.decamelizeKeys(data);

export function sliceItemsFunc<T>(items: T[], step: number): T[] {
  return [...items].slice(0, DEFAULT_PAGE_SIZE * step);
}
export const showMoreIsActive = (itemCount: number, step: number): boolean =>
  step < Math.ceil(itemCount / DEFAULT_PAGE_SIZE);

export const urlParamsToObject = (params: string[]): object =>
  Object.assign(
    {},
    ...params.map((param) => JSON.parse(`{"${param.replace(/=/g, '":"')}"}`))
  );

export const getActiveLocale = (): Locale =>
  localStorage.getItem(LANG) === LANGUAGES.EN ? enLocale : hyLocale;

export const getClipBoardContent = (id: string): string =>
  document.getElementById(id)?.innerText as string;

export const slicePhoneNumberPlus = (phoneNumber = ''): string =>
  phoneNumber.replace('+', '');

export const removeDublicateTags = (tags: TagOption[]) => {
  const uniqueTags = [];
  const seenProperties: string[] = [];

  for (let tag of tags) {
    if (!seenProperties.includes(tag['Tag Type'])) {
      uniqueTags.push(tag);
      seenProperties.push(tag['Tag Type']);
    }
  } 

  return uniqueTags;
} 

export const setLastPath = (): void => {
  const now = new Date();
  const expirationDate = new Date(now.getTime() + lastPathExpirationMinutes * 60000);
  const item = {
    value: localStorage.getItem("path"),
    expirationDate: expirationDate.toISOString()
  };
  localStorage.setItem("lastPath", JSON.stringify(item));
};

export const getLastPath = () => {
  const itemString = localStorage.getItem("lastPath");
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const now = new Date();
  const expirationDate = new Date(item.expirationDate);
  if (now >= expirationDate) {
    localStorage.removeItem("lastPath");
    return null;
  }
  
  return item.value;
};
