import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import RoutesWrapper from './RoutesWrapper';
import Notification from './components/Notification';
import ScrollToTop from './utils/ScrollToTop';
import store from './store';
import i18n from './i18n';

import './utils/Icons';
import './assets/base.scss';
import * as Sentry from "@sentry/react";

const App = (): ReactElement => (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Notification />
          <BrowserRouter basename="/">
            <ScrollToTop>
              <RoutesWrapper />
            </ScrollToTop>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </Sentry.ErrorBoundary>
);

export default App;
