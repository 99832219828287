import React, {useEffect} from 'react';
import { useCinchyUser } from '../../hooks/useCinchyUser';
import { Redirect } from 'react-router';
import {setToken} from "../../helpers/auth";

function Auth() {

    const userData = useCinchyUser();

    if(userData) {
        setToken(userData.access_token);
        let lastPathInfo = JSON.parse(localStorage.getItem('lastPath'));
        console.log('Log ::: access_token retrieved');
        return <Redirect to={`${lastPathInfo?.value || '/'}`} />;
    }

    return (
        <div>
            ...Loading
        </div>
    );
}

export default Auth;