import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

import TableHead from './TableHead';
import Toolbar from './Toolbar';

import { useTableHeight } from '../../hooks/useTableHeight';

import { paginationItemHeight } from '../TCompaniesByCompany/styles';
import { useStyles } from './styles';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el) => [el]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function GenericTable({ headCells = [], rows = [], title, tableActions = null, getHeadCellStyle, renderCell, getActionsCell, getCellStyle, children, fixedColumnHint, searchBar, scrollable = null }) {
    const actionsColumnRef = useRef(null);
    const classes = useStyles({ left: actionsColumnRef.current?.getBoundingClientRect?.()?.width });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const { tableContainerRef, paginationItemWidth, tableOffsetFromTop } = useTableHeight();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <>
            <div className="mb-spacing-6-x2">
                <Paper className={classes.paper} style={{ maxWidth: `calc(100vw - ${paginationItemWidth}px)` }}>
                    <Toolbar numSelected={selected.length} title={title}>
                        {tableActions}
                    </Toolbar>
                    {searchBar && searchBar}
                    <TableContainer ref={tableContainerRef} style={{ minHeight: !scrollable && `calc(100vh - ${tableOffsetFromTop}px - ${paginationItemHeight}px)`, maxHeight: !scrollable && `calc(100vh - ${tableOffsetFromTop}px - ${paginationItemHeight}px)`}}>
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='small'
                            aria-label="enhanced table">
                            <TableHead
                                fixedColumnHint={fixedColumnHint}
                                headCells={headCells}
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                getHeadCellStyle={getHeadCellStyle}
                                actionsColumn={getActionsCell ? true : false}
                            />
                            <TableBody>
                                {/*{stableSort(rows, getComparator(order, orderBy))*/}
                                {/*  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                                {rows.map((row, rowIndex) => {
                                    const isItemSelected = isSelected(row.name);

                                    return (
                                        <TableRow
                                            // onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                            className={classes.tableRow}
                                        >
                                            {/*<TableCell*/}
                                            {/*    className={clsx(classes.fixedCell, classes.whiteBackground)}*/}
                                            {/*    padding="checkbox"*/}
                                            {/*>*/}
                                            {/*  <Checkbox className={classes.checkbox} checked={isItemSelected} />*/}
                                            {/*</TableCell>*/}
                                            {getActionsCell && <TableCell
                                                ref={actionsColumnRef}
                                                className={clsx(classes.fixedCell, classes.whiteBackground)}
                                                // padding="checkbox"
                                            >
                                                {getActionsCell?.({row})}
                                            </TableCell>}
                                            {Object.keys(row).filter(key => headCells.includes(key)).map((cell, index) => <TableCell key={cell} style={getCellStyle?.(cell, rowIndex)} className={clsx(classes.tableCell, index === 0 && tableActions && classes.secondColumn)}>{renderCell(cell, row, rowIndex)}</TableCell>)}
                                        </TableRow>
                                    );
                                })}
                                {/*{emptyRows > 0 && (*/}
                                {/*  <TableRow style={{ height: (53) * emptyRows }}>*/}
                                {/*    <TableCell colSpan={6} />*/}
                                {/*  </TableRow>*/}
                                {/*)}*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {children}
                </Paper>
            </div>
        </>
    );
}
