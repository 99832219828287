import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { MoonLoader } from 'react-spinners';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ModalForm from '../ModalForm';
import {
  createResearch,
  getListOfTechnologies,
  getListOfCompanies,
  getResearchId,
  taggingResearchByTechnology,
  taggingResearchByCompany,
  updateResearch,
} from '../../store/sourcing/sourcingThunks';
import { selectListOfTechnologies, selectListOfCompanies } from '../../store/sourcing/sourcingSlice';
import { TechnologyTag, CompanyItem, CompanyResearch, ResearchData } from '../../interfaces';
import DataTree from '../DataTree';
import * as NotificationHelper from '../../helpers/notification';

import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: '#ffffff',
    fontSize: "1.2rem",
    padding: "0.7rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
  title: {
    fontWeight: 700,
    color: '#3b3e66',
  },
  radioBtn: {
    color: '#6e3cfa !important',
  },
  checkBtn: {
    color: '#6e3cfa !important',
    transform: 'scale(1.2)',
  },
  inputField: {
    minWidth: '270px',
  },
}));

interface AddResearchModalProps {
  isOpen: boolean;
  mode: 'create' | 'edit',
  setIsOpen: (isOpen: boolean) => void;
  researchData?: ResearchData;
}

type TechnologyTreeItem = {
  tech: string,
  checked: boolean,
};

const AddResearchModal: React.FC<AddResearchModalProps> = ({
  isOpen = true,
  mode = 'create',
  setIsOpen,
  researchData,
}) => {
  const classes = useStyles();

  const [titleValue, setTitleValue] = useState("");
  const [authorValue, setAuthorValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [technologiesTree, setTechnologiesTree] = useState<any>({});
  const [type, setType] = useState('Market Map');
  const [current, setCurrent] = useState(false);
  const [owner, setOwner] = useState("");

  const [companyArray, setCompanyArray] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const [error, setError] = useState({
    title: false,
    date: false,
  });

  const [init, setInit] = useState(false);

  const listOfTechnologies: TechnologyTag[] = useSelector(selectListOfTechnologies);
  const listOfCompanies: CompanyItem[] = useSelector(selectListOfCompanies);

  const dispatch = useDispatch();

  const handleSave = () => {
    setError({ title: !Boolean(titleValue), date: !Boolean(dateValue) });

    if (!titleValue || !dateValue) {
      return;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setIsRequestPending(true);

    const research: CompanyResearch = {
      author: authorValue,
      current: current,
      date: moment(dateValue).format("YYYY-MM-DD"),
      owner: owner,
      title: titleValue,
      type: type,
    };

    let selectedTechnologies: string[] = [];

    // Retrieve checked technologies 
    Object.values(technologiesTree).forEach((category: any) => {
      Object.values(category.data).forEach((subCategory: any) => {
        Object.values(subCategory.data as TechnologyTreeItem[]).forEach((tech) => {
          if (tech.checked) {
            selectedTechnologies.push(tech.tech)
          }
        })
      })
    })

    let createdResearch;

    try {
      if (mode === 'create') {
        await dispatch(createResearch(research));
        const resultAction = await dispatch(getResearchId({
          date: moment(dateValue).format("YYYY-MM-DD"), title: titleValue
        }));
        createdResearch = (resultAction as any).payload;

      } else if (mode === 'edit') {
        await dispatch(updateResearch({ ...research, cinchyid: researchData!['Cinchy Id'] }));
        createdResearch = [{'Cinchy Id': researchData!['Cinchy Id']}];
      }

      // tagging by technology
      if (selectedTechnologies.length && createdResearch.length) {

        const researchId = (createdResearch as any)[0]['Cinchy Id'];
        const dispatchPromises = selectedTechnologies.map(async (technology) => {
          try {
            const result = await dispatch(taggingResearchByTechnology({
              researchId,
              technology,
            }));
            return result;
          } catch (error) {
            setIsRequestPending(false);
            NotificationHelper.show(`Error dispatching add technology for research id ${researchId}:`, 'error');
            throw error;
          }
        });

        await Promise.all(dispatchPromises);
      }

      // tagging by company
      if (selectedCompanies.length && createdResearch.length) {
        const researchId = (createdResearch as any)[0]['Cinchy Id'];
        const dispatchPromises = selectedCompanies.map(async (companyname) => {
          try {
            const result = await dispatch(taggingResearchByCompany({
              researchId,
              companyname,
            }));
            return result;
          } catch (error) {
            setIsRequestPending(false)
            NotificationHelper.show(`Error dispatching add technology for research id ${researchId}:`, 'error');
            throw error;
          }
        });

        await Promise.all(dispatchPromises);
      }

    } catch (error) {
      setIsRequestPending(false)
      console.log('Creating research error');
    }

    setIsRequestPending(false)
    handleClose();

  };

  const handleClose = () => {
    setTitleValue("");
    setAuthorValue("");
    setDateValue("");
    setTechnologiesTree({});
    setType('Market Map');
    setCurrent(false);
    setCompanyArray([]);
    setSearchTerm("");
    setSuggestions([]);
    setSelectedCompanies([]);
    setIsOpen(false);
    setInit(false);
    setOwner("");
  };

  const handleTitleChange = (event: any) => {
    setTitleValue(event.target.value);
    setError({ ...error, title: false });
  };

  const handleAuthorChange = (event: any) => {
    setAuthorValue(event.target.value);
  };

  const handleDateChange = (event: any) => {
    setDateValue(event.target.value);
    setError({ ...error, date: false });
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  const handleChangeCurrent = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrent(event.target.checked);
  };

  const handleInputChange = (e: any) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSelectCompany = (company: any) => {
    if (!selectedCompanies.includes(company)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
    const filteredCompanies = companyArray.filter((c: any) => company !== c);
    const filteredSuggestions = suggestions.filter((c: any) => company !== c);
    setSuggestions(filteredSuggestions);
    setCompanyArray(filteredCompanies as any);
  };

  const handleRemoveClick = (companyToRemove: any) => {
    const updatedSelectedCompanies = selectedCompanies.filter(
      (company: any) => company !== companyToRemove
    );
    setCompanyArray([...companyArray, companyToRemove]);
    setSelectedCompanies(updatedSelectedCompanies);
  };

  const createTechnologyTree = (technologyList: TechnologyTag[]) => {
    const nestedObject: any = {};

    technologyList.forEach((item) => {
      const { Category, 'Sub Category': SubCategory, Tech } = item;

      if (!nestedObject[Category]) {
        nestedObject[Category] = { data: {}, checked: false };
      }

      if (!nestedObject[Category].data[SubCategory]) {
        nestedObject[Category].data[SubCategory] = { data: [], checked: false };
      }

      nestedObject[Category].data[SubCategory].data.push({ tech: Tech, checked: false });
    });

    return nestedObject;
  };

  const handleCheckTechnology = (event: any, level: number, key1: string, key2?: string, key3?: string) => {
    const updated = { ...technologiesTree };
    const checked = event.target.checked;

    if (level === 0) {
      updated[key1].checked = checked;
      for (let key2 of Object.keys(updated[key1].data)) {
        updated[key1].data[key2].checked = checked;

        updated[key1].data[key2].data = updated[key1].data[key2].data.map((item: any) => {
          return { ...item, checked }
        })
      }
    } else if (level === 1) {
      updated[key1].data[key2 as string].checked = checked;
      updated[key1].data[key2 as string].data = updated[key1].data[key2 as string].data.map((item: any) => {
        return { ...item, checked }
      });

      if (!checked) {
        updated[key1].checked = checked;
      }

    } else if (level === 2) {
      updated[key1].data[key2 as string].data = updated[key1].data[key2 as string].data.map((item: any) => {
        if (key3 === item.tech) {
          return { ...item, checked };
        }
        return item;
      });
    }

    setTechnologiesTree(updated);
  };

  useEffect(() => {
    dispatch(getListOfTechnologies());
    dispatch(getListOfCompanies());
  }, []);

  useEffect(() => {
    if (listOfCompanies?.length) {
      const companies = listOfCompanies.map((company: any) => company.Company);
      setCompanyArray(companies);
    }
  }, [listOfCompanies]);

  useEffect(() => {
    if (listOfTechnologies.length && isOpen) {
      const tree = createTechnologyTree(listOfTechnologies);
      setTechnologiesTree(tree);
    }

    if (mode === 'edit' && researchData) {
      setTitleValue(researchData.Title);
      setDateValue(moment(researchData.Date).format("YYYY-MM-DD"));
      setType(researchData.Type);
      setCurrent(researchData.Current || false);
      setSelectedCompanies(researchData.Companies || []);
      setOwner(researchData.Owner || "");
    }
  }, [listOfTechnologies, isOpen]);

  useEffect(() => {
    if (!_.isEmpty(technologiesTree) && mode === 'edit' && researchData && researchData.Technology && !init) {

      let updatedTechnologyTree = { ...technologiesTree };
      let technologies = researchData.Technology ? researchData.Technology.split(',').map(tech => tech.trim()) : [];
      let categoryList = researchData.Category ? researchData.Category.split(',').map(cat => cat.trim()) : [];
      let subCategoryList = researchData["Sub Category"] ? researchData["Sub Category"].split(',').map(subCat => subCat.trim()) : [];

      Object.entries(updatedTechnologyTree).forEach(([categoryKey, category]: any) => {
        if (categoryList.includes(categoryKey)) {
          category.checked = true;
        }
        Object.entries(category.data).forEach(([subCategoryKey, subCategory]: any) => {
          if (subCategoryList.includes(subCategoryKey)) {
            subCategory.checked = true;
          }
          Object.entries(subCategory.data).forEach(([techKey, tech]: any) => {
            if (technologies.includes(tech.tech)) {
              tech.checked = true;
            }
          });
        });
      });

      setTechnologiesTree(updatedTechnologyTree);
      setInit(true);
    }

  }, [technologiesTree]);

  useEffect(() => {
    let filteredCompanies: any = [];
    if (searchTerm) {
      filteredCompanies = companyArray.filter(
        (company) => company?.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }

    setSuggestions(filteredCompanies);
  }, [searchTerm]);

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <div className="border-0">
        <div className="card-body">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>Add Research / Thesis</h6>
          </div>
          <div>
            <div>
              <label className={styles.fieldTilte} htmlFor="inputField">Title</label>
            </div>
            <div>
              <TextField
                type="text"
                id="inputField"
                className={classes.inputField}
                variant="outlined"
                value={titleValue}
                margin="dense"
                onChange={handleTitleChange}
                error={error.title}
                helperText={error.title && "Title must be filled"}
              />
            </div>
          </div>

          <div className="mt-4">
            <label className={`${styles.fieldTilte} mb-2`}>Tag Technology</label>

            {!!listOfTechnologies?.length && (
              <DataTree
                tree={technologiesTree}
                handleChange={handleCheckTechnology}
              />
            )}
          </div>
          <div className={styles.searchCompaniesContainer}>
            <div className={styles.searchPartContainer}>
              <label className={styles.fieldTilte}>Tag companies</label>
              <TextField
                type="text"
                placeholder="Search for companies"
                className={classes.inputField}
                variant="outlined"
                margin="dense"
                value={searchTerm}
                onChange={handleInputChange}
              />
              {!!suggestions.length && suggestions.map((company: any, index: number) => (
                <div className={styles.suggestedCompany} key={index}>
                  <span
                    className={styles.suggestedCompanyText}
                    onClick={() => handleSelectCompany(company)}
                  >
                    {company}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.selectedCompaniesContainer}>
              {!!selectedCompanies.length && <label>Selected Companies</label>}
              {selectedCompanies.map((company: string) => (
                <div className={styles.selectedCompany}>
                  <span className={styles.companyName} key={company}>
                    {company}
                  </span>
                  <Close
                    className={styles.closeButton}
                    onClick={() => handleRemoveClick(company)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div>
              <label className={styles.fieldTilte} htmlFor="inputField">Author</label>
            </div>
            <div>
              <TextField
                type="text"
                id="inputField"
                className={classes.inputField}
                variant="outlined"
                margin="dense"
                value={authorValue}
                onChange={handleAuthorChange}
              />
            </div>
          </div>
          <div>
            <div className={styles.optionsContainer}>
              <FormControl component="fieldset" className="mt-4">
                <label className={classes.title}>Type</label>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={type}
                  onChange={handleTypeChange}
                >
                  <FormControlLabel value="Market Map" control={<Radio className={classes.radioBtn} />} label="Market Map" />
                  <FormControlLabel value="Diagram" control={<Radio className={classes.radioBtn} />} label="Diagram" />
                  <FormControlLabel value="Thought Piece" control={<Radio className={classes.radioBtn} />} label="Thought Piece" />
                  <FormControlLabel value="Industry Report" control={<Radio className={classes.radioBtn} />} label="Industry Report" />
                  <FormControlLabel value="Hype Cycle" control={<Radio className={classes.radioBtn} />} label="Hype Cycle" />
                  <FormControlLabel value="Forecast" control={<Radio className={classes.radioBtn} />} label="Forecast" />
                  <FormControlLabel value="Interview" control={<Radio className={classes.radioBtn} />} label="Interview" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="mb-2">
            <div>
              <label htmlFor="inputField" className={styles.fieldTilte}>Date</label>
            </div>
            <div>
              <TextField
                id="date"
                type="date"
                className={classes.inputField}
                margin="dense"
                value={dateValue}
                variant="outlined"
                onChange={handleDateChange}
                error={error.date ? true : false}
                helperText={error.date && "Date must be filled"}
              />
            </div>
          </div>
          <div>
            <div>
              <label className={styles.fieldTilte}>Current?</label>
            </div>
            <div>
              <Checkbox
                checked={current}
                className={classes.checkBtn}
                onChange={handleChangeCurrent}
                size="medium"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              disabled={isRequestPending}
              onClick={handleSave}
            >
              {isRequestPending ? <MoonLoader size={20} /> : 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default AddResearchModal;
