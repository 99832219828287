import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import isEqual from 'lodash/isEqual';
import { selectTagOptions } from "../../store/sourcing/sourcingSlice";
import {
  updateTags
} from "../../store/sourcing/sourcingThunks";
import Snackbar from "@material-ui/core/Snackbar";

interface TagsSelectProps {
  tagsInfo: any;
  cinchyId: string;
}

const TagsSelect = ({ cinchyId, tagsInfo }: TagsSelectProps) => {
  const [tagNames, setTagNames] = useState([]);
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([] as any);
  const [tagSelectOptions, setTagSelectOptions] = useState([] as any);

  const tagsData = useSelector(selectTagOptions);

  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleUpdateSectors = useCallback(
    async (values) => {
      if (values) {
        const finalSectors = values?.join(";");
        await dispatch(
          updateTags({ cinchyId: cinchyId, tags: finalSectors })
        )
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
    },
    [dispatch, cinchyId]
  );

  useEffect(() => {
    setTagNames(tagsData?.map((o: any) => o["Tag"]));
  }, [tagsData]);

  useEffect(() => {
    setSelectedValues(tagsInfo?.map?.((item: string) => item.trim()));
  }, [tagsInfo]);

  useEffect(() => {
    setTagSelectOptions(
      tagNames?.map((option: any) => ({
        value: option?.trim(),
        label: option?.trim(),
      }))
    );
  }, [tagNames]);
  
  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "rgba(110, 60, 250, 0.05)",
            primary: "#6e3cfa",
          },
        })}
        styles={{
          input: (providedValue) => ({
            ...providedValue,
          }),
          placeholder: (providedValue) => ({
            ...providedValue,
            margin: "0 4px",
          }),
          control: (base) => ({
            ...base,
            width: "300px",
            marginLeft: "10px",
          }),
        }}
        options={Object.values(tagSelectOptions || {})}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        placeholder="Select Tags"
        value={tagSelectOptions?.filter((option: any) =>
          selectedValues?.includes(option.value)
        )}
        onChange={(selected) => {
          const values = (selected || []).map(({ value }) => value.trim());
          setSelectedValues(values);
          handleUpdateSectors(values);
        }}
      />
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return isEqual(prevProps.tagsInfo,nextProps.tagsInfo)
};

export default React.memo(TagsSelect, areEqual);
