import React from 'react';
import { InputAdornment, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
        borderRadius: 4,
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
        }
    },
    menuItem: {
        fontSize: 12,
        fontFamily: 'Quicksand',
    }
});

const StyledInput = withStyles({
    root: {
        'label + &': {
            marginTop: '8px',
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            fontSize: 14,
            fontFamily: 'Quicksand',
            padding: '10px 26px 10px 12px',
            transition: 'background-color 300ms',
            border: 'none',
            boxShadow: 'none',
            color: 'black'
        },
    },
})(InputBase);

function SearchBar({ handleSearch, column, setColumn }) {

    const handleChange = (event) => {
        setColumn(event.target.value);
    };
    const classes = useStyles();
    return (
        <>
            <div className="d-flex w-100 pl-3 align-items-center">
                <FormControl className={classes.formControl}>
                    <Select
                        value={column}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        input={<StyledInput />}
                    >
                        <MenuItem value="" disabled className={classes.menuItem}>Select Column</MenuItem>
                        <MenuItem value="Company" className={classes.menuItem}>Company</MenuItem>
                        <MenuItem value="Investors" className={classes.menuItem}>Investors</MenuItem>
                        <MenuItem value="Acquirer" className={classes.menuItem}>Acquirer</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    size="small"
                    className="w-100 font-12"
                    classes={{ root: 'input-border-0' }}
                    id="search-tag"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                    InputProps={{
                        style: {
                            fontFamily: 'Quicksand, sans-serif',
                            fontSize: '14px',
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        </>
    );
}

export default SearchBar;