import React, { useEffect, useState } from 'react';
import { SingleSelect } from '../../../components/SingleSelect/SIngleSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMissPassLossList
} from '../../../store/sourcing/sourcingThunks';
import { selectMissPassLoss } from '../../../store/sourcing/sourcingSlice';

function MPLSelect({ handleSelect, defaultValue }) {
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMissPassLossList());
    }, [dispatch]);

    let data = useSelector(selectMissPassLoss);

    useEffect(() => {
        setOptions(data?.map(d=> {
            return { value: d["Status"], label: d["Status"] }
        }))
    }, [data]);

    return (
        <SingleSelect onChange={handleSelect} name={''} options={options} menuPlacement='bottom' defaultValue={defaultValue && {'label': defaultValue, 'value': defaultValue}}/>
    );
}

export default MPLSelect;