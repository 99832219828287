import React, { memo, useEffect } from 'react';
import { useField } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";

function Range({ onChange, name, label, isReset, selectedRange, ...props }) {
    const [field, , helpers] = useField(props.field);

    useEffect(() => {
        helpers.setValue([new Date(), new Date()])
    }, [])

    useEffect(() => {
        if(isReset) {
            helpers.setValue([new Date(), new Date()]);
        }
    }, [isReset]);

    const handleSelectMinDate = (date) => {
        helpers.setValue([date || 'N/A', field.value?.[1] || 'N/A'])
    }
    const handleSelectMaxDate = (date) => {
        helpers.setValue([field.value?.[0] || 'N/A',date || 'N/A'])
    }

    return (<div className="mt-2">
        <span className="font-14 flex-1">{label}</span>
        <div className="d-flex font-12">
            <FormControl className="mr-1">
            <DatePicker
                  handleSelectDate={(date) => handleSelectMinDate(date)}
                  selectedDate={field.value?.[0]}
            ></DatePicker>
            </FormControl>
            <FormControl  className="ml-2">
                <DatePicker
                  handleSelectDate={(date) => handleSelectMaxDate(date)}
                  selectedDate={field.value?.[1]}
            ></DatePicker>
            </FormControl>
        </div>
        </div>);
}

export default memo(Range);
