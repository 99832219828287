import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

const categories = {
    whitespace: [
        { name: 'View Taxonomy', category: 'taxonomy' },
        { name: 'by Category', category: 'category' },
        { name: 'by Sub-Category', category: 'subcategory' },
        { name: 'by Technology', category: 'technology' },
        { name: 'view tagging details', category: 'tagging-details' }
    ],
    companies: [
        { name: 'All Companies', category: 'company' },
        { name: 'Sweet Spot', category: 'sweet-spot' },
        { name: 'Last Weeks Meetings', category: 'last-weeks-meetings' },
        { name: 'Next Weeks Meetings', category: 'next-weeks-meetings' },
        { name: 'Needs Primary Contact', category: 'needs-primary-contact' },
    ],
    financings: [
        { name: 'Financings', category: 'all' },
        { name: 'Last week financing', category: 'last-week-financing' },
        { name: 'Last week M&A', category: 'last-week-ma' },
    ],
    investors: [
        { name: 'All', category: 'all' },
        { name: 'Europe', category: 'eu' },
    ]
};


const WhitespaceCategoryButtons = ({ category, mainPath }) => {
    const history = useHistory();
    const [activeCategory, setActiveCategory] = useState(categories[category]?.[1]);
    const { pathname } = history.location;

    useEffect(() => {
        const activeCategoryName = pathname?.split('/')?.[3];
        const activeTabId = categories[category]?.find(item => item.category === activeCategoryName)?.category || categories[category]?.[0]?.category;
        setActiveCategory(activeTabId)
    }, [pathname, category])

    const handleChangeCategory = (item) => {
        if (activeCategory !== item.category) {
            const newPath = `/${mainPath}/${category}/${item.category}`;
            history.replace(newPath);
        }
    };

    return <>
        <div className="d-flex pb-1">
            {(categories[category] || []).map(item => <Button
                key={item.category}
                size="small"
                onClick={() => handleChangeCategory(item)}
                className={`${activeCategory === item.category ? 'btn-neutral-primary' : ''} mr-2`}
            >
                <span className="btn-wrapper--label">{item.name}</span>
            </Button>)}
        </div>
    </>
}

export default memo(WhitespaceCategoryButtons);