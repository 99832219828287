import React, {useState} from 'react';
import {ListItem} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IDropDown {
    title: string,
    children: JSX.Element,
    isSelected?: boolean,
}

export const Dropdown = ({ title, children, isSelected }: IDropDown) => {
    const [isDropDownVisible, setDropDownIsVisible] = useState<boolean>(false)

    const toggleDropDown = () => {
        setDropDownIsVisible(!isDropDownVisible)
    }

    return (
        <div className="dropdown">
            <ListItem
                button
                selected
                onClick={() => toggleDropDown()}
                className={`pl-2 mt-2 mb-2 dropdown-toggle d-flex align-items-center justify-content-center b-radius-4 ${isSelected ? 'highlight' : ''}`}
            >
                <span className="font-14 flex-1">{title}</span>
                <div>
                    <FontAwesomeIcon
                        icon={['fas', 'angle-down']}
                        className="font-size-md"
                    />
                </div>
            </ListItem>
            {isDropDownVisible && (
                <div className="dropdown-content">
                    {children}
                </div>
            )}
        </div>
    );
};

