import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Table, TableCell, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, ArrowRight} from '@material-ui/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


import TSkeleton from '../../../components/TSkeleton/TSkeleton';
import ResearchTableActions from '../../../components/ResearchTableActions';
import AddResearchModal from '../../../components/AddResearchModal';


import {
  selectLoading,
  selectResearch,
  selectResearchColumns,
  selectResearchLoading
} from '../../../store/sourcing/sourcingSlice';
import { deleteResearch, getResearch } from '../../../store/sourcing/sourcingThunks';
import { DEFAULT_RESEARCH_COLUMNS } from '../../../constants';

import { setResearchFilterOptions } from '../../../store/filter/filterSlice';
import { useFilter } from '../../../hooks/useFilter';
import { filterCompany } from '../Companies/helper';
import { formatEmptyCell } from '../../../utils/utils';

import styles from './styles.module.scss';
import { defaultColumn, highlight, highLightColumn, tHeadStyle } from '../utils';

const notificationInitial  = {
  open: false,
  vertical: "top",
  horizontal: "center",
  toastrStyle: "",
  message: "This is a toastr/snackbar notification!",
}

function extractDate(dateTimeString: any) {
  const date = new Date(dateTimeString);
  const options = { month: 'short', day: '2-digit', year: 'numeric' };
  return  date.toLocaleDateString(undefined, options as any);
}

const changeOptions = {
  null: '--',
  true: 'Yes',
  false: 'No'
}
function extractString(value: string) {
  // @ts-ignore
  return changeOptions[value] || value;
}

export default function Research() {
  const [researchData, setResearchData] = useState([] as any);
  const [headers, setHeaders] = useState([] as any[]);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredColumns, setFilteredColumns] = useState([] as string[]);
  const [selectedColumnIndexes, setSelectedColumnIndexes] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationData, setNotificationData] = useState(notificationInitial);
  const [isEditResearchOPen, setIsEditResearchOpen] = useState(false);
  const [selectedResearchData, setSelectedResearchData] = useState<any>(null);
  const [selectedColumns, setSelectedColumns] = useState(
      JSON.parse(localStorage.getItem("selectedResearchColumns") as string) ||
      DEFAULT_RESEARCH_COLUMNS as string[]
  );

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;

  const dispatch = useDispatch();


  const { columns, rows: customers } = useSelector(selectResearch);
  const isLoading = useSelector(selectLoading);
  const researchLoading = useSelector(selectResearchLoading);
  const tableColumns = useSelector(selectResearchColumns);

  useEffect(() => {
    dispatch(getResearch(DEFAULT_RESEARCH_COLUMNS));
  }, []);

  useEffect(() => {
    if(customers.length) {
      dispatch(setResearchFilterOptions(customers));
    }
  }, [customers]);

  const { filterOptions } = useFilter({});

  const filteredRows = useMemo(() => {
    // @ts-ignore
    return Object.keys(filterOptions).length ? filterCompany(filterOptions,  customers)
        : customers
  }, [filterOptions]);


  const columnsToHighLight = useMemo(() => {
    let options = new Set(Object.keys(filterOptions));
    return filteredColumns.filter(item => options.has(item));
  }, [filterOptions, filteredColumns])

  useEffect(() => {
    if (customers.length) {
      setHeaders(columns);
      setResearchData(
          filteredRows.map((row: any) => {
            let finalRow = [] as any;
            columns.map((header: any) => finalRow.push(row[header["Column Name"].toLocaleLowerCase()]));

            return finalRow;
          })
      );
    }
  }, [customers, filteredRows?.length]);


  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterColumns = (columns: any) => {
    const selectedColumns = ['cinchy id', 'title', ...columns];
    setSelectedColumns(selectedColumns);
    localStorage.setItem('selectedResearchColumns', JSON.stringify(selectedColumns));
  };

  const handleEditResearch = (headers: string[], researchData: string[]) => {
    const data: { [key: string]: any } = {};
    headers.forEach((header: string, index: number) => {
      data[header] = researchData[index];
    })
    setSelectedResearchData(data);
    setIsEditResearchOpen(true);
  };

  const handleDeleteResearch = async (researchId: number) => {
    await dispatch(deleteResearch({ researchId }));
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(researchData?.length / rowsPerPage || 0))
  }, [rowsPerPage, researchData]);

  useEffect(() => {
    const selected = headers.filter((header: any) => {
      return selectedColumns.includes(header["Column Name"].toLowerCase());
    });
    setFilteredColumns(selected);
  }, [selectedColumns]);

  useEffect(() => {

    if (filteredColumns.length) {
      let selectedIndexes: number[] = [];
      headers.forEach((header: any, index: number) => {
        if (selectedColumns.includes(header["Column Name"].toLowerCase())) {
          selectedIndexes.push(index);
        }
      });
      setSelectedColumnIndexes(selectedIndexes);
    }
  }, [filteredColumns]);

  useEffect(() => {
    const selected = headers.filter((header: any) => {
      return selectedColumns.includes(header["Column Name"].toLowerCase());
    });
    setFilteredColumns(selected);
  }, [headers]);

  if (researchLoading) {
    return <TSkeleton />
  }

  return (
      <>
        <>
          <ResearchTableActions
              // filteredRows={filteredRows}
              // cells={cells}
              selectedColumns={selectedColumns.slice(1)}
              columns={tableColumns.slice(1)}
              handleSelectColumns={handleFilterColumns}
          />
          <div className="table-responsive-md mb-spacing-2-x2">
            <div className={styles.tableContainer}>
              <Table
                  className={`table table-hover table-bordered ${styles.table}`}
              >
                <thead className={`${styles.tableHead} ${styles.stickyHeader}`}>
                <tr>
                  {(filteredColumns.length ? filteredColumns : headers).map(
                      (header: any, index: number) =>
                           (
                              <TableCell
                                  className={`${styles.column} font-14`}
                                  // @ts-ignore
                                  style={columnsToHighLight.includes(header["Column Name"]) ? highlight : tHeadStyle}
                                  key={index}
                              >
                                {header["Column Name"]}
                              </TableCell>
                          )
                  )}
                  <th id="actions" className={styles.actionsColumn}>
                    Actions
                  </th>
                </tr>
                </thead>
                  <tbody>
                  {researchData?.slice(
                      (currentPage - 1) * rowsPerPage,
                      (currentPage - 1) * rowsPerPage + rowsPerPage
                  )?.map((row: any, index: number) => {
                    return (
                        <tr key={index}>
                          {row?.filter?.((item: any, index: number) => selectedColumnIndexes.includes(index))
                              ?.map((col: any, colIndex: number) => {                                
                                  return (
                                      <td
                                          key={colIndex}
                                          style={columnsToHighLight.includes(filteredColumns[colIndex]) ? highLightColumn : defaultColumn}
                                          className={`${styles.col} font-14`}
                                      >
                                        {headers[colIndex]["Column Name"] === "Attachment"
                                            ? <Tooltip title="Download file">
                                              <div dangerouslySetInnerHTML={{ __html: col }}  />
                                            </Tooltip>
                                            : headers[colIndex]["Column Name"] === "Date" ? formatEmptyCell(extractDate(col)) : formatEmptyCell(extractString(col))}
                                      </td>
                                  );
                                
                              })}
                          <td id="actions" className="text-center">
                            <>
                              <Button
                                  className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                  disabled={isLoading}
                              >
                                <FontAwesomeIcon
                                    icon={["far", "edit"]}
                                    className="font-size-sm"
                                    onClick={() => handleEditResearch(headers, row)}
                                />
                              </Button>
                              <Button
                                  className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                  disabled={isLoading}
                              >
                                <FontAwesomeIcon
                                    icon={["fas", "times"]}
                                    className="font-size-sm"
                                    onClick={() => { handleDeleteResearch(row[0]) }}
                                />
                              </Button>
                            </>
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
              </Table>
            </div>

            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <IconButton
                    disabled={currentPage === 1}
                    onClick={() => goToPage(currentPage - 1)}
                >
                  <ArrowLeft />
                </IconButton>

                <span>
                Page {currentPage} of {totalPages}
              </span>
                <IconButton
                    disabled={currentPage === totalPages}
                    onClick={() => goToPage(currentPage + 1)}
                >
                  <ArrowRight />
                </IconButton>
              </div>
            </div>

            <AddResearchModal
                isOpen={isEditResearchOPen}
                mode="edit"
                setIsOpen={setIsEditResearchOpen}
                researchData={selectedResearchData}
            />
          </div>
        </>
      </>
  );
}
