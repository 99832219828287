import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import ColumnsModal from '../ColumnsModal';
import CreateCompanyTagsModal from '../CreateCompanyTagsModal';
import ColumnsCompaniesModal from '../ColumnsCompaniesModal';
import CreateSectorsModal from '../CreateSectorsModal';

export default function CompaniesTableActions({ 
    columns,
    selectedColumns, 
    handleSelectColumns 
}) {
    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);
    const [isAddTagsModalOpen, setIsAddTagsModalOpen] = useState(false);
    const [isAddSectorsModalOpen, setIsAddSectorsModalOpen] = useState(false);

    return (
        <>
            {/*<div className="d-flex justify-content-between align-items-center pb-4">*/}
            {/*    <div className={styles.actions}>*/}
                    <Button
                        size="small"
                        onClick={() => setIsColumnsModalOpen(!isColumnsModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">columns</span>
                    </Button>
                    <Button
                        size="small"
                        onClick={() => setIsAddTagsModalOpen(!isAddTagsModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">tags</span>
                    </Button>
                    <Button
                        size="small"
                        onClick={() => setIsAddSectorsModalOpen(!isAddSectorsModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">sectors</span>
                    </Button>

            <ColumnsCompaniesModal  
                isOpen={isColumnsModalOpen} 
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
            />

            <CreateCompanyTagsModal 
                isOpen={isAddTagsModalOpen}
                setIsOpen={setIsAddTagsModalOpen}
            />   

            <CreateSectorsModal 
                isOpen={isAddSectorsModalOpen}
                setIsOpen={setIsAddSectorsModalOpen}
            />
        </>
    );
}
