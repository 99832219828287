import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {  
  getTroubleBreakingIn,
  getCoverageChartData,
  getHygieneChartData,
  getTopMainData,
  getDashboardInvestors,
  getCategoryCoverage,
  getActivityData,
  getPersonalDashboardName,
  getActiveCompanies,
  getCoverageScore
} from './dashboardThunks';

interface DashboardState {
  troubleBreakingIn: any;
  dashboardInvestors: any;
  coverageChart: any;
  hygieneChart: any;
  categoryCoverage: any;
  activeCompanies: any;
  topMain: any;
  activityData: any;
  isLoading: boolean;
  personalDashboardName: any;
  coverageScore: any;
}

const initialState: DashboardState = {
  troubleBreakingIn: {rows: [], columns: [], isLoading: false},
  coverageChart: {data: [], isLoading: false},
  dashboardInvestors: {rows: [], columns: [], isLoading: false},
  categoryCoverage: {rows: [], columns: [], isLoading: false},
  activeCompanies: {rows: [], columns: [], isLoading: false},
  hygieneChart: {data: [], isLoading: false},
  topMain: {data: [], isLoading: false},
  activityData: {data: [], isLoading: false},
  personalDashboardName: null,
  coverageScore: {data: [], isLoading: false},
  isLoading: false
};

const dashboardSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTroubleBreakingIn.pending, (state) => {
        state.troubleBreakingIn.isLoading = true;
      })
      .addCase(
        getTroubleBreakingIn.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.troubleBreakingIn = action.payload;
          state.troubleBreakingIn.isLoading = false;
        }
      )
      .addCase(getTroubleBreakingIn.rejected, (state) => {
        state.troubleBreakingIn = false;
      })
      .addCase(getDashboardInvestors.pending, (state) => {
        state.dashboardInvestors.isLoading = true;
      })
      .addCase(
        getDashboardInvestors.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.dashboardInvestors = action.payload;
          state.dashboardInvestors.isLoading = false;
        }
      )
      .addCase(getDashboardInvestors.rejected, (state) => {
        state.dashboardInvestors = false;
      })
      .addCase(getCategoryCoverage.pending, (state) => {
        state.categoryCoverage.isLoading = true;
      })
      .addCase(
        getCategoryCoverage.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.categoryCoverage = action.payload;
          state.categoryCoverage.isLoading = false;
        }
      )
      .addCase(getCategoryCoverage.rejected, (state) => {
        state.categoryCoverage = false;
      })
      .addCase(getActiveCompanies.pending, (state) => {
        state.activeCompanies.isLoading = true;
      })
      .addCase(
        getActiveCompanies.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.activeCompanies = action.payload;
          state.activeCompanies.isLoading = false;
        }
      )
      .addCase(getActiveCompanies.rejected, (state) => {
        state.activeCompanies = false;
      })
      .addCase(getCoverageChartData.pending, (state) => {
        state.coverageChart.isLoading = true;
      })
      .addCase(
        getCoverageChartData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.coverageChart.data = action.payload;
          state.coverageChart.isLoading = false;
        }
      )
      .addCase(getCoverageChartData.rejected, (state) => {
        state.coverageChart.isLoading = false;
      })
      .addCase(getHygieneChartData.pending, (state) => {
        state.hygieneChart.isLoading = true;
      })
      .addCase(
        getHygieneChartData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.hygieneChart.data = action.payload;
          state.hygieneChart.isLoading = false;
        }
      )
      .addCase(getHygieneChartData.rejected, (state) => {
        state.hygieneChart.isLoading = false;
      })
      .addCase(getTopMainData.pending, (state) => {
        state.topMain.isLoading = true;        
      })
      .addCase(
        getTopMainData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.topMain.data = action.payload;
          state.topMain.isLoading = false;
        }
      )
      .addCase(getTopMainData.rejected, (state) => {
        state.topMain.isLoading = false;
      })
      .addCase(getActivityData.pending, (state) => {
        state.activityData.isLoading = true;
      })
      .addCase(
        getActivityData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.activityData.data = action.payload;
          state.activityData.isLoading = false;
        }
      )
      .addCase(getActivityData.rejected, (state) => {
        state.activityData.isLoading = false;
      })
      .addCase(getPersonalDashboardName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getPersonalDashboardName.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.personalDashboardName = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(getPersonalDashboardName.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCoverageScore.pending, (state) => {
        state.coverageScore.isLoading = true;
      })
      .addCase(
        getCoverageScore.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.coverageScore.data = action.payload;
          state.coverageScore.isLoading = false;
        }
      )
      .addCase(getCoverageScore.rejected, (state) => {
        state.coverageScore.isLoading = false;
      })
  },
});

export const selectTroubleBreakingIn = (state: RootState) => state.dashboard.troubleBreakingIn;
export const selectDashboardInvestors = (state: RootState) => state.dashboard.dashboardInvestors;
export const selectCategoryCoverage = (state: RootState) => state.dashboard.categoryCoverage;
export const selectActiveCompanies = (state: RootState) => state.dashboard.activeCompanies;
export const selectCoverageChart = (state: RootState) => state.dashboard.coverageChart;
export const selectHygieneChart = (state: RootState) => state.dashboard.hygieneChart;
export const selectTopMainData = (state: RootState) => state.dashboard.topMain;
export const selectActivityData = (state: RootState) => state.dashboard.activityData;
export const selectPersonalDashboardName = (state: RootState) => state.dashboard.personalDashboardName;
export const selectCoverageScore = (state: RootState) => state.dashboard.coverageScore;

export default dashboardSlice.reducer;
