import React from 'react';
import { formatNumberInMillions, numberWithCommas } from '../../../../utils/dataGrouping';
import {makeStyles} from "@material-ui/core/styles";
import {subTableColorMapping} from "../configs";

const useStyles = makeStyles(() => ({
    backlog: {
        color: subTableColorMapping['Backlog / Pursuing']
    },
    relationship: {
        color: subTableColorMapping['Relationship']
    },
    lowPriority: {
        color: subTableColorMapping['Low Priority / DNP']
    },
    portco: {
        color: subTableColorMapping['Portcos']
    },
    relative: {
        position: 'relative',
        justifyContent: 'space-evenly',
        fontSize: '18px',
    },
}))


const CompaniesInvestment = ({ totalCCount, totalInvestedCount }) => {
    return <>
        <td className="text-center">
            <div className="badge badge-neutral-first text-first px-4">
                {numberWithCommas(totalCCount)}
            </div> <br/>
            <div className="badge badge-neutral-second text-first px-4">
                {formatNumberInMillions(totalInvestedCount)}
            </div> <br/>
        </td>
    </>
}

const SubTableHead = () => {
    const classes = useStyles();
    return <>
        <div className={`d-flex text-nowrap font-12 ${classes.relative}`}>
            <div className={`${classes.subTableColumn} pr-2`}>
                <div className={classes.backlog}>Backlog / Pursuing</div>
                <div className='divider'></div>
                <div className={classes.relationship}>Relationship</div>
            </div>
            <div className={`${classes.subTableColumn} pl-2`}>
                <div className={classes.lowPriority}>Low Priority / DNP</div>
                <div className='divider'></div>
                <div className={classes.portco}>Portcos</div>
            </div>
        </div>
    </>
}

const SubTableBody = ({ modifiedSubTable, item}) => {
    const classes = useStyles();
    return <>
        <div className={`d-flex text-nowrap font-weight-bold ${classes.relative} font-12`}>
            <div className={`${classes.subTableColumn} pr-2`}>
                <div className={classes.backlog}>{item?.['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos']?.split(',')?.[modifiedSubTable['Backlog / Pursuing']]}</div>
                <div className='divider'></div>
                <div className={classes.relationship}>{item?.['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos']?.split(',')?.[modifiedSubTable['Releationship']]}</div>
            </div>
            {/*<div className={`divider-v ${classes.dividerPosition}`}></div>*/}
            <div className={`${classes.subTableColumn} pl-2`}>
                <div className={classes.lowPriority}>{item?.['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos']?.split(',')?.[modifiedSubTable['Low Priority / DNP']]}</div>
                <div className='divider'></div>
                <div className={classes.portco}>{item?.['Backlog / Pursuing, Low Priority / DNP, Releationship, Portcos']?.split(',')?.[modifiedSubTable['Portcos']]}</div>
            </div>
        </div>
    </>
}

export { CompaniesInvestment, SubTableHead, SubTableBody };
