import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface InvestorsPeopleProps {
  data: any;
}

const InvestorsPeople = ({ data }: InvestorsPeopleProps) => {
  const [investorsTags, setInvestorsTags] = useState([]);
  const [peopleTags, setPeopleTags] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const investorArr = data?.[0]?.["Investors"]?.split(",");
      const peopleArr = data?.[0]?.["Key Investors"]?.split(",");
      setInvestorsTags(investorArr);
      setPeopleTags(peopleArr);
    }
  }, [data]);

  return (
    <>
      <div className={styles.investorPeopleTitleContainer}>
        <p className={`${styles.investorPeopleText} font-14 font-weight-bold`}>Investors</p>
      </div>
      <div className={styles.singleSection}>
        <div>
          <p className={`${styles.investorPeopleSubText} mt-0 font-12`}>Key Investors</p>
          <div className={styles.investorPeopleSubSection}>
            {peopleTags?.map((item: string) => (
              <p className={`${styles.investorPeopleTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.investorPeopleSubText} font-12`}>Investors</p>
          <div className={styles.investorPeopleSubSection}>
            {investorsTags?.map((item: string) => (
              <p className={`${styles.investorPeopleTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorsPeople;
