import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  Card,
  Button
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    border0: {
        background: 'yellow',
    },
}));

export default function Accordion({ title, children }) {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const classes = useStyles();

    return (
        <>
            <div className="accordion mb-2">
              <Card className={clsx('card-box', { 'panel-open': isOpen })}>
                <Card>
                  <div className={`${classes.border0} card-header`}>
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={toggle}>
                          <span>{title}</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={isOpen}>
                    <div>
                      {children}
                    </div>
                  </Collapse>
                </Card>
              </Card>
            </div>
        </>
    );
}
