import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStatuses } from '../store/sourcing/sourcingThunks';

const useStatuses = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStatuses());
    }, []);
}

export { useStatuses };